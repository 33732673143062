function misc(state ={isLoading: false}, action) {
    switch (action.type) {
        case 'GET_ENUMERATION_REQUEST':
            return {...state, isLoading: true} 
        case 'GET_ENUMERATION_SUCCESS':
            console.log("GET_ENUMERATION_SUCCESS", action.data);
            const newEnum = {...state.enum, ...action.data}
            return {...state, isLoading: false, enum: newEnum} 
        case 'GET_ENUMERATION_FAILURE':
            console.log("GET_ENUMERATION_FAILURE", action.data);
            return {...state, isLoading: false}
        case 'SET_ALERT_REQUEST':
            console.log('SET_ALERT_REQUEST !#$!@!@#$!@#$!@#$ -> ',action);
            let currAlerts = (state.alerts && JSON.parse(JSON.stringify(state.alerts))) || {}
            if (action.reqBody) {
                currAlerts[action.reqBody.alertType] = action.reqBody.alertMessage
            }
            return {...state, isLoading: false, alerts: currAlerts}
        case 'CLEAR_ALERT_REQUEST':
            let nowAlerts = (state.alerts && JSON.parse(JSON.stringify(state.alerts))) || {}
            if (action.reqBody) {
                nowAlerts[action.reqBody.alertType] = ""
            }
            return {...state, isLoading: false, alerts: nowAlerts}
      default:
        return state
    }
  }

export default misc