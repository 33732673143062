function payoutsInvoices(state ={isLoading: false}, action) {
    switch (action.type) {
        // PAYOUTS
        case 'SEARCH_PAYOUTS_REQUEST':
            return {...state, isLoading: true} 
        case 'SEARCH_PAYOUTS_SUCCESS':
            console.log('SEARCH_PAYOUTS_SUCCESS', action);
            // const payouts_hits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            // const search_after_payouts = payouts_hits.length > 0 ? payouts_hits[payouts_hits.length - 1].sort : [];
            // const totalHitsPayouts = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            // const PayoutsHitsSource = payouts_hits.map((eachHit) => eachHit['_source'])
            const queryPayoutsByStatus = (action.data && action.data.data && action.data.data.queryPayoutsByStatus) || null
            const hits = queryPayoutsByStatus ? queryPayoutsByStatus.items ? queryPayoutsByStatus.items : [] : []
            const nextToken = queryPayoutsByStatus ? queryPayoutsByStatus.nextToken : undefined
            return {...state,
                payouts: hits,
                nextToken: nextToken,
                isLoading: false
            }
        case 'SEARCH_PAYOUTS_FAILURE':
            console.log('POST_SEARCH_BALANCES_FAILURE', action);
            return {...state, isLoading: false}
        case 'SEARCH_AFTER_PAYOUTS_REQUEST':
            return {...state, isLoading: true}
        case 'SEARCH_AFTER_PAYOUTS_SUCCESS':
            console.log('SEARCH_AFTER_PAYOUTS_SUCCESS', action);
            // const nextPayoutHits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            // const totalPayoutHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            // const next_search_after_payouts = nextPayoutHits.length > 0 ? nextPayoutHits[nextPayoutHits.length - 1].sort : [];
            // const nextPayoutsHitsSource = nextPayoutHits.map((eachHit) => eachHit['_source'])
            // const combinedPayoutsHits = [...state.payouts]
            // const combinedPayoutIDs = {};
            // combinedPayoutsHits.forEach((eachHit) => combinedPayoutIDs[eachHit.id] = true)
            // nextPayoutsHitsSource.forEach((newHit) => {
            //     if (!combinedPayoutIDs[newHit.id]) {
            //         combinedPayoutsHits.push(newHit)
            //     }
            // })
            const queryNextPayoutsByStatus = (action.data && action.data.data && action.data.data.queryPayoutsByStatus) || null
            const nextHits = queryNextPayoutsByStatus ? queryNextPayoutsByStatus.items ? queryNextPayoutsByStatus.items : [] : []
            const nextNextToken = queryNextPayoutsByStatus ? queryNextPayoutsByStatus.nextToken : undefined
            const combinedHits = state.payouts.concat(nextHits);
            return {...state,
                payouts: combinedHits,
                nextToken: nextNextToken,
                isLoading: false
            }
        case 'SEARCH_AFTER_PAYOUTS_FAILURE':
            return {...state, isLoading: false}
        case 'POST_CREATE_PAYOUT_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_CREATE_PAYOUT_SUCCESS':
            const currentPayouts = state.payouts || []
            console.log('POST_CREATE_PAYOUT_SUCCESS', action.data);
            const newPayout = action.data || {}
            if (!newPayout.id) return {...state, isLoading: false}
            return {...state,
                isLoading: false,
                payouts: [...currentPayouts, newPayout]
            }
        case 'POST_CREATE_PAYOUT_FAILURE':
            return {...state, isLoading: false}
        case 'PATCH_PAYOUT_REQUEST':
        case 'PATCH_PAYOUT_APPROVAL_REQUEST':
        case 'PATCH_PAYOUT_CANCEL_REQUEST':
        case 'PATCH_PAYOUT_COMPLETE_REQUEST':
            return {...state, isLoading: true}
        case 'PATCH_PAYOUT_SUCCESS':
        case 'PATCH_PAYOUT_APPROVAL_SUCCESS':
        case 'PATCH_PAYOUT_CANCEL_SUCCESS':
        case 'PATCH_PAYOUT_COMPLETE_SUCCESS':
            console.log('PATCH_PAYOUT_SUCCESS', action);
            let targetPatchedPayout = (action && action.data) || {}
            const patchPayoutHits = state.payouts.map((eachHit) => {
                if (eachHit.id === targetPatchedPayout.id) return targetPatchedPayout
                return eachHit
            })
            return {...state, isLoading: false, payouts: patchPayoutHits}
        case 'PATCH_PAYOUT_FAILURE':
        case 'PATCH_PAYOUT_APPROVAL_FAILURE':
        case 'PATCH_PAYOUT_CANCEL_FAILURE':
        case 'PATCH_PAYOUT_COMPLETE_FAILURE': 
            return {...state, isLoading: false}
        case 'CLEAR_PAYOUTS':
            return {...state, isLoading: false, payouts: []}
            // INVOICES
        case 'SEARCH_INVOICES_REQUEST':
            return {...state, isLoading: true} 
        case 'SEARCH_INVOICES_SUCCESS':
            console.log('SEARCH_INVOICES_SUCCESS', action);
            const queryInvoicesByOrganizationId = (action.data && action.data.data && action.data.data.queryInvoicesByOrganizationId) || null
            let invoices = queryInvoicesByOrganizationId ? (queryInvoicesByOrganizationId.items ? queryInvoicesByOrganizationId.items : []) : []
            const invoicesNextToken = queryInvoicesByOrganizationId ? queryInvoicesByOrganizationId.nextToken : undefined
            return {...state,
                invoices: invoices,
                invoiceNextToken: invoicesNextToken,
                isLoading: false
            }
        case 'SEARCH_INVOICES_FAILURE':
            return {...state, isLoading: false}
        case 'SEARCH_AFTER_INVOICES_REQUEST':
            return {...state, isLoading: true}
        case 'SEARCH_AFTER_INVOICES_SUCCESS':
            console.log('SEARCH_AFTER_INVOICES_SUCCESS', action);
            const nextInvoiceHits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const totalInvoiceHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const next_search_after_invoices = nextInvoiceHits.length > 0 ? nextInvoiceHits[nextInvoiceHits.length - 1].sort : [];
            const nextInvoicesHitsSource = nextInvoiceHits.map((eachHit) => eachHit['_source'])
            const combinedInvoicesHits = [...state.payouts]
            const combinedInvoiceIDs = {};
            combinedInvoicesHits.forEach((eachHit) => combinedInvoiceIDs[eachHit.id] = true)
            nextInvoicesHitsSource.forEach((newHit) => {
                if (!combinedInvoiceIDs[newHit.id]) {
                    combinedInvoicesHits.push(newHit)
                }
            })

            const nextQueryInvoicesByOrganizationId = (action.data && action.data.data && action.data.data.queryInvoicesByOrganizationId) || null
            const nextInvoicesHits = nextQueryInvoicesByOrganizationId ? nextQueryInvoicesByOrganizationId.items ? nextQueryInvoicesByOrganizationId.items : [] : []
            const invoiceNextNextToken = nextQueryInvoicesByOrganizationId ? nextQueryInvoicesByOrganizationId.nextToken : undefined
            const invoiceCombinedHits = state.hits.concat(nextInvoicesHits);
            return {...state,
                invoices: invoiceCombinedHits,
                invoiceNextToken: invoiceNextNextToken,
                isLoading: false
            }
        case 'SEARCH_AFTER_INVOICES_FAILURE':
            return {...state, isLoading: false}
        case 'POST_CREATE_INVOICE_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_CREATE_INVOICE_SUCCESS':
            const currentInvoices = state.invoices || []
            console.log('POST_CREATE_PAYOUT_SUCCESS', action.data);
            const newInvoice = action.data || {}
            if (!newInvoice.id) return {...state, isLoading: false}
            return {...state,
                isLoading: false,
                invoices: [...currentInvoices, newInvoice]
            }
        case 'POST_CREATE_INVOICE_FAILURE':
            return {...state, isLoading: false}
        case 'PATCH_INVOICE_REQUEST':
        case 'PATCH_INVOICE_CANCEL_REQUEST':
        case 'PATCH_INVOICE_COMPLETE_REQUEST':
            return {...state, isLoading: true}
        case 'PATCH_INVOICE_SUCCESS':
        case 'PATCH_INVOICE_CANCEL_SUCCESS':
        case 'PATCH_INVOICE_COMPLETE_SUCCESS':
            console.log('PATCH_INVOICE_SUCCESS', action);
            let targetPatchedInvoice = (action && action.data) || {}
            const patchInvoiceHits = state.invoices.map((eachHit) => {
                if (eachHit.id === targetPatchedInvoice.id) return targetPatchedInvoice
                return eachHit
            })
            return {...state, isLoading: false, invoices: patchInvoiceHits}
        case 'PATCH_INVOICE_FAILURE':
        case 'PATCH_INVOICE_CANCEL_FAILURE':
        case 'PATCH_INVOICE_COMPLETE_FAILURE':
            return {...state, isLoading: false}
        case 'CLEAR_INVOICES':
            return {...state, isLoading: false, invoices: []}
        case 'GET_INVOICE_REQUEST':
            return {...state, isLoading: true} 
        case 'GET_INVOICE_SUCCESS':
            console.log('GET_INVOICE_SUCCESS', action);
            let targetFetchedInvoice = (action && action.data) || {}
            const renewedInvoiceHits = state.invoices.map((eachHit) => {
                if (eachHit.id === targetFetchedInvoice.id) return targetFetchedInvoice
                return eachHit
            })
            return {...state, isLoading: false, invoices: renewedInvoiceHits }
        case 'GET_INVOICE_FAILURE':
            return {...state, isLoading: false}
      default:
        return state
    }
  }

export default payoutsInvoices