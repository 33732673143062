import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import rocket from '../../src/rocket.png';
import Slide from '@material-ui/core/Slide';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import { TrendingUpRounded } from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchApi as fetchTxnAppSyncApi } from '../app/utils/callTxnAppSyncApi';
import { fetchApi as fetchLedgerAppSyncApi } from '../app/utils/callLedgerAppSyncApi';
import GoogleMaps from './GoogleMaps'
import { FormHelperText } from '@material-ui/core';
import ReactJson from 'react-json-view'


const currencyFormatter = require('currency-formatter');

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const axios = require('axios');
const AppBarMod = withStyles({
    root: {
        '&.MuiPaper-elevation4': {
            boxShadow: 'none',
        },
        '&.MuiAppBar-colorDefault': {
            backgroundColor: '#ffffff'
        },
    },
})(AppBar);


const ButtonMod = withStyles({
    root: {
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);


const DateTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const OutlinedInputPayment = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '11px 14px',
        }
    },
})(OutlinedInput);

const InputGreen = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(OutlinedInput);



const useStyles = makeStyles(theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    label: {
        color: '#7f7f7f'
    },
    poslist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    dialogTitle: {
        color: '#0c3336',
        fontSize: '1.7em'
    },
    dialogBold: {
        color: '#68d0af',
        fontWeight: '700'
    },
    paymentDetailAccordion: {
        width: '100%',
        marginBottom: '1em'
    },
    txnLogGrid: {
        margin: 0
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        },
        '& .MuiTablePagination-caption': {
            display: 'none'
        },
    }
}));
  const txnStates = ['CREATED', 'DECLINED', 'REVERSED', 'EXPIRED', 'PENDING_CAPTURE', 'PENDING_SETTLEMENT', 'SETTLED', 'PENDING_REFUND', 'REFUNDED', 'PENDING_AUTHORIZATION']
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

const PaymentsReport = (props) => {
    const classes = useStyles(props)
    const { toggleDrawer, openDrawer, toggleAppbarWhiteFont, fetchOrganization, currencies,
        getEnumeration, organization, GetOrgPos, organizationPos, orgPmtSvcAccs, GetOrgAccounts, 
        postPaymentsSearch, nextToken, hits, postPaymentsSearchAfter, clearPaymentsSearch,
        environment, updatePaymentsSearch
    } = props
    const [loading, SetLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    const [pos, setPOS] = useState("");
    const [pmtId, setPmtId] = useState('');
    const [states, setStates] = useState('');
    const [amtFrom, setAmtFrom] = useState('');
    const [amtTo, setAmtTo] = useState('');
    // Later just have to Make a button to change the UTC +X
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [accounts, setAccounts] = useState("");
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [page, setPage] = React.useState(1);
    const [captureAmount, setCaptureAmount] = useState(0);
    const [refundAmount, setRefundAmount] = useState(0);
    const [loadedPage, setLoadedPage] = React.useState(1);
    const [paymentDetail, setPaymentDetail] = React.useState({});
    const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
    const closeDiaLog = () => {
        setPaymentDetail({})
        // removePaymentDetail()
        setDialogIsOpen(false)
    }
    const theme = useTheme();
    const getAmount = (params) => {
        // console.log('getAmount params !~!@#~!@#!~@#!@#!~@#!~@# ', params);
        if ((params && params.row && params.row.currency) && (params && params.row && params.row.amount)) {
            return currencyFormat(params.row.currency, params.row.amount)
        } else {
            return ''
        }
    }

    const constructSearchReqBody = (passedCurrency="SGD", passedPageSize, restartSearch=false) => {
        let targetCurrency = currency || passedCurrency
        // if (!restartSearch && search_after.length > 0) {
        //     initialStruct.from = -1
        //     initialStruct.search_after = search_after
        // }
        let initialStruct = {}
        // DATE QUERY
        let dateQuery = ""
        if (dateFrom || dateTo) {
            dateQuery += "{"
            if (!dateFrom || !dateTo) {
                if (dateFrom) dateQuery += `ge: "${dateFrom}" `
                if (dateTo) dateQuery += `le: "${dateTo}"`
            } else if (dateFrom && dateTo) {
                dateQuery += `between: ["${dateFrom}", "${dateTo}"]`
            }
            dateQuery += "}"
        }
        // AMUNT QUERY
        let amountQuery = ""
        if (amtTo || amtFrom) {
            amountQuery += "{"
            if (amtFrom) amountQuery += `ge: ${amtFrom},`
            if (amtTo) amountQuery += `le: ${amtTo}`
            amountQuery += "}"
        }
        // POS ID QUERY
        let posQuery = ""
        if (pos) {
            posQuery += "{"
            posQuery += `eq: "${pos}"`
            posQuery += "}"
        }
        // STATE QUERY
        let stateQuery = ""
        if (states) {
            stateQuery += "{"
            stateQuery += `eq: "${states}"`
            stateQuery += "}"
        }
        // PMT ACCT QUERY
        let pmtAcctQuery = ""
        if (accounts) {
            pmtAcctQuery += "{"
            pmtAcctQuery += `eq: "${accounts}"`
            pmtAcctQuery += "}"
        }

        let query = ""
        if (pmtId && pos) {
            query = `
                {
                    getPayments(id: "${pmtId}", posId: "${pos}") 
                    { 
                        amount
                        organizationId
                        currency
                        createdAt
                        amountCaptured
                        accountId
                        amountRefunded
                        authCode
                        customerId
                        id
                        paymentMethod {
                            type
                            card {
                                data {
                                    iin
                                    last4
                                    scheme
                                }
                                expiry {
                                    month
                                    year
                                }
                                name
                            }
                            id
                        }
                        posId
                        routeId
                        source
                        state
                        updatedAt
                        merchantData
                    }
                }
            `
        } else {
            query = `
                query listPayments($organizationId: String) {
                    queryPaymentsByOrganizationId(
                        queryInput: { 
                            organizationId: { 
                                eq: $organizationId 
                            }
                            ${dateQuery ? `createdAt: ${dateQuery}` : ""}
                        }
                        filter: { 
                            currency: { eq: "${targetCurrency}" }
                            ${amountQuery ? `amount: ${amountQuery}` : ""}
                            ${posQuery ? `posId: ${posQuery}` : ""}
                            ${stateQuery ? `state: ${stateQuery}` : ""}
                            ${pmtAcctQuery ? `accountId: ${pmtAcctQuery}` : ""}
                        }
                        nextToken: "${(!restartSearch && nextToken) ? nextToken : ""}",
                    ) 
                    { items { 
                        amount
                        organizationId
                        currency
                        createdAt
                        amountCaptured
                        accountId
                        amountRefunded
                        authCode
                        customerId
                        id
                        paymentMethod {
                            type
                            card {
                                data {
                                    iin
                                    last4
                                    scheme
                                }
                                expiry {
                                    month
                                    year
                                }
                                name
                            }
                            id
                        }
                        posId
                        routeId
                        source
                        state
                        updatedAt
                        merchantData
                    }, nextToken } 
                }
            `
            initialStruct.variables = {
                organizationId: organization.id
            }
        }
        initialStruct.query = query
        return initialStruct
    }
    useEffect(() => {
        if (!openDrawer) toggleDrawer(true)
    },[]);
    useEffect(() => {
        // Check if data of payment detail is still the same as the data from hits
        hits.forEach((singleHit) => {
            if (singleHit.id === paymentDetail.id) {
                let newPaymentDetail = JSON.parse(JSON.stringify(paymentDetail))
                newPaymentDetail.state = singleHit.state
                setPaymentDetail(newPaymentDetail);
            }
        })
    },[hits]);
    useEffect(() => {
        if (organization.id) {
            // console.log(JSON.stringify(organization));
            let targetCurrency = (organization && organization.banks && organization.banks[0].account && organization.banks[0].account.currency) || 'SGD'
            setCurrency(targetCurrency)
            let esSearchReqBody = constructSearchReqBody(targetCurrency, searchSize, true)
            // postPaymentsSearch({organizationId: organization.id}, esSearchReqBody)
            postPaymentsSearch(esSearchReqBody)
            setLoadedPage(1)
        }
    }, [organization.id]);
    useLayoutEffect(() => {
        if (!organization.id) {
            const url = window.location.href
            let urlArr = url.split('/')
            let organizationID = urlArr[urlArr.length - 2]
            // console.log('organizationID -> ', organizationID);
            fetchOrganization({organizationId: organizationID})
            GetOrgAccounts({organizationId: organizationID})
            GetOrgPos({organizationId: organizationID})
        } else {
            GetOrgPos({organizationId: organization.id})
        }
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearPaymentsSearch()
        };
    },[])

    const history = useHistory();

    const currencyFormat = (currency, amount) => {
        if (!currency || !amount) return ''
        const currencyDetails = currencyFormatter.findCurrency(currency);
        let amountString = `${amount}`
        let amountBeforeDecimal = ''
        let amountAfterDecimal = ''
        if (currencyDetails.decimalDigits > 0 && amount !== '0') {
            let count = 0
            for (var i = amountString.length - 1; i >= 0; i--) {
                if (count < currencyDetails.decimalDigits) {
                    amountAfterDecimal = amountString[i] + amountAfterDecimal
                    count++
                } else {
                    amountBeforeDecimal = amountString[i] + amountBeforeDecimal 
                }
            }
            if (currencyDetails.decimalDigits > amountAfterDecimal.length) {
                for (var i = 0; i < currencyDetails.decimalDigits - amountAfterDecimal.length; i++) {
                    amountAfterDecimal = '0' + amountAfterDecimal
                }
            }
        } else {
            amountBeforeDecimal = amountString
        }
        if (!amountBeforeDecimal.length) amountBeforeDecimal = '0'
        // Add in Seperators to amountBeforeDecimal
        let amountBeforeDecimalReverseArr = amountBeforeDecimal.split('').reverse()
        amountBeforeDecimal = ''
        amountBeforeDecimalReverseArr.forEach((digit, index) => {
            if (((index + 1 ) % 3) === 0) {
                amountBeforeDecimal += `${digit}` + `${currencyDetails.thousandsSeparator}`
            } else {
                amountBeforeDecimal += digit
            }
        })
        amountBeforeDecimal = amountBeforeDecimal.split('').reverse().join('')
        if (amountBeforeDecimal[0] === currencyDetails.thousandsSeparator) {
            amountBeforeDecimal = amountBeforeDecimal.slice(1)
        }
        return `${currencyDetails.symbol}${currencyDetails.spaceBetweenAmountAndSymbol === true ? ' ': ''}${amountBeforeDecimal}${currencyDetails.decimalDigits > 0 ? currencyDetails.decimalSeparator : ''}${(amount == '0' && currencyDetails.decimalDigits == 2) ? '00' : amountAfterDecimal}`
    }

    const calculateNet = (paymentDetail) => {
        if (!paymentDetail.currency || !paymentDetail.amount) return ''
        let netAmount = parseInt(paymentDetail.amount)
        if (paymentDetail && paymentDetail.paymentLogs) {
            paymentDetail.paymentLogs.forEach((txnLog) => {
                if (txnLog &&txnLog.fee && txnLog.fee.amount) {
                    let fees = parseInt(txnLog.fee.amount)
                    netAmount -= fees
                }
            })
        }
        return currencyFormat(paymentDetail.currency, `${netAmount}`)
    }

    const usePreviousToken = (value) => {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    // const prevToken = usePreviousToken(nextToken);
    useEffect(() => {
        // if(prevToken.nextToken) {

        //  // process here
        // }
        if (nextToken && (hits && hits.length === 0)) {
            let esSearchReqBody = constructSearchReqBody(currency, searchSize, false);
            postPaymentsSearchAfter(esSearchReqBody)
        }
    }, [nextToken])

    return (
    <main className={classes.topMargin}>
    <Typography variant="h4" className={classes.Title}>Payments</Typography>
    <AppBarMod position="static" color="default"></AppBarMod>
    <Container>
        <div className={classes.poslist}>
            <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={currency || 'Select Currency'}
                                onChange={(e) => {
                                    setCurrency(e.target.value)
                                }}
                                label="Currency"
                            >
                                {
                                    currencies && currencies.length > 0 && currencies.map((curr, index) => {
                                        return <MenuItem key={index} value={curr.value.currency_alpha3_code}>{curr.name}</MenuItem>
                                    })
                                }
                                <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Payment ID</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setPmtId(e.target.value)} defaultValue={pmtId}/>
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Status</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={states || 'Select Payment Status'}
                                onChange={(e) => {
                                    setStates(e.target.value)
                                }}
                                label={`Payment State`}
                            >
                                {[...txnStates, ""].map((singleState) => (
                                    <MenuItem key={singleState} value={singleState}>
                                        {singleState}
                                    </MenuItem>
                                ))}
                                <MenuItem key={'select_pmt_state'} value={''}>Select Payment Status</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Amount From (In lowest denominator e.g. cents, 100 for $1.00)</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setAmtFrom(e.target.value)} defaultValue={amtFrom}/>
                        </Grid>
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Amount To (In lowest denominator e.g. cents, 100 for $1.00)</Typography>
                            <OutlinedInputMod fullWidth onChange={(e) => setAmtTo(e.target.value)} defaultValue={amtTo}/>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Date From</Typography>
                            <DateTextField
                                id="datefrom"
                                type="datetime-local"
                                onChange={(e) => {
                                    // console.log('e.target.value -> ', e.target.value)
                                    let date = new Date(e.target.value)
                                    // console.log('local datetime -> ', date.toString())
                                    // console.log('utc datetime -> ', date.toUTCString())
                                    // console.log('iso datetime -> ', date.toISOString())
                                    setDateFrom(date.toISOString())
                                }}
                                defaultValue={dateFrom || "2017-05-24T10:30"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item md={6} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Date To</Typography>
                            <DateTextField
                                id="dateTo"
                                type="datetime-local"
                                onChange={(e) => {
                                    // console.log('e.target.value -> ', e.target.value)
                                    let date = new Date(e.target.value)
                                    // console.log('local datetime -> ', date.toString())
                                    // console.log('utc datetime -> ', date.toUTCString())
                                    // console.log('iso datetime -> ', date.toISOString())
                                    setDateTo(date.toISOString())
                                }}
                                defaultValue={dateTo || "2017-05-24T10:30"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            </div>
            <ButtonMod onClick={() => {
                setPage(1);
                setLoadedPage(1)
                setSearchFrom(0);
                let esSearchReqBody = constructSearchReqBody(currency, null, true)
                // console.log('PLEASE DONT FIRE! esSearchReqBody : ', esSearchReqBody);
                // postPaymentsSearch({organizationId: organization.id}, esSearchReqBody)
                postPaymentsSearch(esSearchReqBody)
            }}>Search</ButtonMod>&nbsp;<ButtonMod onClick={async() => {
                if (hits && hits.length) {
                    console.log('hits.length : ', hits.length);
                    let completePaymentsInfo = await Promise.all(hits.map(async (eachPmt) => {
                        const pmtTxnResults = await fetchTxnAppSyncApi(environment,
                            '/',
                            'post',
                            null,
                            {
                                query: `
                                    query listTransactions {
                                        queryTransactionsByPOSPaymentId(queryInput: {posPaymentId: {eq: "${eachPmt.posId}|${eachPmt.id}"}}) {
                                            items {
                                                account {
                                                    id
                                                    processor
                                                }
                                                amount
                                                createdAt
                                                currency
                                                id
                                                parentTransaction {
                                                    id
                                                }
                                                paymentId
                                                posId
                                                processor {
                                                    authCode
                                                    isAutoCaptured
                                                    status
                                                    transactionId
                                                }
                                                sourceIp
                                                status
                                                type
                                                updatedAt
                                            }
                                        }
                                    }
                                `,
                                variables: { organizationId: organization.id }
                        })
                        let txnResults = (pmtTxnResults && pmtTxnResults.data && pmtTxnResults.data.data && pmtTxnResults.data.data.queryTransactionsByPOSPaymentId && pmtTxnResults.data.data.queryTransactionsByPOSPaymentId.items) || [];
                        if (txnResults.length) {
                            // GET LEDGER
                            txnResults = await Promise.all(txnResults.map(async(eachTxn) => {
                                const txnLedgerResult = await fetchLedgerAppSyncApi(environment,
                                    '/',
                                    'post',
                                    null,
                                    {
                                        query: `
                                            query listLedgers {
                                                queryLedgerByPOSPaymentTransactionId(
                                                    queryInput: {
                                                        posPaymentTransactionId: {
                                                            eq: "${eachTxn.posId}|${eachTxn.paymentId}|${eachTxn.id}"
                                                        }
                                                    }
                                                ) {
                                                    items {
                                                        fee {
                                                            amount
                                                            contractId
                                                            type
                                                        }
                                                        accountId
                                                        createdAt
                                                        currency
                                                        posPaymentTransactionId
                                                        type
                                                    }
                                                }
                                            }
                                        `,
                                        variables: { organizationId: organization.id }
                                })
                                let ledgerResults = (txnLedgerResult && txnLedgerResult.data && txnLedgerResult.data.data && txnLedgerResult.data.data.queryLedgerByPOSPaymentTransactionId && txnLedgerResult.data.data.queryLedgerByPOSPaymentTransactionId.items) || [];
                                if (ledgerResults.length) {
                                    ledgerResults.map((eachLedger) => {
                                        if (eachLedger && eachLedger.type === 'BILLING') {
                                            eachTxn.fee = eachLedger.fee
                                        }
                                        return eachLedger
                                    })
                                }
                                return eachTxn
                            }))
                            let unique_sourceIp = []
                            txnResults = txnResults.map((eachTxn) => {
                                if (unique_sourceIp.indexOf(eachTxn.sourceIp) < 0) {
                                    unique_sourceIp.push(eachTxn.sourceIp)
                                    return eachTxn
                                } else {
                                    return eachTxn
                                }
                            }).filter((eachTxn) => eachTxn)

                        }
                        eachPmt.paymentLogs = JSON.parse(JSON.stringify((txnResults)));
                        return eachPmt
                    }))
                    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(completePaymentsInfo));
                    var dlAnchorElem = document.getElementById('downloadAnchorElem');
                    dlAnchorElem.setAttribute("href",     dataStr     );
                    dlAnchorElem.setAttribute("download", "payments.json");
                    dlAnchorElem.click();

                    var dataStr2 = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(hits));
                    dlAnchorElem.setAttribute("href",     dataStr2     );
                    dlAnchorElem.setAttribute("download", "hits.json");
                    dlAnchorElem.click();
                }
            }}>Download Current Payments</ButtonMod>

        </div>
        <div>
            <a id="downloadAnchorElem" style={{ display: 'none'}}></a>
        </div>
        <div className={classes.poslist}>
            <Grid container spacing={3}>
                {/* List of Payments */}
                {/* {`totalHits -> ${totalHits}`}
                {`hits -> ${hits.length}`} */}
                <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                    <XGrid
                        className={classes.xgrid}
                        onPageChange={(event) => {
                            console.log('FIRE FIRE FIRE!!!');
                            // console.log('onPageChange FIRED first part !!', event.page , ' ', loadedPage);
                            // console.log('onPageChange event.page -> ', event.page);
                            if (page >= event.page) return
                            // setPage(event.page);
                            if (event.page <= loadedPage) return
                            // console.log('onPageChange FIRED second part !!', event.page , ' ', loadedPage);
                            setLoadedPage(loadedPage + 1)
                            let esSearchReqBody = constructSearchReqBody(currency, searchSize, false)
                            // postPaymentsSearchAfter({organizationId: organization.id}, esSearchReqBody)
                            console.log('=== NEXT TOKEN ====');
                            console.log(nextToken);
                            if (!nextToken) {
                                return;
                            }

                            console.log('IF EMPTY SHOULD NOT FIRE');
                            postPaymentsSearchAfter(esSearchReqBody)
                        }}
                        hideFooterRowCount
                        rowsPerPageOptions={[10]}
                        page={page}
                        pageSize={13}
                        pagination={true}
                        columns={[
                            { 
                                field: 'amount',
                                headerName: 'Amount',
                                width: 170, 
                                valueGetter: getAmount,
                            },
                            { 
                                field: 'id',
                                headerName: 'Payment ID',
                                width: 350,
                                valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                            },
                            { 
                                field: 'paymentMethod',
                                headerName: 'Payment Method',
                                width: 170,
                                valueGetter: (params) => {
                                    // console.log('params !@#!@#!@#~!#~!@#~!@#!~@#@ ---> ', params);
                                    return `${(params && params.row && params.row.paymentMethod && params.row.paymentMethod.type) || ""}`
                                },
                            },
                            { 
                                field: 'status',
                                headerName: 'Status',
                                width: 170,
                                valueGetter: (params) => `${(params && params.row && params.row.state) || ""}`,
                            },
                            {
                                field: 'date',
                                headerName: 'Date',
                                width: 280,
                                valueGetter: (params) => {
                                    let dateString = ''
                                    if (params && params.row && params.row.createdAt) {
                                        let date = new Date(params.row.createdAt)
                                        dateString = date.toString()
                                        // console.log('utc datetime -> ', date.toUTCString())
                                        // console.log('iso datetime -> ', date.toISOString())
                                    } 
                                    return dateString
                                    // `${(params && params.row && params.row.createdAt) || ""}`
                                },
                            },
                            { 
                                field: 'posId',
                                headerName: 'Point of Sales ID',
                                width: 350,
                                valueGetter: (params) => `${(params && params.row && params.row.posId) || ""}`,
                            },
                        ]}
                        rows={hits}
                        onRowClick={async (rowParams) => {
                            console.log('FIRED ROW CLICK! ->', rowParams);
                            SetLoading(true)
                            try {
                                const pmtTxnResults = await fetchTxnAppSyncApi(environment,
                                    '/',
                                    'post',
                                    null,
                                    // ${rowParams.row.posId}|${rowParams.row.id}
                                    // 5393e478-a49b-4185-ac93-379057e2cb47|a11c5061-2bb8-482e-909f-6166834b782c
                                    {
                                        query: `
                                            query listTransactions {
                                                queryTransactionsByPOSPaymentId(queryInput: {posPaymentId: {eq: "${rowParams.row.posId}|${rowParams.row.id}"}}) {
                                                    items {
                                                        account {
                                                            id
                                                            processor
                                                        }
                                                        amount
                                                        createdAt
                                                        currency
                                                        id
                                                        parentTransaction {
                                                            id
                                                        }
                                                        paymentId
                                                        posId
                                                        processor {
                                                            authCode
                                                            isAutoCaptured
                                                            status
                                                            transactionId
                                                        }
                                                        sourceIp
                                                        status
                                                        type
                                                        updatedAt
                                                    }
                                                }
                                            }
                                        `,
                                        variables: { organizationId: organization.id }
                                })
                                let txnResults = (pmtTxnResults && pmtTxnResults.data && pmtTxnResults.data.data && pmtTxnResults.data.data.queryTransactionsByPOSPaymentId && pmtTxnResults.data.data.queryTransactionsByPOSPaymentId.items) || [];
                                if (txnResults.length) {
                                    // GET LEDGER
                                    txnResults = await Promise.all(txnResults.map(async(eachTxn) => {
                                        const txnLedgerResult = await fetchLedgerAppSyncApi(environment,
                                            '/',
                                            'post',
                                            null,
                                            {
                                                query: `
                                                    query listLedgers {
                                                        queryLedgerByPOSPaymentTransactionId(
                                                            queryInput: {
                                                                posPaymentTransactionId: {
                                                                    eq: "${eachTxn.posId}|${eachTxn.paymentId}|${eachTxn.id}"
                                                                }
                                                            }
                                                        ) {
                                                            items {
                                                                fee {
                                                                    amount
                                                                    contractId
                                                                    type
                                                                }
                                                                accountId
                                                                createdAt
                                                                currency
                                                                posPaymentTransactionId
                                                                type
                                                            }
                                                        }
                                                    }
                                                `,
                                                variables: { organizationId: organization.id }
                                        })
                                        let ledgerResults = (txnLedgerResult && txnLedgerResult.data && txnLedgerResult.data.data && txnLedgerResult.data.data.queryLedgerByPOSPaymentTransactionId && txnLedgerResult.data.data.queryLedgerByPOSPaymentTransactionId.items) || [];
                                        if (ledgerResults.length) {
                                            ledgerResults.map((eachLedger) => {
                                                if (eachLedger && eachLedger.type === 'BILLING') {
                                                    eachTxn.fee = eachLedger.fee
                                                }
                                                return eachLedger
                                            })
                                        }
                                        return eachTxn
                                    }))
                                    let unique_sourceIp = []
                                    txnResults = txnResults.map((eachTxn) => {
                                        if (unique_sourceIp.indexOf(eachTxn.sourceIp) < 0) {
                                            unique_sourceIp.push(eachTxn.sourceIp)
                                            return eachTxn
                                        } else {
                                            return eachTxn
                                        }
                                    }).filter((eachTxn) => eachTxn)

                                }
                                let paymentDetails = JSON.parse(JSON.stringify(rowParams.row));
                                paymentDetails.paymentLogs = JSON.parse(JSON.stringify((txnResults)));
                                setPaymentDetail(JSON.parse(JSON.stringify(paymentDetails)));
                                SetLoading(false)
                                setDialogIsOpen(true)
                            } catch (err) {
                                // console.log(err);
                                SetLoading(false)
                                return err
                            }
                        }}
                        rowCount={nextToken ? 9999999999999 : (hits.length)}
                    />
                </div>
            </Grid>
        </div>
        <Dialog
            fullWidth
            maxWidth={'xl'}
            open={dialogIsOpen}
            onClose={closeDiaLog}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">Payment Details</DialogTitle>
            <DialogContent>
                {/* {`${JSON.stringify(paymentDetail)}`} */}
                <Grid container spacing={3}>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>Payment ID</span> {`${paymentDetail.id}`}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><span className={classes.dialogTitle}>{`${paymentDetail.currency} ${currencyFormat(paymentDetail.currency,paymentDetail.amount)}`}</span></Typography>
                    </Grid>
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Date</b></Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {`${(paymentDetail.createdAt && new Date(paymentDetail.createdAt).toString()) || ""}`}
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom><b>Customer ID</b></Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>{`${paymentDetail.customerId || ""}`}</Typography>
                    </Grid>
                    <Grid item md={3} xs={3} sm={3}>
                        {paymentDetail.paymentMethod && <Typography className={classes.title} color="textSecondary" gutterBottom><b>Payment Method</b></Typography>}
                        {paymentDetail.paymentMethod && paymentDetail.paymentMethod.card && <Typography className={classes.title} color="textSecondary" gutterBottom>{`${paymentDetail.paymentMethod && paymentDetail.paymentMethod.card && paymentDetail.paymentMethod.card.data && paymentDetail.paymentMethod.card.data.scheme + ' '}${(paymentDetail.paymentMethod && paymentDetail.paymentMethod.card && paymentDetail.paymentMethod.card.data && '.... ' + paymentDetail.paymentMethod.card.data.last4) || ""}`}</Typography>}
                    </Grid>
                    <Grid item md={3} xs={3} sm={3}>
                        {/* <Typography className={classes.title} color="textSecondary" gutterBottom><b>Product</b></Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>{`${(paymentDetail.productId) || ""}`}</Typography> */}
                    </Grid>
                    {/* Payment Details */}
                    <Accordion className={classes.paymentDetailAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2a-header"
                        >
                            &nbsp;&nbsp;<Typography className={classes.dialogTitle}>Payment Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.paymentDetailAccordion}>
                            {/* Transaction Logs */}
                            <Grid container spacing={3}>
                                <Grid item md={4} xs={4} sm={4}>Transacted Amount</Grid>
                                <Grid item md={4} xs={4} sm={4}></Grid>
                                <Grid item md={4} xs={4} sm={4}>{`${paymentDetail.currency} ${currencyFormat(paymentDetail.currency, paymentDetail.amount)}`}</Grid>
                                {/* Note this part we have to be clear how will we charge the customer and how to deduce the fees calculation */}
                                {
                                    paymentDetail && paymentDetail.paymentLogs && paymentDetail.paymentLogs.map((txnLog, index) => {
                                        console.log('txnLog -> ', txnLog);
                                        return <Grid key={index} container spacing={3} className={classes.txnLogGrid}>
                                            <Grid item md={4} xs={4} sm={4}>{`${(txnLog && txnLog.type)} FEES`}</Grid>
                                            <Grid item md={4} xs={4} sm={4}>{`${(txnLog && txnLog.status)}`}</Grid>
                                            <Grid item md={4} xs={4} sm={4}>{`${paymentDetail.currency} ${(((txnLog && txnLog.fee) && (txnLog && txnLog.currency)) && ((txnLog && txnLog.fee) && (txnLog && txnLog.amount)) && currencyFormat(txnLog.currency, txnLog.fee.amount)) || currencyFormat(paymentDetail.currency, '0')}`}</Grid>
                                        </Grid>
                                    })
                                }
                                <Grid item md={4} xs={4} sm={4}><b>NET RECEIVABLE</b></Grid>
                                <Grid item md={4} xs={4} sm={4}></Grid>
                                <Grid item md={4} xs={4} sm={4}><b>{`${paymentDetail.currency} ${calculateNet(paymentDetail)}`}</b></Grid>
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.dialogBold}>Status</Typography>
                                </Grid>
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.dialogBold}>
                                        {`${(paymentDetail.state) || "{state}"}`}
                                    </Typography>
                                </Grid>
                                <Grid item md={4} xs={4} sm={4}></Grid>
                                {paymentDetail && paymentDetail.state === "PENDING_CAPTURE" && <Grid item md={6} xs={6} sm={6}>{paymentDetail && paymentDetail.state === "PENDING_CAPTURE" && 
                                    <div>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>WORK IN PROGRESS</Typography>
                                        <OutlinedInputPayment onChange={(e) => setCaptureAmount(e.target.value)} defaultValue={captureAmount}/>&nbsp;
                                        <ButtonMod size={'large'} disabled={loading} onClick={async () => {
                                            SetLoading(true)
                                            try {
                                                // const captureResponse = await fetchApi(environment,
                                                //     '/payments-execution/organizations/:organizationId/pos/:posId/payments/:paymentId/captures',
                                                //     'post',
                                                //     {organizationId: organization.id, posId: paymentDetail.posId, paymentId: paymentDetail.id },
                                                //     {
                                                //         "amount": parseInt(captureAmount),
                                                //     }
                                                // )
                                                // // console.log('captureResponse.data ', captureResponse.data);
                                                // if (captureResponse && captureResponse.data && captureResponse.data.payment && captureResponse.data.payment.state === 'PENDING_SETTLEMENT') {
                                                //     setCaptureAmount(0)
                                                //     // Update Data in Store
                                                //     updatePaymentsSearch(captureResponse.data)
                                                // }
                                            } catch (err) {
                                                // console.log('error : ', err);
                                                SetLoading(false)
                                            }
                                            SetLoading(false)
                                        }}>Capture</ButtonMod>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>(In lowest denominator e.g. cents, 100 for $1.00)</Typography>
                                    </div>}
                                </Grid>}
                                {paymentDetail && paymentDetail.state === "PENDING_CAPTURE" && <Grid item md={6} xs={6} sm={6}>{paymentDetail && paymentDetail.state === "PENDING_CAPTURE" && 
                                    <div><Typography className={classes.title} color="textSecondary" gutterBottom>WORK IN PROGRESS</Typography><ButtonMod size={'large'} disabled={loading} onClick={async () => {
                                        SetLoading(true)
                                        try {
                                            // const voidResponse = await fetchApi(environment,
                                            //     '/payments-execution/organizations/:organizationId/pos/:posId/payments/:paymentId/voids',
                                            //     'post',
                                            //     {organizationId: organization.id, posId: paymentDetail.posId, paymentId: paymentDetail.id },
                                            //     {
                                            //     }
                                            // )
                                            // if (voidResponse && voidResponse.data && voidResponse.data.payment && voidResponse.data.payment.state === 'VOID') {
                                            //     // TODO: Update Data in Store
                                            // }
                                        } catch (err) {
                                            console.log('error : ', err);
                                            SetLoading(false)
                                        }
                                        SetLoading(false)
                                    }}>Void</ButtonMod><Typography className={classes.title} color="textSecondary" gutterBottom>Void This Transaction</Typography></div>}
                                </Grid>}
                                {paymentDetail && paymentDetail.state === "SETTLED" && <Grid item md={6} xs={6} sm={6}>{paymentDetail && paymentDetail.state === "SETTLED" && 
                                    <div>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>WORK IN PROGRESS</Typography>
                                        <OutlinedInputPayment onChange={(e) => setRefundAmount(e.target.value)} defaultValue={refundAmount}/>&nbsp;
                                        <ButtonMod size={'large'} disabled={loading} onClick={async () => { 
                                            SetLoading(true)
                                            try {
                                                // const refundResponse = await fetchApi(environment,
                                                //     '/payments-execution/organizations/:organizationId/pos/:posId/payments/:paymentId/refunds',
                                                //     'post',
                                                //     {organizationId: organization.id, posId: paymentDetail.posId, paymentId: paymentDetail.id },
                                                //     {
                                                //         "amount": parseInt(refundAmount),
                                                //     }
                                                // )
                                                // if (refundResponse && refundResponse.data && refundResponse.data.payment && (refundResponse.data.payment.state === 'PENDING_REFUND' || refundResponse.data.payment.state === 'REFUNDED')) {
                                                //     setRefundAmount(0)
                                                //     //Update Data in Store
                                                //     updatePaymentsSearch(refundResponse.data)
                                                // }
                                            } catch (err) {
                                                // console.log('error : ', err);
                                                SetLoading(false)
                                            }
                                            SetLoading(false)
                                        }}>REFUND</ButtonMod>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>(In lowest denominator e.g. cents, 100 for $1.00)</Typography>
                                    </div>}
                                </Grid>}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {/* Payment Method */}
                    {paymentDetail.paymentMethod && <Accordion className={classes.paymentDetailAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2a-header"
                        >
                            &nbsp;&nbsp;<Typography className={classes.dialogTitle}>Payment Method</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.paymentDetailAccordion}>
                            <Grid container spacing={3}>
                                <Grid item md={4} xs={4} sm={4}>ID</Grid>
                                <Grid item md={4} xs={4} sm={4}>{`${(paymentDetail.paymentMethod && paymentDetail.paymentMethod.id)}`}</Grid>
                                <Grid item md={4} xs={4} sm={4}></Grid>
                                <Grid item md={4} xs={4} sm={4}>Type</Grid>
                                <Grid item md={4} xs={4} sm={4}>{`${(paymentDetail.paymentMethod && paymentDetail.paymentMethod.type)}`}</Grid>
                                <Grid item md={4} xs={4} sm={4}></Grid>
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}>Scheme</Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}>{`${(paymentDetail.paymentMethod  && paymentDetail.paymentMethod.card && paymentDetail.paymentMethod.card.data && paymentDetail.paymentMethod.card.data.scheme)}`}</Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}></Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}>IIN</Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}>{`${(paymentDetail.paymentMethod  && paymentDetail.paymentMethod.card && paymentDetail.paymentMethod.card.data && paymentDetail.paymentMethod.card.data.iin)}`}</Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}></Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}>Last 4</Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}>{`${(paymentDetail.paymentMethod  && paymentDetail.paymentMethod.card && paymentDetail.paymentMethod.card.data && paymentDetail.paymentMethod.card.data.last4)}`}</Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}></Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}>Expiry</Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}>{`${(paymentDetail.paymentMethod  && paymentDetail.paymentMethod.card && paymentDetail.paymentMethod.card && paymentDetail.paymentMethod.card.expiry && paymentDetail.paymentMethod.card.expiry.month)}/ ${(paymentDetail.paymentMethod && paymentDetail.paymentMethod.card && paymentDetail.paymentMethod.card.expiry && paymentDetail.paymentMethod.card.expiry.year)}`}</Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}></Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}>Auth Code</Grid> }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && paymentDetail.paymentLogs.map((eachLog, index) => {
                                    if (eachLog && eachLog.processor && eachLog.processor.authCode) {
                                        return <Grid key={index} item md={4} xs={4} sm={4}>{`${(eachLog.processor.authCode) || '{no authCode}'}`}</Grid>
                                    } else {
                                        return ''
                                    }
                                }) }
                                { paymentDetail.paymentMethod && paymentDetail.paymentMethod.type === 'card' && <Grid item md={4} xs={4} sm={4}></Grid> }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>}
                    {/* Risk Insights */}
                    <Accordion className={classes.paymentDetailAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2a-header" 
                        >
                            &nbsp;&nbsp;<Typography className={classes.dialogTitle}>Risk Insights</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.paymentDetailAccordion}>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12} sm={12}>IP Addresses & Countries</Grid>
                                {paymentDetail && paymentDetail.paymentLogs && paymentDetail.paymentLogs.length && paymentDetail.paymentLogs.map((eachTxn) => {
                                    if (eachTxn && eachTxn.geolocation) {
                                        return <Grid item md={12} xs={12} sm={12}>{eachTxn.sourceIp} - {eachTxn.geolocation && eachTxn.geolocation.country && eachTxn.geolocation.country.names && eachTxn.geolocation.country.names.en}</Grid>
                                    } else {
                                        return ''
                                    }
                                })}
                                {paymentDetail && paymentDetail.paymentLogs && paymentDetail.paymentLogs.length && <Grid item md={12} xs={12} sm={12}>
                                    {paymentDetail && paymentDetail.paymentLogs && paymentDetail.paymentLogs.length && 
                                    <Typography className={classes.dialogTitle}>WORK IN PROGRESS</Typography>
                                    // <GoogleMaps 
                                    //     markers={(paymentDetail.paymentLogs.map((eachTxn) => {
                                    //         return {
                                    //             latitude: eachTxn.geolocation.location.latitude, longitude: eachTxn.geolocation.location.longitude
                                    //         }
                                    //     }))}
                                    //     initialCenter={{lat: paymentDetail.paymentLogs[0].geolocation.location.latitude, lng: paymentDetail.paymentLogs[0].geolocation.location.longitude}}
                                    // />
                                    }
                                </Grid>}
                                {paymentDetail && paymentDetail.paymentLogs && !paymentDetail.paymentLogs.length && <Grid item md={12} xs={12} sm={12}>No IP Address Found</Grid>}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {/* MERCHANT DATA */}
                    {paymentDetail && paymentDetail.merchantData && <Accordion className={classes.paymentDetailAccordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2a-header" 
                        >
                            &nbsp;&nbsp;<Typography className={classes.dialogTitle}>Merchant Data</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.paymentDetailAccordion}>
                            <Grid container spacing={3}>
                                <ReactJson src={JSON.parse(paymentDetail.merchantData)} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>}
                </Grid>
            </DialogContent>
            <Backdrop className={classes.backdrop} open={loading} >
                <CircularProgress className={classes.circularProgress} />
            </Backdrop>
        </Dialog>
    </Container>
    <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress className={classes.circularProgress} />
    </Backdrop>
    </main>
    )
}

export default PaymentsReport