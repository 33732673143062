import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { feeType, customFeeType } from '../app/globals';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Paper from '@material-ui/core/Paper';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const Contracts = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, createContract, currencies,
        contracts, postContractsSearch, postContractsSearchAfter, totalHitsContracts,
        search_after_contracts, updateContract, deleteContract, clearContractsSearch,
        getEnumeration, nextToken
    } = props
    const urlArr = window.location.href.split('/')
    const orgID = urlArr[urlArr.length - 1]
    const [contractDialog, setContractDialog] = useState(false);
    const [newContract, setNewContract] = useState(false);
    const [contractDetails, setContractDetails] = useState({});
    const [newFee, setNewFee] = useState({});
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [searchId, setSearchId] = useState('');
    const [searchVersion, setSearchVersion] = useState('');
    const [searchFees, setSearchFees] = useState([]);
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);
    const [feeCustomFee, setFeeCustomFee] = useState({});

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody()
        postContractsSearch(esSearchReqBody)
        setLoadedPage(1)
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearContractsSearch()
        };
    },[])

    // const prevToken = usePreviousToken(nextToken);
    useEffect(() => {
        // if(prevToken.nextToken) {

        //  // process here
        // }
        if (nextToken && (contracts && contracts.length === 0)) {
            let esSearchReqBody = constructSearchReqBody(searchSize, false);
            postContractsSearchAfter(esSearchReqBody)
        }
    }, [nextToken])

    const constructSearchReqBody = (passedPageSize, restartSearch=false) => {
        let initialStruct = {}
        let query = ""
        if (searchId && searchVersion) {
            query = `
                {
                    getContracts(id: "${searchId}", version: ${searchVersion}) 
                    { 
                        id
                        version
                        fees
                        name
                        createdAt
                    }
                }
            `
        } else {
            query = `
                query listContracts {
                    listContracts(
                        nextToken: "${(!restartSearch && nextToken) ? nextToken : ""}",
                        limit: 100,
                    ) 
                    { items { 
                        id
                        version
                        fees
                        name
                        createdAt
                    }, nextToken } 
                }
            `
        }
        initialStruct.query = query
        return initialStruct
    }


    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={contractDialog} 
            onClose={()=> {
                setContractDialog(false)
                setContractDetails({})
            }} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            <CloseIcon className={classes.closeBtn} onClick={()=> {
                setContractDialog(false)
                setContractDetails({})
            }}/>
            {contractDetails && !contractDetails.id &&<Typography className={classes.Title} color="textSecondary" gutterBottom>Create Contract</Typography>}
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        contractDetails && contractDetails.id && <Grid item md={8} xs={8} sm={8}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Contract ID: {contractDetails.id}</Typography>
                        </Grid>
                    }
                    {
                        contractDetails && contractDetails.version && <Grid item md={3} xs={3} sm={3}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Version: {contractDetails.version}</Typography>
                        </Grid>
                    }
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Contract Name</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            value={(contractDetails.name) || ''}
                            onChange={(e) => setContractDetails({...contractDetails, name: e.target.value })} 
                        />
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(contractDetails && contractDetails.currency) || 'Select Currency'}
                            onChange={(e) => {
                                setContractDetails({...contractDetails, currency: e.target.value })
                            }}
                            label="Type"
                        >
                            {
                                currencies.map(
                                    (currency, index) => {
                                    return <MenuItem key={index} value={(currency && currency.value && currency.value.currency_alpha3_code) || ""}>{(currency && currency.name) || "Unknown Currency"}</MenuItem>
                                })
                            }
                            <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Fees</Typography>
                        {
                            contractDetails && contractDetails.fees && Object.keys(contractDetails.fees).map((key, index) => {
                                return <Paper elevation={3} style={{padding: '1em', marginTop: '1em'}}><Grid container spacing={3} key={key}>
                                    <Grid item md={4} xs={4} sm={4}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>TYPE</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={(key) || 'Select Type'}
                                            onChange={(e) => {
                                                let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                if (!currentFees[key]) return;
                                                let currentFee = JSON.parse(JSON.stringify(currentFees[key]))
                                                delete currentFees[key]
                                                currentFee["type"] = e.target.value
                                                currentFees[e.target.value] = currentFee
                                                setContractDetails({...contractDetails, fees: currentFees})
                                            }}
                                            label="Type"
                                        >
                                            {
                                                feeType.map(
                                                    (type, index) => {
                                                    return <MenuItem key={index} value={type}>{type}</MenuItem>
                                                })
                                            }
                                            <MenuItem key={'select_type'} value={'Select Type'}>Select Type</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={2} xs={2} sm={2}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>FLAT</Typography>
                                        <OutlinedInputMod 
                                            fullWidth
                                            defaultValue={(contractDetails.fees[key].flat) || ""}
                                            onChange={(e) => {
                                                let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                if (!currentFees[key]) return;
                                                currentFees[key].flat = parseInt(e.target.value)
                                                setContractDetails({...contractDetails, fees: currentFees })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={2} sm={2}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>MIN</Typography>
                                        <OutlinedInputMod 
                                            fullWidth
                                            defaultValue={(contractDetails && contractDetails.fees && contractDetails.fees[key] && contractDetails.fees[key].minimum) || ""}
                                            onChange={(e) => {
                                                let currentFees =  JSON.parse(JSON.stringify(contractDetails.fees))
                                                if (!currentFees[key]) return;
                                                currentFees[key].minimum = parseInt(e.target.value)
                                                setContractDetails({...contractDetails, fees: currentFees })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={2} sm={2}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>%</Typography>
                                        <OutlinedInputMod 
                                            fullWidth
                                            defaultValue={(contractDetails && contractDetails.fees && contractDetails.fees[key] && contractDetails.fees[key].percentage) || ""}
                                            onChange={(e) => {
                                                let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                if (!currentFees[key]) return;
                                                currentFees[key].percentage = parseFloat(e.target.value)
                                                setContractDetails({...contractDetails, fees: currentFees })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={2} sm={2}>
                                        <IconButton 
                                            style={{color: '#424242', marginTop: '1em'}} 
                                            aria-controls="simple-menu" 
                                            aria-haspopup="true"
                                            onClick={() => {
                                                if (!contractDetails.fees || !contractDetails.fees[key]) return
                                                let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                if (!currentFees[key]) return;
                                                delete currentFees[key]
                                                setContractDetails({...contractDetails, fees: currentFees })
                                            }}
                                        >
                                            <DeleteIcon fontSize="large"/>
                                        </IconButton>
                                    </Grid>
                                    {/* EDIT EXISTING CUSTOM FEE */}
                                    {
                                        contractDetails && contractDetails.fees && contractDetails.fees[key] && contractDetails.fees[key].customFees && Object.keys(contractDetails.fees[key].customFees).map((eachCustomKey, ckIdx) => {
                                            return <Grid container spacing={3} key={'subKey'+ckIdx} style={{padding: '1em'}}>
                                                <Grid item md={4} xs={4} sm={4}>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>CUSTOM TYPE</Typography>
                                                    <Select
                                                        fullWidth
                                                        variant="outlined"
                                                        className={classes.select}
                                                        value={(eachCustomKey) || 'Select Type'}
                                                        onChange={(e) => {
                                                            let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                            let currentCustomFees = JSON.parse(JSON.stringify(contractDetails.fees[key].customFees))
                                                            if (!currentCustomFees[eachCustomKey]) return;
                                                            let currentFee = JSON.parse(JSON.stringify(contractDetails.fees[key].customFees[eachCustomKey]))
                                                            delete currentFees[key].customFees[eachCustomKey]
                                                            // currentFee["type"] = e.target.value
                                                            currentFees[key].customFees[e.target.value] = currentFee
                                                            setContractDetails({...contractDetails, fees: currentFees})
                                                        }}
                                                        label="Type"
                                                    >
                                                        {
                                                            customFeeType.map(
                                                                (type, index) => {
                                                                return <MenuItem key={index} value={type}>{type}</MenuItem>
                                                            })
                                                        }
                                                        <MenuItem key={'select_type'} value={'Select Type'}>Select Type</MenuItem>
                                                    </Select>
                                                </Grid>
                                                {/* CUSTOM FLAT */}
                                                <Grid item md={2} xs={2} sm={2}>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>FLAT</Typography>
                                                    <OutlinedInputMod 
                                                        fullWidth
                                                        value={(contractDetails && contractDetails.fees && contractDetails.fees[key] && contractDetails.fees[key].customFees && contractDetails.fees[key].customFees[eachCustomKey] && contractDetails.fees[key].customFees[eachCustomKey].flat) || ""}
                                                        onChange={(e) => {
                                                            let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                            let currentCustomFees = JSON.parse(JSON.stringify(contractDetails.fees[key].customFees))
                                                            if (!currentCustomFees[eachCustomKey]) return;
                                                            let currentFee = JSON.parse(JSON.stringify(currentCustomFees[eachCustomKey]))
                                                            currentFee.flat = parseInt(e.target.value)
                                                            currentFees[key].customFees[eachCustomKey] = currentFee
                                                            setContractDetails({...contractDetails, fees: currentFees })
                                                        }}
                                                    />
                                                </Grid>
                                                {/* CUSTOM MIN */}
                                                <Grid item md={2} xs={2} sm={2}>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>MIN</Typography>
                                                    <OutlinedInputMod 
                                                        fullWidth
                                                        value={(contractDetails && contractDetails.fees && contractDetails.fees[key] && contractDetails.fees[key].customFees && contractDetails.fees[key].customFees[eachCustomKey] && contractDetails.fees[key].customFees[eachCustomKey].minimum) || ""}
                                                        onChange={(e) => {
                                                            let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                            let currentCustomFees = JSON.parse(JSON.stringify(contractDetails.fees[key].customFees))
                                                            if (!currentCustomFees[eachCustomKey]) return;
                                                            let currentFee = JSON.parse(JSON.stringify(currentCustomFees[eachCustomKey]))
                                                            currentFee.minimum = parseInt(e.target.value)
                                                            currentFees[key].customFees[eachCustomKey] = currentFee
                                                            setContractDetails({...contractDetails, fees: currentFees })
                                                        }}
                                                    />
                                                </Grid>
                                                {/* CUSTOM % */}
                                                <Grid item md={2} xs={2} sm={2}>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>%</Typography>
                                                    <OutlinedInputMod 
                                                        fullWidth
                                                        value={(contractDetails && contractDetails.fees && contractDetails.fees[key] && contractDetails.fees[key].customFees && contractDetails.fees[key].customFees[eachCustomKey] && contractDetails.fees[key].customFees[eachCustomKey].percentage) || ""}
                                                        onChange={(e) => {
                                                            let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                            let currentCustomFees = JSON.parse(JSON.stringify(contractDetails.fees[key].customFees))
                                                            if (!currentCustomFees[eachCustomKey]) return;
                                                            let currentFee = JSON.parse(JSON.stringify(currentCustomFees[eachCustomKey]))
                                                            currentFee.percentage = parseFloat(e.target.value)
                                                            currentFees[key].customFees[eachCustomKey] = currentFee
                                                            setContractDetails({...contractDetails, fees: currentFees })
                                                        }}
                                                    />
                                                </Grid>
                                                {/* CUSTOM DELETE */}
                                                <Grid item md={2} xs={2} sm={2}>
                                                    <IconButton 
                                                        style={{color: '#424242', marginTop: '1em'}} 
                                                        aria-controls="simple-menu" 
                                                        aria-haspopup="true"
                                                        onClick={() => {
                                                            if (!contractDetails.fees || !contractDetails.fees[key] || !contractDetails.fees[key].customFees || !contractDetails.fees[key].customFees[eachCustomKey]) return
                                                            let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                            let currentCustomFees = JSON.parse(JSON.stringify(contractDetails.fees[key].customFees))
                                                            if (!currentCustomFees[eachCustomKey]) return;
                                                            delete currentCustomFees[eachCustomKey]
                                                            currentFees[key].customFees = currentCustomFees
                                                            setContractDetails({...contractDetails, fees: currentFees })
                                                        }}
                                                    >
                                                        <DeleteIcon fontSize="large"/>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                    {/* ADD NEW CUSTOM FEE */}
                                    <Grid item md={8} xs={8} sm={8}>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            label="Type"
                                            value={(feeCustomFee && feeCustomFee[key]) || {}}
                                            onChange={(e) => {
                                                let currentFeeCustomFee = JSON.parse(JSON.stringify(feeCustomFee))
                                                currentFeeCustomFee[key] = e.target.value
                                                setFeeCustomFee(currentFeeCustomFee)
                                            }}
                                        >
                                            {
                                                customFeeType.map(
                                                    (type, index) => {
                                                    return <MenuItem key={index} value={type}>{type}</MenuItem>
                                                })
                                            }
                                            <MenuItem key={'select_type'} value={'Select Type'}>Select Type</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={4} xs={4} sm={4}>
                                        <Button
                                            onClick={(e) => {
                                                let currentFees = JSON.parse(JSON.stringify(contractDetails.fees))
                                                if (!currentFees[key]) return;
                                                let currentFee = JSON.parse(JSON.stringify(currentFees[key]))
                                                if (currentFee.customFees) {
                                                    currentFee.customFees[feeCustomFee[key]] = {}
                                                } else {
                                                    let newCustomFees = {}
                                                    newCustomFees[feeCustomFee[key]] = {}
                                                    currentFee.customFees = newCustomFees
                                                }
                                                currentFees[key] = currentFee
                                                setContractDetails({...contractDetails, fees: currentFees})
                                            }}
                                        >
                                            ADD CUSTOM FEES
                                        </Button>
                                    </Grid>
                                    {/* TODO COST */}
                                </Grid>
                                </Paper>
                            })
                        }
                    </Grid>
                    <br/>
                    <Grid item md={12} xs={12} sm={12}>Add New Fee</Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>TYPE</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(newFee && newFee.type) || 'Select Type'}
                            onChange={(e) => {
                                setNewFee({...newFee, type: e.target.value})
                            }}
                            label="Type"
                        >
                            {
                                feeType.map(
                                    (type, index) => {
                                    return <MenuItem key={index} value={type}>{type}</MenuItem>
                                })
                            }
                            <MenuItem key={'select_type'} value={'Select Type'}>Select Type</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={2} xs={2} sm={2}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>FLAT</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            inputProps={{
                                name: 'max-width',
                            }}
                            onChange={(e) => {
                                setNewFee({...newFee, flat: parseInt(e.target.value)})
                            }}
                            defaultValue={(newFee && newFee.flat) || ""}
                        />
                    </Grid>
                    <Grid item md={2} xs={2} sm={2}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>MIN</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            inputProps={{
                                name: 'max-width',
                            }}
                            onChange={(e) => {
                                setNewFee({...newFee, minimum: parseInt(e.target.value)})
                            }}
                            defaultValue={(newFee && newFee.minimum) || ""}
                        />
                    </Grid>
                    <Grid item md={2} xs={2} sm={2}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>%</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            inputProps={{
                                name: 'max-width',
                            }}
                            onChange={(e) => {
                                setNewFee({...newFee, percentage: parseFloat(e.target.value)})
                            }}
                            defaultValue={(newFee && newFee.percentage) || ""}
                        />
                    </Grid>
                    <Grid item md={2} xs={2} sm={2}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                if (newFee && newFee.type && feeType.includes(newFee.type)) {
                                    let currentFees = (contractDetails && contractDetails.fees && JSON.parse(JSON.stringify(contractDetails.fees))) || {}
                                    currentFees[newFee.type] = newFee
                                    console.log(currentFees);
                                    setContractDetails({...contractDetails, fees: currentFees})
                                }
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        {contractDetails && !contractDetails.id && <ButtonMod onClick={() => {
                            createContract(contractDetails)
                            setContractDialog(false)
                            setContractDetails({})
                        }}>
                            CREATE
                        </ButtonMod>}
                        {contractDetails && contractDetails.id && <ButtonMod onClick={() => {
                            let letTargetFees = {}
                            Object.keys(contractDetails.fees).map((eachKey) => {
                                letTargetFees[eachKey] = Object.assign({}, contractDetails.fees[eachKey])
                            })
                            updateContract({contractId: contractDetails.id}, {
                                name: contractDetails.name,
                                currency: (contractDetails.currency || ""),
                                fees: letTargetFees
                            })
                            setContractDialog(false)
                            setContractDetails({})
                        }}>
                            SAVE
                        </ButtonMod>} 
                        {contractDetails && contractDetails.id && <ButtonMod onClick={() => {
                            deleteContract({contractId: `${contractDetails.id}`})
                            setContractDialog(false)
                            setContractDetails({})
                        }}>
                            DELETE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Contracts</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Contract Name</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchName) || ''}
                                            onChange={(e) => setSearchName(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Contract ID</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchId) || ''}
                                            onChange={(e) => setSearchId(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Contract Version</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchVersion) || ''}
                                            onChange={(e) => setSearchVersion(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                            {searchFees.map((singleFee, index) => {
                                                return <Grid container spacing={3}>
                                                    <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>TYPE</Typography>
                                                        <Select
                                                            fullWidth
                                                            variant="outlined"
                                                            className={classes.select}
                                                            value={(singleFee && singleFee.type) || 'Select Type'}
                                                            onChange={(e) => {
                                                                let currentFee = JSON.parse(JSON.stringify(singleFee)) || {};
                                                                currentFee.type = e.target.value;
                                                                let currSearchFees = JSON.parse(JSON.stringify(searchFees))
                                                                currSearchFees[index] = currentFee
                                                                setSearchFees(currSearchFees)
                                                            }}
                                                            label="Type"
                                                        >
                                                            {
                                                                feeType.map(
                                                                    (type, index) => {
                                                                    return <MenuItem key={index} value={type}>{type}</MenuItem>
                                                                })
                                                            }
                                                            <MenuItem key={'select_type'} value={'Select Type'}>Select Type</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                    <Grid item md={2} xs={2} sm={2}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>FLAT</Typography>
                                                        <OutlinedInputMod 
                                                            maxWidth={'md'} 
                                                            fullWidth
                                                            inputProps={{
                                                                name: 'max-width',
                                                            }}
                                                            onChange={(e) => {
                                                                let currentFee = JSON.parse(JSON.stringify(singleFee)) || {};
                                                                currentFee.flat = e.target.value;
                                                                let currSearchFees = JSON.parse(JSON.stringify(searchFees))
                                                                currSearchFees[index] = currentFee
                                                                setSearchFees(currSearchFees)
                                                            }}
                                                            value={(singleFee && singleFee.flat) || ""}
                                                        />
                                                    </Grid>
                                                    <Grid item md={2} xs={2} sm={2}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>MIN</Typography>
                                                        <OutlinedInputMod 
                                                            maxWidth={'md'} 
                                                            fullWidth
                                                            inputProps={{
                                                                name: 'max-width',
                                                            }}
                                                            onChange={(e) => {
                                                                let currentFee = JSON.parse(JSON.stringify(singleFee)) || {};
                                                                currentFee.min = e.target.value;
                                                                let currSearchFees = JSON.parse(JSON.stringify(searchFees))
                                                                currSearchFees[index] = currentFee
                                                                setSearchFees(currSearchFees)
                                                            }}
                                                            value={(singleFee && singleFee.min) || ""}
                                                        />
                                                    </Grid>
                                                    <Grid item md={2} xs={2} sm={2}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>%</Typography>
                                                        <OutlinedInputMod 
                                                            maxWidth={'md'} 
                                                            fullWidth
                                                            inputProps={{
                                                                name: 'max-width',
                                                            }}
                                                            onChange={(e) => {
                                                                let currentFee = JSON.parse(JSON.stringify(singleFee)) || {};
                                                                currentFee.percentage = e.target.value;
                                                                let currSearchFees = JSON.parse(JSON.stringify(searchFees))
                                                                currSearchFees[index] = currentFee
                                                                setSearchFees(currSearchFees)
                                                            }}
                                                            value={(singleFee && singleFee.percentage) || ""}
                                                        />
                                                    </Grid>
                                                    <Grid item md={2} xs={2} sm={2}>
                                                        <IconButton 
                                                            style={{color: '#424242', marginTop: '1em'}} 
                                                            aria-controls="simple-menu" 
                                                            aria-haspopup="true"
                                                            onClick={() => {
                                                                let currSearchFees = JSON.parse(JSON.stringify(searchFees))
                                                                currSearchFees.splice(index, 1);
                                                                setSearchFees(currSearchFees)
                                                            }}
                                                        >
                                                            <DeleteIcon fontSize="large"/>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            })}
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                let currSearchFees = JSON.parse(JSON.stringify(searchFees))
                                                currSearchFees.push({})
                                                setSearchFees(currSearchFees)
                                            }}
                                        >
                                            ADD FEE SEARCH CRITERIA
                                        </ButtonMod>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(null, true)
                                                postContractsSearch(esSearchReqBody)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setNewContract(true)
                                setContractDialog(true)
                                setContractDetails({})
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(event) => {
                                console.log('FIRE FIRE FIRE!!!');
                                console.log(JSON.stringify(contracts));
                                // console.log('onPageChange FIRED first part !!', event.page , ' ', loadedPage);
                                // console.log('onPageChange event.page -> ', event.page);
                                if (page >= event.page) return
                                // setPage(event.page);
                                if (event.page <= loadedPage) return
                                // console.log('onPageChange FIRED second part !!', event.page , ' ', loadedPage);
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody(searchSize, false)
                                // postPaymentsSearchAfter({organizationId: organization.id}, esSearchReqBody)
                                console.log('=== NEXT TOKEN ====');
                                console.log(nextToken);
                                if (!nextToken) {
                                    return;
                                }
    
                                console.log('IF EMPTY SHOULD NOT FIRE');
                                postContractsSearchAfter(esSearchReqBody)
                            }}
                            hideFooterRowCount
                            rowCount={nextToken ? 9999999999999 : (contracts.length)}
                            rowsPerPageOptions={[10]}
                            pageSize={13}
                            pagination={true}
                            columns={[
                                { 
                                    field: 'id',
                                    headerName: 'Contract ID',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                                },
                                { 
                                    field: 'name',
                                    headerName: 'Name',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.name) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.AUTHENTICATION',
                                    headerName: 'AUTHENTICATION',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["AUTHENTICATION"] && JSON.stringify(params.row.fees["AUTHENTICATION"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.AUTHORIZATION',
                                    headerName: 'AUTHORIZATION',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["AUTHORIZATION"] && JSON.stringify(params.row.fees["AUTHORIZATION"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.CAPTURE',
                                    headerName: 'CAPTURE',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["CAPTURE"] && JSON.stringify(params.row.fees["CAPTURE"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.REFUND',
                                    headerName: 'REFUND',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["REFUND"] && JSON.stringify(params.row.fees["REFUND"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.VOID',
                                    headerName: 'VOID',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["VOID"] && JSON.stringify(params.row.fees["VOID"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.CHARGEBACK',
                                    headerName: 'CHARGEBACK',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["CHARGEBACK"] && JSON.stringify(params.row.fees["CHARGEBACK"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.ONE_TIME',
                                    headerName: 'ONE_TIME',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["ONE_TIME"] && JSON.stringify(params.row.fees["ONE_TIME"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.SMS',
                                    headerName: 'SMS',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["SMS"] && JSON.stringify(params.row.fees["SMS"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.TOKEN',
                                    headerName: 'TOKEN',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["TOKEN"] && JSON.stringify(params.row.fees["TOKEN"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.ANNUAL',
                                    headerName: 'ANNUAL',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["ANNUAL"] && JSON.stringify(params.row.fees["ANNUAL"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'fees.MONTHLY',
                                    headerName: 'MONTHLY',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.fees && params.row.fees["MONTHLY"] && JSON.stringify(params.row.fees["MONTHLY"])) || ""}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'version',
                                    headerName: 'Ver.',
                                    width: 140,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.version) || ""}`
                                    },
                                },
                            ]}
                            rows={contracts}
                            onRowClick={async (rowParams) => {
                                setContractDetails(rowParams.row)
                                setContractDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default Contracts