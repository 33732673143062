import React, { useLayoutEffect, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { makeStyles, createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { LiberalizeSSO } from 'liberalize-sso-js-sdk'
import Appbar from './containers/Appbar'
import { drawerWidth } from './app/globals'
import CompanyDashboard from './containers/CompanyDashboard';
import CompanySettings from './containers/CompanySettings';
import Contracts from './containers/Contracts';
import Payouts from './containers/Payouts';
import Invoices from './containers/Invoices';
import KnowYourCustomer from './containers/KnowYourCustomer';
import Disputes from './containers/Disputes';
import PaymentAccounts from './containers/PaymentAccounts';
import Balances from './containers/Balances';
import Payables from './containers/Payables';
import FundingSchedules from './containers/FundingSchedules';
import BillingSchedules from './containers/BillingSchedules';
import PointOfSales from './containers/PointOfSales';
import Payments from './containers/Payments';
import Main from './containers/Main'
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function App(props) {
  const classes = useStyles(props)
  const { openDrawer, toggleDrawer, setUser, user, alerts } = props
  const [libsso, setLibSso] = React.useState(null);
  useLayoutEffect(() => {
    setLibSso(new LiberalizeSSO("1234-1234-1234-1234"))
    console.log('set class');
  }, [])
  useEffect(() => {
    if (libsso) {
      libsso.getUser().then((result)=> {
        console.log('SSO RESULT => ', result);
        if (result === undefined || (result && result.message)) {
          libsso.signIn(process.env.REACT_APP_PRODUCTION_MANAGEMENT_CONSOLE_FRONTEND_URL)
        } else {
          setUser(result)
        }
      })
    }
  },[libsso])
  return (
    <main 
    className={clsx(classes.content, {
      [classes.contentShift]: !openDrawer,
    })}>
      {alerts && alerts.error && <Alert severity="error">{alerts.error}</Alert>}
      {alerts && alerts.warning && <Alert severity="warning">{alerts.warning}</Alert>}
      {alerts && alerts.info && <Alert severity="info">{alerts.info}</Alert>}
      {alerts && alerts.success && <Alert severity="success">{alerts.success}</Alert>}
      <Appbar/>
      <Switch>
        <Route path="/" exact component={Main}/>
        <Route path="/contracts" exact component={Contracts}/>
        <Route path="/payouts" exact component={Payouts}/>
        <Route path="/invoices" exact component={Invoices}/>
        <Route path="/company/:companyId" exact component={CompanyDashboard}/>
        <Route path="/company/:companyId/company-settings" exact component={CompanySettings}/>
        <Route path="/company/:companyId/kyc" exact component={KnowYourCustomer}/>
        <Route path="/company/:companyId/disputes" exact component={Disputes}/>
        <Route path="/company/:companyId/payment-accounts" exact component={PaymentAccounts}/>
        <Route path="/company/:companyId/balances" exact component={Balances}/>
        <Route path="/company/:companyId/payables" exact component={Payables}/>
        <Route path="/company/:companyId/payments" exact component={Payments}/>
        <Route path="/company/:companyId/funding-schedules" exact component={FundingSchedules}/>
        <Route path="/company/:companyId/billing-schedules" exact component={BillingSchedules}/>
        <Route path="/company/:companyId/point-of-sales" exact component={PointOfSales}/>
        {/* <Route path="/company" exact component={OurCompany}/> */}
        {/* <Route component={NoMatch} /> */}
      </Switch>
    </main>
  );
}

export default App;
