function disputes(state ={isLoading: false}, action) {
    switch (action.type) {
        // case 'GET_S3_FILE_REQUEST':
        //     return {...state, isLoading: true} 
        // case 'GET_S3_FILE_SUCCESS':
        //     return {...state, isLoading: false} 
        // case 'GET_S3_FILE_FAILURE':
        //     return {...state, isLoading: false}
        // case 'SET_IS_LOADING':
        //     return {...state, isLoading: true}
        // case 'SET_NOT_LOADING':
        //     return {...state, isLoading: false}
        case 'POST_CREATE_DISPUTE_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_CREATE_DISPUTE_SUCCESS':
            const currentDisputes = state.allDisputes || []
            return {...state,
                isLoading: false,
                allDisputes: [...currentDisputes, action.data]
            }
        case 'POST_CREATE_DISPUTE_FAILURE':
            return {...state, isLoading: false}
        case 'UPDATE_DISPUTE_REQUEST':
            return {...state, isLoading: true}
        case 'UPDATE_DISPUTE_SUCCESS':
            console.log('UPDATE_DISPUTE_SUCCESS', action);
            let targetPatchDispute = (action && action.data) || {}
            const patchDisputeHits = state.allDisputes.map((eachHit) => {
                if (eachHit.id === targetPatchDispute.id) return targetPatchDispute
                return eachHit
            })
            return {...state, isLoading: false, allDisputes: patchDisputeHits}
        case 'UPDATE_DISPUTE_FAILURE':
            return {...state, isLoading: false}
        case 'DELETE_DISPUTE_REQUEST':
            return {...state, isLoading: true}
        case 'DELETE_DISPUTE_SUCCESS':
            console.log('DELETE_KYC_SUCCESS', action);
            return {...state, isLoading: false}
        case 'DELETE_DISPUTE_FAILURE':
            return {...state, isLoading: false}
        case 'POST_DISPUTES_SEARCH_REQUEST':
            return {...state, isLoading: true}
        case 'POST_DISPUTES_SEARCH_SUCCESS':
            console.log('POST_DISPUTES_SEARCH_SUCCESS', action);
            const dispute_hits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const search_after_disputes = dispute_hits.length > 0 ? dispute_hits[dispute_hits.length - 1].sort : [];
            const totalHitsDisputes = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const disputeHitsSource = dispute_hits.map((eachHit) => eachHit['_source'])
            return {...state,
                allDisputes: disputeHitsSource,
                totalHitsDisputes: totalHitsDisputes,
                search_after_disputes: search_after_disputes,
                isLoading: false
            }
        case 'POST_DISPUTES_SEARCH_FAILURE':
            return {...state, isLoading: false}
        case 'POST_DISPUTES_SEARCH_AFTER_REQUEST':
            return {...state, isLoading: true}
        case 'POST_DISPUTES_SEARCH_AFTER_SUCCESS':
            const nextDisputeHits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const totalDisputesHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const next_search_after_disputes = nextDisputeHits.length > 0 ? nextDisputeHits[nextDisputeHits.length - 1].sort : [];
            const nextDisputeHitsSource = nextDisputeHits.map((eachHit) => eachHit['_source'])
            const combinedDisputesHits = [...state.allDisputes]
            const combinedDisputeIDs = {};
            combinedDisputesHits.forEach((eachHit) => combinedDisputeIDs[eachHit.id] = true)
            nextDisputeHitsSource.forEach((newHit) => {
                if (!combinedDisputeIDs[newHit.id]) {
                    combinedDisputesHits.push(newHit)
                }
            })
            return {...state,
                allDisputes: combinedDisputesHits,
                totalHitsDisputes: totalDisputesHitsAfter,
                search_after_disputes: next_search_after_disputes,
                isLoading: false
            }
        case 'POST_DISPUTES_SEARCH_AFTER_FAILURE':
            return {...state, isLoading: false}
        case 'CLEAR_DISPUTE_SEARCH':
            return {...state, allDisputes: [], totalHitsDisputes: 0, search_after_disputes: [], isLoading: false}
      default:
        return state
    }
  }

export default disputes