import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, clearOrgState } from '../actions'
import AppDrawer from '../components/AppDrawer'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
})
  
const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    clearOrgState: () => dispatch(clearOrgState()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppDrawer)