import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { feeType } from '../app/globals';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { posTypes } from '../app/globals';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const PointOfSales = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, clearPosSearch, getEnumeration, currencies, clearAlertMessage, alertMessage,
        patchPos, postPointOfSalesSearch, search_after_pos, pos, postPointOfSalesSearchAfter, totalHitsPos, 
        postContractsSearch, contracts } = props
    const [posDialog, setPosDialog] = useState(false);
    const [posDetails, setPosDetails] = useState({});
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchPosType, setSearchPosType] = useState('');
    const [searchLocation, setSearchLocation] = useState('');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody()
        postPointOfSalesSearch(esSearchReqBody, {organizationId: organization.id})
        setLoadedPage(1)
        return function cleanup() {
            clearPosSearch()
        };
    },[])

    useEffect(() => {
        if (organization && organization.id) {
            let esSearchReqBody = constructSearchReqBody()
            postPointOfSalesSearch(esSearchReqBody, { organizationId: organization.id})
        }
    },[organization, organization.id])

    const constructSearchReqBody = (passedPageSize, restartSearch=false, contractName='') => {
        let initialStruct = {
            from: restartSearch ? 0 : searchFrom,
            size: passedPageSize || searchSize,
            query: {
                bool: {
                    must: [
                    ]
                },
            },
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        if (searchPosType && !contractName) {
            initialStruct.query.bool.must.push(
                { "match": { "type": searchPosType }}
            )
        }
        if (contractName) {
            initialStruct.regexp = {
                name: {
                    value: contractName + ".*",
                    flags: "ALL",
                    max_determinized_states: 10000,
                    rewrite: "constant_score"
                }
            }
        }
        if (!restartSearch && search_after_pos.length > 0) {
            initialStruct.from = -1
            initialStruct.search_after = search_after_pos
        }
        return initialStruct
    }
    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={posDialog} 
            onClose={()=> {setPosDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            <CloseIcon className={classes.closeBtn} onClick={()=> {setPosDialog(false)}}/>
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {/* POS ID */}
                    {
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Point Of Sales ID: {posDetails.id}</Typography>
                        </Grid>
                    }
                    {/* POS NAME */}
                    {
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Point Of Sales Name: {posDetails.name}</Typography>
                        </Grid>
                    }
                    {/* Type of POS */}
                    {<Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Type: </Typography> {posDetails.type}
                    </Grid>}
                    {/* Organization */}
                    {
                        posDetails && posDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Organization ID: {posDetails.organizationId}</Typography>
                        </Grid>
                    }
                    {/* Contracts Search by Name */}
                    {
                        <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Contract</Typography>
                            <GreenAutocomplete
                                id="tags-filled"
                                options={contracts}
                                getOptionLabel={(option) => {
                                    return option.name
                                }}
                                onChange={(e,v) => { 
                                    if (v && v.id) {
                                        console.log("e ->", e)
                                        console.log("v ->", v)
                                        setPosDetails({...posDetails, contractId: v.id  })
                                    }
                                }}
                                fullWidth
                                freeSolo
                                renderInput={(params) => (
                                    <GreenTextField 
                                        {...params}
                                        variant="filled"
                                        placeholder="Contracts"
                                        onChange={(e) => {
                                            let esSearchReqBody = constructSearchReqBody(10, true, e.target.value)
                                            postContractsSearch(esSearchReqBody)
                                        }}
                                    />
                                )}
                            />
                        {posDetails && posDetails.contractId && <Typography className={classes.title} color="textSecondary" gutterBottom>Selected ContractID: {posDetails.contractId}</Typography>}
                    </Grid>
                    }
                    {/* isActivated */}
                    {   posDetails.type === "terminals" &&
                        <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>IsActivated (For Payment Terminals Only):</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={(posDetails.isActivated)}
                                onChange={(e) => {
                                    setPosDetails({...posDetails, isActivated: e.target.value  })
                                }}
                                label="Activation"
                            >
                                <MenuItem key={'select_activation'} value={'Select Activation'}>Select Activation</MenuItem>
                                <MenuItem key={'activation_true'} value={true}>True</MenuItem>
                                <MenuItem key={'activation_false'} value={false}>False</MenuItem>
                            </Select>
                        </Grid>
                    }
                    {/* paymentTerminalType */}
                    {   posDetails.type === "terminals" &&
                        <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Terminal Type (For Payment Terminals Only):</Typography>
                            <Select
                                fullWidth
                                variant="outlined"
                                className={classes.select}
                                value={(posDetails.paymentTerminalType) || 'Select Payment Terminal Type'}
                                onChange={(e) => {
                                    setPosDetails({...posDetails, paymentTerminalType: e.target.value  })
                                }}
                                label="Payment Terminal Type"
                            >
                                <MenuItem key={'select_paymentTerminalType'} value={'Select Payment Terminal Type'}>Select Payment Terminal Type</MenuItem>
                                <MenuItem key={'paymentTerminalType_eftpos'} value={'EFTPOS'}>EFTPOS</MenuItem>
                                <MenuItem key={'paymentTerminalType_contactless'} value={'CONTACTLESS'}>CONTACTLESS</MenuItem>
                            </Select>
                        </Grid>
                    }
                    {/* Logo */}
                    {
                        <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Logo: </Typography>
                            <div>{posDetails.logo && <img src={posDetails.logo}/>}</div>
                        </Grid>
                    }
                    {/* TimeStamps */}
                    {
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created At: {posDetails.createdAt && new Date(posDetails.createdAt).toString()}</Typography>
                        </Grid>
                    }
                    {
                        <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Updated At: {posDetails.updatedAt && new Date(posDetails.updatedAt).toString()}</Typography>
                        </Grid>
                    }
                    {/* {JSON.stringify(posDetails)} */}
                    <Grid item md={11} xs={11} sm={11}>
                        {<ButtonMod onClick={() => {
                            if (!organization || !organization.id || !posDetails.id) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization Loaded'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                const currposDetails = posDetails
                                console.log('currposDetails -> ', currposDetails)
                                currposDetails.organizationId = organization.id
                                patchPos({posId: posDetails.id}, currposDetails)
                            }
                            setPosDialog(false)
                            setPosDetails({})
                        }}>
                            UPDATE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Point Of Sales</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Type</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={(searchPosType) || 'Select Type'}
                                            onChange={(e) => {
                                                setSearchPosType(e.target.value)
                                            }}
                                            label="Type"
                                        >
                                            {
                                                posTypes.map(
                                                    (type, index) => {
                                                    return <MenuItem key={index} value={type}>{type}</MenuItem>
                                                })
                                            }
                                            <MenuItem key={'select_type'} value={'Select Type'}>Select Type</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(null, true)
                                                postPointOfSalesSearch(esSearchReqBody, { organizationId: organization.id })
                                                setSearchLocation('')
                                                setIsExpanded(false)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setPosDialog(true)
                                setPosDetails({})
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody()
                                postPointOfSalesSearchAfter(esSearchReqBody, {organizationId: organization.id})
                            }}
                            onPageSizeChange={(params) =>{
                                if (searchSize === params.pageSize) return
                                setSearchSize(params.pageSize)
                                setPage(1);
                                setLoadedPage(1)
                                let esSearchReqBody = constructSearchReqBody(params.pageSize, true)
                                postPointOfSalesSearch(esSearchReqBody, {organizationId: organization.id})
                            }}
                            rowCount={totalHitsPos}
                            rowsPerPageOptions={[10,25,50,100]}
                            pageSize={10}
                            pagination
                            columns={[
                                { 
                                    field: 'id',
                                    headerName: 'POS ID',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.id) || ""}`
                                    },
                                },
                                { 
                                    field: 'name',
                                    headerName: 'POS Name',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.name) || ""}`
                                    },
                                },
                                { 
                                    field: 'type',
                                    headerName: 'POS Type',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.type) || ""}`
                                    },
                                },
                                { 
                                    field: 'logo',
                                    headerName: 'POS Logo',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.logo) || ""}`
                                    },
                                },
                                { 
                                    field: 'contractId',
                                    headerName: 'Contract ID',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.contractId) || ""}`
                                    },
                                },
                                { 
                                    field: 'organizationId',
                                    headerName: 'Organization ID',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.organizationId) || ""}`,
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'updatedAt',
                                    headerName: 'Updated At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.updatedAt) {
                                            let date = new Date(params.row.updatedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                }
                            ]}
                            rows={pos}
                            onRowClick={async (rowParams) => {
                                setPosDetails(rowParams.row)
                                setPosDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default PointOfSales