import { CALL_DISPUTE_API } from '../app/middleware/callDisputeApi.js';

export const downloadDisputeS3File = (params, query) => ({
    types: ['GET_S3_FILE_REQUEST', 'GET_S3_FILE_SUCCESS', 'GET_S3_FILE_FAILURE'],
    [CALL_DISPUTE_API]: '/organizations/:organizationId/disputedoc',
    method: 'get',
    params: params,
    query: query
})

export const createDispute = (params, reqBody) => ({
    types: ['POST_CREATE_DISPUTE_REQUEST', 'POST_CREATE_DISPUTE_SUCCESS', 'POST_CREATE_DISPUTE_FAILURE'],
    [CALL_DISPUTE_API]: '/organizations/:organizationId/dispute',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const updateDispute = (params, reqBody) => ({
    types: ['UPDATE_DISPUTE_REQUEST', 'UPDATE_DISPUTE_SUCCESS', 'UPDATE_DISPUTE_FAILURE'],
    [CALL_DISPUTE_API]: '/disputes/:disputeId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const deleteDispute = (params) => ({
    types: ['DELETE_DISPUTE_REQUEST', 'DELETE_DISPUTE_SUCCESS', 'DELETE_DISPUTE_FAILURE'],
    [CALL_DISPUTE_API]: '/disputes/:disputeId',
    method: 'delete',
    params: params
})

export const postDisputesSearch = (reqBody) => ({
    types: ['POST_DISPUTES_SEARCH_REQUEST', 'POST_DISPUTES_SEARCH_SUCCESS', 'POST_DISPUTES_SEARCH_FAILURE'],
    [CALL_DISPUTE_API]: '/disputes-search',
    method: 'post',
    reqBody: reqBody
})

export const postDisputesSearchAfter = (reqBody) => ({
    types: ['POST_DISPUTES_SEARCH_AFTER_REQUEST', 'POST_DISPUTES_SEARCH_AFTER_SUCCESS', 'POST_DISPUTES_SEARCH_AFTER_FAILURE'],
    [CALL_DISPUTE_API]: '/disputes-search',
    method: 'post',
    reqBody: reqBody
})

export const clearDisputesSearch = (params, query) => ({
    type: 'CLEAR_DISPUTE_SEARCH',
})

export const setDisputeLoading = (params, query) => ({
    type: 'SET_IS_LOADING',
})

export const setDisputeNotLoading = (params, query) => ({
    type: 'SET_NOT_LOADING',
})