import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { feeType } from '../app/globals';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const currencyFormatter = require('currency-formatter');

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        padding: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const Payables = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, clearPayables, getEnumeration, currencies, clearAlertMessage, alertMessage,
        createPayable, searchPayables, search_after_payables, payables, searchAfterPayables, totalHitsPayables,
        postBillingsSearch, postBillingsSearchAfter, clearBillingsSearch, billings, billingsNextToken
    } = props
    const [payableDialog, setPayableDialog] = useState(false);
    const [payableDetails, setPayableDetails] = useState({});
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchCurrency, setSearchCurrency] = useState('');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    const currencyFormat = (currency, amount, symbol=false) => {
        if (!currency || !(amount >= 0)) return ''
        const currencyDetails = currencyFormatter.findCurrency(currency);
        let amountString = `${amount}`
        let amountBeforeDecimal = ''
        let amountAfterDecimal = ''
        if (currencyDetails.decimalDigits > 0 && amount !== '0') {
            let count = 0
            for (var i = amountString.length - 1; i >= 0; i--) {
                if (count < currencyDetails.decimalDigits) {
                    amountAfterDecimal = amountString[i] + amountAfterDecimal
                    count++
                } else {
                    amountBeforeDecimal = amountString[i] + amountBeforeDecimal 
                }
            }
            if (currencyDetails.decimalDigits > amountAfterDecimal.length) {
                for (var i = 0; i < currencyDetails.decimalDigits - amountAfterDecimal.length; i++) {
                    amountAfterDecimal = '0' + amountAfterDecimal
                }
            }
        } else {
            amountBeforeDecimal = amountString
        }
        if (!amountBeforeDecimal.length) amountBeforeDecimal = '0'
        // Add in Seperators to amountBeforeDecimal
        let amountBeforeDecimalReverseArr = amountBeforeDecimal.split('').reverse()
        amountBeforeDecimal = ''
        amountBeforeDecimalReverseArr.forEach((digit, index) => {
            if (((index + 1 ) % 3) === 0) {
                amountBeforeDecimal += `${digit}` + `${currencyDetails.thousandsSeparator}`
            } else {
                amountBeforeDecimal += digit
            }
        })
        amountBeforeDecimal = amountBeforeDecimal.split('').reverse().join('')
        if (amountBeforeDecimal[0] === currencyDetails.thousandsSeparator) {
            amountBeforeDecimal = amountBeforeDecimal.slice(1)
        }
        return `${symbol ? currencyDetails.symbol : ''}${currencyDetails.spaceBetweenAmountAndSymbol === true ? ' ': ''}${amountBeforeDecimal}${currencyDetails.decimalDigits > 0 ? currencyDetails.decimalSeparator : ''}${(amount == '0' && currencyDetails.decimalDigits == 2) ? '00' : amountAfterDecimal}`
    }

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody(null, true)
        searchPayables({ organizationId: organization.id}, esSearchReqBody)
        setLoadedPage(1)
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearPayables()
        };
    },[])

    useEffect(() => {
        if (organization && organization.id) {
            let esSearchReqBody = constructSearchReqBody(null, true)
            searchPayables({ organizationId: organization.id}, esSearchReqBody)
        }
    },[organization, organization.id])

    const constructSearchReqBody = (passedPageSize, restartSearch=false, contractName='') => {
        let initialStruct = {
            from: restartSearch ? 0 : searchFrom,
            size: passedPageSize || searchSize,
            query: {
                bool: {
                    must: [
                    ]
                },
            },
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        if (searchCurrency) {
            initialStruct.query.bool.must.push(
                { "match": { "currency": searchCurrency }}
            )
        }
        if (organization && organization.id) {
            initialStruct.query.bool.must.push(
                { "match": { "organizationId": organization.id }}
            )
        }
        if (!restartSearch && search_after_payables.length > 0) {
            initialStruct.from = -1
            initialStruct.search_after = search_after_payables
        }
        return initialStruct
    }

    const constructBillingsSearchReqBody = (passedCurrency) => {
        let initialStruct = {}
        let query = `query listBillings ($organizationId: String!) {
                queryBillingsByOrganizationID (
                    organizationId: $organizationId,
                    filter: {
                        ${passedCurrency ? `currency: { eq: "${passedCurrency}" }` : ""}
                    }
                    ${billingsNextToken ? `nextToken: ${billingsNextToken}`: ""}
                ) {
                items {
                    end
                    id
                    organizationAccountId
                    organizationId
                    billedAt
                    createdAt
                    dueDate
                    start
                    updatedAt
                    accountId
                    amount
                    heldAmount
                    billedAmount
                    refundReversalAmount
                    rollingReserveReversalAmount
                    currency
                    isBilling
                    isBilled
                }
                }
            }
        `
        initialStruct.variables = {
            organizationId: organization.id
        }
        initialStruct.query = query
        return initialStruct
    }
    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={payableDialog} 
            onClose={()=> {setPayableDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            <Container maxWidth="lg" className={classes.formContainer}>
                <CloseIcon className={classes.closeBtn} onClick={()=> {setPayableDialog(false)}}/>
                <Grid container spacing={3}>
                    {
                        payableDetails && payableDetails.organizationId && <Grid item md={8} xs={8} sm={8}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Organization ID: {payableDetails.organizationId}</Typography>
                        </Grid>
                    }
                    {/* Currency */}
                    {
                        payableDetails && payableDetails.organizationId && <Grid item md={8} xs={8} sm={8}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Currency: &nbsp;
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code == payableDetails.currency) {
                                        return <span>{currency.name}</span>
                                    }
                                })
                            }
                            </Typography>
                        </Grid>
                    }
                    {payableDetails && ! payableDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(payableDetails && payableDetails.currency) || ""}
                            onChange={(e) => {
                                setPayableDetails({...payableDetails, currency: e.target.value })
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'currency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>}
                    {/* Available */}
                    {
                        payableDetails && payableDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Available: {payableDetails.available}</Typography>
                        </Grid>
                    }
                    {/* Held */}
                    {
                        payableDetails && payableDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Held: {payableDetails.held}</Typography>
                        </Grid>
                    }
                    {/* billingIds
                    {JSON.stringify(billings)} */}
                    {/* {
                        payableDetails && payableDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Billing IDs</Typography>
                            {payableDetails.billingIds && payableDetails.billingIds.length > 0 ? payableDetails.billingIds.map((eachId) => {
                                return <div>{eachId}</div>
                            }): 'empty'}
                        </Grid>
                    } */}
                    {/* createdAt */}
                    {
                        payableDetails && payableDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created At: {payableDetails.createdAt && new Date(payableDetails.createdAt).toString()}</Typography>
                        </Grid>
                    }
                    {/* updatedAt */}
                    {
                        payableDetails && payableDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Updated At: {payableDetails.updatedAt && new Date(payableDetails.updatedAt).toString()}</Typography>
                        </Grid>
                    }
                    {/* {JSON.stringify(payableDetails)} */}
                    <Grid item md={11} xs={11} sm={11}>
                        {payableDetails && !payableDetails.id && <ButtonMod onClick={() => {
                            if (!organization || !organization.id) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization Loaded'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                const currpayableDetails = payableDetails
                                currpayableDetails.available = 0
                                currpayableDetails.held = 0
                                currpayableDetails.organizationId = organization.id
                                createPayable(currpayableDetails)
                            }
                            setPayableDialog(false)
                            setPayableDetails({})
                        }}>
                            CREATE
                        </ButtonMod>}
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                    <div style={{ height: 520, width: '100%' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            loading={isLoading}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructBillingsSearchReqBody(payableDetails.currency)
                                postBillingsSearchAfter(esSearchReqBody)
                            }}
                            hideFooterRowCount
                            rowsPerPageOptions={[10]}
                            pageSize={13}
                            pagination={billingsNextToken ? true : false}
                            columns={[
                                { 
                                    field: 'id',
                                    headerName: 'Billing ID',
                                    width: 280,
                                    valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                                },
                                { 
                                    field: 'currency',
                                    headerName: 'Currency',
                                    width: 100, 
                                    valueGetter: (params) => `${(params && params.row && params.row.currency) || "unknown"}`,
                                },
                                { 
                                    field: 'amount',
                                    headerName: 'Amount',
                                    width: 170,
                                    valueGetter: (params) => `${(params && params.row && params.row.amount && currencyFormat(params.row.currency, params.row.amount, true)) || currencyFormat(params.row.currency, 0, true)}`,
                                },
                                {
                                    field: 'heldAmount',
                                    headerName: 'Held Amount',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.heldAmount && currencyFormat(params.row.currency, params.row.heldAmount, true)) || currencyFormat(params.row.currency, 0, true)}`,
                                },
                                {
                                    field: 'billedAmount',
                                    headerName: 'Billing Amount',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.billedAmount && currencyFormat(params.row.currency, params.row.billedAmount, true)) || currencyFormat(params.row.currency, 0, true)}`,
                                },
                                {
                                    field: 'refundReversalAmount',
                                    headerName: 'Refund Reversal Amount',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.refundReversalAmount && currencyFormat(params.row.currency, params.row.refundReversalAmount, true)) || currencyFormat(params.row.currency, 0, true)}`,
                                },
                                {
                                    field: 'rollingReserveReversalAmount',
                                    headerName: 'Rolling Reserve Reversal Amount',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.rollingReserveReversalAmount && currencyFormat(params.row.currency, params.row.rollingReserveReversalAmount, true)) || currencyFormat(params.row.currency, 0, true)}`,
                                },
                                {
                                    field: 'isBilling',
                                    headerName: 'Is Billing',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.isBilling)}`,
                                },
                                {
                                    field: 'isBilled',
                                    headerName: 'Is Billed',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.isBilled)}`,
                                },
                                { 
                                    field: 'billedAt',
                                    headerName: 'Billed At',
                                    width: 210,
                                    valueGetter: (params) => {
                                        let dateString = 'unknown'
                                        if (params && params.row && params.row.billedAt) {
                                            let date = new Date(params.row.billedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'dueDate',
                                    headerName: 'Due Date At',
                                    width: 210,
                                    valueGetter: (params) => {
                                        let dateString = 'unknown'
                                        if (params && params.row && params.row.dueDate) {
                                            let date = new Date(params.row.dueDate)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                {
                                    field: 'createdAt',
                                    headerName: 'Created At',
                                    width: 480,
                                    valueGetter: (params) =>{
                                        let dateString = 'unknown'
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                            ]}
                            rows={billings}
                            rowHeight={38}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Payables</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={(searchCurrency) || ""}
                                            onChange={(e) => {
                                                setSearchCurrency(e.target.value)
                                            }}
                                            label="Transaction"
                                        >
                                            {
                                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                                    if (currency.value && currency.value.currency_alpha3_code) {
                                                        return <MenuItem key={'currency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                                    }
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(null, true)
                                                searchPayables({ organizationId: organization.id}, esSearchReqBody)
                                                setSearchCurrency('')
                                                setIsExpanded(false)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setPayableDialog(true)
                                setPayableDetails({})
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody()
                                searchAfterPayables({organizationId: organization.id}, esSearchReqBody)
                            }}
                            onPageSizeChange={(params) =>{
                                if (searchSize === params.pageSize) return
                                setSearchSize(params.pageSize)
                                setPage(1);
                                setLoadedPage(1)
                                let esSearchReqBody = constructSearchReqBody(params.pageSize, true)
                                searchPayables({organizationId: organization.id}, esSearchReqBody)
                            }}
                            rowCount={totalHitsPayables}
                            rowsPerPageOptions={[10,25,50,100]}
                            pageSize={10}
                            pagination
                            columns={[
                                { 
                                    field: 'organizationId',
                                    headerName: 'Organization ID',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.organizationId) || ""}`,
                                },
                                { 
                                    field: 'id', // this is field was added in the reducer, which is the currency field
                                    headerName: 'Currency',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.id) || ""}`
                                    },
                                },
                                { 
                                    field: 'available',
                                    headerName: 'Available',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.available)}`
                                    },
                                },
                                { 
                                    field: 'held',
                                    headerName: 'Held',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.held)}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'updatedAt',
                                    headerName: 'Updated At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.updatedAt) {
                                            let date = new Date(params.row.updatedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                            ]}
                            rows={payables}
                            onRowClick={async (rowParams) => {
                                setPayableDetails(rowParams.row)
                                console.log(rowParams.row);
                                let esSearchReqBody = constructBillingsSearchReqBody(rowParams.row.currency)
                                postBillingsSearch(esSearchReqBody)
                                setPayableDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default Payables