import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, postContractsSearch, postContractsSearchAfter, clearContractsSearch,
        getEnumeration, createPaymentAccount, clearAlertMessage, alertMessage, postPaymentAccountsSearch, postPaymentAccountsSearchAfter, 
        clearPaymentAccountsSearch, updatePaymentAccounts, deletePaymentAccounts } from '../actions'
import PaymentAccounts from '../components/PaymentAccounts'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.organizations && state.organizations.isLoading) || false,
    contracts: (state.organizations && state.organizations.contracts) || [],
    totalHitsContracts: (state.organizations && state.organizations.totalHitsContracts) || 0,
    search_after_contracts: (state.organizations && state.organizations.search_after_contracts) || [],
    countries: (state.misc && state.misc.enum && state.misc.enum.country) || [],
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    mcc: (state.misc && state.misc.enum && state.misc.enum.mcc) || [],
    environment: (state.appstate && state.appstate.environment) || 'staging',
    paymentAccounts: (state.organizations && state.organizations.paymentAccounts) || [],
    totalHitsPaymentAccounts: (state.organizations && state.organizations.totalHitsPaymentAccounts) || 0,
    search_after_payment_accounts: (state.organizations && state.organizations.search_after_payment_accounts) || [],
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    postContractsSearch: (reqBody) => dispatch(postContractsSearch(reqBody)),
    postContractsSearchAfter: (reqBody) => dispatch(postContractsSearchAfter(reqBody)),
    clearContractsSearch: () => dispatch(clearContractsSearch()),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    createPaymentAccount: (params, reqBody) => dispatch(createPaymentAccount(params, reqBody)),
    clearAlertMessage: (reqBody) => dispatch(clearAlertMessage(reqBody)),
    alertMessage: (reqBody) => dispatch(alertMessage(reqBody)),
    postPaymentAccountsSearch: (reqBody) => dispatch(postPaymentAccountsSearch(reqBody)),
    postPaymentAccountsSearchAfter: (reqBody) => dispatch(postPaymentAccountsSearchAfter(reqBody)),
    clearPaymentAccountsSearch: () => dispatch(clearPaymentAccountsSearch()),
    updatePaymentAccounts: (params, reqBody) => dispatch(updatePaymentAccounts(params, reqBody)),
    deletePaymentAccounts: (params) => dispatch(deletePaymentAccounts(params)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentAccounts)