import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { feeType } from '../app/globals';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const FundingSchedules = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, clearFundingSchedulesSearch, getEnumeration, currencies, clearAlertMessage, alertMessage,
        createFundingSchedule, postFundingSchedulesSearch, search_after_funding_schedules, funding_schedules, postFundingSchedulesSearchAfter, totalHitsFundingSchdules, 
        postPaymentAccountsSearch, paymentAccounts, nextToken } = props
    const [fundingScheduleDialog, setFundingScheduleDialog] = useState(false);
    const [fundingScheduleDetails, setFundingScheduleDetails] = useState({});
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchAccountId, setSearchAccountId] = useState('');
    const [searchLocation, setSearchLocation] = useState('');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody()
        postFundingSchedulesSearch(esSearchReqBody)
        setLoadedPage(1)
        return function cleanup() {
            clearFundingSchedulesSearch()
        };
    },[])

    useEffect(() => {
        if (organization && organization.id) {
            let esSearchReqBody = constructSearchReqBody()
            postFundingSchedulesSearch(esSearchReqBody)
            postPaymentAccountsSearch({ 
                query: `
                query listAccounts {
                    listAccounts(
                        filter: { 
                            ${organization.id ? `organizationId: { eq: "${organization.id}" }` : ""}
                        },
                        limit: 100
                    ) 
                    { items { 
                        id
                        organizationId
                        name
                        contractId
                        processor
                        descriptor
                        country
                        posType
                        sourceTypes
                        createdAt
                        updatedAt
                        credentials
                        merchantProfile
                        currency
                        rollingReserve
                        funding
                        types
                    }, nextToken } 
                }
            `
            })
        }
    },[organization, organization.id])

    const constructSearchReqBody = (passedPageSize, restartSearch=false, paymentServiceAccountName='') => {
        let initialStruct = {};
        let query = ""
        query = `
            query listFundingSchedules {
                listFundingSchedules(
                    organizationId: "${(organization && organization.id) || ""}",
                    nextToken: "${(!restartSearch && nextToken) ? nextToken : ""}",
                    limit: 100,
                ) 
                { items { 
                    accountId
                    location
                    organizationAccountId
                    organizationId
                    term
                    createdAt
                }, nextToken } 
            }
        `
        initialStruct.query = query
        return initialStruct
    }
    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={fundingScheduleDialog} 
            onClose={()=> {setFundingScheduleDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            <CloseIcon className={classes.closeBtn} onClick={()=> {setFundingScheduleDialog(false)}}/>
            {fundingScheduleDetails && !fundingScheduleDetails.id &&<Typography className={classes.Title} color="textSecondary" gutterBottom>Create Funding Schedules</Typography>}
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        fundingScheduleDetails && fundingScheduleDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Organization ID: {fundingScheduleDetails.organizationId}</Typography>
                        </Grid>
                    }
                    {/* Account ID */}
                    {
                        fundingScheduleDetails && fundingScheduleDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account ID: {fundingScheduleDetails.accountId}</Typography>
                        </Grid>
                    }
                    {
                        fundingScheduleDetails && !fundingScheduleDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account</Typography>
                            <GreenAutocomplete
                                id="tags-filled"
                                options={paymentAccounts}
                                getOptionLabel={(option) => {
                                    if (option.organizationId == organization.id) {
                                        return option.name
                                    }
                                    return ""
                                }}
                                onChange={(e,v) => { 
                                    if (v && v.id) {
                                        setFundingScheduleDetails({...fundingScheduleDetails, accountId: v.id, accountsOrganizationId: v.organizationId })
                                    }
                                }}
                                fullWidth
                                freeSolo
                                renderInput={(params) => (
                                    <GreenTextField 
                                        {...params}
                                        variant="filled"
                                        placeholder="Payment Service Accounts"
                                        // onChange={(e) => {
                                        //     let esSearchReqBody = constructSearchReqBody(50, true, e.target.value)
                                        //     postPaymentAccountsSearch(esSearchReqBody)
                                        // }}
                                    />
                                )}
                            />
                        {fundingScheduleDetails && fundingScheduleDetails.accountId && <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account ID: {fundingScheduleDetails.accountId}</Typography>}
                        {fundingScheduleDetails && fundingScheduleDetails.accountsOrganizationId && <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account's Organization ID: {fundingScheduleDetails.accountsOrganizationId}</Typography>}
                    </Grid>
                    }
                    {/* Location */}
                    {
                        fundingScheduleDetails && fundingScheduleDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Location: {fundingScheduleDetails.location}</Typography>
                        </Grid>
                    }
                    {fundingScheduleDetails && !fundingScheduleDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Location</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            value={(fundingScheduleDetails.location) || ''}
                            onChange={(e) => setFundingScheduleDetails({...fundingScheduleDetails, location: e.target.value })} 
                        />
                    </Grid>}
                    {/* Term */}
                    {
                        fundingScheduleDetails && fundingScheduleDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Term: {fundingScheduleDetails.term}</Typography>
                        </Grid>
                    }
                    {fundingScheduleDetails && !fundingScheduleDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Term</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            value={(fundingScheduleDetails.term) || ''}
                            onChange={(e) => setFundingScheduleDetails({...fundingScheduleDetails, term: parseInt(e.target.value) })} 
                        />
                    </Grid>}
                    {/* createdAt */}
                    {
                        fundingScheduleDetails && fundingScheduleDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created At: {fundingScheduleDetails.createdAt && new Date(fundingScheduleDetails.createdAt).toString()}</Typography>
                        </Grid>
                    }
                    {/* {JSON.stringify(fundingScheduleDetails)} */}
                    <Grid item md={11} xs={11} sm={11}>
                        {fundingScheduleDetails && !fundingScheduleDetails.id && <ButtonMod onClick={() => {
                            if (!organization || !organization.id) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization Loaded'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                const currfundingScheduleDetails = fundingScheduleDetails
                                console.log('currfundingScheduleDetails -> ', currfundingScheduleDetails)
                                currfundingScheduleDetails.organizationId = organization.id
                                createFundingSchedule(currfundingScheduleDetails)
                            }
                            setFundingScheduleDialog(false)
                            setFundingScheduleDetails({})
                        }}>
                            CREATE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Funding Schedules</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Location</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchLocation) || ''}
                                            onChange={(e) => setSearchLocation( e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(null, true)
                                                postFundingSchedulesSearch(esSearchReqBody)
                                                setSearchLocation('')
                                                setIsExpanded(false)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setFundingScheduleDialog(true)
                                setFundingScheduleDetails({})
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(event) => {                                
                                // console.log('onPageChange FIRED first part !!', event.page , ' ', loadedPage);
                                // console.log('onPageChange event.page -> ', event.page);
                                if (page >= event.page) return
                                // setPage(event.page);
                                if (event.page <= loadedPage) return
                                // console.log('onPageChange FIRED second part !!', event.page , ' ', loadedPage);
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody(searchSize, false)
                                // postPaymentsSearchAfter({organizationId: organization.id}, esSearchReqBody)
                                console.log('=== NEXT TOKEN ====');
                                console.log(nextToken);
                                if (!nextToken) {
                                    return;
                                }
                                console.log('IF EMPTY SHOULD NOT FIRE');
                                postFundingSchedulesSearchAfter(esSearchReqBody)
                            }}
                            hideFooterRowCount
                            rowCount={nextToken ? 9999999999999 : (funding_schedules.length)}
                            rowsPerPageOptions={[10]}
                            pageSize={13}
                            pagination={true}
                            columns={[
                                { 
                                    field: 'organizationId',
                                    headerName: 'Organization ID',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.organizationId) || ""}`,
                                },
                                { 
                                    field: 'id', // this is field was added in the reducer, which is the currency field
                                    headerName: 'Payment Service Account ID',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.id) || ""}`
                                    },
                                },
                                { 
                                    field: 'location',
                                    headerName: 'Location',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.location)}`
                                    },
                                },
                                { 
                                    field: 'term',
                                    headerName: 'Term',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.term)}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                            ]}
                            rows={funding_schedules}
                            onRowClick={async (rowParams) => {
                                setFundingScheduleDetails(rowParams.row)
                                setFundingScheduleDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default FundingSchedules