import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, fetchOrganization, getEnumeration, 
    GetOrgAccounts, GetOrgPos, postPaymentsSearch, postPaymentsSearchAfter, 
    clearPaymentsSearch, updatePaymentsSearch } from '../actions'
import Payments from '../components/Payments'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.payments && state.payments.isLoading) || false,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    organizationPos: state.organizations.organizationPos || [],
    orgPmtSvcAccs: (state.organizations.orgPmtSvcAccs && state.organizations.orgPmtSvcAccs.accounts) || [],
    nextToken: (state.payments && state.payments.nextToken) || "",
    hits: (state.payments && state.payments.hits) || [],
    environment: (state.appstate && state.appstate.environment) || 'staging',
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    fetchOrganization: (params) => dispatch(fetchOrganization(params)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    GetOrgPos: (params) => dispatch(GetOrgPos(params)),
    GetOrgAccounts: (params) => dispatch(GetOrgAccounts(params)),
    postPaymentsSearch: (reqBody) => dispatch(postPaymentsSearch(reqBody)),
    postPaymentsSearchAfter: (reqBody) => dispatch(postPaymentsSearchAfter(reqBody)),
    clearPaymentsSearch: () => dispatch(clearPaymentsSearch()),
    updatePaymentsSearch: (reqBody) => dispatch(updatePaymentsSearch(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Payments)