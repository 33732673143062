function contracts(state ={isLoading: false, hits: [], search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'POST_CONTRACTS_SEARCH_REQUEST':
            // console.log('POST_CONTRACTS_SEARCH_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_CONTRACTS_SEARCH_SUCCESS':
            console.log('POST_CONTRACTS_SEARCH_SUCCESS', action);
            const listContracts = (action.data && action.data.data && action.data.data.listContracts) || null
            let hits = (listContracts && listContracts.items) ? listContracts.items : []
            const nextToken = (listContracts && listContracts.nextToken) ? listContracts.nextToken : undefined
            hits = hits.map((eachHit) => {
                if (eachHit && eachHit.fees) {
                    eachHit.fees = JSON.parse(eachHit.fees)
                }
                return eachHit
            })

            hits.sort((a,b) => a.version - b.version)
            let hitVersioonMap = {}
            hits.map((eachHit) => {
                if (!(eachHit.id in hitVersioonMap)) {
                    hitVersioonMap[eachHit.id] = eachHit.version
                } else {
                    if (hitVersioonMap[eachHit.id] && eachHit.version > hitVersioonMap[eachHit.id]) {
                        hitVersioonMap[eachHit.id] = eachHit.version
                    }
                }
            })
            hits = hits.map((eachHit)=> {
                if (hitVersioonMap[eachHit.id] && hitVersioonMap[eachHit.id] === eachHit.version) {
                    return eachHit
                } else {
                    return ''
                }
            }).filter((eachHit) => eachHit)
            return {...state,
                hits: hits,
                nextToken: nextToken,
                isLoading: false
            }
        case 'POST_CONTRACTS_SEARCH_FAILURE':
            // console.log('POST_CONTRACTS_SEARCH_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_CONTRACTS_SEARCH_AFTER_REQUEST':
            // console.log('POST_CONTRACTS_SEARCH_AFTER_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_CONTRACTS_SEARCH_AFTER_SUCCESS':
            console.log('POST_CONTRACTS_SEARCH_AFTER_SUCCESS', action);
            const listNextContracts = (action.data && action.data.data && action.data.data.listContracts) || null
            let nextHits = (listNextContracts && listNextContracts.items) ? listNextContracts.items : []
            const nextNextToken = listNextContracts ? listNextContracts.nextToken : undefined
            const currentContractIDs = state.hits.map((contract) => contract.id)

            nextHits = nextHits.map((eachHit)=> {
                if (currentContractIDs.indexOf(eachHit.id) >= 0) return '';
                return eachHit
            }).filter((eachHit) => eachHit)
            let combinedHits = state.hits.concat(nextHits);
            combinedHits = combinedHits.map((eachHit) => {
                if (eachHit && eachHit.fees && (typeof eachHit.fees === "string")) {
                    eachHit.fees = JSON.parse(eachHit.fees)
                }
                return eachHit
            })

            combinedHits.sort((a,b) => a.version - b.version)
            let nextHitVersioonMap = {}
            combinedHits.map((eachHit) => {
                if (!eachHit.id in nextHitVersioonMap) {
                    nextHitVersioonMap[eachHit.id] = eachHit.version
                } else {
                    if (nextHitVersioonMap[eachHit.id] && eachHit.version > nextHitVersioonMap[eachHit.id]) {
                        nextHitVersioonMap[eachHit.id] = eachHit.version
                    }
                }
            })
            combinedHits = combinedHits.map((eachHit)=> {
                if (nextHitVersioonMap[eachHit.id] && nextHitVersioonMap[eachHit.id].version === eachHit.version) {
                    return eachHit
                } else {
                    return ''
                }
            }).filter((eachHit) => eachHit)
            return {...state,
                hits: combinedHits,
                nextToken: nextNextToken,
                isLoading: false
            }
        case 'POST_CONTRACTS_SEARCH_AFTER_FAILURE':
            // console.log('POST_CONTRACTS_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_CONTRACTS_SEARCH':
            return {...state,
                hits: [],
                totalHits: 0,
                search_after: [],
            }
        // case 'UPDATE_CONTRACTS_SEARCH':
        //     // console.log('UPDATE_CONTRACTS_SEARCH', action);
        //     let targetUpdatePaymentId = (action && action.reqBody && action.reqBody.payment && action.reqBody.payment.id) || null
        //     const newHits = state.hits.map((eachHit) => {
        //         if (eachHit.id === targetUpdatePaymentId) {
        //             // console.log('target eachHit -> ', eachHit);
        //             // console.log('intended update payment -> ', action.reqBody.payment);
        //             return action.reqBody.payment
        //         }
        //         return eachHit
        //     })
        //     return {...state,
        //         hits: newHits
        //     }
        default:
            return {...state, isLoading: false} 
    }
  }

export default contracts