import { CALL_FUNDINGS_BILLINGS_API } from '../app/middleware/callFundingsBillingsApi.js';

// Funding
export const createFundingSchedule = (reqBody) => ({
    types: ['POST_CREATE_FUNDING_SCHEDULE_REQUEST', 'POST_CREATE_FUNDING_SCHEDULE_SUCCESS', 'POST_CREATE_FUNDING_SCHEDULE_FAILURE'],
    [CALL_FUNDINGS_BILLINGS_API]: '/funding-schedules',
    method: 'post',
    reqBody: reqBody,
})

export const searchFundingSchedules = (params, reqBody) => ({
    types: ['POST_SEARCH_FUNDING_SCHEDULES_REQUEST', 'POST_SEARCH_FUNDING_SCHEDULES_SUCCESS', 'POST_SEARCH_FUNDING_SCHEDULES_FAILURE'],
    [CALL_FUNDINGS_BILLINGS_API]: '/funding-schedules/organizations/:organizationId/search',
    method: 'post',
    params: params,
    reqBody: reqBody,
})

export const searchAfterFundingSchedules = (params, reqBody) => ({
    types: ['POST_SEARCH_AFTER_FUNDING_SCHEDULES_REQUEST', 'POST_SEARCH_AFTER_FUNDING_SCHEDULES_SUCCESS', 'POST_SEARCH_AFTER_FUNDING_SCHEDULES_FAILURE'],
    [CALL_FUNDINGS_BILLINGS_API]: '/funding-schedules/organizations/:organizationId/search',
    method: 'post',
    params: params,
    reqBody: reqBody,
})

export const clearFundingSchedules = () => ({
    type: 'SET_CLEAR_FUNDING_SCHEDULES',
})

// Billing
export const createBillingSchedule = (reqBody) => ({
    types: ['POST_CREATE_BILLING_SCHEDULE_REQUEST', 'POST_CREATE_BILLING_SCHEDULE_SUCCESS', 'POST_CREATE_BILLING_SCHEDULE_FAILURE'],
    [CALL_FUNDINGS_BILLINGS_API]: '/billing-schedules',
    method: 'post',
    reqBody: reqBody,
})

export const searchBillingSchedules = (params, reqBody) => ({
    types: ['POST_SEARCH_BILLING_SCHEDULES_REQUEST', 'POST_SEARCH_BILLING_SCHEDULES_SUCCESS', 'POST_SEARCH_BILLING_SCHEDULES_FAILURE'],
    [CALL_FUNDINGS_BILLINGS_API]: '/billing-schedules/organizations/{organizationId}/search',
    method: 'post',
    params: params,
    reqBody: reqBody,
})

export const searchAfterBillingSchedules = (params, reqBody) => ({
    types: ['POST_SEARCH_AFTER_BILLING_SCHEDULES_REQUEST', 'POST_SEARCH_AFTER_BILLING_SCHEDULES_SUCCESS', 'POST_SEARCH_AFTER_BILLING_SCHEDULES_FAILURE'],
    [CALL_FUNDINGS_BILLINGS_API]: '/billing-schedules/organizations/{organizationId}/search',
    method: 'post',
    params: params,
    reqBody: reqBody,
})

export const clearBillingSchedules = () => ({
    type: 'SET_CLEAR_BILLING_SCHEDULES',
})