import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, clearAlertMessage, alertMessage, searchBillingSchedules, searchAfterBillingSchedules, 
    createBillingSchedule, clearBillingSchedules, postPaymentAccountsSearch,
    postBillingSchedulesSearch, postBillingSchedulesSearchAfter, clearBillingSchedulesSearch
    } from '../actions'
import BillingSchedules from '../components/BillingSchedules.js'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.schedules && state.schedules.isLoading) || false,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    billing_schedules: (state.schedules && state.schedules.billingHits) || [],
    nextToken: (state.schedules && state.schedules.billingNextToken) || "",
    totalHitsBillingSchdules: (state && state.schedules && state.schedules.billingHits && state.schedules.billingHits.length) || 0,
    search_after_billing_schedules: (state.fundingsBillings && state.fundingsBillings.search_after_billing_schedules) || [],
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    clearAlertMessage: (reqBody) => dispatch(clearAlertMessage(reqBody)),
    alertMessage: (reqBody) => dispatch(alertMessage(reqBody)),
    postBillingSchedulesSearch: (reqBody) => dispatch(postBillingSchedulesSearch(reqBody)),
    postBillingSchedulesSearchAfter: (reqBody) => dispatch(postBillingSchedulesSearchAfter(reqBody)),
    createBillingSchedule: (reqBody) => dispatch(createBillingSchedule(reqBody)),
    clearBillingSchedulesSearch: () => dispatch(clearBillingSchedulesSearch())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BillingSchedules)