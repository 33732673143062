import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { feeType, allTransactionModes, finalRiskRatingQuestions, allInvoicestatus } from '../app/globals';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const Invoices = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, clearInvoices, getEnumeration, currencies, countries, clearAlertMessage, alertMessage,
        createInvoice, searchInvoice, invoiceNextToken, invoices, searchAfterInvoice, totalHitsInvoices, postKYCsSearch,
        allKycs, updateInvoice, getInvoice, cancelInvoice, completeInvoice, user, 
    } = props
    const [invoiceDialog, setInvoiceDialog] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState({});
    const [searchOrganizationName, setSearchOrganizationName] = useState(0);
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchOrganizationId, setSearchOrganizationId] = useState('');
    const [searchTxnMode, setSearchTxnMode] = useState('');
    const [searchTxnReference, setSearchTxnReference] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody()
        searchInvoice(esSearchReqBody)
        setLoadedPage(1)
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearInvoices()
        };
    },[])

    useEffect(() => {
        if (organization && organization.id) {
            let esSearchReqBody = constructSearchReqBody()
            searchInvoice(esSearchReqBody)
        }
    },[organization, organization.id])

    const constructSearchReqBody = (passedCurrency="",restartSearch=false) => {
        // console.log('process.env.MATERIAL_LICENSE_KEY -> ', process.env.REACT_APP_MATERIAL_LICENSE_KEY);
        let initialStruct = {}
        let dateQuery = ""
        // if (dateFrom || dateTo) {
        //     dateQuery += "{"
        //     if (!dateFrom || !dateTo) {
        //         if (dateFrom) dateQuery += `ge: "${dateFrom}" `
        //         if (dateTo) dateQuery += `le: "${dateTo}"`
        //     } else if (dateFrom && dateTo) {
        //         dateQuery += `between: ["${dateFrom}", "${dateTo}"]`
        //     }
        //     dateQuery += "}"
        // }
        let query = 
        `
            query listInvoices($organizationId: String!) {
                queryInvoicesByOrganizationId(
                    queryInput: { 
                        organizationId: { 
                            eq: $organizationId 
                        }
                    }
                    ${(dateQuery || passedCurrency) ? `filter: { 
                        ${dateQuery ? `createdAt: ${dateQuery}` : ""}
                        ${passedCurrency ? `currency: { eq: "${passedCurrency}" }` : ""}
                    }` : ""}
                    nextToken: "${(!restartSearch && invoiceNextToken) ? invoiceNextToken : ""}",
                ) 
                { items { 
                    id
                    organizationId
                    status
                    currency
                    amount
                    fees {
                        amount
                        currency
                        description
                    }
                    billings {
                        accountId
                        id
                        currency
                        amount
                        heldAmount
                        billedAmount
                        refundReversalAmount
                        refundReversalHeldAmount
                        refundReversalBilledAmount
                        rollingReserveReversalAmount
                        rollingReserveReversalHeldAmount
                        rollingReserveReversalBilledAmount
                        isBilled
                        isRefundReversalBilled
                        isRollingReserveReversalBilled
                    }
                    transactionMode
                    transactionReference
                    approvedBy
                    originator {
                        isEntity
                        identification
                        name
                        dob
                        bank {
                            name
                            code
                            branch
                            swift
                            account {
                                number
                                name
                                currency
                            }
                        }
                    }
                    benefactor {
                        isEntity
                        identification
                        name
                        dob
                        bank {
                            name
                            code
                            branch
                            swift
                            account {
                                number
                                name
                                currency
                            }
                        }
                    }
                    processedAt
                    createdAt
                    updatedAt
                }, nextToken } 
            }
        `
        initialStruct.variables = {
            organizationId: organization.id
        }
        initialStruct.query = query
        return initialStruct
    }

    const constructSearchOrganizationReqBody = (organziationName='') => {
        let initialStruct = {
            query: {},
            from: 0,
            size: 10,
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        if (organziationName) {
            initialStruct.query.regexp = {
                name: {
                    value: organziationName + ".*",
                    flags: "ALL",
                    max_determinized_states: 10000,
                    rewrite: "constant_score"
                }
            }
        }

        return initialStruct
    }

    const constructLatestKYCReqBody = (rowData) => {
        let initialStruct = {
            from: 0,
            size: 7,
            query: {
                bool: {
                    must: [
                    ]
                },
            },
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        initialStruct.query.bool.must.push(
            { "match": { "organization.id": (rowData && rowData.organizationId) || "" }}
        )
        initialStruct.query.bool.must.push(
            { "match": { "isCompleted": true }}
        )
        return initialStruct
    }

    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={invoiceDialog} 
            onClose={()=> {setInvoiceDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            <CloseIcon className={classes.closeBtn} onClick={()=> {setInvoiceDialog(false)}}/>
            {invoiceDetails && !invoiceDetails.id &&<Typography className={classes.Title} color="textSecondary" gutterBottom>Create Invoice</Typography>}
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        invoiceDetails && invoiceDetails.id && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Organization ID: {invoiceDetails.organizationId}</Typography>
                        </Grid>
                    }
                    {/* Invoice ID */}
                    {
                        invoiceDetails && invoiceDetails.id && <Grid item md={5} xs={5} sm={5}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Invoice ID: {invoiceDetails.id}</Typography>
                        </Grid>
                    }
                    {/* Benefactor */}
                    {/* Benefactor Edit */}
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status === "PENDING_PAYMENT" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Benefactor Address:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 1:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.line1) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.address.line1 = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 2:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.line2) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.address.line2 = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>City:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.city) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.address.city = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.state) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.address.state = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.country) || 'Select Country'}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.address.country = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }}
                                        label="Type"
                                >
                                        {
                                            countries.map(
                                                (country, index) => {
                                                return <MenuItem key={index} value={(country  && country.value && country.value.country_alpha3_code) || ""}>{(country && country.name) || "Unknown Country"}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'select_country'} value={'Select Country'}>Select Country</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Postal:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.postal) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.address.postal = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                        </Grid></Grid>
                    }
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status === "PENDING_PAYMENT" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Benefactor Bank:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.name) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.bank.name = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Branch Code:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.branch) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.bank.branch = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Swift Code:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.swift) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.bank.swift = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Name:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.account && invoiceDetails.benefactor.bank.account.name) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.bank.account.name = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.account && invoiceDetails.benefactor.bank.account.number) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.bank.account.number = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.account && invoiceDetails.benefactor.bank.account.currency) || 'Select Currency'}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.benefactor.bank.account.currency = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }}
                                        label="Type"
                                >
                                        {
                                            currencies.map(
                                                (currency, index) => {
                                                return <MenuItem key={index} value={(currency && currency.value && currency.value.currency_alpha3_code) || ""}>{(currency && currency.name) || "Unknown Currency"}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* Benefactor Complete */}
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status !== "PENDING_PAYMENT" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Benefactor Address:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 1:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.line1)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 2:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.line2)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>City:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.city)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.state)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.country)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Postal:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.address && invoiceDetails.benefactor.address.postal)}
                                </Grid>
                        </Grid></Grid>
                    }
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status === "PAID" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Benefactor Bank:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.name)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Branch Code:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.branch)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Swift Code:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.swift)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Name:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.account && invoiceDetails.benefactor.bank.account.name)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number:</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.account && invoiceDetails.benefactor.bank.account.number)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                    {(invoiceDetails && invoiceDetails.benefactor && invoiceDetails.benefactor.bank && invoiceDetails.benefactor.bank.account && invoiceDetails.benefactor.bank.account.currency)}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* Originator */}
                    {/* Originator Edit */}
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status === "PENDING_PAYMENT" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Originator Address:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 1:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.line1) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.address.line1 = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 2:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.line2) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.address.line2 = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>City:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.city) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.address.city = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.state) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.address.state = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.country) || 'Select Country'}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.address.country = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }}
                                        label="Type"
                                >
                                        {
                                            countries.map(
                                                (country, index) => {
                                                return <MenuItem key={index} value={(country  && country.value && country.value.country_alpha3_code) || ""}>{(country && country.name) || "Unknown Country"}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'select_country'} value={'Select Country'}>Select Country</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Postal:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.postal) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.address.postal = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                        </Grid></Grid>
                    }
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status === "PENDING_PAYMENT" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Originator Bank:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.name) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.bank.name = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Branch Code:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.branch) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.bank.branch = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Swift Code:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.swift) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.bank.swift = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Name:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.account && invoiceDetails.originator.bank.account.name) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.bank.account.name = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.account && invoiceDetails.originator.bank.account.number) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.bank.account.number = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.account && invoiceDetails.originator.bank.account.currency) || 'Select Currency'}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.originator.bank.account.currency = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }}
                                        label="Type"
                                >
                                        {
                                            currencies.map(
                                                (currency, index) => {
                                                return <MenuItem key={index} value={(currency && currency.value && currency.value.currency_alpha3_code) || ""}>{(currency && currency.name) || "Unknown Currency"}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* Originator Complete */}
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status !== "PENDING_PAYMENT" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Originator Address:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 1:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.line1)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 2:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.line2)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>City:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.city)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.state)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.country)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Postal:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.address && invoiceDetails.originator.address.postal)}
                                </Grid>
                        </Grid></Grid>
                    }
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status !== "PENDING_PAYMENT" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Originator Bank:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.name)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Branch Code:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.branch)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Swift Code:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.swift)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Name:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.account && invoiceDetails.originator.bank.account.name)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number:</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.account && invoiceDetails.originator.bank.account.number)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                    {(invoiceDetails && invoiceDetails.originator && invoiceDetails.originator.bank && invoiceDetails.originator.bank.account && invoiceDetails.originator.bank.account.currency)}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* Transaction Details Edit */}
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status === "PENDING_PAYMENT" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Details:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Mode:</Typography>
                                    <Select
                                        fullWidth
                                        className={classes.select}
                                        variant="outlined"
                                        value={(invoiceDetails && invoiceDetails.transactionMode) || ""}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.transactionMode = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }}
                                        label="Payment Mode"
                                        >
                                        {allTransactionModes.map((paymentMode) => (
                                            <MenuItem key={paymentMode} value={paymentMode}>{paymentMode}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Reference:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(invoiceDetails && invoiceDetails.transactionReference) || ''}
                                        onChange={(e) => {
                                            let currinvoiceDetails = JSON.parse(JSON.stringify(invoiceDetails))
                                            currinvoiceDetails.transactionReference = e.target.value
                                            setInvoiceDetails(currinvoiceDetails)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* Transaction Details Complete */}
                    {
                        invoiceDetails && invoiceDetails.id && invoiceDetails.status !== "PENDING_PAYMENT" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Details:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Mode:</Typography>
                                    {invoiceDetails.transactionMode}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Reference:</Typography>
                                    {invoiceDetails.transactionReference}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* createdAt */}
                    {
                        invoiceDetails && invoiceDetails.id && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created At: {invoiceDetails.createdAt && new Date(invoiceDetails.createdAt).toString()}</Typography>
                        </Grid>
                    }
                    <Grid item md={11} xs={11} sm={11}>
                        {invoiceDetails && invoiceDetails.id && invoiceDetails.status === "PENDING_PAYMENT" && <ButtonMod onClick={() => {
                            if (!invoiceDetails || !invoiceDetails.organizationId) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization in Invoice'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                cancelInvoice({ invoiceId: invoiceDetails.id }, { organizationId: invoiceDetails.organizationId })
                            }
                            setInvoiceDialog(false)
                            setInvoiceDetails({})
                        }}>
                            CANCEL
                        </ButtonMod>}&nbsp;&nbsp;
                        {invoiceDetails && invoiceDetails.id && invoiceDetails.status === "PENDING_PAYMENT" && <ButtonMod onClick={() => {
                            if (!invoiceDetails || !invoiceDetails.id) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Invoices Loaded'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                updateInvoice({invoiceId: invoiceDetails.id }, invoiceDetails)
                            }
                            setInvoiceDialog(false)
                            setInvoiceDetails({})
                        }}>
                            UPDATE
                        </ButtonMod>}
                        &nbsp;&nbsp;
                        {invoiceDetails && invoiceDetails.id && invoiceDetails.status === "PENDING_PAYMENT" && <ButtonMod onClick={() => {
                            if (!invoiceDetails || !invoiceDetails.organizationId) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization in Invoice'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                completeInvoice({ invoiceId: invoiceDetails.id }, { organizationId: invoiceDetails.organizationId })
                            }
                            setInvoiceDialog(false)
                            setInvoiceDetails({})
                        }}>
                            COMPLETE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Invoices</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Invoice Status</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={searchStatus}
                                            onChange={(e) => {
                                                setSearchStatus(e.target.value)
                                            }}
                                            label="Type"
                                        >
                                            {
                                                allInvoicestatus.map(
                                                    (status, index) => {
                                                    return <MenuItem key={status + index} value={status}>{status}</MenuItem>
                                                })
                                            }
                                            <MenuItem key={'select_status'} value={""}>Select Status</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Mode:</Typography>
                                        <Select
                                            fullWidth
                                            className={classes.select}
                                            variant="outlined"
                                            value={(searchTxnMode) || ""}
                                            onChange={(e) => {
                                                setSearchTxnMode(e.target.value)
                                            }}
                                            label="Payment Mode"
                                            >
                                            {allTransactionModes.map((paymentMode) => (
                                                <MenuItem key={paymentMode} value={paymentMode}>{paymentMode}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Reference:</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchTxnReference) || ''}
                                            onChange={(e) => {
                                                setSearchTxnReference(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(null, true)
                                                searchInvoice(esSearchReqBody)
                                                setIsExpanded(false)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setInvoiceDialog(true)
                                setInvoiceDetails({})
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody()
                                searchAfterInvoice(esSearchReqBody)
                            }}
                            onPageSizeChange={(params) =>{
                                if (searchSize === params.pageSize) return
                                setSearchSize(params.pageSize)
                                setPage(1);
                                setLoadedPage(1)
                                let esSearchReqBody = constructSearchReqBody(null, true)
                                searchInvoice(esSearchReqBody)
                            }}
                            rowCount={totalHitsInvoices}
                            rowsPerPageOptions={[10,25,50,100]}
                            pageSize={10}
                            pagination
                            columns={[
                                {
                                    field: 'organizationId',
                                    headerName: 'Organization ID',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.organizationId) || ""}`,
                                },
                                { 
                                    field: 'id', // this is field was added in the reducer, which is the currency field
                                    headerName: 'Invoice ID',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.id) || ""}`
                                    },
                                },
                                { 
                                    field: 'amount',
                                    headerName: 'Amount',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.amount)}`
                                    },
                                },
                                { 
                                    field: 'currency',
                                    headerName: 'Currency',
                                    width: 140,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.currency)}`
                                    },
                                },
                                { 
                                    field: 'transactionMode',
                                    headerName: 'Transaction Mode',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.transactionMode) || ""}`
                                    },
                                },
                                { 
                                    field: 'transactionReference',
                                    headerName: 'Transaction Reference',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.transactionReference) || ""}`
                                    },
                                },
                                { 
                                    field: 'status',
                                    headerName: 'Status',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.status) || ""}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'updatedAt',
                                    headerName: 'Updated At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.updatedAt) {
                                            let date = new Date(params.row.updatedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                }
                            ]}
                            rows={invoices}
                            onRowClick={async (rowParams) => {
                                setInvoiceDetails(rowParams.row)
                                setInvoiceDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default Invoices