import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, clearAlertMessage, alertMessage, searchInvoice, 
    searchAfterInvoice, createInvoice, clearInvoices, postPaymentAccountsSearch, postKYCsSearch, updateInvoice,
    getInvoice, cancelInvoice, completeInvoice, postSearchOrganizations
    } from '../actions'
import Invoices from '../components/Invoices.js'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.payoutsInvoices && state.payoutsInvoices.isLoading) || false,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    countries: (state.misc && state.misc.enum && state.misc.enum.country) || [],
    invoices: (state.payoutsInvoices && state.payoutsInvoices.invoices) || [],
    invoiceNextToken: (state.payoutsInvoices && state.payoutsInvoices.invoiceNextToken) || [],
    allKycs: (state.kyc && state.kyc.allKycs) || [],
    user: state.users.user,
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    clearAlertMessage: (reqBody) => dispatch(clearAlertMessage(reqBody)),
    alertMessage: (reqBody) => dispatch(alertMessage(reqBody)),
    searchInvoice: (reqBody) => dispatch(searchInvoice(reqBody)),
    searchAfterInvoice: (reqBody) => dispatch(searchAfterInvoice(reqBody)),
    createInvoice: (reqBody) => dispatch(createInvoice(reqBody)),
    clearInvoices: () => dispatch(clearInvoices()),
    postKYCsSearch: (reqBody) => dispatch(postKYCsSearch(reqBody)),
    updateInvoice: (params, reqBody) => dispatch(updateInvoice(params, reqBody)),
    getInvoice: (params) => dispatch(getInvoice(params)),
    cancelInvoice: (params, reqBody) => dispatch(cancelInvoice(params, reqBody)),
    completeInvoice: (params, reqBody) => dispatch(completeInvoice(params, reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Invoices)