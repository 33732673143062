import { CALL_BILLING_SCHEDULES_APPSYNC_API } from '../app/middleware/callBillingSchedulesAppSync.js';
import { CALL_FUNDING_SCHEDULES_APPSYNC_API } from '../app/middleware/callFundingSchedulesAppSync.js';

export const postFundingSchedulesSearch = (reqBody) => ({
    types: ['POST_FUNDING_SCHEDULES_SEARCH_REQUEST', 'POST_FUNDING_SCHEDULES_SEARCH_SUCCESS', 'POST_FUNDING_SCHEDULES_SEARCH_FAILURE'],
    [CALL_FUNDING_SCHEDULES_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postFundingSchedulesSearchAfter = (reqBody) => ({
    types: ['POST_FUNDING_SCHEDULES_SEARCH_AFTER_REQUEST', 'POST_FUNDING_SCHEDULES_SEARCH_AFTER_SUCCESS', 'POST_FUNDING_SCHEDULES_SEARCH_AFTER_FAILURE'],
    [CALL_FUNDING_SCHEDULES_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const clearFundingSchedulesSearch = () => ({
    type: 'CLEAR_FUNDING_SCHEDULES_SEARCH'
})

export const updateFundingSchedulesSearch = (reqBody) => ({
    type: 'UPDATE_FUNDING_SCHEDULES_SEARCH',
    reqBody: reqBody
})

export const postBillingSchedulesSearch = (reqBody) => ({
    types: ['POST_BILLING_SCHEDULES_SEARCH_REQUEST', 'POST_BILLING_SCHEDULES_SEARCH_SUCCESS', 'POST_BILLING_SCHEDULES_SEARCH_FAILURE'],
    [CALL_BILLING_SCHEDULES_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postBillingSchedulesSearchAfter = (reqBody) => ({
    types: ['POST_BILLING_SCHEDULES_SEARCH_AFTER_REQUEST', 'POST_BILLING_SCHEDULES_SEARCH_AFTER_SUCCESS', 'POST_BILLING_SCHEDULES_SEARCH_AFTER_FAILURE'],
    [CALL_BILLING_SCHEDULES_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const clearBillingSchedulesSearch = () => ({
    type: 'CLEAR_BILLING_SCHEDULES_SEARCH'
})

export const updateBillingSchedulesSearch = (reqBody) => ({
    type: 'UPDATE_BILLING_SCHEDULES_SEARCH',
    reqBody: reqBody
})

