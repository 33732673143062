import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont } from '../actions'
import CompanyDashboard from '../components/CompanyDashboard'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyDashboard)