import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { feeType, allTransactionModes, finalRiskRatingQuestions, allPayoutStatus } from '../app/globals';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const DateTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const Payouts = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, clearPayouts, getEnumeration, currencies, countries, clearAlertMessage, alertMessage,
        createPayout, searchPayout, payouts, searchAfterPayout, postKYCsSearch,
        allKycs, updatePayout, approvePayout, cancelPayout, completePayout, user, nextToken 
    } = props
    const [payoutDialog, setPayoutDialog] = useState(false);
    const [payoutDetails, setPayoutDetails] = useState({});
    const [searchOrganizationName, setSearchOrganizationName] = useState(0);
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchOrganizationId, setSearchOrganizationId] = useState('');
    const [searchLocation, setSearchLocation] = useState('');
    const [searchStatus, setSearchStatus] = useState('PENDING_APPROVAL');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody()
        searchPayout(esSearchReqBody)
        setLoadedPage(1)
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearPayouts()
        };
    },[])

    useEffect(() => {
        if (organization && organization.id) {
            let esSearchReqBody = constructSearchReqBody()
            searchPayout(esSearchReqBody)
        }
    },[organization, organization.id])

    const constructSearchReqBody = (passedPageSize, restartSearch=false) => {
        let initialStruct = {}
        let query = `
            query listPayouts($status: String!) {
                queryPayoutsByStatus(
                    status: $status 
                    nextToken: "${(!restartSearch && nextToken) ? nextToken : ""}"
                ) 
                { items { 
                    id
                    kycId
                    organizationId
                    createdAt
                    amount
                    benefactor {
                        isEntity
                        identification
                        name
                        dob
                        address {
                            line1
                            line2
                            city
                            state
                            country
                            postal
                        }
                        bank {
                            name
                            code
                            branch
                            swift
                            account {
                                number
                                name
                                currency
                            }
                        }
                    }
                    fundings {
                        accountId
                        id
                        amount
                        rollingReserveAmount
                    }
                    originator {
                        isEntity
                        identification
                        name
                        dob
                        address {
                            line1
                            line2
                            city
                            state
                            country
                            postal
                        }
                        bank {
                            name
                            code
                            branch
                            swift
                            account {
                                number
                                name
                                currency
                            }
                        }
                    }
                    transactionMode
                    currency
                    status
                    processedAt
                    updatedAt
                }, nextToken } 
            }
        `
        initialStruct.query = query
        initialStruct.variables = {
            status: (searchStatus ? searchStatus : "PENDING_APPROVAL")
        }
        return initialStruct
    }

    const constructSearchOrganizationReqBody = (organziationName='') => {
        let initialStruct = {
            query: {},
            from: 0,
            size: 10,
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        if (organziationName) {
            initialStruct.query.regexp = {
                name: {
                    value: organziationName + ".*",
                    flags: "ALL",
                    max_determinized_states: 10000,
                    rewrite: "constant_score"
                }
            }
        }

        return initialStruct
    }

    const constructLatestKYCReqBody = (rowData) => {
        let initialStruct = {
            from: 0,
            size: 7,
            query: {
                bool: {
                    must: [
                    ]
                },
            },
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        initialStruct.query.bool.must.push(
            { "match": { "organization.id": (rowData && rowData.organizationId) || "" }}
        )
        initialStruct.query.bool.must.push(
            { "match": { "isCompleted": true }}
        )
        return initialStruct
    }

    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={payoutDialog} 
            onClose={()=> {setPayoutDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            <CloseIcon className={classes.closeBtn} onClick={()=> {setPayoutDialog(false)}}/>
            {payoutDetails && !payoutDetails.id &&<Typography className={classes.Title} color="textSecondary" gutterBottom>Create Payouts</Typography>}
            <Typography className={classes.title} color="error" gutterBottom>!Please remember to do your KYC first</Typography>
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        payoutDetails && payoutDetails.id && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Organization ID: {payoutDetails.organizationId}</Typography>
                        </Grid>
                    }
                    {
                        payoutDetails && !payoutDetails.id && <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Organization ID:</Typography>
                            <OutlinedInputMod 
                                maxWidth={'md'}
                                fullWidth
                                value={(payoutDetails && payoutDetails.organizationId) || ''}
                                onChange={(e) => {
                                    let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                    currPayoutDetails.organizationId = e.target.value
                                    setPayoutDetails(currPayoutDetails)
                                }} 
                            />
                        </Grid>
                    }
                    {
                        payoutDetails && !payoutDetails.id && <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Mode:</Typography>
                            <Select
                                fullWidth
                                className={classes.select}
                                variant="outlined"
                                value={(payoutDetails && payoutDetails.transactionMode) || ""}
                                onChange={(e) => {
                                    let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                    currPayoutDetails.transactionMode = e.target.value
                                    setPayoutDetails(currPayoutDetails)
                                }}
                                label="Transaction Mode"
                                >
                                {allTransactionModes.map((paymentMode) => (
                                    <MenuItem key={paymentMode} value={paymentMode}>{paymentMode}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    }
                    {payoutDetails && !payoutDetails.id && <Grid item md={12} xs={12} sm={12}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    className={classes.select}
                                    value={(payoutDetails && payoutDetails.currency) || 'Select Currency'}
                                    onChange={(e) => {
                                        let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                        currPayoutDetails.currency = e.target.value
                                        setPayoutDetails(currPayoutDetails)
                                    }}
                                    label="Type"
                            >
                                    {
                                        currencies.map(
                                            (currency, index) => {
                                            return <MenuItem key={index} value={(currency && currency.value && currency.value.currency_alpha3_code) || ""}>{(currency && currency.name) || "Unknown Currency"}</MenuItem>
                                        })
                                    }
                                    <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                                </Select>
                            </Grid>
                    }
                    {
                        payoutDetails && !payoutDetails.id && <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Amount</Typography>
                            <OutlinedInputMod 
                                maxWidth={'md'}
                                fullWidth
                                value={(payoutDetails && payoutDetails.amount) || ''}
                                onChange={(e) => {
                                    let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                    currPayoutDetails.amount = parseInt(e.target.value)
                                    setPayoutDetails(currPayoutDetails)
                                }} 
                            />
                        </Grid>
                    }
                    {/* Payout ID */}
                    {
                        payoutDetails && payoutDetails.id && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Payout ID: {payoutDetails.id}</Typography>
                        </Grid>
                    }
                    {/* Benefactor */}
                    {/* Benefactor Edit */}
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status === "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Benefactor Address:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 1:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.line1) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.address.line1 = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 2:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.line2) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.address.line2 = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>City:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.city) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.address.city = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.state) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.address.state = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.country) || 'Select Country'}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.address.country = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }}
                                        label="Type"
                                >
                                        {
                                            countries.map(
                                                (country, index) => {
                                                return <MenuItem key={index} value={(country  && country.value && country.value.country_alpha3_code) || ""}>{(country && country.name) || "Unknown Country"}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'select_country'} value={'Select Country'}>Select Country</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Postal:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.postal) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.address.postal = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                        </Grid></Grid>
                    }
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status === "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Benefactor Bank:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.name) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.bank.name = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Branch Code:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.branch) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.bank.branch = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Swift Code:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.swift) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.bank.swift = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Name:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.account && payoutDetails.benefactor.bank.account.name) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.bank.account.name = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.account && payoutDetails.benefactor.bank.account.number) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.bank.account.number = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.account && payoutDetails.benefactor.bank.account.currency) || 'Select Currency'}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.benefactor.bank.account.currency = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }}
                                        label="Type"
                                >
                                        {
                                            currencies.map(
                                                (currency, index) => {
                                                return <MenuItem key={index} value={(currency && currency.value && currency.value.currency_alpha3_code) || ""}>{(currency && currency.name) || "Unknown Currency"}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* Benefactor Complete */}
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status !== "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Benefactor Address:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 1:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.line1)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 2:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.line2)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>City:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.city)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.state)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.country)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Postal:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.address && payoutDetails.benefactor.address.postal)}
                                </Grid>
                        </Grid></Grid>
                    }
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status !== "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Benefactor Bank:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.name)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Branch Code:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.branch)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Swift Code:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.swift)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Name:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.account && payoutDetails.benefactor.bank.account.name)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number:</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.account && payoutDetails.benefactor.bank.account.number)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                    {(payoutDetails && payoutDetails.benefactor && payoutDetails.benefactor.bank && payoutDetails.benefactor.bank.account && payoutDetails.benefactor.bank.account.currency)}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* Originator */}
                    {/* Originator Edit */}
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status === "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Originator Address:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 1:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.line1) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.address.line1 = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 2:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.line2) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.address.line2 = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>City:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.city) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.address.city = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.state) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.address.state = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.country) || 'Select Country'}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.address.country = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }}
                                        label="Type"
                                >
                                        {
                                            countries.map(
                                                (country, index) => {
                                                return <MenuItem key={index} value={(country  && country.value && country.value.country_alpha3_code) || ""}>{(country && country.name) || "Unknown Country"}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'select_country'} value={'Select Country'}>Select Country</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Postal:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.postal) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.address.postal = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                        </Grid></Grid>
                    }
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status === "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Originator Bank:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.name) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.bank.name = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Branch Code:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.branch) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.bank.branch = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Swift Code:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.swift) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.bank.swift = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Name:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.account && payoutDetails.originator.bank.account.name) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.bank.account.name = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.account && payoutDetails.originator.bank.account.number) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.bank.account.number = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.account && payoutDetails.originator.bank.account.currency) || 'Select Currency'}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.originator.bank.account.currency = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }}
                                        label="Type"
                                >
                                        {
                                            currencies.map(
                                                (currency, index) => {
                                                return <MenuItem key={index} value={(currency && currency.value && currency.value.currency_alpha3_code) || ""}>{(currency && currency.name) || "Unknown Currency"}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'select_currency'} value={'Select Currency'}>Select Currency</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* Originator Complete */}
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status !== "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Originator Address:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 1:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.line1)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Line 2:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.line2)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>City:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.city)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.state)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Country</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.country)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Postal:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.address && payoutDetails.originator.address.postal)}
                                </Grid>
                        </Grid></Grid>
                    }
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status !== "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Originator Bank:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Bank Name:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.name)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Branch Code:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.branch)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Swift Code:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.swift)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Name:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.account && payoutDetails.originator.bank.account.name)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Account Number:</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.account && payoutDetails.originator.bank.account.number)}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                    {(payoutDetails && payoutDetails.originator && payoutDetails.originator.bank && payoutDetails.originator.bank.account && payoutDetails.originator.bank.account.currency)}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* KYC */}
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status === "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>KYC:</Typography>
                            <Select
                                fullWidth
                                className={classes.select}
                                variant="outlined"
                                value={(payoutDetails && payoutDetails.kycId) || ""}
                                onChange={(e) => {
                                    let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                    currPayoutDetails.kycId = e.target.value
                                    setPayoutDetails(currPayoutDetails)
                                }}
                                label="Payment Mode"
                                >
                                {allKycs.map((eachKyc, index) => {
                                    let riskRatingArr = finalRiskRatingQuestions.finalRiskRating.radioRiskMapping
                                    riskRatingArr = riskRatingArr.filter((eachRisk) => eachRisk.rating === eachKyc.finalRiskRating)
                                    if (riskRatingArr.length < 1) {
                                        return <MenuItem key={'kyc-'+index} value={eachKyc.id}>Invalid KYC Final Risk Rating</MenuItem>
                                    }
                                    return <MenuItem key={'kyc-'+index} value={eachKyc.id}>{`KYC ID: ${eachKyc.id}, Rating: ${riskRatingArr[0].value}, Last Updated: ${eachKyc.updatedAt && new Date(eachKyc.updatedAt).toString()}`}</MenuItem>
                                })}
                            </Select>
                        </Grid>
                    }
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status !== "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>KYC ID: {payoutDetails && payoutDetails.kycId}</Typography>
                        </Grid>
                    }
                    {/* Transaction Details Edit */}
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status === "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Details:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Mode:</Typography>
                                    <Select
                                        fullWidth
                                        className={classes.select}
                                        variant="outlined"
                                        value={(payoutDetails && payoutDetails.transactionMode) || ""}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.transactionMode = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }}
                                        label="Payment Mode"
                                        >
                                        {allTransactionModes.map((paymentMode) => (
                                            <MenuItem key={paymentMode} value={paymentMode}>{paymentMode}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Reference:</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(payoutDetails && payoutDetails.transactionReference) || ''}
                                        onChange={(e) => {
                                            let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                            currPayoutDetails.transactionReference = e.target.value
                                            setPayoutDetails(currPayoutDetails)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* Transaction Details Complete */}
                    {
                        payoutDetails && payoutDetails.id && payoutDetails.status !== "PENDING_APPROVAL" && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Details:</Typography>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Mode:</Typography>
                                    {payoutDetails.transactionMode}
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Reference:</Typography>
                                    {payoutDetails.transactionReference}
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/* createdAt */}
                    {
                        payoutDetails && payoutDetails.id && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created At: {payoutDetails.createdAt && new Date(payoutDetails.createdAt).toString()}</Typography>
                        </Grid>
                    }
                    <Grid item md={11} xs={11} sm={11}>
                    {payoutDetails && !payoutDetails.id && <ButtonMod onClick={() => {
                            if (!payoutDetails || !payoutDetails.organizationId) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization in Payout'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                createPayout({ 
                                    organizationId: payoutDetails.organizationId,
                                    currency: payoutDetails.currency,
                                    transactionMode: payoutDetails.transactionMode,
                                    amount: payoutDetails.amount,
                                })
                            }
                            setPayoutDialog(false)
                            setPayoutDetails({})
                        }}>
                            CREATE
                        </ButtonMod>}&nbsp;&nbsp;
                        {payoutDetails && payoutDetails.id && payoutDetails.status === "PENDING_APPROVAL" && <ButtonMod onClick={() => {
                            if (!payoutDetails || !payoutDetails.organizationId) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization in Payout'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                cancelPayout({ payoutId: payoutDetails.id }, { organizationId: payoutDetails.organizationId })
                            }
                            setPayoutDialog(false)
                            setPayoutDetails({})
                        }}>
                            CANCEL
                        </ButtonMod>}&nbsp;&nbsp;
                        {payoutDetails && payoutDetails.id && payoutDetails.status === "PENDING_APPROVAL" && <ButtonMod onClick={() => {
                            if (!payoutDetails || !payoutDetails.id) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Payouts Loaded'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                updatePayout({payoutId: payoutDetails.id }, payoutDetails)
                            }
                            setPayoutDialog(false)
                            setPayoutDetails({})
                        }}>
                            UPDATE
                        </ButtonMod>}
                        &nbsp;&nbsp;
                        {payoutDetails && payoutDetails.id && payoutDetails.status === "PENDING_APPROVAL" && <ButtonMod onClick={() => {
                            if (!payoutDetails || !payoutDetails.id) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Payouts Loaded'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else if (!user || !user.id) {
                                alertMessage({alertType: 'warning', alertMessage: 'No User Found'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                const currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                currPayoutDetails.approvedBy = user.id
                                currPayoutDetails.kycId = 'a7bda112-bafb-4e1d-97a0-dae371343909'; // temporary
                                approvePayout({payoutId: currPayoutDetails.id }, currPayoutDetails)
                            }
                            setPayoutDialog(false)
                            setPayoutDetails({})
                        }}>
                            APPROVE
                        </ButtonMod>}
                        { payoutDetails && payoutDetails.id && payoutDetails.status === "APPROVED" && <Grid item md={12} xs={12} sm={12}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>ProcessedAt:</Typography>
                            <DateTextField
                                id="merchantDate"
                                type="datetime-local"
                                onChange={(e) => {
                                    let date = new Date(e.target.value)
                                    let currPayoutDetails = JSON.parse(JSON.stringify(payoutDetails))
                                    currPayoutDetails.processedAt = date.toISOString()
                                    setPayoutDetails(currPayoutDetails)
                                }}
                                defaultValue={(payoutDetails.processedAt && moment.utc(payoutDetails.processedAt).local().format('YYYY-MM-DDTHH:mm:ss')) || "2017-07-27T15:24:00"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <div>
                                {payoutDetails.processedAt && new Date(payoutDetails.processedAt).toString()}
                            </div>
                        </Grid>
                        }
                        {payoutDetails && payoutDetails.id && payoutDetails.status === "APPROVED" && <ButtonMod onClick={() => {
                            if (!payoutDetails || !payoutDetails.organizationId) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization in Payout'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else if (!payoutDetails.processedAt) {
                                alertMessage({alertType: 'warning', alertMessage: 'No ProcessedAt in Payout'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                completePayout({ payoutId: payoutDetails.id }, { organizationId: payoutDetails.organizationId, processedAt: payoutDetails.processedAt })
                            }
                            setPayoutDialog(false)
                            setPayoutDetails({})
                        }}>
                            COMPLETE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Payouts</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payout Status</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={searchStatus}
                                            onChange={(e) => {
                                                setSearchStatus(e.target.value)
                                            }}
                                            label="Type"
                                        >
                                            {
                                                allPayoutStatus.map(
                                                    (status, index) => {
                                                    return <MenuItem key={status + index} value={status}>{status}</MenuItem>
                                                })
                                            }
                                            <MenuItem key={'select_status'} value={""}>Select Status</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(null, true)
                                                searchPayout(esSearchReqBody)
                                                setSearchLocation('')
                                                setIsExpanded(false)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setPayoutDialog(true)
                                setPayoutDetails({})
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody()
                                searchAfterPayout(esSearchReqBody)
                            }}
                            hideFooterRowCount
                            rowsPerPageOptions={[10]}
                            pageSize={13}
                            pagination={nextToken ? true : false}
                            columns={[
                                {
                                    field: 'organizationId',
                                    headerName: 'Organization ID',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.organizationId) || ""}`,
                                },
                                { 
                                    field: 'id', // this is field was added in the reducer, which is the currency field
                                    headerName: 'Payout ID',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.id) || ""}`
                                    },
                                },
                                { 
                                    field: 'amount',
                                    headerName: 'Amount',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.amount)}`
                                    },
                                },
                                { 
                                    field: 'currency',
                                    headerName: 'Currency',
                                    width: 140,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.currency)}`
                                    },
                                },
                                { 
                                    field: 'transactionMode',
                                    headerName: 'Transaction Mode',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.transactionMode)}`
                                    },
                                },
                                { 
                                    field: 'rollingReserveAmount',
                                    headerName: 'Rolling Reserve Amount',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.rollingReserveAmount) || ""}`
                                    },
                                },
                                { 
                                    field: 'status',
                                    headerName: 'Status',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.status) || ""}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'updatedAt',
                                    headerName: 'Updated At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.updatedAt) {
                                            let date = new Date(params.row.updatedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                }
                            ]}
                            rows={payouts}
                            onRowClick={async (rowParams) => {
                                let esSearchReqBody = constructLatestKYCReqBody(rowParams.row)
                                postKYCsSearch(esSearchReqBody)
                                let init_payout = JSON.parse(JSON.stringify(rowParams.row))
                                const benefactor = {
                                    address: {},
                                    bank: {
                                        account: {}
                                    }
                                }
                                const originator = {
                                    address: {
                                        line1: "3016 Bedok North Avenue 4",
                                        line2: "#08-31, EASTECH",
                                        postal: "489947",
                                        state: "Singapore",
                                        city: "Singapore",
                                        country: "SGP"
                                    },
                                    bank: {
                                        name: "DBS Bank",
                                        branch: "072",
                                        swift: "DBSSGSG",
                                        account: {
                                            number: "0720531868",
                                            name: "LIBERALIZE PTE LTD",
                                            currency: "SGD",
                                        }
                                    }
                                }
                                if (rowParams.row && rowParams.row.benefactor && rowParams.row.benefactor.address && !rowParams.row.benefactor.address.line1) {
                                    if (organization && organization.address && organization.address.line1) benefactor.address.line1 = organization.address.line1
                                    if (organization && organization.address && organization.address.line2) benefactor.address.line2 = organization.address.line2
                                    if (organization && organization.address && organization.address.city) benefactor.address.city = organization.address.city
                                    if (organization && organization.address && organization.address.postal) benefactor.address.postal = organization.address.postal
                                    if (organization && organization.address && organization.address.state) benefactor.address.state = organization.address.state
                                    if (organization && organization.country) benefactor.address.country = organization.country
                                    init_payout.benefactor = benefactor
                                }
                                if (rowParams.row && rowParams.row.benefactor && rowParams.row.benefactor.bank && !rowParams.row.benefactor.bank.name) {
                                    if (organization && organization.banks && organization.banks.length > 0 && organization.banks[0].name) benefactor.bank.name = organization.banks[0].name
                                    if (organization && organization.banks && organization.banks.length > 0 && organization.banks[0].branch) benefactor.bank.branch = organization.banks[0].branch
                                    if (organization && organization.banks && organization.banks.length > 0 && organization.banks[0].swift) benefactor.bank.swift = organization.banks[0].swift
                                    if (organization && organization.banks && organization.banks.length > 0 && organization.banks[0].account && organization.banks[0].account.number) benefactor.bank.account.number = organization.banks[0].account.number
                                    if (organization && organization.banks && organization.banks.length > 0 && organization.banks[0].account && organization.banks[0].account.name) benefactor.bank.account.name = organization.banks[0].account.name
                                    if (organization && organization.banks && organization.banks.length > 0 && organization.banks[0].account && organization.banks[0].account.currency) benefactor.bank.account.currency = organization.banks[0].account.currency
                                    init_payout.benefactor = benefactor
                                }
                                init_payout.originator = originator
                                setPayoutDetails(init_payout)
                                setPayoutDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default Payouts