function fundingsBillings(state ={isLoading: false}, action) {
    switch (action.type) {
        // FUNDING
        case 'POST_SEARCH_FUNDING_SCHEDULES_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_SEARCH_FUNDING_SCHEDULES_SUCCESS':
            console.log('POST_SEARCH_FUNDING_SCHEDULES_SUCCESS', action);
            const funding_schedules_hits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const search_after_funding_schedules = funding_schedules_hits.length > 0 ? funding_schedules_hits[funding_schedules_hits.length - 1].sort : [];
            const totalHitsFundingSchdules = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const fundingSchedulesHitsSource = funding_schedules_hits.map((eachHit) => {
                eachHit['_source'].id = eachHit['_source'].accountId
                return eachHit['_source']
            })
            return {...state,
                funding_schedules: fundingSchedulesHitsSource,
                totalHitsFundingSchdules: totalHitsFundingSchdules,
                search_after_funding_schedules: search_after_funding_schedules,
                isLoading: false
            }
        case 'POST_SEARCH_FUNDING_SCHEDULES_FAILURE':
            console.log('POST_SEARCH_BALANCES_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_SEARCH_AFTER_FUNDING_SCHEDULES_REQUEST':
            return {...state, isLoading: true}
        case 'POST_SEARCH_AFTER_FUNDING_SCHEDULES_SUCCESS':
            console.log('POST_SEARCH_AFTER_FUNDING_SCHEDULES_SUCCESS', action);
            const nextFundingScheduleHits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const totalFundingHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const next_search_after_funding_schedules = nextFundingScheduleHits.length > 0 ? nextFundingScheduleHits[nextFundingScheduleHits.length - 1].sort : [];
            const nextFundingScheduleHitsSource = nextFundingScheduleHits.map((eachHit) => {
                eachHit['_source'].id = eachHit['_source'].accountId
                return eachHit['_source']
            })
            const combinedFundingScheduleHits = [...state.funding_schedules]
            const combinedFundingSchedulesIDs = {};
            combinedFundingScheduleHits.forEach((eachHit) => combinedFundingSchedulesIDs[eachHit.id] = true)
            nextFundingScheduleHitsSource.forEach((newHit) => {
                if (!combinedFundingSchedulesIDs[newHit.id]) {
                    combinedFundingScheduleHits.push(newHit)
                }
            })
            return {...state,
                funding_schedules: combinedFundingScheduleHits,
                totalHitsFundingSchdules: totalFundingHitsAfter,
                search_after_funding_schedules: next_search_after_funding_schedules,
                isLoading: false
            }
        case 'POST_SEARCH_AFTER_FUNDING_SCHEDULES_FAILURE':
            return {...state, isLoading: false}
        case 'POST_CREATE_FUNDING_SCHEDULE_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_CREATE_FUNDING_SCHEDULE_SUCCESS':
            const currentFundingSchedules = state.funding_schedules || []
            console.log('action.data -> ', action.data);
            const newFundingSchedules = action.data || {}
            newFundingSchedules.id = newFundingSchedules.accountId
            if (!newFundingSchedules.id) return {...state, isLoading: false}
            return {...state,
                isLoading: false,
                funding_schedules: [...currentFundingSchedules, newFundingSchedules]
            }
        case 'POST_CREATE_FUNDING_SCHEDULE_FAILURE':
            return {...state, isLoading: false}
        case 'SET_CLEAR_FUNDING_SCHEDULES':
            return {...state, isLoading: false, funding_schedules: []}
        // BILLING
        case 'POST_SEARCH_BILLING_SCHEDULES_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_SEARCH_BILLING_SCHEDULES_SUCCESS':
            console.log('POST_SEARCH_BILLING_SCHEDULES_SUCCESS', action);
            const billing_schedules_hits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const search_after_billing_schedules = billing_schedules_hits.length > 0 ? billing_schedules_hits[billing_schedules_hits.length - 1].sort : [];
            const totalHitsBillingSchdules = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const billingSchedulesHitsSource = billing_schedules_hits.map((eachHit) => {
                eachHit['_source'].id = eachHit['_source'].location
                return eachHit['_source']
            })
            return {...state,
                billing_schedules: billingSchedulesHitsSource,
                totalHitsBillingSchdules: totalHitsBillingSchdules,
                search_after_billing_schedules: search_after_billing_schedules,
                isLoading: false
            }
        case 'POST_SEARCH_BILLING_SCHEDULES_FAILURE':
            console.log('POST_SEARCH_BALANCES_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_SEARCH_AFTER_BILLING_SCHEDULES_REQUEST':
            return {...state, isLoading: true}
        case 'POST_SEARCH_AFTER_BILLING_SCHEDULES_SUCCESS':
            console.log('POST_SEARCH_AFTER_BILLING_SCHEDULES_SUCCESS', action);
            const nextBillingScheduleHits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const totalBillingHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const next_search_after_billing_schedules = nextBillingScheduleHits.length > 0 ? nextBillingScheduleHits[nextBillingScheduleHits.length - 1].sort : [];
            const nextBillingScheduleHitsSource = nextBillingScheduleHits.map((eachHit) => {
                eachHit['_source'].id = eachHit['_source'].location
                return eachHit['_source']
            })
            const combinedBillingScheduleHits = [...state.billing_schedules]
            const combinedBillingSchedulesIDs = {};
            combinedBillingScheduleHits.forEach((eachHit) => combinedBillingSchedulesIDs[eachHit.id] = true)
            nextBillingScheduleHitsSource.forEach((newHit) => {
                if (!combinedBillingSchedulesIDs[newHit.id]) {
                    combinedBillingScheduleHits.push(newHit)
                }
            })
            return {...state,
                billing_schedules: combinedBillingScheduleHits,
                totalHitsBillingSchdules: totalBillingHitsAfter,
                search_after_billing_schedules: next_search_after_billing_schedules,
                isLoading: false
            }
        case 'POST_SEARCH_AFTER_BILLING_SCHEDULES_FAILURE':
            return {...state, isLoading: false}
        case 'POST_CREATE_BILLING_SCHEDULE_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_CREATE_BILLING_SCHEDULE_SUCCESS':
            const currentBillingSchedules = state.billing_schedules || []
            console.log('action.data -> ', action.data);
            const newBillingSchedules = action.data || {}
            newBillingSchedules.id = newBillingSchedules.location
            if (!newBillingSchedules.id) return {...state, isLoading: false}
            return {...state,
                isLoading: false,
                billing_schedules: [...currentBillingSchedules, newBillingSchedules]
            }
        case 'POST_CREATE_BILLING_SCHEDULE_FAILURE':
        return {...state, isLoading: false}
        case 'SET_CLEAR_BILLING_SCHEDULES':
            return {...state, isLoading: false, billing_schedules: []}
      default:
        return state
    }
  }

export default fundingsBillings