import { CALL_API } from '../app/middleware/callApi.js';
import { CALL_ORG_APPSYNC_API } from '../app/middleware/callOrganizationAppSync'
import { CALL_PMT_SVC_ACT_APPSYNC_API } from '../app/middleware/callPmtSvcActAppSync'

export const postSearchOrganizations = (reqBody) => ({
    types: ['SEARCH_ORGANIZATIONS_REQUEST', 'SEARCH_ORGANIZATIONS_SUCCESS', 'SEARCH_ORGANIZATIONS_FAILURE'],
    [CALL_ORG_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postSearchAfterOrganizations = (reqBody) => ({
    types: ['SEARCH_AFTER_ORGANIZATIONS_REQUEST', 'SEARCH_AFTER_ORGANIZATIONS_SUCCESS', 'SEARCH_AFTER_ORGANIZATIONS_FAILURE'],
    [CALL_ORG_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const fetchOrganization = (params) => ({
    types: ['GET_ORGANIZATIONS_REQUEST', 'GET_ORGANIZATIONS_SUCCESS', 'GET_ORGANIZATIONS_FAILURE'],
    [CALL_API]: '/organizations/:organizationId',
    method: 'get',
    params: params
})

export const patchOrganization = (params, reqBody) => ({
    types: ['PATCH_ORGANIZATIONS_REQUEST', 'PATCH_ORGANIZATIONS_SUCCESS', 'PATCH_ORGANIZATIONS_FAILURE'],
    [CALL_API]: '/organizations/:organizationId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const GetOrgPos = (params, query) => ({
    types: ['GET_ORG_POS_REQUEST', 'GET_ORG_POS_SUCCESS', 'GET_ORG_POS_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/pos',
    method: 'get',
    params: params,
    query: query
})

export const createContract = (reqBody) => ({
    types: ['CREATE_CONTRACT_REQUEST', 'CREATE_CONTRACT_SUCCESS', 'CREATE_CONTRACT_FAILURE'],
    [CALL_API]: '/contracts',
    method: 'post',
    reqBody: reqBody
})

export const updateContract = (params, reqBody) => ({
    types: ['PATCH_CONTRACT_REQUEST', 'PATCH_CONTRACT_SUCCESS', 'PATCH_CONTRACT_FAILURE'],
    [CALL_API]: '/contracts/:contractId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const deleteContract = (params) => ({
    types: ['DELETE_CONTRACT_REQUEST', 'DELETE_CONTRACT_SUCCESS', 'DELETE_CONTRACT_FAILURE'],
    [CALL_API]: '/contracts/:contractId',
    method: 'delete',
    params: params,
})

export const postContractsSearch = (reqBody) => ({
    types: ['POST_CONTRACTS_SEARCH_REQUEST', 'POST_CONTRACTS_SEARCH_SUCCESS', 'POST_CONTRACTS_SEARCH_FAILURE'],
    [CALL_API]: '/contracts-search',
    method: 'post',
    reqBody: reqBody
})

export const postContractsSearchAfter = (reqBody) => ({
    types: ['POST_CONTRACTS_SEARCH_AFTER_REQUEST', 'POST_CONTRACTS_SEARCH_AFTER_SUCCESS', 'POST_CONTRACTS_SEARCH_AFTER_FAILURE'],
    [CALL_API]: '/contracts-search',
    method: 'post',
    reqBody: reqBody
})

export const clearContractsSearch = () => ({
    type: 'CLEAR_CONTRACT_STATE',
})

export const clearOrgState = () => ({
    type: 'CLEAR_ORG_STATE',
})

export const setOrgState = (reqBody) => ({
    type: 'SET_ORG_STATE',
    reqBody: reqBody
})

export const createPaymentAccount = (params, reqBody) => ({
    types: ['POST_CREATE_PAYMENT_ACCOUNT_REQUEST', 'POST_CREATE_PAYMENT_ACCOUNT_SUCCESS', 'POST_CREATE_PAYMENT_ACCOUNT_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/accounts',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const GetOrgAccounts = (params) => ({
    types: ['GET_ORG_PMTSVCACC_REQUEST', 'GET_ORG_PMTSVCACC_SUCCESS', 'GET_ORG_PMTSVCACC_FAILURE'],
    [CALL_API]: '/organizations/:organizationId/accounts',
    method: 'get',
    params: params,
})

export const postPaymentAccountsSearch = (reqBody) => ({
    types: ['POST_PAYMENT_ACCOUNTS_SEARCH_REQUEST', 'POST_PAYMENT_ACCOUNTS_SEARCH_SUCCESS', 'POST_PAYMENT_ACCOUNTS_SEARCH_FAILURE'],
    [CALL_PMT_SVC_ACT_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody,
})

export const postPaymentAccountsSearchAfter = (reqBody) => ({
    types: ['POST_PAYMENT_ACCOUNTS_SEARCH_AFTER_REQUEST', 'POST_PAYMENT_ACCOUNTS_SEARCH_AFTER_SUCCESS', 'POST_PAYMENT_ACCOUNTS_SEARCH_AFTER_FAILURE'],
    [CALL_PMT_SVC_ACT_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody,
})

export const clearPaymentAccountsSearch = () => ({
    type: 'CLEAR_PAYMENT_ACCOUNTS_STATE',
})

export const updatePaymentAccounts = (params, reqBody) => ({
    types: ['PATCH_PAYMENT_ACCOUNT_REQUEST', 'PATCH_PAYMENT_ACCOUNT_SUCCESS', 'PATCH_PAYMENT_ACCOUNT_FAILURE'],
    [CALL_API]: '/accounts/:accountId',
    method: 'patch',
    params: params,
    reqBody: reqBody,
})

export const deletePaymentAccounts = (params) => ({
    types: ['DELETE_PAYMENT_ACCOUNT_REQUEST', 'DELETE_PAYMENT_ACCOUNT_SUCCESS', 'DELETE_PAYMENT_ACCOUNT_FAILURE'],
    [CALL_API]: '/accounts/:accountId',
    method: 'delete',
    params: params
})

// The query is for organizationId, see admin console backend
export const postPointOfSalesSearch = (reqBody, query) => ({
    types: ['POST_POINT_OF_SALES_SEARCH_REQUEST', 'POST_POINT_OF_SALES_SEARCH_SUCCESS', 'POST_POINT_OF_SALES_SEARCH_FAILURE'],
    [CALL_API]: '/pos-search',
    method: 'post',
    reqBody: reqBody,
    query: query
})

export const postPointOfSalesSearchAfter = (reqBody, query) => ({
    types: ['POST_POINT_OF_SALES_SEARCH_AFTER_REQUEST', 'POST_POINT_OF_SALES_SEARCH_AFTER_SUCCESS', 'POST_POINT_OF_SALES_SEARCH_AFTER_FAILURE'],
    [CALL_API]: '/pos-search',
    method: 'post',
    reqBody: reqBody,
    query: query
})

export const clearPosSearch = () => ({
    type: 'CLEAR_POINT_OF_SALES_STATE',
})

export const patchPos = (params, reqBody) => ({
    types: ['PATCH_POINT_OF_SALES_REQUEST', 'PATCH_POINT_OF_SALES_SUCCESS', 'PATCH_POINT_OF_SALES_FAILURE'],
    [CALL_API]: '/pos/:posId',
    method: 'patch',
    params: params,
    reqBody: reqBody
})

export const setOrganizationLoading = () => ({
    type: 'SET_ORGANIZATION_LOADING',
})

export const setOrganizationNotLoading = () => ({
    type: 'SET_ORGANIZATION_NOT_LOADING',
})