import { CALL_PMTS_APPSYNC_API } from '../app/middleware/callPaymentAppSync.js';

export const postPaymentsSearch = (reqBody) => ({
    types: ['POST_PAYMENTS_SEARCH_REQUEST', 'POST_PAYMENTS_SEARCH_SUCCESS', 'POST_PAYMENTS_SEARCH_FAILURE'],
    [CALL_PMTS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postPaymentsSearchAfter = (reqBody) => ({
    types: ['POST_PAYMENTS_SEARCH_AFTER_REQUEST', 'POST_PAYMENTS_SEARCH_AFTER_SUCCESS', 'POST_PAYMENTS_SEARCH_AFTER_FAILURE'],
    [CALL_PMTS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const clearPaymentsSearch = () => ({
    type: 'CLEAR_PAYMENTS_SEARCH'
})

export const updatePaymentsSearch = (reqBody) => ({
    type: 'UPDATE_PAYMENTS_SEARCH',
    reqBody: reqBody
})

