import { connect } from 'react-redux'
import { toggleDrawer, setAppEnv, clearOrgState } from '../actions'
import Appbar from '../components/Appbar'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organizations: state.organizations.organizations,
    organization: state.organizations.organization,
    user: state.users.user,
    appbarIsWhiteFont: state.appBars ? state.appBars.appbarIsWhiteFont : false,
    appEnv: state.appstate ? state.appstate : 'staging'
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    setAppEnv: (env) => dispatch(setAppEnv(env)),
    clearOrgState: () => dispatch(clearOrgState()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Appbar)