import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import './index.css';
import App from './containers/App';
import configureStore from './app/configureStore';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import DrawerState from './containers/DrawerState'

const store = configureStore({ navDrawers: { isOpen: false }, appBars: { appbarIsWhiteFont: true } })
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <DrawerState/>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
