import { CALL_API } from '../app/middleware/callApi.js';

export const getEnumeration = (params) => ({
    types: ['GET_ENUMERATION_REQUEST', 'GET_ENUMERATION_SUCCESS', 'GET_ENUMERATION_FAILURE'],
    [CALL_API]: '/enumerations/:enumGroup',
    method: 'get',
    params: params,
})

export const alertMessage = (reqBody) => ({
    type: 'SET_ALERT_REQUEST',
    reqBody: reqBody,
})

export const clearAlertMessage = (reqBody) => ({
    type: 'CLEAR_ALERT_REQUEST',
    reqBody: reqBody,
})