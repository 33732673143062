import { connect } from 'react-redux'
import { toggleDrawer, setUser, getUserOrganizations } from '../actions'
import App from '../App'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users.user,
    alerts: (state.misc && state.misc.alerts) || {}
})
  
const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    setUser: (user) => dispatch(setUser(user)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App)