import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, setOrgState, downloadDisputeS3File, createDispute, 
    updateDispute, deleteDispute, postDisputesSearch, postDisputesSearchAfter, clearDisputesSearch, setDisputeLoading, 
    setDisputeNotLoading, postSearchOrganizations
} from '../actions'
import Disputes from '../components/Disputes'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    organizations: (state.organizations && state.organizations.organizations) || [],
    currentUser: (state.users && state.users.user) || {},
    countries: (state.misc && state.misc.enum && state.misc.enum.country) || [],
    processors: (state.misc && state.misc.enum && state.misc.enum.processor) || [],
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    pdtSvcTypes: (state.misc && state.misc.enum && state.misc.enum.productservicetype) || [],
    disputes: (state.misc && state.misc.enum && state.misc.enum.dispute) || [],
    mcc: (state.misc && state.misc.enum && state.misc.enum.mcc) || [],
    environment: (state.appstate && state.appstate.environment) || 'staging',
    allDisputes: (state.disputes && state.disputes.allDisputes) || [],
    totalHitsDisputes: (state.disputes && state.disputes.totalHitsDisputes) || 0,
    search_after_disputes: (state.disputes && state.disputes.search_after_disputes) || [],
    isLoading: (state.disputes && state.disputes.isLoading) || false,
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    setLoading: () => dispatch(setDisputeLoading()),
    setNotLoading: () => dispatch(setDisputeNotLoading()),
    createDispute: (params, reqBody) => dispatch(createDispute(params, reqBody)),
    updateDispute: (params, reqBody) => dispatch(updateDispute(params, reqBody)),
    deleteDispute: (params) => dispatch(deleteDispute(params)),
    postDisputesSearch: (reqBody) => dispatch(postDisputesSearch(reqBody)),
    postDisputesSearchAfter: (reqBody) => dispatch(postDisputesSearchAfter(reqBody)),
    clearDisputesSearch: () => dispatch(clearDisputesSearch()),
    setOrgState: (reqBody) => dispatch(setOrgState(reqBody)),
    postSearchOrganizations: (reqBody) => dispatch(postSearchOrganizations(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Disputes)