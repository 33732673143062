import { fetchApi } from '../utils/callInvoiceAppSyncApi';

export const CALL_INVOICE_APPSYNC_API = Symbol('callInvoiceAppSync key');

// Action key that carries API call info interpreted by this Redux middleware.
const callApi = store => next => async (action) => {
    // console.log('action IN callPmtsReportApi MIDDLEWARE is', action);
    // console.log("next IN callPmtsReportApi MIDDLEWARE => ", next);
    // console.log("store IN callPmtsReportApi MIDDLEWARE => ", store);
    const apiRoutes = action[CALL_INVOICE_APPSYNC_API];

    // console.log('apiRoutes => ', apiRoutes);
    // If it's not a callPmtsReportApi's action skip to next action.
    if (typeof apiRoutes === 'undefined') {
        // console.log('NOT HERE GOING NEXT');
        return next(action);
    }
    const state = store.getState()
    const env = state.appstate.environment
    const { method, types, params, reqBody, query } = action;
    // console.log(' WHAT TYPES: ', types);
    // console.log('params => ', params);
    // console.log('reqBody => ', reqBody);
    next({ type: types[0] });
    return fetchApi(env, apiRoutes, method, params, reqBody, query).then(
        response => next({
        data: response.data,
        type: types[1],
        }), error => next({
        error: (error && error.message) || 'Something bad happened',
        type: types[2]
        })
    )
}

export default callApi