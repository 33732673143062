import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { feeType, allComplianceApprovalStatus } from '../app/globals';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {fetchApi} from '../app/utils/callApi';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    companyProfile: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const CompanySettings = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, getEnumeration, countries, mcc, currencies, clearAlertMessage, alertMessage,
        searchFundingSchedules, search_after_funding_schedules, fetchOrganization, patchOrganization, environment, setOrganizationLoading,
        setOrganizationNotLoading, contracts, postContractsSearch } = props
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [orgContract, setOrgContract] = useState({});
    const [displayOrgContract, setDisplayOrgContract] = useState({});
    const [orgContractSearched, setOrgContractSearched] = useState({});
    const [onlineContracts, setOnlineContracts] = useState([]);
    const [onlineContract, setOnlineContract] = useState({}); // this is for when selecting from the auto complete
    const [physicalContracts, setPhysicalContracts] = useState([]);
    const [physicalContract, setPhysicalContract] = useState({}); // this is for when selecting from the auto complete
    const [contractDialog, setContractDialog] = useState(false)
    const [contractDetails, setContractDetails] = useState({})
    const [searchLocation, setSearchLocation] = useState('');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    useLayoutEffect(() => {
        let display = JSON.parse(JSON.stringify(orgContract))
        display.createdAt = new Date(display.createdAt).toString()
        setDisplayOrgContract(display)
    },[orgContract])

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        const urlArr = window.location.href.split('/')
        const orgID = urlArr[urlArr.length - 2]
        fetchOrganization({organizationId: orgID})
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        getEnumeration({enumGroup: 'mcc'})
        setLoadedPage(1)
    },[])

    useEffect(() => {
        if (organization && organization.id) {
            let esSearchReqBody = constructSearchReqBody()
        }
    },[organization, organization.id])

    const fetchContracts = async (contractIdsArr, contractType) => {
        setOrganizationLoading()
        let allContracts = []
        await Promise.all(contractIdsArr.map(async(eachId) => {
            console.log('fetching id -> ', eachId)
            const { data } = await fetchApi(environment,
                '/contracts/:contractId',
                'get',
                {contractId: eachId}
            )
            console.log('retrieved -> ', data)
            allContracts.push(data)
        }))

        switch (contractType) {
            case 'online':
                console.log('setting online contracts -> ', allContracts)
                setOnlineContracts(allContracts)
                break;
            case 'physical':
                console.log('setting physical contracts -> ', allContracts)
                setPhysicalContracts(allContracts)
                break;
            case 'organization':
                console.log('setting organization contract -> ', allContracts)
                setOrgContract(allContracts[0])
                break;
            default:
                console.log('invalid type')
                break;
        }
        setOrganizationNotLoading()
        return true
    }

    const constructSearchReqBody = (passedPageSize, restartSearch=false, contractName='') => {
        let initialStruct = {
            from: restartSearch ? 0 : searchFrom,
            size: passedPageSize || searchSize,
            query: {
                bool: {
                    must: [
                    ]
                },
            },
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        if (contractName) {
            initialStruct.regexp = {
                name: {
                    value: contractName + ".*",
                    flags: "ALL",
                    max_determinized_states: 10000,
                    rewrite: "constant_score"
                }
            }
        }
        return initialStruct
    }

    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={contractDialog} 
            onClose={()=> {setContractDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            <CloseIcon className={classes.closeBtn} onClick={()=> {setContractDialog(false)}}/>
            {<Typography className={classes.Title} color="textSecondary" gutterBottom>Edit Contracts</Typography>}
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {/* Organization ContractId */}
                    <Grid item md={12} xs={12} sm={12}>
                        <Grid container spacing={3}>
                            <Grid item md={10} xs={10} sm={10}>
                                <pre><Typography className={classes.title} color="textSecondary" gutterBottom>
                                    ORGANIZATION CONTRACTS: 
                                    {JSON.stringify(displayOrgContract, undefined, 2)}
                                </Typography></pre>
                            </Grid>
                            <Grid item md={2} xs={2} sm={2}>
                                <IconButton 
                                    onClick={() => { 
                                        setOrgContract({})
                                    }}
                                    component="span"
                                >
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={10} xs={10} sm={10}>
                                <GreenAutocomplete
                                    id="tags-filled"
                                    options={contracts}
                                    getOptionLabel={(option) => {
                                        console.log('option -> ', option)
                                        if (option) {
                                            let label = option.name + " - "
                                            if (option.fees) {
                                                Object.keys(option.fees).map((eachFee) => {
                                                    label += ` ${eachFee}, `
                                                })
                                            }
                                            return label
                                        }
                                    }
                                    }
                                    onChange={(e,v) => { 
                                        if (v) {
                                            setOrgContractSearched(v)
                                        }
                                    }}
                                    fullWidth
                                    freeSolo
                                    renderInput={(params) => (
                                        <GreenTextField 
                                            {...params}
                                            variant="filled"
                                            placeholder="Contract Name"
                                            onChange={(e) => {
                                                let esSearchReqBody = constructSearchReqBody(10, true, e.target.value)
                                                postContractsSearch(esSearchReqBody)
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={2} xs={2} sm={2}>
                                <ButtonMod onClick={() => {
                                        let currentOrganizationContract = JSON.parse(JSON.stringify(orgContractSearched))
                                        setOrgContract(currentOrganizationContract)
                                    }}
                                >
                                    APPLY
                                </ButtonMod>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Online POS Contracts */}
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>ONLINE POS CONTRACTS: </Typography>
                        {
                            onlineContracts && onlineContracts.map((eachOnlineContract, index) => {
                                eachOnlineContract.createdAt = new Date(eachOnlineContract.createdAt).toString()
                                return <Grid container spacing={3}>
                                    <Grid item md={10} xs={10} sm={10}>
                                        <pre>{JSON.stringify(eachOnlineContract, undefined, 2)}</pre>
                                    </Grid>
                                    <Grid item md={2} xs={2} sm={2}>
                                        <IconButton 
                                            onClick={() => { 
                                                let newOnlineContracts = onlineContracts.filter((eachContract, eachIndex) => {
                                                    if (eachIndex === index) return false
                                                    return eachContract
                                                })
                                                setOnlineContracts(newOnlineContracts)
                                            }}
                                            component="span"
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            })
                        }
                        <Grid container spacing={3}>
                            <Grid item md={10} xs={10} sm={10}>
                                <GreenAutocomplete
                                    id="tags-filled"
                                    options={contracts}
                                    getOptionLabel={(option) => {
                                        console.log('option -> ', option)
                                        if (option) {
                                            let label = option.name + " - "
                                            if (option.fees) {
                                                Object.keys(option.fees).map((eachFee) => {
                                                    label += ` ${eachFee}, `
                                                })
                                            }
                                            return label
                                        }
                                    }
                                    }
                                    onChange={(e,v) => { 
                                        if (v) {
                                            setOnlineContract(v)
                                        }
                                    }}
                                    fullWidth
                                    freeSolo
                                    renderInput={(params) => (
                                        <GreenTextField 
                                            {...params}
                                            variant="filled"
                                            placeholder="Contract Name"
                                            onChange={(e) => {
                                                let esSearchReqBody = constructSearchReqBody(10, true, e.target.value)
                                                postContractsSearch(esSearchReqBody)
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={2} xs={2} sm={2}>
                                <ButtonMod onClick={() => {
                                        let currentOnlineContracts = JSON.parse(JSON.stringify(onlineContracts))
                                        let currentOnlineContract = JSON.parse(JSON.stringify(onlineContract))
                                        currentOnlineContracts = [...currentOnlineContracts, currentOnlineContract]
                                        setOnlineContracts(currentOnlineContracts)
                                    }}
                                >
                                    ADD
                                </ButtonMod>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Physical POS Contracts */}
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>PHYSICAL POS CONTRACTS: </Typography>
                        {
                            physicalContracts && physicalContracts.map((eachPhysicalContract, index) => {
                                eachPhysicalContract.createdAt = new Date(eachPhysicalContract.createdAt).toString()
                                return <Grid container spacing={3}>
                                    <Grid item md={10} xs={10} sm={10}>
                                        <pre>{JSON.stringify(eachPhysicalContract, undefined, 2)}</pre>
                                    </Grid>
                                    <Grid item md={2} xs={2} sm={2}>
                                        <IconButton 
                                            onClick={() => { 
                                                let newPhysicalContracts = physicalContracts.filter((eachContract, eachIndex) => {
                                                    if (eachIndex === index) return false
                                                    return eachContract
                                                })
                                                setPhysicalContracts(newPhysicalContracts)
                                            }}
                                            component="span"
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            })
                        }
                        <Grid container spacing={3}>
                            <Grid item md={10} xs={10} sm={10}>
                                <GreenAutocomplete
                                    id="tags-filled"
                                    options={contracts}
                                    getOptionLabel={(option) => {
                                        console.log('option -> ', option)
                                        if (option) {
                                            let label = option.name + " - "
                                            if (option.fees) {
                                                Object.keys(option.fees).map((eachFee) => {
                                                    label += ` ${eachFee}, `
                                                })
                                            }
                                            return label
                                        }
                                    }
                                    }
                                    onChange={(e,v) => { 
                                        if (v) {
                                            setPhysicalContract(v)
                                        }
                                    }}
                                    fullWidth
                                    freeSolo
                                    renderInput={(params) => (
                                        <GreenTextField 
                                            {...params}
                                            variant="filled"
                                            placeholder="Contract Name"
                                            onChange={(e) => {
                                                let esSearchReqBody = constructSearchReqBody(10, true, e.target.value)
                                                postContractsSearch(esSearchReqBody)
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item md={2} xs={2} sm={2}>
                                <ButtonMod onClick={() => {
                                        let currentPhysicalContracts = JSON.parse(JSON.stringify(physicalContracts))
                                        let currentPhysicalContract = JSON.parse(JSON.stringify(physicalContract))
                                        currentPhysicalContracts = [...currentPhysicalContracts, currentPhysicalContract]
                                        setPhysicalContracts(currentPhysicalContracts)
                                    }}
                                >
                                    ADD
                                </ButtonMod>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        {<ButtonMod onClick={() => {
                            let reqBody = {
                                pos: {
                                    online: {
                                        contractIds: []
                                    },
                                    physical: {
                                        contractIds: []
                                    }
                                }
                            }
                            if (orgContract.id) {
                                reqBody.contractId = orgContract.id
                            } else {
                                reqBody.contractId = null
                            }
                            if (onlineContracts && onlineContracts.length > 0) {
                                let onlineContractIds = onlineContracts.map((eachCon) => {
                                    return eachCon.id
                                })
                                reqBody.pos.online.contractIds = onlineContractIds
                            }
                            if (physicalContracts && physicalContracts.length > 0) {
                                let physicalContractIds = physicalContracts.map((eachCon) => {
                                    return eachCon.id
                                })
                                reqBody.pos.physical.contractIds = physicalContractIds
                            }
                            patchOrganization({organizationId: organization.id}, reqBody)
                            setOnlineContracts([])
                            setOnlineContract({})
                            setPhysicalContracts([])
                            setPhysicalContract({})
                            setOrgContract({})
                            setContractDialog(false)
                        }}>
                            SAVE
                        </ButtonMod>}&nbsp;&nbsp;
                        {<ButtonMod onClick={() => {
                            setOnlineContracts([])
                            setOnlineContract({})
                            setPhysicalContracts([])
                            setPhysicalContract({})
                            setOrgContract({})
                            setContractDialog(false)
                        }}>
                            CANCEL
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Company Settings</Typography>
        <Container>
            <div className={classes.companyProfile}>
                <Grid container spacing={3}>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>ID: {organization.id}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Name: {organization.name}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Doing Business As: {organization.dba}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>UEN: {organization.uen}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>DOB: {organization.dob}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Country: {
                            organization.country && countries.map((eachCountry) => {
                                if (eachCountry.value.country_alpha3_code === organization.country) {
                                    return <span>{eachCountry.name} "{eachCountry.value.country_alpha3_code}"</span>
                                }
                        })}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Description: {organization.description}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Type: {organization.type}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>MCC: {
                                organization.mcc && mcc.map((eachCode) => {
                                    if (eachCode.value.code === organization.mcc) {
                                        return <span>{eachCode.value.text} ({eachCode.value.code})</span>
                                    }
                                })
                            }</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Website: {organization.website}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <pre><Typography className={classes.title} color="textSecondary" gutterBottom>Address: {JSON.stringify(organization.address, undefined, 2)}</Typography></pre>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <pre><Typography className={classes.title} color="textSecondary" gutterBottom>Contact: {JSON.stringify(organization.contactId, undefined, 2)}</Typography></pre>
                    </Grid>
                    {
                        organization.banks && organization.banks.map((eachBank, index) => {
                            return <Grid item md={6} xs={6} sm={6}>
                                <pre><Typography className={classes.title} color="textSecondary" gutterBottom>Bank #{index+1}: {JSON.stringify(eachBank, undefined, 2)}</Typography></pre>
                            </Grid>
                        })
                    }
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Contract based on Organization's ContactID Field: {organization.contractId}</Typography>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        {
                            organization && organization.pos && organization.pos.online && organization.pos.online.contractIds && organization.pos.online.contractIds.map((eachContract, index) => {
                                return <Typography className={classes.title} color="textSecondary" gutterBottom>Online Contract #{index+1}: {eachContract}</Typography>
                            })
                        }
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        {
                            organization && organization.pos && organization.pos.physical && organization.pos.physical.contractIds && organization.pos.physical.contractIds.map((eachContract, index) => {
                                return <Typography className={classes.title} color="textSecondary" gutterBottom>Physical Contract #{index+1}: {eachContract}</Typography>
                            })
                        }
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <ButtonMod onClick={async () => {
                                setContractDetails({ 
                                    contractId: organization.contractId,
                                    pos: organization.pos
                                })
                                if (organization && organization.pos && organization.pos.online && organization.pos.online.contractIds) {
                                    await fetchContracts(organization.pos.online.contractIds, 'online')
                                }
                                if (organization && organization.pos && organization.pos.physical && organization.pos.physical.contractIds) {
                                    await fetchContracts(organization.pos.physical.contractIds, 'physical')
                                }
                                if (organization && organization.contractId) {
                                    await fetchContracts([organization.contractId], 'organization')
                                }
                                setContractDialog(true)
                            }}>
                            VIEW CONTRACTS
                        </ButtonMod>
                    </Grid>
                    { organization && organization.complianceApproval && <Grid item md={12} xs={12} sm={12}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Compliance Aproval Status</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(organization && organization.complianceApproval)}
                            onChange={(e) => {
                                if (!organization.id) {
                                    alert('NO ORGANIZATION ID')
                                    return
                                }
                                console.log('e.target.value -> ', e.target.value)
                                patchOrganization({organizationId: organization.id}, { complianceApproval: e.target.value })
                            }}
                            label="status"
                        >
                            {
                                allComplianceApprovalStatus.map(
                                    (status, index) => {
                                    return <MenuItem key={status} value={status}>{status}</MenuItem>
                                })
                            }
                        </Select>
                    </Grid>}
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default CompanySettings