import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, clearAlertMessage, alertMessage, searchBalances, searchAfterBalances, 
    createBalance, clearBalances
    } from '../actions'
import Balances from '../components/Balances'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.balancesPayables && state.balancesPayables.isLoading) || false,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    balances: (state.balancesPayables && state.balancesPayables.balances) || [],
    balancesNextToken: (state.balancesPayables && state.balancesPayables.balancesNextToken) || [],
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    clearAlertMessage: (reqBody) => dispatch(clearAlertMessage(reqBody)),
    alertMessage: (reqBody) => dispatch(alertMessage(reqBody)),
    searchBalances: (reqBody) => dispatch(searchBalances(reqBody)),
    searchAfterBalances: (reqBody) => dispatch(searchAfterBalances(reqBody)),
    createBalance: (reqBody) => dispatch(createBalance(reqBody)),
    clearBalances: () => dispatch(clearBalances())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Balances)