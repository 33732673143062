import monitorReducersEnhancer from './enhancers/monitorReducers'
import loggerMiddleware from './middleware/logger'
import callApiMiddleware from './middleware/callApi'
import callKycApiMiddleware from './middleware/callKycApi'
import callDisputeApiMiddleware from './middleware/callDisputeApi'
import callBalancesPayablesApiMiddleware from './middleware/callBalancesPayablesApi'
import callFundingsBillingsApiMiddleware from './middleware/callFundingsBillingsApi'
import callPayoutsInvoicesApiMiddleware from './middleware/callPayoutsInvoicesApi'
import callPayoutsAppSync from './middleware/callPayoutsAppSync'
import callContractAppSync from './middleware/callContractAppSync'
import callPaymentAppSyncApi from './middleware/callPaymentAppSync'
import callBillingsAppSync from './middleware/callBillingsAppSync'
import callBalancesAppSync from './middleware/callBalancesAppSync'
import callInvoicesAppSync from './middleware/callInvoicesAppSync'
import callOrganizationAppSync from './middleware/callOrganizationAppSync'
import callPmtSvcActAppSync from './middleware/callPmtSvcActAppSync'
import callKycAppSync from './middleware/callKycAppSyncApi'
import callFundingSchedulesAppSync from './middleware/callFundingSchedulesAppSync'
import callBillingSchedulesAppSync from './middleware/callBillingSchedulesAppSync'
import rootReducer from './reducers'

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

export default function configureAppStore(preloadedState) {
  // console.log('preloadedState : ', preloadedState);
  
  const store = configureStore({
    reducer: rootReducer,
    middleware: process.env.NODE_ENV === 'development' ? [
      loggerMiddleware,callPaymentAppSyncApi, callPayoutsAppSync, callContractAppSync, callFundingSchedulesAppSync, callBillingSchedulesAppSync, callBillingsAppSync, callBalancesAppSync, callInvoicesAppSync,callOrganizationAppSync, callPmtSvcActAppSync, callKycAppSync, callApiMiddleware, callKycApiMiddleware,callDisputeApiMiddleware, callBalancesPayablesApiMiddleware, callFundingsBillingsApiMiddleware, callPayoutsInvoicesApiMiddleware, ...getDefaultMiddleware()
    ] : [
      callPaymentAppSyncApi, callPayoutsAppSync, callContractAppSync, callFundingSchedulesAppSync, callBillingSchedulesAppSync, callBillingsAppSync, callBalancesAppSync, callInvoicesAppSync,callOrganizationAppSync, callPmtSvcActAppSync, callKycAppSync, callApiMiddleware, callKycApiMiddleware,callDisputeApiMiddleware, callBalancesPayablesApiMiddleware, callFundingsBillingsApiMiddleware, callPayoutsInvoicesApiMiddleware, ...getDefaultMiddleware()
    ],
    preloadedState,
    enhancers: process.env.NODE_ENV === 'development' ? [monitorReducersEnhancer]: []
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }
  // console.log('store state : ', store.getState());
  return store
}