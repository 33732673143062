import React, {useLayoutEffect, useEffect} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    colorBanner: {
        background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%)`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'initial',
        minHeight: '22em',
        marginBottom: '-14em'
    },
}));


const CompanyDashboard = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, openDrawer, toggleDrawer, organization } = props
    const urlArr = window.location.href.split('/')
    const orgID = urlArr[urlArr.length - 1]
    useEffect(() => {
        // console.log('useEffect on dashboard !');
        if (!openDrawer) toggleDrawer() // close it if its open
    },[])
    useEffect(() => {
        // console.log('use Effect on dashboard');
    },[orgID])
    // console.log('props => ', props);
    // console.log('orgID => ', orgID);
    return (
    <main>
        <div className={classes.colorBanner}></div>
    </main>
    )
}

export default CompanyDashboard