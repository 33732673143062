import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, clearAlertMessage, alertMessage, searchPayables, searchAfterPayables, 
    createPayable, clearPayables, postBillingsSearch, postBillingsSearchAfter, clearBillingsSearch
    } from '../actions'
import Payables from '../components/Payables.js'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: ((state.balancesPayables && state.balancesPayables.isLoading) || (state.billings && state.billings.isLoading)) || false,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    payables: (state.balancesPayables && state.balancesPayables.payables) || [],
    totalHitsPayables: (state.balancesPayables && state.balancesPayables.totalHitsPayables) || 0,
    search_after_payables: (state.balancesPayables && state.balancesPayables.search_after_payables) || [],
    billings: (state.billings && state.billings.billings) || [],
    billingsNextToken: (state.billings && state.billings.billingsNextToken) || "",
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    clearAlertMessage: (reqBody) => dispatch(clearAlertMessage(reqBody)),
    alertMessage: (reqBody) => dispatch(alertMessage(reqBody)),
    searchPayables: (params, reqBody) => dispatch(searchPayables(params, reqBody)),
    searchAfterPayables: (params, reqBody) => dispatch(searchAfterPayables(params, reqBody)),
    createPayable: (reqBody) => dispatch(createPayable(reqBody)),
    clearPayables: () => dispatch(clearPayables()),
    postBillingsSearch: (reqBody) => dispatch(postBillingsSearch(reqBody)),
    postBillingsSearchAfter: (reqBody) => dispatch(postBillingsSearchAfter(reqBody)),
    clearBillingsSearch: () => dispatch(clearBillingsSearch()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Payables)