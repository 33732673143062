import { CALL_PAYOUTS_INVOICES_API } from '../app/middleware/callPayoutsInvoicesApi.js';
import { CALL_PAYOUTS_APPSYNC_API } from '../app/middleware/callPayoutsAppSync.js';
import { CALL_INVOICE_APPSYNC_API } from '../app/middleware/callInvoicesAppSync.js';
// Payouts

export const createPayout = (reqBody) => ({
    types: ['POST_CREATE_PAYOUT_REQUEST', 'POST_CREATE_PAYOUT_SUCCESS', 'POST_CREATE_PAYOUT_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/payouts',
    method: 'post',
    reqBody: reqBody,
})

export const updatePayout = (params, reqBody) => ({
    types: ['PATCH_PAYOUT_REQUEST', 'PATCH_PAYOUT_SUCCESS', 'PATCH_PAYOUT_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/payouts/:payoutId',
    method: 'patch',
    params: params,
    reqBody: reqBody,
})

export const approvePayout = (params, reqBody) => ({
    types: ['PATCH_PAYOUT_APPROVAL_REQUEST', 'PATCH_PAYOUT_APPROVAL_SUCCESS', 'PATCH_PAYOUT_APPROVAL_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/payouts/:payoutId/approve',
    method: 'patch',
    params: params,
    reqBody: reqBody,
})

export const cancelPayout = (params, reqBody) => ({
    types: ['PATCH_PAYOUT_CANCEL_REQUEST', 'PATCH_PAYOUT_CANCEL_SUCCESS', 'PATCH_PAYOUT_CANCEL_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/payouts/:payoutId/cancel',
    method: 'patch',
    params: params,
    reqBody: reqBody,
})

export const completePayout = (params, reqBody) => ({
    types: ['PATCH_PAYOUT_COMPLETE_REQUEST', 'PATCH_PAYOUT_COMPLETE_SUCCESS', 'PATCH_PAYOUT_COMPLETE_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/payouts/:payoutId/complete',
    method: 'patch',
    params: params,
    reqBody: reqBody,
})

// if query { organizationId: "xxx" } it will search for :organizationId index
export const searchPayout = (reqBody) => ({
    types: ['SEARCH_PAYOUTS_REQUEST', 'SEARCH_PAYOUTS_SUCCESS', 'SEARCH_PAYOUTS_FAILURE'],
    [CALL_PAYOUTS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody,
})

export const searchAfterPayout = (reqBody) => ({
    types: ['SEARCH_AFTER_PAYOUTS_REQUEST', 'SEARCH_AFTER_PAYOUTS_SUCCESS', 'SEARCH_AFTER_PAYOUTS_FAILURE'],
    [CALL_PAYOUTS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody,
})

export const clearPayouts = () => ({
    type: 'CLEAR_PAYOUTS',
})

// Invoice

export const createInvoice = (reqBody) => ({
    types: ['POST_CREATE_INVOICE_REQUEST', 'POST_CREATE_INVOICE_SUCCESS', 'POST_CREATE_INVOICE_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/invoices',
    method: 'post',
    reqBody: reqBody,
})

export const updateInvoice = (params, reqBody) => ({
    types: ['PATCH_INVOICE_REQUEST', 'PATCH_INVOICE_SUCCESS', 'PATCH_INVOICE_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/invoices/:invoiceId',
    method: 'patch',
    params: params,
    reqBody: reqBody,
})

export const getInvoice = (params) => ({
    types: ['GET_INVOICE_REQUEST', 'GET_INVOICE_SUCCESS', 'GET_INVOICE_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/invoices/:invoiceId',
    method: 'get',
    params: params,
})

export const cancelInvoice = (params, reqBody) => ({
    types: ['PATCH_INVOICE_CANCEL_REQUEST', 'PATCH_INVOICE_CANCEL_SUCCESS', 'PATCH_INVOICE_CANCEL_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/invoices/:invoiceId/cancel',
    method: 'patch',
    params: params,
    reqBody: reqBody,
})

export const completeInvoice = (params, reqBody) => ({
    types: ['PATCH_INVOICE_COMPLETE_REQUEST', 'PATCH_INVOICE_COMPLETE_SUCCESS', 'PATCH_INVOICE_COMPLETE_FAILURE'],
    [CALL_PAYOUTS_INVOICES_API]: '/invoices/:invoiceId/complete',
    method: 'patch',
    params: params,
    reqBody: reqBody,
})

// if query { organizationId: "xxx" } it will search for :organizationId index
export const searchInvoice = (reqBody) => ({
    types: ['SEARCH_INVOICES_REQUEST', 'SEARCH_INVOICES_SUCCESS', 'SEARCH_INVOICES_FAILURE'],
    [CALL_INVOICE_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody,
})

export const searchAfterInvoice = (reqBody) => ({
    types: ['SEARCH_AFTER_INVOICES_REQUEST', 'SEARCH_AFTER_INVOICES_SUCCESS', 'SEARCH_AFTER_INVOICES_FAILURE'],
    [CALL_INVOICE_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody,
})

export const clearInvoices = () => ({
    type: 'CLEAR_INVOICES',
})