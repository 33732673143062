function appstate(state ={isLoading: false, environment: 'staging'}, action) {
    switch (action.type) {
      case 'SET_APP_ENV':
        console.log('set env! : ', action);
        return {
          ...state,
          environment: action.data.environment
        }
      default:
        return state
    }
  }

export default appstate