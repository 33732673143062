import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { feeType, paymentAccountTypes } from '../app/globals';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { fetchApi } from '../app/utils/callApi'

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const InputGreen = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(OutlinedInput);

const PaymentAccounts = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, contracts, postContractsSearch, postContractsSearchAfter, totalHitsContracts,
        search_after_contracts, clearContractsSearch, getEnumeration, countries, currencies, clearAlertMessage, alertMessage,
        createPaymentAccount, postPaymentAccountsSearch, paymentAccounts, totalHitsPaymentAccounts, payment_accounts_nextToken,
        postPaymentAccountsSearchAfter, clearPaymentAccountsSearch, updatePaymentAccounts, deletePaymentAccounts, environment } = props
    const urlArr = window.location.href.split('/')
    const orgID = urlArr[urlArr.length - 1]
    const [paymentAccountDialog, setPaymentAccountDialog] = useState(false);
    const [paymentAccountDetails, setPaymentAccountDetails] = useState({});
    const [newFee, setNewFee] = useState({});
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [searchProcessor, setSearchProcessor] = useState('');
    const [searchCountry, setSearchCountry] = useState('');
    const [searchSettlementCurrency, setSearchSettlementCurrency] = useState('');
    const [searchTransactionCurrency, setSearchTransactionCurrency] = useState('');
    const [searchId, setSearchId] = useState('');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody()
        postContractsSearch(esSearchReqBody)
        setLoadedPage(1)
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearContractsSearch()
            clearPaymentAccountsSearch()
        };
    },[])

    useEffect(() => {
        if (organization && organization.id) {
            let esSearchReqBody = constructSearchReqBody()
            postPaymentAccountsSearch(esSearchReqBody)
        }
    },[organization, organization.id])

    const constructSearchReqBody = (restartSearch=false) => {
        let initialStruct = {}
        // id is available but not in use
        let query = ""
        // could add filter for organization as well
        if (searchId) {
            query = `
                query listAccounts {
                    listAccounts(
                        filter: { 
                            ${searchId ? `id: { eq: "${searchId}" }` : ""}
                        },
                        limit: 1000,
                        nextToken: "${(!restartSearch && payment_accounts_nextToken) ? payment_accounts_nextToken : ""}",
                    ) 
                    { items { 
                        id
                        organizationId
                        name
                        contractId
                        processor
                        descriptor
                        country
                        posType
                        sourceTypes
                        createdAt
                        updatedAt
                        credentials
                        merchantProfile
                        currency
                        rollingReserve
                        funding
                        types
                    }, nextToken } 
                }
            `
        } else {
            query = `
                query listAccounts {
                    listAccounts(
                        limit: 1000,
                        nextToken: "${(!restartSearch && payment_accounts_nextToken) ? payment_accounts_nextToken : ""}",
                    ) 
                    { items { 
                        id
                        organizationId
                        name
                        contractId
                        processor
                        descriptor
                        country
                        posType
                        sourceTypes
                        createdAt
                        updatedAt
                        credentials
                        merchantProfile
                        currency
                        rollingReserve
                        funding
                        types
                    }, nextToken } 
                }
            `
        }
        initialStruct.query = query
        return initialStruct
    }

    // const prevToken = usePreviousToken(nextToken);
    useEffect(() => {
        // if(prevToken.nextToken) {

        //  // process here
        // }
        if (payment_accounts_nextToken && (paymentAccounts && paymentAccounts.length === 0)) {
            let esSearchReqBody = constructSearchReqBody()
            postPaymentAccountsSearch(esSearchReqBody)
        }
    }, [payment_accounts_nextToken])

    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={paymentAccountDialog} 
            onClose={()=> {setPaymentAccountDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            <CloseIcon className={classes.closeBtn} onClick={()=> {setPaymentAccountDialog(false)}}/>
            {paymentAccountDetails && !paymentAccountDetails.id &&<Typography className={classes.Title} color="textSecondary" gutterBottom>Create Payment Service Account</Typography>}
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        paymentAccountDetails && paymentAccountDetails.id && <Grid item md={8} xs={8} sm={8}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account ID: {paymentAccountDetails.id}</Typography>
                        </Grid>
                    }
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account Name</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            value={(paymentAccountDetails.name) || ''}
                            onChange={(e) => setPaymentAccountDetails({...paymentAccountDetails, name: e.target.value })} 
                        />
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Contract</Typography>
                            <GreenAutocomplete
                                id="tags-filled"
                                options={contracts}
                                getOptionLabel={(option) => option.name}
                                onChange={(e,v) => { 
                                    if (v && v.id) {
                                        setPaymentAccountDetails({...paymentAccountDetails, contractId: v.id })
                                    }
                                }}
                                fullWidth
                                freeSolo
                                renderInput={(params) => (
                                    <GreenTextField 
                                        {...params}
                                        variant="filled"
                                        placeholder="Contracts"
                                        onChange={(e) => {
                                            let esSearchReqBody = constructSearchReqBody(true)
                                            postContractsSearch(esSearchReqBody)
                                        }}
                                    />
                                )}
                            />
                        {paymentAccountDetails && paymentAccountDetails.contractId && <Typography className={classes.title} color="textSecondary" gutterBottom>ContractID: {paymentAccountDetails.contractId}</Typography>}
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Acquirer/Processor Country</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(paymentAccountDetails && paymentAccountDetails.country) || ""}
                            onChange={(e) => {
                                setPaymentAccountDetails({...paymentAccountDetails, country: e.target.value })
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.map((country, index) => {
                                    return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                })
                            }
                            <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>POS Type</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(paymentAccountDetails && paymentAccountDetails.posType) || ""}
                            onChange={(e) => {
                                setPaymentAccountDetails({...paymentAccountDetails, posType: e.target.value })
                            }}
                            label="Country"
                        >
                            {
                                ['elements', 'terminals', 'collections', 'checkoutpages'].map((posType, index) => {
                                    return <MenuItem key={'posType-'+ index} value={posType}>{posType.toUpperCase()}</MenuItem>
                                })
                            }
                            <MenuItem key={'posType-none'} value={""}>Please Select a POS Type</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            value={(paymentAccountDetails.processor) || ''}
                            onChange={(e) => setPaymentAccountDetails({...paymentAccountDetails, processor: e.target.value })} 
                        />
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Source Types (Please paste the whole json value e.g. {`{"paynow": {}, "grabpay": {}}`})</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            value={(paymentAccountDetails.sourceTypes && JSON.stringify(paymentAccountDetails.sourceTypes)) || '{}'}
                            onChange={(e) => setPaymentAccountDetails({...paymentAccountDetails, sourceTypes: JSON.parse(e.target.value) })} 
                        />
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account Types</Typography>
                        <Select
                            fullWidth
                            className={classes.select}
                            variant="outlined"
                            multiple
                            value={paymentAccountDetails.types || []}
                            onChange={(e) => {
                                console.log('paymentAccountDetails -> ', paymentAccountDetails)
                                setPaymentAccountDetails({...paymentAccountDetails, types: e.target.value })
                            }}
                            input={<InputGreen variant="outlined" />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                                MenuProps={MenuProps}
                            >
                            {paymentAccountTypes && paymentAccountTypes.map((accountType) => (
                                <MenuItem key={accountType} value={accountType}>
                                    {accountType}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Descriptor</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            value={(paymentAccountDetails.descriptor) || ''}
                            onChange={(e) => setPaymentAccountDetails({...paymentAccountDetails, descriptor: e.target.value })} 
                        />
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Credentials (Please paste the whole json value e.g. {`{"test":"testting123"}`})</Typography>
                        <OutlinedInputMod 
                            maxWidth={'md'} 
                            fullWidth
                            value={
                                (paymentAccountDetails.credentials && JSON.stringify(paymentAccountDetails.credentials) ) || '{}'
                            }
                            // value={(paymentAccountDetails.credentials && typeof JSON.parse(paymentAccountDetails.credentials) === 'object' ? JSON.stringify(paymentAccountDetails.credentials) : JSON.stringify(JSON.parse(paymentAccountDetails.credentials)) )) || '{}'}
                            onChange={(e) => setPaymentAccountDetails({...paymentAccountDetails, credentials: JSON.parse(e.target.value) })} 
                        />
                    </Grid>
                    <Grid item md={5} xs={5} sm={5}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Settlement Currency</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(paymentAccountDetails && paymentAccountDetails.currency && paymentAccountDetails.currency.settlement) || ""}
                            onChange={(e) => {
                                let currCurrency = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.currency) || {}))
                                currCurrency.settlement = e.target.value
                                setPaymentAccountDetails({...paymentAccountDetails, currency: currCurrency })
                            }}
                            label="Settlement"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'currency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Currency</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(paymentAccountDetails && paymentAccountDetails.currency && paymentAccountDetails.currency.transaction) || ""}
                            onChange={(e) => {
                                let currCurrency = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.currency) || {}))
                                currCurrency.transaction = e.target.value
                                setPaymentAccountDetails({...paymentAccountDetails, currency: currCurrency })
                            }}
                            label="Transaction"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'currency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Grid container spacing={3}>
                            <Grid item md={3} xs={3} sm={3}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Rolling Reserve %</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(paymentAccountDetails.rollingReserve && paymentAccountDetails.rollingReserve.percentage) || '0'}
                                    onChange={(e) => {
                                        let currRR = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.rollingReserve) || {} ))
                                        currRR.percentage = parseInt(e.target.value)
                                        setPaymentAccountDetails({...paymentAccountDetails, rollingReserve: currRR })
                                    }}
                                />
                            </Grid>
                            <Grid item md={3} xs={3} sm={3}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Rolling Reserve Flat</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(paymentAccountDetails.rollingReserve && paymentAccountDetails.rollingReserve.flat) || '0'}
                                    onChange={(e) => {
                                        let currRR = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.rollingReserve) || {} ))
                                        currRR.flat = parseInt(e.target.value)
                                        setPaymentAccountDetails({...paymentAccountDetails, rollingReserve: currRR })
                                    }}
                                />
                            </Grid>
                            <Grid item md={3} xs={3} sm={3}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Rolling Reserve Term</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(paymentAccountDetails.rollingReserve && paymentAccountDetails.rollingReserve.term) || '0'}
                                    onChange={(e) => {
                                        let currRR = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.rollingReserve) || {} ))
                                        currRR.term = parseInt(e.target.value)
                                        setPaymentAccountDetails({...paymentAccountDetails, rollingReserve: currRR })
                                    }}
                                />
                            </Grid>
                            <Grid item md={3} xs={3} sm={3}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Rolling Reserve Days</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(paymentAccountDetails.rollingReserve && paymentAccountDetails.rollingReserve.days) || '0'}
                                    onChange={(e) => {
                                        let currRR = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.rollingReserve) || {} ))
                                        currRR.days = parseInt(e.target.value)
                                        setPaymentAccountDetails({...paymentAccountDetails, rollingReserve: currRR })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Funding Term</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(paymentAccountDetails.funding && paymentAccountDetails.funding.term) || '0'}
                                    onChange={(e) => {
                                        let currFunding = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.funding) || {} ))
                                        currFunding.term = parseInt(e.target.value)
                                        setPaymentAccountDetails({...paymentAccountDetails, funding: currFunding })
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Funding Location</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(paymentAccountDetails.funding && paymentAccountDetails.funding.location) || ''}
                                    onChange={(e) => {
                                        let currFunding = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.funding) || {} ))
                                        currFunding.location = e.target.value
                                        setPaymentAccountDetails({...paymentAccountDetails, funding: currFunding })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Legal Name</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(paymentAccountDetails.merchantProfile && paymentAccountDetails.merchantProfile.name) || ''}
                                    onChange={(e) => {
                                        let currProfile = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.merchantProfile) || {} ))
                                        currProfile.name = e.target.value
                                        setPaymentAccountDetails({...paymentAccountDetails, merchantProfile: currProfile })
                                    }}
                                />
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Category Code (based on Acquirer/Processor list)</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'}
                                    fullWidth
                                    value={(paymentAccountDetails.merchantProfile && paymentAccountDetails.merchantProfile.mcc) || ''}
                                    onChange={(e) => {
                                        let currProfile = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.merchantProfile) || {} ))
                                        currProfile.mcc = e.target.value
                                        setPaymentAccountDetails({...paymentAccountDetails, merchantProfile: currProfile })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant City</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(paymentAccountDetails.merchantProfile && paymentAccountDetails.merchantProfile.city) || ''}
                                    onChange={(e) => {
                                        let currProfile = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.merchantProfile) || {} ))
                                        currProfile.city = e.target.value
                                        setPaymentAccountDetails({...paymentAccountDetails, merchantProfile: currProfile })
                                    }}
                                />
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Country</Typography>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    className={classes.select}
                                    value={(paymentAccountDetails && paymentAccountDetails.merchantProfile && paymentAccountDetails.merchantProfile.country) || ""}
                                    onChange={(e) => {
                                        let currProfile = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.merchantProfile) || {} ))
                                        currProfile.country = e.target.value
                                        setPaymentAccountDetails({...paymentAccountDetails, merchantProfile: currProfile })
                                    }}
                                    label="Country"
                                >
                                    {
                                        countries && countries.map((country, index) => {
                                            return <MenuItem key={'country-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                        })
                                    }
                                    <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Postal</Typography>
                                <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(paymentAccountDetails.merchantProfile && paymentAccountDetails.merchantProfile.postal) || ''}
                                    onChange={(e) => {
                                        let currProfile = JSON.parse(JSON.stringify((paymentAccountDetails && paymentAccountDetails.merchantProfile) || {} ))
                                        currProfile.postal = e.target.value
                                        setPaymentAccountDetails({...paymentAccountDetails, merchantProfile: currProfile })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* {JSON.stringify(paymentAccountDetails)} */}
                    <Grid item md={11} xs={11} sm={11}>
                        {paymentAccountDetails && !paymentAccountDetails.id && <ButtonMod onClick={() => {
                            if (!organization || !organization.id) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization Loaded'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                },3000)
                            } else {
                                createPaymentAccount({organizationId: organization.id}, paymentAccountDetails)
                                alertMessage({alertType: 'info', alertMessage: 'Remember to Create the Balances and Payables for the same currency.'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'info'})
                                },7000)
                            }
                            setPaymentAccountDialog(false)
                            setPaymentAccountDetails({})
                        }}>
                            CREATE
                        </ButtonMod>}
                        {paymentAccountDetails && paymentAccountDetails.id && <ButtonMod onClick={() => {
                            const reqBody = JSON.parse(JSON.stringify(paymentAccountDetails))
                            updatePaymentAccounts({accountId: paymentAccountDetails.id}, reqBody)
                            setPaymentAccountDialog(false)
                            setPaymentAccountDetails({})
                        }}>
                            SAVE
                        </ButtonMod>} 
                        {paymentAccountDetails && paymentAccountDetails.id && <ButtonMod onClick={() => {
                            deletePaymentAccounts({accountId: `${paymentAccountDetails.id}`})
                            setPaymentAccountDialog(false)
                            setPaymentAccountDetails({})
                        }}>
                            DELETE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Payment Service Accounts</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account Name</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchName) || ''}
                                            onChange={(e) => setSearchName(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account Processor</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchProcessor) || ''}
                                            onChange={(e) => setSearchProcessor(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Payment Service Account Country</Typography>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        className={classes.select}
                                        value={(searchCountry) || ""}
                                        onChange={(e) => {
                                            setSearchCountry(e.target.value)
                                        }}
                                        label="Country"
                                    >
                                        {
                                            countries && countries.map((country, index) => {
                                                return <MenuItem key={'searchCountry-'+ index} value={country.value.country_alpha3_code}>{country.name}</MenuItem>
                                            })
                                        }
                                        <MenuItem key={'country-none'} value={""}>Please Select a Country</MenuItem>
                                    </Select>
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Grid container spacing={3} >
                                            <Grid item md={6} xs={6} sm={6}>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>Settlement Currency</Typography>
                                                <Select
                                                    fullWidth
                                                    variant="outlined"
                                                    className={classes.select}
                                                    value={(searchSettlementCurrency) || ""}
                                                    onChange={(e) => {
                                                        setSearchSettlementCurrency(e.target.value)
                                                    }}
                                                    label="Settlement"
                                                >
                                                    {
                                                        currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                                            if (currency.value && currency.value.currency_alpha3_code) {
                                                                return <MenuItem key={'searchSettlementCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                                            }
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                            <Grid item md={6} xs={6} sm={6}>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Currency</Typography>
                                                <Select
                                                    fullWidth
                                                    variant="outlined"
                                                    className={classes.select}
                                                    value={(searchTransactionCurrency) || ""}
                                                    onChange={(e) => {
                                                        setSearchTransactionCurrency(e.target.value)
                                                    }}
                                                    label="Transaction"
                                                >
                                                    {
                                                        currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                                            if (currency.value && currency.value.currency_alpha3_code) {
                                                                return <MenuItem key={'searchTransactionCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                                            }
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(true)
                                                postPaymentAccountsSearch(esSearchReqBody)
                                                setSearchName('')
                                                setSearchProcessor('')
                                                setSearchCountry('')
                                                setSearchSettlementCurrency('')
                                                setSearchTransactionCurrency('')
                                                setIsExpanded(false)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setPaymentAccountDialog(true)
                                setPaymentAccountDetails({})
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody()
                                if (!payment_accounts_nextToken) {
                                    return;
                                }
                                postPaymentAccountsSearchAfter(esSearchReqBody)
                            }}

                            rowsPerPageOptions={[10]}
                            pageSize={13}
                            hideFooterRowCount
                            pagination
                            columns={[
                                { 
                                    field: 'id',
                                    headerName: 'ID',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                                },
                                { 
                                    field: 'name',
                                    headerName: 'Name',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.name) || ""}`
                                    },
                                },
                                { 
                                    field: 'processor',
                                    headerName: 'Processor',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.processor) || ""}`
                                    },
                                },
                                { 
                                    field: 'posType',
                                    headerName: 'POS Type',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.posType) || ""}`
                                    },
                                },
                                { 
                                    field: 'organizationId',
                                    headerName: 'Organization ID',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.organizationId) || ""}`
                                    },
                                },
                                { 
                                    field: 'contractId',
                                    headerName: 'Contract ID',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.contractId) || ""}`
                                    },
                                },
                                { 
                                    field: 'country',
                                    headerName: 'Country',
                                    width: 140,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.country) || ""}`
                                    },
                                },
                                { 
                                    field: 'currency.settlement',
                                    headerName: 'Settlement',
                                    width: 140,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.currency && params.row.currency.settlement) || ""}`
                                    },
                                },
                                { 
                                    field: 'currency.transaction',
                                    headerName: 'Transaction',
                                    width: 140,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.currency && params.row.currency.transaction) || ""}`
                                    },
                                },
                                { 
                                    field: 'credentials',
                                    headerName: 'Credentials',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.credentials && JSON.stringify(params.row.credentials)) || ""}`
                                    },
                                },
                                { 
                                    field: 'descriptor',
                                    headerName: 'Descriptor',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.descriptor) || ""}`
                                    },
                                },
                                { 
                                    field: 'funding.location',
                                    headerName: 'Funding Location',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.funding && params.row.funding.location) || ""}`
                                    },
                                },
                                { 
                                    field: 'funding.term',
                                    headerName: 'Funding Term',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.funding && params.row.funding.term) || ""}`
                                    },
                                },
                                { 
                                    field: 'rollingReserve.days',
                                    headerName: 'RollingReserve Days',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.rollingReserve && params.row.rollingReserve.days) || ""}`
                                    },
                                },
                                { 
                                    field: 'rollingReserve.flat',
                                    headerName: 'RollingReserve Flat',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.rollingReserve && params.row.rollingReserve.flat) || ""}`
                                    },
                                },
                                { 
                                    field: 'rollingReserve.percentage',
                                    headerName: 'RollingReserve Percentage',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.rollingReserve && params.row.rollingReserve.percentage) || ""}`
                                    },
                                },
                                { 
                                    field: 'rollingReserve.term',
                                    headerName: 'RollingReserve Term',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.rollingReserve && params.row.rollingReserve.term) || ""}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'updatedAt',
                                    headerName: 'Updated At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.updatedAt) {
                                            let date = new Date(params.row.updatedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                            ]}
                            rows={paymentAccounts}
                            rowCount={payment_accounts_nextToken ? 9999999999999 : (paymentAccounts.length)}
                            onRowClick={async (rowParams) => {
                                if (rowParams.row && rowParams.row.id) {
                                    const { data } = await fetchApi(
                                        environment,
                                        'accounts/:accountId',
                                        'GET',
                                        { accountId: rowParams.row.id}
                                    )
                                    
                                    setPaymentAccountDetails(data)
                                    setPaymentAccountDialog(true)
                                }
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default PaymentAccounts