function schedules(state ={isLoading: false, hits: [], search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'POST_FUNDING_SCHEDULES_SEARCH_REQUEST':
            // console.log('POST_FUNDING_SCHEDULES_SEARCH_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_FUNDING_SCHEDULES_SEARCH_SUCCESS':
            console.log('POST_FUNDING_SCHEDULES_SEARCH_SUCCESS', action);
            const listFundingSchedules = (action.data && action.data.data && action.data.data.listFundingSchedules) || null
            let hits = (listFundingSchedules && listFundingSchedules.items) ? listFundingSchedules.items : []
            const nextToken = (listFundingSchedules && listFundingSchedules.nextToken) ? listFundingSchedules.nextToken : undefined
            hits = hits.map((eachHit) => {
                eachHit.id = eachHit.organizationAccountId
                return eachHit
            })
            return {...state,
                fundingHits: hits,
                fundingNextToken: nextToken,
                isLoading: false
            }
        case 'POST_FUNDING_SCHEDULES_SEARCH_FAILURE':
            // console.log('POST_FUNDING_SCHEDULES_SEARCH_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_FUNDING_SCHEDULES_SEARCH_AFTER_REQUEST':
            // console.log('POST_FUNDING_SCHEDULES_SEARCH_AFTER_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_FUNDING_SCHEDULES_SEARCH_AFTER_SUCCESS':
            console.log('POST_FUNDING_SCHEDULES_SEARCH_AFTER_SUCCESS', action);
            const listNextFundingSchedules = (action.data && action.data.data && action.data.data.listFundingSchedules) || null
            let nextHits = (listNextFundingSchedules && listNextFundingSchedules.items) ? listNextFundingSchedules.items : []
            const nextNextToken = listNextFundingSchedules ? listNextFundingSchedules.nextToken : undefined
            const currentFundingSchedulesIDs = state.hits.map((eachHit) => eachHit.organizationAccountId)
            nextHits = nextHits.map((eachHit)=> {
                if (currentFundingSchedulesIDs.indexOf(eachHit.organizationAccountId) >= 0) return '';
                return eachHit
            }).filter((eachHit) => eachHit)
            let combinedHits = state.hits.concat(nextHits);
            combinedHits = combinedHits.map((eachHit) => {
                    eachHit.id = eachHit.organizationAccountId
                return eachHit
            })
            return {...state,
                fundingHits: combinedHits,
                fundingNextToken: nextNextToken,
                isLoading: false
            }
        case 'POST_FUNDING_SCHEDULES_SEARCH_AFTER_FAILURE':
            console.log('POST_FUNDING_SCHEDULES_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_FUNDING_SCHEDULES_SEARCH':
            return {...state,
                fundingHits: [],
                fundingNextToken: "",
                totalHits: 0,
                search_after: [],
            }

        case 'POST_BILLING_SCHEDULES_SEARCH_REQUEST':
            // console.log('POST_BILLING_SCHEDULES_SEARCH_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_BILLING_SCHEDULES_SEARCH_SUCCESS':
            console.log('POST_BILLING_SCHEDULES_SEARCH_SUCCESS', action);
            const listBillingSchedules = (action.data && action.data.data && action.data.data.listBillingSchedules) || null
            let billingHits = (listBillingSchedules && listBillingSchedules.items) ? listBillingSchedules.items : []
            const billingNextToken = (listBillingSchedules && listBillingSchedules.nextToken) ? listBillingSchedules.nextToken : undefined
            billingHits = billingHits.map((eachHit) => {
                eachHit.id = eachHit.organizationId + eachHit.location
                return eachHit
            })
            return {...state,
                billingHits: billingHits,
                billingNextToken: billingNextToken,
                isLoading: false
            }
        case 'POST_BILLING_SCHEDULES_SEARCH_FAILURE':
            // console.log('POST_BILLING_SCHEDULES_SEARCH_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_BILLING_SCHEDULES_SEARCH_AFTER_REQUEST':
            // console.log('POST_BILLING_SCHEDULES_SEARCH_AFTER_REQUEST', action);
            return {...state, isLoading: true}
        case 'POST_BILLING_SCHEDULES_SEARCH_AFTER_SUCCESS':
            console.log('POST_BILLING_SCHEDULES_SEARCH_AFTER_SUCCESS', action);
            const listNextBillingSchedules = (action.data && action.data.data && action.data.data.listBillingSchedules) || null
            let nextBillingHits = (listNextBillingSchedules && listNextBillingSchedules.items) ? listNextBillingSchedules.items : []
            const billingNextNextToken = listNextBillingSchedules ? listNextBillingSchedules.nextToken : undefined
            const currentBillingSchedulesIDs = state.hits.map((eachHit) => eachHit.organizationId + eachHit.location)
            nextBillingHits = nextBillingHits.map((eachHit)=> {
                if (currentBillingSchedulesIDs.indexOf(eachHit.organizationId + eachHit.location) >= 0) return '';
                return eachHit
            }).filter((eachHit) => eachHit)
            let billingCombinedHits = state.hits.concat(nextBillingHits);
            billingCombinedHits = billingCombinedHits.map((eachHit) => {
                    eachHit.id = eachHit.organizationId + eachHit.location
                return eachHit
            })
            return {...state,
                billingHits: billingCombinedHits,
                billingNextToken: billingNextNextToken,
                isLoading: false
            }
        case 'POST_BILLING_SCHEDULES_SEARCH_AFTER_FAILURE':
            console.log('POST_BILLING_SCHEDULES_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_BILLING_SCHEDULES_SEARCH':
            return {...state,
                billingHits: [],
                billingNextToken: "",
                totalHits: 0,
                search_after: [],
            }
        default:
            return {...state, isLoading: false} 
    }
  }

export default schedules