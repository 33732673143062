function organizations(state ={isLoading: false, organizations: [], organization: {}, search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'SEARCH_ORGANIZATIONS_REQUEST':
            return {
                ...state,
                isLoading: true
            }
        case 'SEARCH_ORGANIZATIONS_SUCCESS':
            console.log('SEARCH_ORGANIZATIONS_SUCCESS' ,action.data);
            const listOrganizations = (action && action.data && action.data.data && action.data.data.listOrganizations) || null;
            const hits = listOrganizations ? listOrganizations.items : []
            const nextToken = listOrganizations ? listOrganizations.nextToken : undefined
            return {
                ...state,
                isLoading: false,
                organizations: hits,
                nextToken: nextToken,
            } 
        case 'SEARCH_ORGANIZATIONS_FAILURE':
            console.log('GET_USER_ORGANIZATIONS_FAILURE' ,action.data);
            return {    
                ...state,
                isLoading: false,
            } 
        case 'SEARCH_AFTER_ORGANIZATIONS_REQUEST':
            return {...state, isLoading: true}
        case 'SEARCH_AFTER_ORGANIZATIONS_SUCCESS':
            console.log("SEARCH_AFTER_ORGANIZATIONS_SUCCESS : ", action);
            const nextListOrganizations = (action && action.data && action.data.data && action.data.data.listOrganizations) || null;
            let nextHits = nextListOrganizations ? nextListOrganizations.items : []
            const nextNextToken = nextListOrganizations ? nextListOrganizations.nextToken : undefined
            const currentOrgIDs = state.organizations.map((eachOrg) => eachOrg.id)
            nextHits = nextHits.map((eachHit)=> {
                if (currentOrgIDs.indexOf(eachHit.id) >= 0) return '';
                return eachHit
            }).filter((eachHit) => eachHit)
            console.log("nextHits.length: ", nextHits.length);
            const combinedHits = state.organizations.concat(nextHits);
            // nextHitsSource.forEach((newHit) => {
            //     if (!combinedIDs[newHit.id]) {
            //         combinedHits.push(newHit)
            //     }
            // })
            console.log("combinedHits.length: ", combinedHits.length);
            return {...state,
                organizations: combinedHits,
                nextToken: nextNextToken,
                isLoading: false
            }
        case 'SEARCH_AFTER_ORGANIZATIONS_FAILURE':
            return {...state, isLoading: false}
        case 'CREATE_CONTRACT_REQUEST':
            return {...state, isLoading: true}
        case 'CREATE_CONTRACT_SUCCESS':
            let currentContracts = state.contracts || []
            return {...state,
                isLoading: false,
                contracts: [...currentContracts, action.data]
            }
        case 'CREATE_CONTRACT_FAILURE':
            return {...state, isLoading: false}
        case 'CLEAR_ORG_STATE':
            return {...state, organization: {}, organizations: [], contracts: [], isLoading: false}
        case 'SET_ORG_STATE':
            console.log('set state!', action);
            return {...state, isLoading: false, organization: (action.reqBody ? action.reqBody: {})}
        case 'POST_CONTRACTS_SEARCH_REQUEST':
            return {...state, isLoading: true}
        case 'POST_CONTRACTS_SEARCH_SUCCESS':
            console.log('POST_CONTRACTS_SEARCH_SUCCESS', action);
            const contract_hits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const search_after_contracts = contract_hits.length > 0 ? contract_hits[contract_hits.length - 1].sort : [];
            const totalHitsContracts = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const contractHitsSource = contract_hits.map((eachHit) => eachHit['_source'])
            return {...state,
                contracts: contractHitsSource,
                totalHitsContracts: totalHitsContracts,
                search_after_contracts: search_after_contracts,
                isLoading: false
            }
        case 'POST_CONTRACTS_SEARCH_FAILURE':
            return {...state, isLoading: false}
        case 'POST_CONTRACTS_SEARCH_AFTER_REQUEST':
            return {...state, isLoading: true}
        case 'POST_CONTRACTS_SEARCH_AFTER_SUCCESS':
            console.log('POST_CONTRACTS_SEARCH_AFTER_SUCCESS', action);
            const nextContractHits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const totalContractsHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const next_search_after_contracts = nextContractHits.length > 0 ? nextContractHits[nextContractHits.length - 1].sort : [];
            const nextContractHitsSource = nextContractHits.map((eachHit) => eachHit['_source'])
            const combinedContractsHits = [...state.contracts]
            const combinedContractIDs = {};
            combinedContractsHits.forEach((eachHit) => combinedContractIDs[eachHit.id] = true)
            nextContractHitsSource.forEach((newHit) => {
                if (!combinedContractIDs[newHit.id]) {
                    combinedContractsHits.push(newHit)
                }
            })
            return {...state,
                contracts: combinedContractsHits,
                totalHitsContracts: totalContractsHitsAfter,
                search_after_contracts: next_search_after_contracts,
                isLoading: false
            }
        case 'POST_CONTRACTS_SEARCH_AFTER_FAILURE':
            return {...state, isLoading: false}
        case 'SET_CONTRACT_STATE':
            return {...state, isLoading: false, contracts: []}
        case 'PATCH_CONTRACT_REQUEST':
            return {...state, isLoading: true}
        case 'PATCH_CONTRACT_SUCCESS':
            console.log('PATCH_CONTRACT_SUCCESS', action);
            let targetPatchContract = (action && action.data) || {}
            const patchContractHits = state.contracts.map((eachHit) => {
                if (eachHit.id === targetPatchContract.id) return targetPatchContract
                return eachHit
            })
            return {...state, isLoading: false, contracts: patchContractHits}
        case 'PATCH_CONTRACT_FAILURE':
            return {...state, isLoading: false}
        case 'DELETE_CONTRACT_REQUEST':
            return {...state, isLoading: true}
        case 'DELETE_CONTRACT_SUCCESS':
            console.log('DELETE_CONTRACT_SUCCESS', action);
            // TODO: need response id from delete api
            // let targetPatchContract = (action && action.data && action.data) || {}
            // const patchContractHits = state.contracts.map((eachHit) => {
            //     if (eachHit.id === targetPatchContract.id) return targetPatchContract
            //     return eachHit
            // })
            return {...state, isLoading: false}
        case 'DELETE_CONTRACT_FAILURE':
            return {...state, isLoading: false}
        case 'CLEAR_CONTRACT_STATE':
            return {...state, contracts: [], totalHitsContracts: 0, search_after_contracts: [], isLoading: false}
        case 'POST_CREATE_PAYMENT_ACCOUNT_REQUEST':
            return {...state, isLoading: true}
        case 'POST_CREATE_PAYMENT_ACCOUNT_SUCCESS':
            console.log('POST_CREATE_PAYMENT_ACCOUNT_SUCCESS', action);
            let currAcc;
            if (state.paymentAccounts) {
                currAcc = state.paymentAccounts.map((eachAccount) => eachAccount)
            } else {
                currAcc = []
            }
            currAcc.push(action.data)
            return {...state, isLoading: false, paymentAccounts: currAcc }
        case 'POST_CREATE_PAYMENT_ACCOUNT_FAILURE':
            return {...state, isLoading: false}
        case 'POST_PAYMENT_ACCOUNTS_SEARCH_REQUEST':
            return {...state, isLoading: true}
        case 'POST_PAYMENT_ACCOUNTS_SEARCH_SUCCESS':
            console.log('POST_PAYMENT_ACCOUNTS_SEARCH_SUCCESS', action);
            let payment_account_hits = (action && action.data && action.data.data && action.data.data.listAccounts.items) || [];
            const payment_accounts_nextToken = (action && action.data && action.data.data && action.data.data.listAccounts.nextToken) || "";
            payment_account_hits = payment_account_hits.map((eachAccount) => {
                if (eachAccount.currency) eachAccount.currency = JSON.parse(eachAccount.currency)
                if (eachAccount.funding) eachAccount.funding = JSON.parse(eachAccount.funding)
                if (eachAccount.merchantProfile) eachAccount.merchantProfile = JSON.parse(eachAccount.merchantProfile)
                if (eachAccount.rollingReserve) eachAccount.rollingReserve = JSON.parse(eachAccount.rollingReserve)
                if (eachAccount.sourceTypes) eachAccount.sourceTypes = JSON.parse(eachAccount.sourceTypes)
                return eachAccount
            })
            return {...state,
                paymentAccounts: payment_account_hits,
                payment_accounts_nextToken: payment_accounts_nextToken,
                isLoading: false
            }
        case 'POST_PAYMENT_ACCOUNTS_SEARCH_FAILURE':
            return {...state, isLoading: false}
        case 'CLEAR_PAYMENT_ACCOUNTS_STATE':
            return {...state, isLoading: false, paymentAccounts: [], totalHitsPaymentAccounts: 0, search_after_payment_accounts: [] }
        case 'POST_PAYMENT_ACCOUNTS_SEARCH_AFTER_REQUEST':
            return {...state, isLoading: true}
        case 'POST_PAYMENT_ACCOUNTS_SEARCH_AFTER_SUCCESS':
            console.log('POST_PAYMENT_ACCOUNTS_SEARCH_AFTER_SUCCESS', action);
            let nextPaymentAccountHits = (action && action.data && action.data.data && action.data.data.listAccounts.items) || [];
            const next_search_after_payment_accounts = (action && action.data && action.data.data && action.data.data.listAccounts.nextToken) || "";
            nextPaymentAccountHits = nextPaymentAccountHits.map((eachAccount) => {
                if (eachAccount.currency) eachAccount.currency = JSON.parse(eachAccount.currency)
                if (eachAccount.funding) eachAccount.funding = JSON.parse(eachAccount.funding)
                if (eachAccount.merchantProfile) eachAccount.merchantProfile = JSON.parse(eachAccount.merchantProfile)
                if (eachAccount.rollingReserve) eachAccount.rollingReserve = JSON.parse(eachAccount.rollingReserve)
                if (eachAccount.sourceTypes) eachAccount.sourceTypes = JSON.parse(eachAccount.sourceTypes)
                return eachAccount
            })
            const combinedPaymentAccountsHits = [...state.paymentAccounts]
            const combinedPaymentAccountIDs = {};
            combinedPaymentAccountsHits.forEach((eachHit) => combinedPaymentAccountIDs[eachHit.id] = true)
            nextPaymentAccountHits.forEach((newHit) => {
                if (!combinedPaymentAccountIDs[newHit.id]) {
                    combinedPaymentAccountsHits.push(newHit)
                }
            })
            return {...state,
                paymentAccounts: combinedPaymentAccountsHits,
                payment_accounts_nextToken: next_search_after_payment_accounts,
                isLoading: false
            }
        case 'POST_PAYMENT_ACCOUNTS_SEARCH_AFTER_FAILURE':
            return {...state, isLoading: false}
        case 'PATCH_PAYMENT_ACCOUNT_REQUEST':
            return {...state, isLoading: true}
        case 'PATCH_PAYMENT_ACCOUNT_SUCCESS':
            console.log('PATCH_PAYMENT_ACCOUNT_SUCCESS', action);
            let targetPatchAccount = (action && action.data) || {}
            const patchAccountHits = state.paymentAccounts.map((eachHit) => {
                if (eachHit.id === targetPatchAccount.id) return targetPatchAccount
                return eachHit
            })
            return {...state, isLoading: false, paymentAccounts: patchAccountHits}
        case 'PATCH_PAYMENT_ACCOUNT_FAILURE':
            return {...state, isLoading: false}
        case 'DELETE_PAYMENT_ACCOUNT_REQUEST':
            return {...state, isLoading: true}
        case 'DELETE_PAYMENT_ACCOUNT_SUCCESS':
            console.log('DELETE_PAYMENT_ACCOUNT_SUCCESS', action);
            return {...state, isLoading: false}
        case 'DELETE_PAYMENT_ACCOUNT_FAILURE':
            return {...state, isLoading: false}
        case 'POST_POINT_OF_SALES_SEARCH_REQUEST':
            return {...state, isLoading: true}
        case 'POST_POINT_OF_SALES_SEARCH_SUCCESS':
            console.log('POST_POINT_OF_SALES_SEARCH_SUCCESS', action);
            const pos_hits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const search_after_pos = pos_hits.length > 0 ? pos_hits[pos_hits.length - 1].sort : [];
            const totalHitsPos = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const posHitsSource = pos_hits.map((eachHit) => eachHit['_source'])
            return {...state,
                pos: posHitsSource,
                totalHitsPos: totalHitsPos,
                search_after_pos: search_after_pos,
                isLoading: false
            }
        case 'POST_POINT_OF_SALES_SEARCH_FAILURE':
            return {...state, isLoading: false}
        case 'POST_POINT_OF_SALES_SEARCH_AFTER_REQUEST':
            return {...state, isLoading: true}
        case 'POST_POINT_OF_SALES_SEARCH_AFTER_SUCCESS':
            console.log('POST_POINT_OF_SALES_SEARCH_AFTER_SUCCESS', action);
            const nextPosHits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const totalPosHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const next_search_after_pos = nextPosHits.length > 0 ? nextPosHits[nextPosHits.length - 1].sort : [];
            const nextPosHitsSource = nextPosHits.map((eachHit) => eachHit['_source'])
            const combinedPosHits = [...state.paymentAccounts]
            const combinedPosIDs = {};
            combinedPosHits.forEach((eachHit) => combinedPosIDs[eachHit.id] = true)
            nextPosHitsSource.forEach((newHit) => {
                if (!combinedPosIDs[newHit.id]) {
                    combinedPosHits.push(newHit)
                }
            })
            return {...state,
                pos: combinedPosHits,
                totalHitsPos: totalPosHitsAfter,
                search_after_pos: next_search_after_pos,
                isLoading: false
            }
        case 'POST_POINT_OF_SALES_SEARCH_AFTER_FAILURE':
            return {...state, isLoading: false}
        case 'CLEAR_PAYMENT_ACCOUNTS_STATE':
            return {...state, isLoading: false, pos: [], totalHitsPos: 0, search_after_pos: [] }
        case 'PATCH_POINT_OF_SALES_REQUEST':
            return {...state, isLoading: true}
        case 'PATCH_POINT_OF_SALES_SUCCESS':
            console.log('PATCH_POINT_OF_SALES_SUCCESS', action);
            let targetPatchPos = (action && action.data) || {}
            const patchPosHits = state.pos.map((eachHit) => {
                if (eachHit.id === targetPatchPos.id) return targetPatchPos
                return eachHit
            })
            return {...state, isLoading: false, pos: patchPosHits}
        case 'PATCH_POINT_OF_SALES_FAILURE':
            return {...state, isLoading: false}
        case 'GET_ORGANIZATIONS_REQUEST':
            return {...state, isLoading: true}
        case 'GET_ORGANIZATIONS_SUCCESS':
            console.log('GET_ORGANIZATIONS_SUCCESS', action);
            let targetOrganization = (action && action.data) || {}
            return {...state, isLoading: false, organization: targetOrganization}
        case 'GET_ORGANIZATIONS_FAILURE':
            return {...state, isLoading: false}
        case 'PATCH_ORGANIZATIONS_REQUEST':
            return {...state, isLoading: true}
        case 'PATCH_ORGANIZATIONS_SUCCESS':
            console.log('PATCH_ORGANIZATIONS_SUCCESS', action);
            let targetOrg = (action && action.data) || {}
            const patchOrgHits = state.organizations.map((eachHit) => {
                if (eachHit.id === targetOrg.id) return targetOrg
                return eachHit
            })
            return {...state, isLoading: false, organizations: patchOrgHits, organization: targetOrg}
        case 'PATCH_ORGANIZATIONS_FAILURE':
            return {...state, isLoading: false}
        case 'SET_ORGANIZATION_LOADING':
            return {...state, isLoading: true}
        case 'SET_ORGANIZATION_NOT_LOADING':
            return {...state, isLoading: false}
        default:
            return {...state, isLoading: false} 
    }
  }

export default organizations