import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, clearAlertMessage, alertMessage, 
    searchFundingSchedules, searchAfterFundingSchedules, createFundingSchedule, clearFundingSchedules, 
    postPaymentAccountsSearch, GetOrgAccounts, 
    postFundingSchedulesSearch, postFundingSchedulesSearchAfter, clearFundingSchedulesSearch
    } from '../actions'
import FundingSchedules from '../components/FundingSchedules.js'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.schedules && state.schedules.isLoading) || false,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    paymentAccounts: (state.organizations && state.organizations.paymentAccounts) || [],
    funding_schedules: (state.schedules && state.schedules.fundingHits) || [],
    nextToken: (state.schedules && state.schedules.fundingNextToken) || "",
    totalHitsFundingSchdules: (state && state.schedules && state.schedules.fundingHits && state.schedules.fundingHits.length) || 0,
    search_after_funding_schedules: (state.fundingsBillings && state.fundingsBillings.search_after_funding_schedules) || [],
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    clearAlertMessage: (reqBody) => dispatch(clearAlertMessage(reqBody)),
    alertMessage: (reqBody) => dispatch(alertMessage(reqBody)),
    postPaymentAccountsSearch: (reqBody) => dispatch(postPaymentAccountsSearch(reqBody)),
    GetOrgAccounts: (params) => dispatch(GetOrgAccounts(params)),
    postFundingSchedulesSearch: (reqBody) => dispatch(postFundingSchedulesSearch(reqBody)),
    postFundingSchedulesSearchAfter: (reqBody) => dispatch(postFundingSchedulesSearchAfter(reqBody)),
    createFundingSchedule: (reqBody) => dispatch(createFundingSchedule(reqBody)),
    clearFundingSchedulesSearch: () => dispatch(clearFundingSchedulesSearch())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FundingSchedules)