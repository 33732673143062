const drawerWidth = 280;
const feeType = ["ANNUAL", "MONTHLY", "TOKEN", "SMS", "ONE_TIME", "AUTHORIZATION", "CAPTURE", "REFUND", "AUTHENTICATION", "VOID", "CHARGEBACK"]
const customFeeType = ["GRAB_INSTALMENT_4", "GRAB_POSTPAID"]
const allTransactionModes = ['BANK_TRANSFER']
const allPayoutStatus = ["PENDING_APPROVAL", "CANCELLED", "ESCALATED", "REPORTED", "APPROVED", "COMPLETED"]
const allInvoicestatus = [ "PENDING_PAYMENT", "PAID", "CANCELLED"]
const paymentAccountTypes = [ "DOMESTIC_MONEY_TRANSFER", "CROSS_BORDER_MONEY_TRANSFER", "MERCHANT_ACQUISITION_MONEY_TRANSFER", "E_MONEY_ISSUANCE", "DIGITAL_PAYMENT_TOKEN", "MONEY_CHANGING"]
const customerRiskQuestions = {
    tspModel: {
        value: 'Is Liberalize the Technology Service Provider to the External Third Party?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 1 }, // to convert to rating later
        otherInfo: ''
    },
    pfModel: {
        value: 'Is Liberalize the payment facilitator to the External Third Party?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 2 },
        otherInfo: ''
    },
    boPep: {
        value: 'Is a beneficial owner a politically exposed person?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 1, 1: 3 },
        otherInfo: ''
    },
    authPersonelPep: {
        value: 'Is a director or a person authorised to act on behalf of the External Third Party a politically exposed person?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 1, 1: 3 },
        otherInfo: ''
    },
    complexBiz: {
        value: 'Does the External Third Party ownership structure appear unusual or complex given the nature of business?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    },
    bearerShares: {
        value: 'Is the External Third Party a company with bearer shares?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    },
    shellShelfCompany: {
        value: 'Is the External Third Party a shell/shelf company?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    },
    adverseNews: {
        value: 'Are there any adverse news related to criminal activities, alleged or otherwise?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    },
    financialCondition: {
        value: 'Is the External Third Party in an unfavourable financial condition?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    },
    creditRating: {
        value: 'Is External Third Party rated by a credit rating company?',
        rating: 0,
        radioRiskMapping: [{ value: 'yes (Low Risk)', rating: 1 }, { value: 'yes (Medium Risk)', rating: 2 }, { value: 'yes (High Risk)', rating: 3 }, { value: 'No Rating', rating: 0 }],
        otherInfo: '',
        otherInfoQns: 'What was the risk rating?'
    }
}

const transactionRiskQuestions = {
    tresholdTwoMil: {
        value: 'Is the External Third Party’s estimated annual turnover exceed the threshold of USD 2M?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 1, 1: 2 },
        otherInfo: ''
    },
    unreasonablyHigh: {
        value: 'Is the External Third Party’s estimated annual turnover seem unreasonably high for their size, business nature or industry?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    },
    virtualCurrencies: {
        value: 'Is the External Third Party’s currently transact or intend to transact using virtual currencies? (i.e. Bitcoin, Ethereum)',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    },
    currentAcquirer: {
        value: 'If External Third Party’s have a current acquirer, who is their acquirer ? And does their chargeback ratio exceed the acceptable amount in any of the months within the past one year of records?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 0 },
        otherInfo: '',
        otherInfoQns: 'Acquirer: ______ Chargebacks & Transactions: ______'
    }
}

const industryRiskQuestions = {
    highRiskIndustry: {
        value: 'Entity classified under Restricted Industry Types/High Risk Industry Types by internal policy or by applicable law or regulation?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    }
}

const geographicalRiskQuestions = {
    highRiskCountry: {
        value: 'The External Third Party incorporated in a high risk country or non cooperative jurisdiction under the Financial Action Task Force or by any internal policy?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    },
    nonHighRiskCountryButNotSame: {
        value: 'The External Third Party is incorporated in a non-high risk country or non cooperative jurisdiction under the Financial Action Task Force or by any internal policy and not in the same country as the relying Liberalize Unit?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 2 },
        otherInfo: ''
    },
    nonHighRiskCountryAndSame: {
        value: 'The External Third Party is incorporated in a non-high risk country or non cooperative jurisdiction under the Financial Action Task Force or by any internal policy and in the same country as the relying Liberalize Unit?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 1 },
        otherInfo: ''
    },
    sanctionViolations: {
        value: 'Has the External Third Party or its parent company in the past two years been subject to fines or regulatory actions due to sanctions violations?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 1, 1: 0 },
        otherInfo: '',
        otherInfoQns: 'If Yes, indicate details and consult with Compliance.'
    },
    indirectSanctionViolations: {
        value: 'Is there any indication for potential indirect sanctions issues? (e.g. subsidiary in a sanction country or in resp. neighbour countries relevant news about business activity with sanctioned counterparts etc.)',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 1, 1: 0 },
        otherInfo: '',
        otherInfoQns: 'If Yes, indicate details and consult with Compliance.'
    }
}

const productServiceRiskQuestions = {
    economicActivityMatch: {
        value: 'Does the reported economic activity match their financial statement, URL (Web Address), mobile app, social media or a processing report?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 0 },
        otherInfo: ''
    },
    consumerProtectionFiled: {
        value: 'Has the External Third Party been filed against consumer or reported protection laws or reported of any unfair trading practices?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 0 },
        otherInfo: '',
        otherInfoQns: 'If Yes, indicate details and consult with Compliance.'
    },
    externalDeliveryReliance: {
        value: 'Does the External Third Party rely on other parties for delivering of their goods or services?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 0 },
        otherInfo: '',
        otherInfoQns: 'If Yes, indicate details and consult with Compliance.'
    },
    favourableBusinessPolicies: {
        value: 'Does the External Third Party have favourable standard business contracts and policies?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 1, 1: 0 },
        otherInfo: '',
    },
    unfavourablePaymentFlow: {
        value: 'Does the External Third Party have unfavourable payment flows/processes or has a long servicing subscription model?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 1, 1: 0 },
        otherInfo: '',
        otherInfoQns: 'If Yes, indicate details and consult with Compliance.'
    }
}

// for this parent company risk section -1 rating refers to being not applicable, as it does not need to be answered if there's no parent company
const parentCompanyRiskQuestions = {
    countryRisk: {
        value: 'In which jurisdiction is the parent company incorporated and /or registered?',
        rating: 0,
        radioRiskMapping: [{ value: 'Sensitive Country (High Risk)', rating: 3 }, { value: 'Non-Sensitive Country and not in same country as relying Liberalize unit (Medium Risk)', rating: 2 }, { value: 'Non-Sensitive Country and in same country as relying Liberalize unit (Low Risk)', rating: 1 }, { value: 'Not Applicable', rating: -1 }],
        otherInfo: ''
    },
    financialCondition: {
        value: 'Is the parent company in an unfavourable financial condition?',
        rating: 0,
        radioRiskMapping: [{ value: 'Yes (High Risk)', rating: 3 }, { value: 'No', rating: 0 }, { value: 'Not Applicable', rating: -1 }],
        otherInfo: '',
    },
    creditRating: {
        value: 'Is the parent company rated by a credit rating company?',
        rating: 0,
        radioRiskMapping: [{ value: 'Yes (High Risk)', rating: 3 }, { value: 'Yes (Medium Risk)', rating: 2 }, { value: 'Yes (Low Risk)', rating: 1 }, { value: 'No', rating: 0 }, { value: 'Not Applicable', rating: -1 }],
        otherInfo: '',
        otherInfoQns: 'What was the risk rating? Rating: ______'
    }
}

const regulatoryProceedingsQuestions = {
    worldCheck: {
        value: 'Has a Thomson Reuters World Check been performed?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 0 },
        otherInfo: ''
    },
    worldCheckHits: {
        value: 'Are there any hits on the performed World Check?',
        rating: 0,
        binaryAnswer: false,
        binaryRiskMapping: { 0: 0, 1: 3 },
        otherInfo: ''
    }
}

const finalRiskRatingQuestions = {
    finalRiskRating: {
        radioRiskMapping: [{value: 'Low Risk', rating: 1}, {value: 'Medium Risk', rating: 2}, {value: 'High Risk', rating: 3}]
    }
}

const teamMemberQuestions = {
    clientAdvisor: {
        value: 'Client Advisor',
        userId: '',
        name: '',
        designation: '',
        comments: '',
        place: '',
        date: ''
    },
    underwriter: {
        value: 'Underwriter',
        userId: '',
        name: '',
        designation: '',
        comments: '',
        place: '',
        date: ''
    },
    complianceOfficer: {
        value: 'Compliance Officer',
        userId: '',
        name: '',
        designation: '',
        comments: '',
        place: '',
        date: ''
    }
}

const disputeOfficerQuestions = [
    {
        display: 'Liberalize Officer',
        officer: 'liberalizeOfficer',
        name: '',
        userId: '',
        userEmail: '',
        designation: '',
        comments: '',
        datetime: '',
        jwt: ''
    },
    {
        display: 'Processor Officer',
        officer: 'processorOfficer',
        name: '',
        userId: '',
        userEmail: '',
        designation: '',
        comments: '',
        datetime: '',
        jwt: ''
    },
    {
        display: 'Merchant Officer',
        officer: 'merchantOfficer',
        name: '',
        userId: '',
        userEmail: '',
        designation: '',
        comments: '',
        datetime: '',
        jwt: ''
    },
]

const posTypes = ["paymentterminals", "collections", "checkoutpages", "paymentelements" ]

const allComplianceApprovalStatus = [
    "NO_STATUS",
    "ADMIN_ACTION_REQUIRED",
    "REJECTED",
    "APPROVED",
    "ORGANIZATION_ACTION_REQUIRED"
]

module.exports = {
    drawerWidth: drawerWidth,
    feeType: feeType,
    customFeeType: customFeeType,
    customerRiskQuestions: customerRiskQuestions,
    transactionRiskQuestions: transactionRiskQuestions,
    industryRiskQuestions: industryRiskQuestions,
    geographicalRiskQuestions: geographicalRiskQuestions,
    productServiceRiskQuestions: productServiceRiskQuestions,
    parentCompanyRiskQuestions: parentCompanyRiskQuestions,
    regulatoryProceedingsQuestions: regulatoryProceedingsQuestions,
    finalRiskRatingQuestions: finalRiskRatingQuestions,
    teamMemberQuestions: teamMemberQuestions,
    posTypes: posTypes,
    allTransactionModes: allTransactionModes,
    allPayoutStatus: allPayoutStatus,
    allInvoicestatus: allInvoicestatus,
    allComplianceApprovalStatus: allComplianceApprovalStatus,
    paymentAccountTypes: paymentAccountTypes,
    disputeOfficerQuestions: disputeOfficerQuestions
}