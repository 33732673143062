import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, clearAlertMessage, alertMessage, postPointOfSalesSearch, postPointOfSalesSearchAfter, 
    createFundingSchedule, clearPosSearch, postContractsSearch, patchPos
    } from '../actions'
import PointOfSales from '../components/PointOfSales.js'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.organizations && state.organizations.isLoading) || false,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    pos: (state.organizations && state.organizations.pos) || [],
    totalHitsPos: (state.organizations && state.organizations.totalHitsPos) || 0,
    search_after_pos: (state.organizations && state.organizations.search_after_pos) || [],
    contracts: (state.organizations && state.organizations.contracts) || []
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    clearAlertMessage: (reqBody) => dispatch(clearAlertMessage(reqBody)),
    alertMessage: (reqBody) => dispatch(alertMessage(reqBody)),
    postPointOfSalesSearch: (reqBody, query) => dispatch(postPointOfSalesSearch(reqBody, query)),
    postPointOfSalesSearchAfter: (reqBody, query) => dispatch(postPointOfSalesSearchAfter(reqBody, query)),
    createFundingSchedule: (reqBody) => dispatch(createFundingSchedule(reqBody)),
    clearPosSearch: () => dispatch(clearPosSearch()),
    postContractsSearch: (reqBody) => dispatch(postContractsSearch(reqBody)),
    patchPos: (params, reqBody) => dispatch(patchPos(params, reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PointOfSales)