import { CALL_KYC_API } from '../app/middleware/callKycApi.js';
import { CALL_KYC_APPSYNC_API } from '../app/middleware/callKycAppSyncApi.js';

export const downloadS3File = (params, query) => ({
    types: ['GET_S3_FILE_REQUEST', 'GET_S3_FILE_SUCCESS', 'GET_S3_FILE_FAILURE'],
    [CALL_KYC_API]: '/organizations/:organizationId/kycdoc',
    method: 'get',
    params: params,
    query: query
})

export const createKyc = (params, reqBody) => ({
    types: ['POST_CREATE_KYC_REQUEST', 'POST_CREATE_KYC_SUCCESS', 'POST_CREATE_KYC_FAILURE'],
    [CALL_KYC_API]: '/organizations/:organizationId/kyc',
    method: 'post',
    params: params,
    reqBody: reqBody
})

export const updateKyc = (params, reqBody) => ({
    types: ['PUT_UPDATE_KYC_REQUEST', 'PUT_UPDATE_KYC_SUCCESS', 'PUT_UPDATE_KYC_FAILURE'],
    [CALL_KYC_API]: '/kycs/:kycId',
    method: 'put',
    params: params,
    reqBody: reqBody
})

export const deleteKyc = (params) => ({
    types: ['DELETE_KYC_REQUEST', 'DELETE_KYC_SUCCESS', 'DELETE_KYC_FAILURE'],
    [CALL_KYC_API]: '/kycs/:kycId',
    method: 'delete',
    params: params
})

export const postKYCsSearch = (reqBody) => ({
    types: ['POST_KYCS_SEARCH_REQUEST', 'POST_KYCS_SEARCH_SUCCESS', 'POST_KYCS_SEARCH_FAILURE'],
    [CALL_KYC_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postKYCsSearchAfter = (reqBody) => ({
    types: ['POST_KYCS_SEARCH_AFTER_REQUEST', 'POST_KYCS_SEARCH_AFTER_SUCCESS', 'POST_KYCS_SEARCH_AFTER_FAILURE'],
    [CALL_KYC_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const clearKYCsSearch = (params, query) => ({
    type: 'CLEAR_KYC_SEARCH',
})

export const setLoading = (params, query) => ({
    type: 'SET_IS_LOADING',
})

export const setNotLoading = (params, query) => ({
    type: 'SET_NOT_LOADING',
})