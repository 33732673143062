import { CALL_CONTRACTS_APPSYNC_API } from '../app/middleware/callContractAppSync.js';

export const postContractsSearch = (reqBody) => ({
    types: ['POST_CONTRACTS_SEARCH_REQUEST', 'POST_CONTRACTS_SEARCH_SUCCESS', 'POST_CONTRACTS_SEARCH_FAILURE'],
    [CALL_CONTRACTS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postContractsSearchAfter = (reqBody) => ({
    types: ['POST_CONTRACTS_SEARCH_AFTER_REQUEST', 'POST_CONTRACTS_SEARCH_AFTER_SUCCESS', 'POST_CONTRACTS_SEARCH_AFTER_FAILURE'],
    [CALL_CONTRACTS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const clearContractsSearch = () => ({
    type: 'CLEAR_CONTRACTS_SEARCH'
})

export const updateContractsSearch = (reqBody) => ({
    type: 'UPDATE_CONTRACTS_SEARCH',
    reqBody: reqBody
})

