import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, postSearchOrganizations, postSearchAfterOrganizations, setOrgState } from '../actions'
import Main from '../components/Main'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    user: state.users.user,
    organization: state.organizations.organization,
    organizations: state.organizations.organizations,
    search_after: state.organizations.search_after,
    totalHits: state.organizations.totalHits,
    isLoading: state.organizations.isLoading,
    nextToken: state.organizations.nextToken
})
  
const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    setOrgState: (reqBody) => dispatch(setOrgState(reqBody)),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    postSearchOrganizations: (reqBody) => dispatch(postSearchOrganizations(reqBody)),
    postSearchAfterOrganizations: (reqBody) => dispatch(postSearchAfterOrganizations(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main)