import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, createContract, postContractsSearch,
    postContractsSearchAfter, updateContract, deleteContract, clearContractsSearch,
    getEnumeration
 } from '../actions'
import Contracts from '../components/Contracts'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    isLoading: (state.contracts && state.contracts.isLoading) || false,
    contracts: (state && state.contracts && state.contracts.hits) || [],
    totalHitsContracts: (state && state.contracts && state.contracts.hits && state.contracts.hits.length) || 0,
    search_after_contracts: (state.organizations && state.organizations.search_after_contracts) || [],
    nextToken: (state.contracts && state.contracts.nextToken) || "",
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    createContract: (reqBody) => dispatch(createContract(reqBody)),
    postContractsSearch: (reqBody) => dispatch(postContractsSearch(reqBody)),
    postContractsSearchAfter: (reqBody) => dispatch(postContractsSearchAfter(reqBody)),
    updateContract: (params, reqBody) => dispatch(updateContract(params, reqBody)),
    deleteContract: (params) => dispatch(deleteContract(params)),
    clearContractsSearch: () => dispatch(clearContractsSearch()),
    getEnumeration: (params) => dispatch(getEnumeration(params))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contracts)