import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Typography from '@material-ui/core/Typography';
import { teal } from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom"
import RootRef from '@material-ui/core/RootRef';

import { drawerWidth } from '../app/globals';
import logo from '../logoNname.svg';

const useStyles = makeStyles((theme => ({
    overview: {
      borderRight: '1px solid #ffffff',
      maxWidth: '80%',
      display: 'inline-block'
    },
    homeIcon: {
      paddingRight: '0.2em'
    },
    settingGear: {
      paddingLeft: '0.7em'
    },
    settingGearTrans: {
      transform: `translateX(-0.1em) rotate(-90deg)`,
      transition: 'transform .15s ease-in,opacity .075s ease',
      transitionProperty: 'transform, opacity',
      transitionDuration: '0.15s, 0.075s',
      transitionTimingFunction: 'ease-in, ease',
      transitionDelay: '0s, 0s',
    },
    setting: {
      display: 'inline-block',
      // paddingLeft: '0.7em',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    menuPosition:{
      marginLeft: '5em'
    },
    divider: {
      backgroundColor: '#979797',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        background: '#0c3336',
        width: drawerWidth,
        color: 'white',
    },
    drawerHeaderLogo: {
      maxWidth: '100%',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        '&:hover': {
          cursor: 'pointer',
        },
    },
    subItem: {
      '&:hover': {
        backgroundColor: '#365a5d',
        color: '#FFF'
      }
    },
})));

const AppDrawer = (props) => {
    const domRef = React.createRef();
    const [reportDropDown, setReportDropDown] = useState(true)
    const [complyDropDown, setComplyDropDown] = useState(true)
    const [manageDropDown, setManageDropDown] = useState(true)
    const [reportHover, setReportHover] = useState(false)
    const [complyHover, setComplyHover] = useState(false)
    const [manageHover, setManageHover] = useState(false)
    const [settingHover, setSettingHover] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const { 
      toggleAppbarWhiteFont,
      clearOrgState,
    } = props
    const { openDrawer, toggleDrawer, organization } = props
    const orgID = (organization && organization.id) || ''
    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const backToMain = () => {
      clearOrgState()
      history.push({
          pathname: `/`,
      })
    }
    const backToOrgMain = () => {
      history.push({
          pathname: `/company/${orgID}`,
      })
    }
    const goToSettings = () => {
      handleCloseMenu()
      toggleAppbarWhiteFont(false)
      history.push({
        pathname: `/company/${orgID}/settings`,
      })
    }
    const goToSection = (text) => {
      handleCloseMenu()
      toggleAppbarWhiteFont(false)
      // console.log('text is -> ', text);
      let destination = ''
      switch (text) {
        case 'All Contracts':
          destination = 'contracts'
          history.push({
            pathname: `/contracts`,
          })
          return
        case 'All Payouts':
          destination = 'payouts'
          history.push({
            pathname: `/payouts`,
          })
          return
        case 'All Invoices':
          destination = 'invoices'
          history.push({
            pathname: `/invoices`,
          })
          return
        case 'Company Overview':
          break;
        case 'Company Settings':
          destination = 'company-settings'
          break;
        case 'Payment Service Accounts':
          destination = 'payment-accounts'
          break;
        case 'KYC':
          destination = 'kyc'
          break;
        case 'Disputes':
          destination = 'disputes'
          break;
        case 'Balances':
          destination = 'balances'
          break;
        case 'Payables':
          destination = 'payables'
          break;
        case 'Payments':
          destination = 'payments'
          break;
        case 'Funding Schedules':
          destination = 'funding-schedules'
          break;
        case 'Billing Schedules':
          destination = 'billing-schedules'
          break;
        case 'Point Of Sales':
          destination = 'point-of-sales'
          break;
        default:
          destination = ''
          break;
      }
      history.push({
        pathname: `/company/${orgID}/${destination}`,
      })
    }
    const classes = useStyles(props)
    const theme = useTheme();
    return (<nav><Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
    >
      <div onClick={() => { backToMain() }} className={classes.drawerHeader}>
        <img className={classes.drawerHeaderLogo} src={logo} alt={"liberalizeLogo"} />
      </div>
      <Divider className={classes.divider}  light={true}/>
      {/* OverView */}
      <List component="nav" style={{paddingTop: 0}}>
        <div className={classes.overview}>
        <ListItem button className={classes.subItem}>
          <HomeIcon className={classes.homeIcon} />
          <ListItemText onClick={() => { 
              goToSection('Company Overview')
            }} primary="Company Overview" />
        </ListItem>
        </div>
        <div className={classes.setting} onMouseEnter={() => {setSettingHover(true)}} onMouseLeave={() => {setSettingHover(false)}}>
          <RootRef rootRef={domRef}>
            <Button style={{color: '#ffffff', display: 'block', justifyContent: 'flex-start', padding: 0}} onClick={handleClickMenu} aria-controls="simple-menu" aria-haspopup="true"><SettingsIcon className={settingHover ? classes.settingGearTrans: classes.settingGear } /> {settingHover && <ArrowRightIcon/>}</Button>
            <Menu
              id="simple-menu"
              className={classes.menuPosition}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              container={domRef.current}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => { goToSection('Company Settings')}}>Company Settings</MenuItem>
            </Menu>
          </RootRef>
        </div>
        <Divider className={classes.divider}  light={true}/>
        {/* Report */}
        <ListItem className={classes.subItem} button onMouseEnter={() => {setReportHover(true)}} onMouseLeave={() => {setReportHover(false)}} onClick={() => {setReportDropDown(!reportDropDown)}}>
          <ListItemText ><Typography  variant="h6">Report</Typography></ListItemText>
          {reportHover && (reportDropDown ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        <Collapse in={reportDropDown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {['Payments', 'Balances', 'Payables', 'All Payouts', 'All Invoices'].map((text) => {
              return <ListItem className={classes.subItem} onClick={(e) => { goToSection(text) }} button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            })}
          </List>
        </Collapse>
        <Divider className={classes.divider}  light={true}/>
        {/* Comply */}
        <ListItem className={classes.subItem} button onMouseEnter={() => {setComplyHover(true)}} onMouseLeave={() => {setComplyHover(false)}} onClick={() => {setComplyDropDown(!complyDropDown)}}>
          <ListItemText ><Typography  variant="h6">Comply</Typography></ListItemText>
          {complyHover && (complyDropDown ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        <Collapse in={complyDropDown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {['KYC', 'Disputes'].map((text) => {
              return <ListItem onClick={(e) => { goToSection(text) }} className={classes.subItem} button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            })}
          </List>
        </Collapse>
        <Divider className={classes.divider}  light={true}/>
        {/* Manage */}
        <ListItem className={classes.subItem} button onMouseEnter={() => {setManageHover(true)}} onMouseLeave={() => {setManageHover(false)}} onClick={() => {setManageDropDown(!manageDropDown)}}>
          <ListItemText ><Typography  variant="h6">Manage</Typography></ListItemText>
          {manageHover && (manageDropDown ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        <Collapse in={manageDropDown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {['Funding Schedules', 'Billing Schedules', 'All Contracts', 'Payment Service Accounts', 'Point Of Sales'
              // 'Routes', 'Customers', 'Products & Services'
            ].map((text) => {
              return <ListItem onClick={(e) => { goToSection(text) }} className={classes.subItem} button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            })}
          </List>
        </Collapse>
      </List>
      </Drawer></nav>)
}

export default AppDrawer
