import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { green } from '@material-ui/core/colors';
import moment from 'moment';
import { fetchApi } from '../app/utils/callDisputeApi';
import { createDispute, deleteDispute } from '../actions';

import { disputeOfficerQuestions } from '../app/globals';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    textArea: {
        width: '100%',
        '&:focus-visible': {
            'outline-color': '#68d0af',
        },
    },
    showMore: {
        color: '#68d0af',
        fontWeight: 700,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    hiddeninput: {
        display: 'none',
    },
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const GreenSwitch = withStyles({
    switchBase: {
      color: green[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const DateTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    },
})(TextField);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
        color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const Disputes = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, organizations, isLoading, currentUser, postDisputesSearch, postDisputesSearchAfter, processors,
        totalHitsDisputes, getEnumeration, countries, currencies, mcc, environment, setLoading, setNotLoading, postSearchOrganizations,
        search_after_disputes, clearDisputesSearch, createDispute, updateDispute, deleteDispute, allDisputes, setOrgState, disputes,
        pdtSvcTypes
    } = props
    const urlArr = window.location.href.split('/')
    const orgID = urlArr[urlArr.length - 1]
    const [disputeDialog, setDisputeDialog] = useState(false);
    const [disputeDetails, setDisputeDetails] = useState({});
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchIsResolved, setSearchIsResolved] = useState('');
    const [searchProcessor, setSearchPrcoessor] = useState('');
    const [searchOrganizationId, setSearchOrganizationId] = useState('');
    const [addPosId, setAddPosId] = useState('');
    const [addCbId, setAddCbId] = useState('');
    const [addDbId, setAddDbId] = useState('');
    const [addCourierName, setAddCourierName] = useState('');
    const [addTrackingNumber, setAddTrackingNumber] = useState('');
    const [addProcessorTxnId, setAddProcessorTxnId] = useState('');
    const [addProcessorTxnDatetime, setAddProcessorTxnDatetime] = useState('');
    const [addIssuerTxnId, setAddIssuerTxnId] = useState('');
    const [addIssuerTxnDateTime, setAddIssuerTxnDateTime] = useState('');
    const [addProcessorNetSettlementAmount, setAddProcessorNetSettlementAmount] = useState('');
    const [addProcessorNetSettlementCurrency, setAddProcessorNetSettlementCurrency] = useState('');
    const [addPmtId, setAddPmtId] = useState('');
    const [addTxnId, setAddTxnId] = useState('');
    const [searchLiberalizeOfficerName, setSearchLiberalizeOfficerName] = useState('');
    const [searchMerchantOfficerName, setSearchMerchantOfficerName] = useState('');
    const [searchProcessorOfficerName, setSearchProcessorOfficerName] = useState('');
    const [searchId, setSearchId] = useState('');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody()
        postDisputesSearch(esSearchReqBody)
        setLoadedPage(1)
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        getEnumeration({enumGroup: 'processor'})
        getEnumeration({enumGroup: 'mcc'})
        getEnumeration({enumGroup: 'dispute'})
        getEnumeration({enumGroup: 'productservicetype'})
        // setOrgState(org)
        return function cleanup() {
            clearDisputesSearch()
        };
    },[])

    // useEffect(()=> {
    //     if (organization && organization.id) {
    //         setDisputeDetails(currentDisputeDetails)
    //     }
    // },[organization, organization.id])

    const downloadFile = (s3Key) => {
        console.log(s3Key);
        if (!disputeDetails.organizationId) {
            alert('No Organization Selected!')
            throw new Error('No Organization Selected!')
        }
        setLoading()
        fetchApi(environment,
            '/organizations/:organizationId/disputedoc',
            'get',
            {organizationId: disputeDetails.organizationId},
            null,
            {key: s3Key}
            ).then((result) => {
                console.log('presigned result => ', result);
                setNotLoading()
                const { data } = result
                window.open(data, "_blank")
        })
    }

    const uploadFile = (eventFile, disputeDetailsAttribute) => {
        setLoading()
        let file = eventFile[0]
        // console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setNotLoading()
            return;
        }
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'application/pdf':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setNotLoading()
            alert('Only png, jpeg and pdf file types allowed')
            // this.imageUploading = false
            return;
        }
        const max_file_size = 102400 * 10
        if (file.size <= max_file_size){
            const reader = new FileReader()
            // console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                // console.log('content -> ', content);
                // console.log('file 2 -> ', file);

                saveFile(file, content, disputeDetailsAttribute)
            }
        } else {
            setNotLoading();
            alert('Maximum size exceeded for file! Please make sure file size is less than 1MB');
            // this.imageUploading = false
        }
    }

    const saveFile = (file, content, disputeDetailsAttribute) => {
        try {
            let fileNameArr = file.name.split('.')
            if (!disputeDetails.organizationId) {
                alert('No Organization Selected!')
                throw new Error('No Organization Selected!')
            }
            fetchApi(environment,
                '/organizations/:organizationId/disputedoc',
                'post',
                {organizationId: disputeDetails.organizationId},
                {}
                ).then((result) => {
                    const { data } =result
                    if (!data.url) return
                    const xhr = new XMLHttpRequest()
                    let url = data.url
                    
                    xhr.open(data.method, url)
                    xhr.setRequestHeader('x-amz-acl', data.XamzAcl)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            let currentDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                            if (disputeDetailsAttribute === 'documentsForProcessor') {
                                let currentDocumentsForProcessorFiles = currentDisputeDetails.documentsForProcessor || []
                                currentDisputeDetails.documentsForProcessor = [...currentDocumentsForProcessorFiles, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantCustomerSignature') {
                                let currentMerchantCustomerSignature = currentDisputeDetails.merchantCustomerSignature || []
                                currentDisputeDetails.merchantCustomerSignature = [...currentMerchantCustomerSignature, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantCustomerCommunication') {
                                let currentMerchantCustomerCommunication = currentDisputeDetails.merchantCustomerCommunication || []
                                currentDisputeDetails.merchantCustomerCommunication = [...currentMerchantCustomerCommunication, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantReceipt') {
                                if (!currentDisputeDetails.merchantReceipt) currentDisputeDetails.merchantReceipt = { documents: [], channel: ''}
                                let currentMerchantReceiptDocuments = currentDisputeDetails.merchantReceipt.documents || []
                                currentDisputeDetails.merchantReceipt.documents = [...currentMerchantReceiptDocuments, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantProductServiceDescription') {
                                if (!currentDisputeDetails.merchantProductServiceDescription) currentDisputeDetails.merchantProductServiceDescription = { documents: [], description: ''}
                                let currentPdtSvcDescDocs = currentDisputeDetails.merchantProductServiceDescription.documents || []
                                currentDisputeDetails.merchantProductServiceDescription.documents = [...currentPdtSvcDescDocs, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantRefundPolicy') {
                                currentDisputeDetails.merchantRefundPolicy = { name: file.name, key: data.key }
                            } else if (disputeDetailsAttribute === 'merchantRefundPolicyDisclosure') {
                                if (!currentDisputeDetails.merchantRefundPolicyDisclosure) currentDisputeDetails.merchantRefundPolicyDisclosure = { documents: [], description: ''}
                                let currentRefundDisclosureDocs = currentDisputeDetails.merchantRefundPolicyDisclosure.documents || []
                                currentDisputeDetails.merchantRefundPolicyDisclosure.documents = [...currentRefundDisclosureDocs, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantDuplicateChargeDocuments') {
                                let currentMerchantDuplicateChargeDocuments = currentDisputeDetails.merchantDuplicateChargeDocuments || []
                                currentDisputeDetails.merchantDuplicateChargeDocuments = [...currentMerchantDuplicateChargeDocuments, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantServiceDocumentation') {
                                let currentMerchantServiceDocumentation = currentDisputeDetails.merchantServiceDocumentation || []
                                currentDisputeDetails.merchantServiceDocumentation = [...currentMerchantServiceDocumentation, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantShippingDocumentation') {
                                let currentMerchantShippingDocumentation = currentDisputeDetails.merchantShippingDocumentation || []
                                currentDisputeDetails.merchantShippingDocumentation = [...currentMerchantShippingDocumentation, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantUncategorizedFile') {
                                let currentMerchantUncategorizedFile = currentDisputeDetails.merchantUncategorizedFile || []
                                currentDisputeDetails.merchantUncategorizedFile = [...currentMerchantUncategorizedFile, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantAccessActivityLogs') {
                                let currentMerchantAccessActivityLogs = currentDisputeDetails.merchantAccessActivityLogs || []
                                currentDisputeDetails.merchantAccessActivityLogs = [...currentMerchantAccessActivityLogs, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'merchantCancellationPolicy') {
                                currentDisputeDetails.merchantCancellationPolicy = { name: file.name, key: data.key }
                            } else if (disputeDetailsAttribute === 'merchantCancellationPolicyDisclosure') {
                                if (!currentDisputeDetails.merchantCancellationPolicyDisclosure) currentDisputeDetails.merchantCancellationPolicyDisclosure = { documents: [], description: ''}
                                let currentMerchantCancellationPolicyDisclosure = currentDisputeDetails.merchantCancellationPolicyDisclosure.documents || []
                                currentDisputeDetails.merchantCancellationPolicyDisclosure.documents = [...currentMerchantCancellationPolicyDisclosure, { name: file.name, key: data.key }]
                            } else if (disputeDetailsAttribute === 'processorUploadedDocuments') {
                                let currentProcessorUploadedDocuments = currentDisputeDetails.processorUploadedDocuments || []
                                currentDisputeDetails.processorUploadedDocuments = [...currentProcessorUploadedDocuments, { name: file.name, key: data.key }]
                            }
                            setDisputeDetails(currentDisputeDetails)
                            setNotLoading();
                        }
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setNotLoading();
            alert(err)
        }
    }

    const constructSearchReqBody = (passedPageSize, restartSearch=false, searchOrganizationName='') => {
        let initialStruct = {
            from: searchFrom,
            size: passedPageSize || searchSize,
            query: {
                bool: {
                    must: [
                    ]
                },
            },
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }
        if (searchIsResolved) {
            const letter = searchIsResolved.toLowerCase()
            if (letter === 'y') {
                initialStruct.query.bool.must.push(
                    { "match": { "isResolved": true }}
                )
            } else {
                initialStruct.query.bool.must.push(
                    { "match": { "isResolved": false }}
                )
            }
        }
        if (searchOrganizationName) {
            initialStruct.regexp = {
                name: {
                    value: searchOrganizationName + ".*",
                    flags: "ALL",
                    max_determinized_states: 10000,
                    rewrite: "constant_score"
                }
            }
        }
        // if (searchClientAdvisorName) {
        //     initialStruct.query.bool.must.push(
        //         { "match": { "clientAdvisor.name": searchClientAdvisorName }}
        //     )
        // }
        if (searchProcessor) {
            initialStruct.query.bool.must.push(
                { "match": { "processor": searchProcessor }}
            )
        }
        if (searchOrganizationId) {
            initialStruct.query.bool.must.push(
                { "match": { "organizationId": searchOrganizationId }}
            )
        }
        if (!restartSearch && search_after_disputes.length > 0) {
            initialStruct.from = -1
            initialStruct.search_after = search_after_disputes
        }
        return initialStruct
    }
    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={disputeDialog} 
            onClose={()=> {setDisputeDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            {/* Loading screen */}
            <Backdrop className={classes.backdrop} open={isLoading} >
                <CircularProgress className={classes.circularProgress} />
            </Backdrop>
            <CloseIcon className={classes.closeBtn} onClick={()=> {setDisputeDialog(false)}}/>
            {disputeDetails && !disputeDetails.id &&<Typography className={classes.Title} color="textSecondary" gutterBottom>Create Dispute</Typography>}
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        disputeDetails && disputeDetails.id && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute ID: {disputeDetails.id}</Typography>
                        </Grid>
                    }
                    {/* OrganizationID */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Organization ID:</Typography>
                            <GreenAutocomplete
                                disabled={disputeDetails.isResolved} 
                                id="tags-filled"
                                options={[{ name: 'Please Select An Organization' }, ...organizations]}
                                getOptionLabel={(option) => option.name}
                                onChange={(e,v) => { 
                                    if (v && v.id) {
                                        let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currDisputeDetails = {...currDisputeDetails, organizationId: v.id}
                                        setDisputeDetails(currDisputeDetails)
                                    } else {
                                        let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currDisputeDetails = {...currDisputeDetails, organizationId: ""}
                                        setDisputeDetails(currDisputeDetails)
                                    }
                                }}
                                fullWidth
                                freeSolo
                                renderInput={(params) => (
                                    <GreenTextField
                                        disabled={disputeDetails.isResolved} 
                                        {...params}
                                        variant="filled"
                                        placeholder="Organizations"
                                        onChange={(e) => {
                                            let esSearchReqBody = constructSearchReqBody(10, true, e.target.value)
                                            postSearchOrganizations(esSearchReqBody)
                                        }}
                                    />
                                )}
                            />
                        {disputeDetails && <Typography className={classes.title} color="textSecondary" gutterBottom>OrganizationID: {disputeDetails.organizationId || "No Organization"}</Typography>}
                    </Grid>
                    {/* POS IDs */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>POS IDs:</Typography>
                        <Grid container>
                            {disputeDetails && disputeDetails.posIds && disputeDetails.posIds.length > 0 && disputeDetails.posIds.map((eachPos, posIndex) => {
                                return <Grid item md={12} xs={12} sm={12}>
                                    {eachPos} 
                                    <IconButton
                                        disabled={disputeDetails.isResolved} 
                                        onClick={() => { 
                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            currdisputeDetails.posIds = currdisputeDetails.posIds.filter((targetFile, idx) => {
                                                if (idx == posIndex) return false
                                                return true
                                            })
                                            setDisputeDetails(currdisputeDetails)
                                        }}
                                        component="span"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            })}
                            <Grid item md={7} xs={7} sm={7}>
                                <OutlinedInputMod
                                    placeholder={'Inpute POS ID'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addPosId) || ''}
                                    onChange={(e) => setAddPosId(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={5} xs={5} sm={5}>
                                <IconButton 
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.posIds) currdisputeDetails.posIds = []
                                        currdisputeDetails.posIds.push(addPosId)
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Account ID */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Account ID:</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.accountId) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails = {...currDisputeDetails, accountId: e.target.value}
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Payments */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payments:</Typography>
                        {
                            disputeDetails && disputeDetails.payments && disputeDetails.payments.length > 0  && disputeDetails.payments.map((eachPmt, pmtIndex) => {
                                return <Grid container>
                                    <Grid item md={12} xs={12} sm={12}>
                                        PaymentID: {eachPmt && eachPmt.paymentId} 
                                        <IconButton
                                            disabled={disputeDetails.isResolved} 
                                            onClick={() => { 
                                                let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                                currdisputeDetails.payments = currdisputeDetails.payments.filter((targetPmt, idx) => {
                                                    if (idx == pmtIndex) return false
                                                    return true
                                                })
                                                setDisputeDetails(currdisputeDetails)
                                            }}
                                            component="span"
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item md={12} xs={12} sm={12}>
                                            {
                                                eachPmt && eachPmt.transactionIds && eachPmt.transactionIds.length > 0 && eachPmt.transactionIds.map((eachTxn, txnIndx) => {
                                                    return <Grid container>
                                                        <Grid item md={2} xs={2} sm={2}>
                                                        </Grid>
                                                        <Grid item md={10} xs={10} sm={10}>
                                                            TransactionID: {eachTxn} 
                                                            <IconButton
                                                                disabled={disputeDetails.isResolved} 
                                                                onClick={() => { 
                                                                    let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                                                    currdisputeDetails.payments[pmtIndex].transactionIds = currdisputeDetails.payments[pmtIndex].transactionIds.filter((targetTxn, idx) => {
                                                                        if (idx == txnIndx) return false
                                                                        return true
                                                                    })
                                                                    setDisputeDetails(currdisputeDetails)
                                                                }}
                                                                component="span"
                                                            >
                                                                <DeleteOutlineIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                })
                                            }
                                            <Grid container>
                                                <Grid item md={2} xs={2} sm={2}>
                                                </Grid>
                                                <Grid item md={7} xs={7} sm={7}>
                                                    <OutlinedInputMod
                                                        disabled={disputeDetails.isResolved} 
                                                        placeholder={'Input TransactionID'}
                                                        maxWidth={'md'} 
                                                        fullWidth
                                                        value={(addTxnId) || ''}
                                                        onChange={(e) => setAddTxnId(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={3} xs={3} sm={3}>
                                                    <IconButton
                                                        disabled={disputeDetails.isResolved} 
                                                        style={{color: '#68d0af'}} 
                                                        aria-controls="simple-menu" 
                                                        aria-haspopup="true"
                                                        onClick={() => {
                                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                                            if (!currdisputeDetails.payments[pmtIndex].transactionIds) currdisputeDetails.payments[pmtIndex].transactionIds = []
                                                            currdisputeDetails.payments[pmtIndex].transactionIds.push(addTxnId)
                                                            setDisputeDetails(currdisputeDetails)
                                                        }}
                                                    >
                                                        <AddIcon fontSize="large"/>
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                </Grid>
                            })
                        }
                        <Grid container>
                            <Grid item md={7} xs={7} sm={7}>
                                <OutlinedInputMod
                                    disabled={disputeDetails.isResolved} 
                                    placeholder={'Input PaymentID'}
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addPmtId) || ''}
                                    onChange={(e) => {
                                        setAddPmtId(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item md={5} xs={5} sm={5}>
                                <IconButton
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.payments) currdisputeDetails.payments = []
                                        currdisputeDetails.payments.push({ paymentId: addPmtId, transactionIds: []})
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Chargeback IDs */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Chargeback IDs:</Typography>
                        <Grid container>
                            {disputeDetails && disputeDetails.chargebackIds && disputeDetails.chargebackIds.length > 0 && disputeDetails.chargebackIds.map((eachCb, cbIndex) => {
                                return <Grid item md={12} xs={12} sm={12}>
                                    {eachCb} 
                                    <IconButton
                                        disabled={disputeDetails.isResolved}
                                        onClick={() => { 
                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            currdisputeDetails.chargebackIds = currdisputeDetails.chargebackIds.filter((targetCb, idx) => {
                                                if (idx == cbIndex) return false
                                                return true
                                            })
                                            setDisputeDetails(currdisputeDetails)
                                        }}
                                        component="span"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            })}
                            <Grid item md={7} xs={7} sm={7}>
                                <OutlinedInputMod
                                    placeholder={'Input Chargeback IDs'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addCbId) || ''}
                                    onChange={(e) => setAddCbId(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={5} xs={5} sm={5}>
                                <IconButton
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.chargebackIds) currdisputeDetails.chargebackIds = []
                                        currdisputeDetails.chargebackIds.push(addCbId)
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Invoice ID */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Invoice ID:</Typography>
                        <OutlinedInputMod
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.invoiceId) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails = {...currDisputeDetails, invoiceId: e.target.value}
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Payout ID */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Payout ID:</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.payoutId) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails = {...currDisputeDetails, payoutId: e.target.value}
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Comments */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Comments:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.comments && disputeDetails.comments) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.comments = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {/* Type */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Type:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.type) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.type = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Type"
                        >
                            {
                                ['RETRIEVAL', 'CHARGEBACK', ""].map((targetType, typeIdx) => {
                                        return <MenuItem key={targetType ? targetType : typeIdx} value={targetType}>{targetType ? targetType : "EMPTY"}</MenuItem>
                                })
                            }
                        </Select>
                    </Grid>
                    {/* State */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>State:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.state) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.state = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Type"
                        >
                            {
                                ['CREATED', 'PENDING_MERCHANT_ACTION', 'MERCHANT_RESPONDED', 'NO_MERCHANT_RESPONSE', 'PENDING_PROCESSOR_ACTION', 'PROCESSOR_RESPONDED', 'PROCESSOR_CLOSED', 'WIN', 'LOSE', '', 'CLOSED_WITH_NO_STATUS'].map((targetState, typeIdx) => {
                                        return <MenuItem key={targetState ? targetState : typeIdx} value={targetState}>{targetState ? targetState : "EMPTY"}</MenuItem>
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Merchant Action Deadline */}
                    <Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Action Deadline (UTC):</Typography>
                        <DateTextField
                            disabled={disputeDetails.isResolved} 
                            id="merchantDate"
                            type="datetime-local"
                            onChange={(e) => {
                                let date = new Date(e.target.value)
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantActionDeadline = date.toISOString()
                                setDisputeDetails(currDisputeDetails)
                            }}
                            defaultValue={(disputeDetails.merchantActionDeadline && moment.utc(disputeDetails.merchantActionDeadline).local().format('YYYY-MM-DDTHH:mm:ss')) || "2017-07-27T15:24:00"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div>
                            {disputeDetails.merchantActionDeadline && new Date(disputeDetails.merchantActionDeadline).toString()}
                        </div>
                    </Grid>
                    {/* Processor Action Deadline */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor Action Deadline (UTC):</Typography>
                        <DateTextField
                            disabled={disputeDetails.isResolved} 
                            id="processorDate"
                            type="datetime-local"
                            onChange={(e) => {
                                let date = new Date(e.target.value)
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.processorActionDeadline = date.toISOString()
                                setDisputeDetails(currDisputeDetails)
                            }}
                            defaultValue={(disputeDetails.processorActionDeadline && moment.utc(disputeDetails.processorActionDeadline).local().format('YYYY-MM-DDTHH:mm:ss')) || "2017-07-27T15:24:00"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div>
                            {disputeDetails.processorActionDeadline && new Date(disputeDetails.processorActionDeadline).toString()}
                        </div>
                    </Grid>
                    {/* Liberalize Action Deadline */}
                    <Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Liberalize Action Deadline (UTC):</Typography>
                        <DateTextField
                            disabled={disputeDetails.isResolved} 
                            id="liberalizeDate"
                            type="datetime-local"
                            onChange={(e) => {
                                let date = new Date(e.target.value)
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.liberalizeActionDeadline = date.toISOString()
                                setDisputeDetails(currDisputeDetails)
                            }}
                            defaultValue={(disputeDetails.liberalizeActionDeadline && moment.utc(disputeDetails.liberalizeActionDeadline).local().format('YYYY-MM-DDTHH:mm:ss'))|| "2017-07-27T15:24:00"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div>
                            {disputeDetails.liberalizeActionDeadline && new Date(disputeDetails.liberalizeActionDeadline).toString()}
                        </div>
                    </Grid>
                    {/* Issuer Action Deadline */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Issuer Action Deadline (UTC):</Typography>
                        <DateTextField
                            disabled={disputeDetails.isResolved} 
                            id="issuerDate"
                            type="datetime-local"
                            onChange={(e) => {
                                let date = new Date(e.target.value)
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.issuerActionDeadline = date.toISOString()
                                setDisputeDetails(currDisputeDetails)
                            }}
                            defaultValue={(disputeDetails.issuerActionDeadline && moment.utc(disputeDetails.issuerActionDeadline).local().format('YYYY-MM-DDTHH:mm:ss')) || "2017-07-27T15:24:00"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div>
                            {disputeDetails.issuerActionDeadline && new Date(disputeDetails.issuerActionDeadline).toString()}
                        </div>
                    </Grid>
                    {/* Admin Base Fee Amount */}
                    <Grid item md={8} xs={8} sm={8}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Admin Base Fee Amount: (Amount Paid to Us. Lowest Denomination e.g. 100 for $1)</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.adminFeesBase && disputeDetails.adminFeesBase.amount) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.adminFeesBase) currDisputeDetails.adminFeesBase = { amount: 0, currency: ''}
                                currDisputeDetails.adminFeesBase.amount = parseInt(e.target.value)
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Admin Base Fee Currency */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Admin Base Fee Currency:</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.adminFeesBase && disputeDetails.adminFeesBase.currency) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.adminFeesBase) currDisputeDetails.adminFeesBase = { amount: 0, currency: ''}
                                currDisputeDetails.adminFeesBase.currency = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'adminFeesBaseCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Admin Counter Fee Amount */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Admin Counter Fee Amount: (Amount to Collect)</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.adminFeesCounter && disputeDetails.adminFeesCounter.amount) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.adminFeesCounter) currDisputeDetails.adminFeesCounter = { amount: 0, currency: '', rate: 0.0}
                                currDisputeDetails.adminFeesCounter.amount = parseInt(e.target.value)
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Admin Counter Fee Rate */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Admin Counter Fee Rate: (Decimals)</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.adminFeesCounter && disputeDetails.adminFeesCounter.rate) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.adminFeesCounter) currDisputeDetails.adminFeesCounter = { amount: 0, currency: '', rate: 0.0}
                                currDisputeDetails.adminFeesCounter.rate = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Admin Counter Fee Currency */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Admin Counter Fee Currency:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.adminFeesCounter && disputeDetails.adminFeesCounter.currency) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.adminFeesCounter) currDisputeDetails.adminFeesCounter = { amount: 0, currency: '', rate: 0.0}
                                currDisputeDetails.adminFeesCounter.currency = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'adminFeesCounterCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Dispute Base Fee Amount */}
                    <Grid item md={8} xs={8} sm={8}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Base Fee Amount: (Amount Paid to Us. Lowest Denomination e.g. 100 for $1)</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.disputeBase && disputeDetails.disputeBase.amount) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.disputeBase) currDisputeDetails.disputeBase = { amount: 0, currency: ''}
                                currDisputeDetails.disputeBase.amount = parseInt(e.target.value)
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Dispute Base Fee Currency */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Base Fee Currency:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.disputeBase && disputeDetails.disputeBase.currency) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.disputeBase) currDisputeDetails.disputeBase = { amount: 0, currency: ''}
                                currDisputeDetails.disputeBase.currency = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'disputeBaseCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Dispute Counter Fee Amount */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Counter Fee Amount: (Amount to Collect)</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.disputeCounter && disputeDetails.disputeCounter.amount) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.disputeCounter) currDisputeDetails.disputeCounter = { amount: 0, currency: '', rate: 0.0}
                                currDisputeDetails.disputeCounter.amount = parseInt(e.target.value)
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Dispute Counter Fee Rate */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Counter Fee Rate: (Decimals)</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.disputeCounter && disputeDetails.disputeCounter.rate) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.disputeCounter) currDisputeDetails.disputeCounter = { amount: 0, currency: '', rate: 0.0}
                                currDisputeDetails.disputeCounter.rate = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Dispute Counter Fee Currency */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Counter Fee Currency:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.disputeCounter && disputeDetails.disputeCounter.currency) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.disputeCounter) currDisputeDetails.disputeCounter = { amount: 0, currency: '', rate: 0.0}
                                currDisputeDetails.disputeCounter.currency = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'disputeCounterCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Released Amount */}
                    <Grid item md={8} xs={8} sm={8}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Released Amount: (Amount Paid to Us. Lowest Denomination e.g. 100 for $1)</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.released && disputeDetails.released.amount) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.released) currDisputeDetails.released = { amount: 0, currency: ''}
                                currDisputeDetails.released.amount = parseInt(e.target.value)
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Released Currency */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Released Currency:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.released && disputeDetails.released.currency) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.released) currDisputeDetails.released = { amount: 0, currency: ''}
                                currDisputeDetails.released.currency = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'releasedCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Deducted Amount */}
                    <Grid item md={8} xs={8} sm={8}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Deducted Amount: (Amount Paid to Us. Lowest Denomination e.g. 100 for $1)</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.deducted && disputeDetails.deducted.amount) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.deducted) currDisputeDetails.deducted = { amount: 0, currency: ''}
                                currDisputeDetails.deducted.amount = parseInt(e.target.value)
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Deducted Currency */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Deducted Currency:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.deducted && disputeDetails.deducted.currency) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.deducted) currDisputeDetails.deducted = { amount: 0, currency: ''}
                                currDisputeDetails.deducted.currency = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'deductedCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Disputes */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.liberalizeDisputeCategoryCode && disputeDetails.liberalizeDisputeCategoryCode) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                let targetDisputeCategory = disputes.find((eachDispute) => {
                                    return eachDispute.value.code == e.target.value
                                })
                                currDisputeDetails.liberalizeDisputeCategoryCode = `${targetDisputeCategory.value.code}`
                                currDisputeDetails.liberalizeDisputeCategory = targetDisputeCategory.value.name
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Dispute"
                        >
                            {
                                disputes && disputes.length > 0 && disputes.map((eachDispute, index) => {
                                    if (eachDispute.value && eachDispute.value.code) {
                                        return <MenuItem key={'Dispute'+index} value={`${eachDispute.value.code}`}>{eachDispute.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Requirements:</Typography>
                        {disputes && disputes.length > 0 && disputeDetails.liberalizeDisputeCategory && disputes.map((eachDispute) => {
                            if (eachDispute.value.code == disputeDetails.liberalizeDisputeCategoryCode) {
                                if (eachDispute.value.required) return eachDispute.value.required.join("\n")
                            }
                        })}
                    </Grid>
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Description:</Typography>
                        {disputes && disputes.length > 0 && disputeDetails.liberalizeDisputeCategory && disputes.map((eachDispute) => {
                            if (eachDispute.value.code == disputeDetails.liberalizeDisputeCategoryCode) {
                                return eachDispute.value.description
                            }
                        })}
                    </Grid>
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Dispute Evidence Required:</Typography>
                        {disputes && disputes.length > 0 && disputeDetails.liberalizeDisputeCategory && disputes.map((eachDispute) => {
                            if (eachDispute.value.code == disputeDetails.liberalizeDisputeCategoryCode) {
                                if (eachDispute.value.evidence) return eachDispute.value.evidence.join(", ")
                            }
                        })}
                    </Grid>
                    {/* Product Service Type */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Product Service Type:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.productServiceType && disputeDetails.productServiceType) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.productServiceType = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="PdtSvcType"
                        >
                            {
                                pdtSvcTypes && pdtSvcTypes.length > 0 && pdtSvcTypes.map((pdtSvc, index) => {
                                    if (pdtSvc.value && pdtSvc.value.name) {
                                        return <MenuItem key={'pdtSvc'+index} value={pdtSvc.value.name}>{pdtSvc.value.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Payer Billing Address */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Payer Billing Address:
                        </Typography>
                        {/* Line 1 */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Line 1
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.line1 = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.line1) || ""}
                        />
                        {/* Line 2 */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Line 2
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.line2 = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.line2) || ""}
                        />
                        {/* State */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            State
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.state = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.state) || ""}
                        />
                        {/* City */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            City
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.city = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.city) || ""}
                        />
                        {/* Postal */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Postal
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.postal = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.billingAddress && disputeDetails.billingAddress.postal) || ""}
                        />
                        {/* Country */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Country
                        </Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.billingAddress && disputeDetails.billingAddress.country) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.billingAddress) currDisputeDetails.billingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.billingAddress.country = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.length > 0 && countries.map((cntry, index) => {
                                    if (cntry.value && cntry.value.country_alpha3_code) {
                                        return <MenuItem key={'billingAddressCountry'+index} value={cntry.value.country_alpha3_code}>{cntry.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Customer Name */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Customer Name
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.customerName = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.customerName) || ""}
                        />
                    </Grid>
                    {/* Customer Email */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Customer Email
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.customerEmail = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.customerEmail) || ""}
                        />
                    </Grid>
                    {/* Customer IP Address */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Customer IP Address
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.customerPurchaseIp = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.customerPurchaseIp) || ""}
                        />
                    </Grid>
                    {/* Merchant Customer Signature Files */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Customer Signature</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantCustomerSignature && disputeDetails.merchantCustomerSignature.map((eachFile, fileIndex) => {
                            return <div key={'merchantCustomerSignature'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantCustomerSignature = currdisputeDetails.merchantCustomerSignature.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantCustomerSignature')}} id={`merchantCustomerSignature-button-file`} type="file" />
                        <label htmlFor="merchantCustomerSignature-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Customer Communication Files */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Customer Communication</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantCustomerCommunication && disputeDetails.merchantCustomerCommunication.map((eachFile, fileIndex) => {
                            return <div key={'merchantCustomerCommunication'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantCustomerCommunication = currdisputeDetails.merchantCustomerCommunication.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantCustomerCommunication')}} id={`merchantCustomerCommunication-button-file`} type="file" />
                        <label htmlFor="merchantCustomerCommunication-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Receipt */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Receipt:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                                className={classes.select}
                            value={(disputeDetails && disputeDetails.merchantReceipt && disputeDetails.merchantReceipt.channel) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantReceipt) currDisputeDetails.merchantReceipt = { channel: '', documents: []}
                                currDisputeDetails.merchantReceipt.channel = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="merchantReceipt"
                        >
                            {
                                ['SMS', 'EMAIL', 'PRINT_OUT', 'WHATSAPP', 'OTHERS'].map((channels, index) => {
                                    if (channels) {
                                        return <MenuItem key={'channels'+index} value={channels}>{channels}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Merchant Receipt Files */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Receipt Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantReceipt && disputeDetails.merchantReceipt.documents && disputeDetails.merchantReceipt.documents.map((eachFile, fileIndex) => {
                            return <div key={'merchantReceipt'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantReceipt) currdisputeDetails.merchantReceipt = { channel: '', documents: []}
                                        currdisputeDetails.merchantReceipt.documents = currdisputeDetails.merchantReceipt.documents.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantReceipt')}} id={`merchantReceipt-button-file`} type="file" />
                        <label htmlFor="merchantReceipt-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Product Service Description */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Product Service Description:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantProductServiceDescription && disputeDetails.merchantProductServiceDescription.description) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantProductServiceDescription) currDisputeDetails.merchantProductServiceDescription = { documents: [], description: ''}
                                currDisputeDetails.merchantProductServiceDescription.description = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {/* Merchant Product Service Description Files */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Product Service Description Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantProductServiceDescription && disputeDetails.merchantProductServiceDescription.documents && disputeDetails.merchantProductServiceDescription.documents.map((eachFile, fileIndex) => {
                            return <div key={'merchantProductServiceDescription'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantProductServiceDescription) currdisputeDetails.merchantProductServiceDescription = { documents: [], description: ''}
                                        currdisputeDetails.merchantProductServiceDescription.documents = currdisputeDetails.merchantProductServiceDescription.documents.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantProductServiceDescription')}} id={`merchantProductServiceDescription-button-file`} type="file" />
                        <label htmlFor="merchantProductServiceDescription-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Refund Policy File */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Refund Policy Document</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantRefundPolicy && disputeDetails.merchantRefundPolicy.key && <div key={'merchantRefundPolicy'}>
                                <Typography component={'span'}>{disputeDetails.merchantRefundPolicy.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantRefundPolicy = {}
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(disputeDetails.merchantRefundPolicy.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        }
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantRefundPolicy')}} id={`merchantRefundPolicy-button-file`} type="file" />
                        <label htmlFor="merchantRefundPolicy-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Refund Policy Disclosure Description */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Refund Policy Disclosure Description:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantRefundPolicyDisclosure && disputeDetails.merchantRefundPolicyDisclosure.description) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantRefundPolicyDisclosure) currDisputeDetails.merchantRefundPolicyDisclosure = { documents: [], description: ''}
                                currDisputeDetails.merchantRefundPolicyDisclosure.description = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {/* Merchant Refund Policy Disclosure Description Files */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Refund Policy Disclosure Description Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantRefundPolicyDisclosure && disputeDetails.merchantRefundPolicyDisclosure.documents && disputeDetails.merchantRefundPolicyDisclosure.documents.map((eachFile, fileIndex) => {
                            return <div key={'merchantRefundPolicyDisclosure'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantRefundPolicyDisclosure) currdisputeDetails.merchantRefundPolicyDisclosure = { documents: [], description: ''}
                                        currdisputeDetails.merchantRefundPolicyDisclosure.documents = currdisputeDetails.merchantRefundPolicyDisclosure.documents.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantRefundPolicyDisclosure')}} id={`merchantRefundPolicyDisclosure-button-file`} type="file" />
                        <label htmlFor="merchantRefundPolicyDisclosure-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Refund Refusal Explanation */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Refund Refusal Explanation:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantRefundRefusalExplanation) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantRefundRefusalExplanation = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {/* Merchant Duplicate Charge IDs */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Duplicate Charge IDs:</Typography>
                        <Grid container>
                            {disputeDetails && disputeDetails.merchantDuplicateChargeIds && disputeDetails.merchantDuplicateChargeIds.length > 0 && disputeDetails.merchantDuplicateChargeIds.map((eachCb, cbIndex) => {
                                return <Grid item md={12} xs={12} sm={12}>
                                    {eachCb} 
                                    <IconButton
                                        disabled={disputeDetails.isResolved}
                                        onClick={() => { 
                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            currdisputeDetails.merchantDuplicateChargeIds = currdisputeDetails.merchantDuplicateChargeIds.filter((targetCb, idx) => {
                                                if (idx == cbIndex) return false
                                                return true
                                            })
                                            setDisputeDetails(currdisputeDetails)
                                        }}
                                        component="span"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            })}
                            <Grid item md={7} xs={7} sm={7}>
                                <OutlinedInputMod
                                    placeholder={'Inpute Duplicate Charge IDs'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addDbId) || ''}
                                    onChange={(e) => setAddDbId(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={5} xs={5} sm={5}>
                                <IconButton
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantDuplicateChargeIds) currdisputeDetails.merchantDuplicateChargeIds = []
                                        currdisputeDetails.merchantDuplicateChargeIds.push(addDbId)
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Merchant Duplicate Charge Explanation */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Duplicate Charge Explanation:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantDuplicateChargeExplanation) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantDuplicateChargeExplanation = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {/* Merchant Duplicate Charge Documents Files */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Duplicate Charge Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantDuplicateChargeDocuments && disputeDetails.merchantDuplicateChargeDocuments.map((eachFile, fileIndex) => {
                            return <div key={'merchantDuplicateChargeDocuments'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantDuplicateChargeDocuments = currdisputeDetails.merchantDuplicateChargeDocuments.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantDuplicateChargeDocuments')}} id={`merchantDuplicateChargeDocuments-button-file`} type="file" />
                        <label htmlFor="merchantDuplicateChargeDocuments-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Service Date */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Service Date (UTC):</Typography>
                        <DateTextField
                            disabled={disputeDetails.isResolved} 
                            id="datefrom"
                            type="datetime-local"
                            onChange={(e) => {
                                let date = new Date(e.target.value)
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantServiceDate = date.toISOString()
                                setDisputeDetails(currDisputeDetails)
                            }}
                            defaultValue={(disputeDetails.merchantServiceDate && moment.utc(disputeDetails.merchantServiceDate).local().format('YYYY-MM-DDTHH:mm:ss')) || "2017-05-24T10:30"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div>
                            {disputeDetails.merchantServiceDate && new Date(disputeDetails.merchantServiceDate).toString()}
                        </div>
                    </Grid>
                    {/* Merchant Service Documentations Files */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Service Documentations:</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantServiceDocumentation && disputeDetails.merchantServiceDocumentation.map((eachFile, fileIndex) => {
                            return <div key={'merchantServiceDocumentation'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantServiceDocumentation = currdisputeDetails.merchantServiceDocumentation.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantServiceDocumentation')}} id={`merchantServiceDocumentation-button-file`} type="file" />
                        <label htmlFor="merchantServiceDocumentation-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Shipping Date */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Shipping Date (UTC):</Typography>
                        <DateTextField
                            disabled={disputeDetails.isResolved} 
                            id="datefrom"
                            type="datetime-local"
                            onChange={(e) => {
                                let date = new Date(e.target.value)
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantShippingDate = date.toISOString()
                                setDisputeDetails(currDisputeDetails)
                            }}
                            defaultValue={(disputeDetails.merchantShippingDate && moment.utc(disputeDetails.merchantShippingDate).local().format('YYYY-MM-DDTHH:mm:ss')) || "2017-05-24T10:30"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <div>
                            {disputeDetails.merchantShippingDate && new Date(disputeDetails.merchantShippingDate).toString()}
                        </div>
                    </Grid>
                    {/* Merchant Shipping Documentation Files */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Shipping Documentations:</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantShippingDocumentation && disputeDetails.merchantShippingDocumentation.map((eachFile, fileIndex) => {
                            return <div key={'merchantShippingDocumentation'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantShippingDocumentation = currdisputeDetails.merchantShippingDocumentation.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantShippingDocumentation')}} id={`merchantShippingDocumentation-button-file`} type="file" />
                        <label htmlFor="merchantShippingDocumentation-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Shipping Address */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Merchant Shipping Address:
                        </Typography>
                        {/* Line 1 */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Line 1
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.line1 = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.line1) || ""}
                        />
                        {/* Line 2 */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Line 2
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.line2 = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.line2) || ""}
                        />
                        {/* State */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            State
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.state = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.state) || ""}
                        />
                        {/* City */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            City
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.city = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.city) || ""}
                        />
                        {/* Postal */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Postal
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.postal = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.postal) || ""}
                        />
                        {/* Country */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Country
                        </Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.merchantShippingAddress && disputeDetails.merchantShippingAddress.country) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantShippingAddress) currDisputeDetails.merchantShippingAddress = { line1: "", line2: "", city: "", state: "", country: "", postal: "" }
                                currDisputeDetails.merchantShippingAddress.country = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.length > 0 && countries.map((cntry, index) => {
                                    if (cntry.value && cntry.value.country_alpha3_code) {
                                        return <MenuItem key={'merchantShippingAddressCountry'+index} value={cntry.value.country_alpha3_code}>{cntry.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Merchant Shipping Couriers */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Shipping Couriers:</Typography>
                        <Grid container spacing={1}>
                            {disputeDetails && disputeDetails.merchantShippingCouriers && disputeDetails.merchantShippingCouriers.length > 0 && disputeDetails.merchantShippingCouriers.map((eachCourrier, cbIndex) => {
                                return <Grid item md={12} xs={12} sm={12}>
                                    Name: {eachCourrier.name} Tracking No.:{eachCourrier.trackingNumber}
                                    <IconButton
                                        disabled={disputeDetails.isResolved}
                                        onClick={() => { 
                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            currdisputeDetails.merchantShippingCouriers = currdisputeDetails.merchantShippingCouriers.filter((targetCb, idx) => {
                                                if (idx == cbIndex) return false
                                                return true
                                            })
                                            setDisputeDetails(currdisputeDetails)
                                        }}
                                        component="span"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            })}
                            <Grid item md={4} xs={4} sm={4}>
                                <OutlinedInputMod
                                    placeholder={'Input Name Of Courier'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addCourierName) || ''}
                                    onChange={(e) => setAddCourierName(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <OutlinedInputMod
                                    placeholder={'Input Tracking Number'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addTrackingNumber) || ''}
                                    onChange={(e) => setAddTrackingNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid item md={3} xs={3} sm={3}>
                                <IconButton
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        console.log('currdisputeDetails 1-> ', currdisputeDetails)
                                        if (!currdisputeDetails.merchantShippingCouriers) currdisputeDetails.merchantShippingCouriers = []
                                        console.log('currdisputeDetails 2-> ', currdisputeDetails)
                                        currdisputeDetails.merchantShippingCouriers.push({ name: addCourierName, trackingNumber: addTrackingNumber})
                                        console.log('currdisputeDetails 3-> ', currdisputeDetails)
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Merchant Uncategorized Text */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Uncategorized Text:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantUncategorizedText) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantUncategorizedText = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {/* Merchant Uncategorized File */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Uncategorized Files:</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantUncategorizedFile && disputeDetails.merchantUncategorizedFile.map((eachFile, fileIndex) => {
                            return <div key={'merchantUncategorizedFile'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantUncategorizedFile = currdisputeDetails.merchantUncategorizedFile.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantUncategorizedFile')}} id={`merchantUncategorizedFile-button-file`} type="file" />
                        <label htmlFor="merchantUncategorizedFile-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Access Activity Logs File */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Access Activity Logs Files:</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantAccessActivityLogs && disputeDetails.merchantAccessActivityLogs.map((eachFile, fileIndex) => {
                            return <div key={'merchantAccessActivityLogs'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantAccessActivityLogs = currdisputeDetails.merchantAccessActivityLogs.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantAccessActivityLogs')}} id={`merchantAccessActivityLogs-button-file`} type="file" />
                        <label htmlFor="merchantAccessActivityLogs-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Cancellation Policy File */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Cancellation Policy Document</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantCancellationPolicy && disputeDetails.merchantCancellationPolicy.key && <div key={'merchantCancellationPolicy'}>
                                <Typography component={'span'}>{disputeDetails.merchantCancellationPolicy.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.merchantCancellationPolicy = {}
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(disputeDetails.merchantCancellationPolicy.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        }
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantCancellationPolicy')}} id={`merchantCancellationPolicy-button-file`} type="file" />
                        <label htmlFor="merchantCancellationPolicy-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Cancellation Policy Disclosure Description */}
                    <Grid item md={4} xs={4} sm={4}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Cancellation Policy Disclosure Description:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantCancellationPolicyDisclosure && disputeDetails.merchantCancellationPolicyDisclosure.description) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.merchantCancellationPolicyDisclosure) currDisputeDetails.merchantCancellationPolicyDisclosure = { documents: [], description: ''}
                                currDisputeDetails.merchantCancellationPolicyDisclosure.description = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {/* Merchant Cancellation Policy Disclosure Description Files */}
                    {<Grid item md={7} xs={7} sm={7}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Cancellation Policy Disclosure Description Documents</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.merchantCancellationPolicyDisclosure && disputeDetails.merchantCancellationPolicyDisclosure.documents && disputeDetails.merchantCancellationPolicyDisclosure.documents.map((eachFile, fileIndex) => {
                            return <div key={'merchantCancellationPolicyDisclosure'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.merchantCancellationPolicyDisclosure) currdisputeDetails.merchantCancellationPolicyDisclosure = { documents: [], description: ''}
                                        currdisputeDetails.merchantCancellationPolicyDisclosure.documents = currdisputeDetails.merchantCancellationPolicyDisclosure.documents.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'merchantCancellationPolicyDisclosure')}} id={`merchantCancellationPolicyDisclosure-button-file`} type="file" />
                        <label htmlFor="merchantCancellationPolicyDisclosure-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Merchant Cancellation Rebuttal */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Cancellation Rebuttal:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.merchantCancellationRebuttal) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.merchantCancellationRebuttal = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {/* Processor Txn IDs */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor Txn IDs:</Typography>
                        <Grid container>
                            {disputeDetails && disputeDetails.processorTxnIds && disputeDetails.processorTxnIds.length > 0 && disputeDetails.processorTxnIds.map((eachProcessotTxn, cbIndex) => {
                                return <Grid item md={12} xs={12} sm={12}>
                                    TxnID: {eachProcessotTxn.txnId} Date: {eachProcessotTxn.txnDatetime ? new Date(eachProcessotTxn.txnDatetime).toString() : ""} 
                                    <IconButton
                                        disabled={disputeDetails.isResolved}
                                        onClick={() => { 
                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            currdisputeDetails.processorTxnIds = currdisputeDetails.processorTxnIds.filter((targetCb, idx) => {
                                                if (idx == cbIndex) return false
                                                return true
                                            })
                                            setDisputeDetails(currdisputeDetails)
                                        }}
                                        component="span"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            })}
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Input Txn Date (UTC):</Typography>
                                <DateTextField
                                    disabled={disputeDetails.isResolved} 
                                    id="datefrom"
                                    type="datetime-local"
                                    onChange={(e) => {
                                        let date = new Date(e.target.value)
                                        setAddProcessorTxnDatetime(date.toISOString())
                                    }}
                                    defaultValue={(addProcessorTxnDatetime && moment.utc(addProcessorTxnDatetime).local().format('YYYY-MM-DDTHH:mm:ss')) || "2017-05-24T10:30"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <div>
                                    Selected Datetime: {addProcessorTxnDatetime && new Date(addProcessorTxnDatetime).toString()}
                                </div>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <OutlinedInputMod
                                    placeholder={'Input Processor\s Txn ID'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addProcessorTxnId && addProcessorTxnId.split('Z')[0]) || ''}
                                    onChange={(e) => setAddProcessorTxnId(e.target.value)}
                                />
                            </Grid> 
                            <Grid item md={4} xs={4} sm={4}>
                                <IconButton
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.processorTxnIds) currdisputeDetails.processorTxnIds = []
                                        currdisputeDetails.processorTxnIds.push({ txnId: addProcessorTxnId, txnDatetime: addProcessorTxnDatetime})
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Issuer Txn IDs */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Issuer Txn IDs:</Typography>
                        <Grid container>
                            {disputeDetails && disputeDetails.issuerTxnIds && disputeDetails.issuerTxnIds.length > 0 && disputeDetails.issuerTxnIds.map((eachProcessotTxn, cbIndex) => {
                                return <Grid item md={12} xs={12} sm={12}>
                                    TxnID:{eachProcessotTxn.txnId} Date:{eachProcessotTxn.txnDatetime ? new Date(eachProcessotTxn.txnDatetime).toString() : ""} 
                                    <IconButton
                                        disabled={disputeDetails.isResolved}
                                        onClick={() => { 
                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            currdisputeDetails.issuerTxnIds = currdisputeDetails.issuerTxnIds.filter((targetCb, idx) => {
                                                if (idx == cbIndex) return false
                                                return true
                                            })
                                            setDisputeDetails(currdisputeDetails)
                                        }}
                                        component="span"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            })}
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Input Txn Date (UTC):</Typography>
                                <DateTextField
                                    disabled={disputeDetails.isResolved} 
                                    id="datefrom"
                                    type="datetime-local"
                                    onChange={(e) => {
                                        let date = new Date(e.target.value)
                                        setAddIssuerTxnDateTime(date.toISOString())
                                    }}
                                    defaultValue={(addIssuerTxnDateTime && moment.utc(addIssuerTxnDateTime).local().format('YYYY-MM-DDTHH:mm:ss')) || "2017-05-24T10:30"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <div>
                                    Selected Datetime: {addIssuerTxnDateTime && new Date(addIssuerTxnDateTime).toString()}
                                </div>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <OutlinedInputMod
                                    placeholder={'Input Issuer\s Txn ID'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addIssuerTxnId) || ''}
                                    onChange={(e) => setAddIssuerTxnId(e.target.value)}
                                />
                            </Grid> 
                            <Grid item md={4} xs={4} sm={4}>
                                <IconButton
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.issuerTxnIds) currdisputeDetails.issuerTxnIds = []
                                        currdisputeDetails.issuerTxnIds.push({ txnId: addIssuerTxnId, txnDatetime: addIssuerTxnDateTime})
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Processor Net Settlement */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor Net Settlement:</Typography>
                        <Grid container spacing={1}>
                            {disputeDetails && disputeDetails.processorNetSettlement && disputeDetails.processorNetSettlement.length > 0 && disputeDetails.processorNetSettlement.map((eachProcessotSettlement, cbIndex) => {
                                return <Grid item md={12} xs={12} sm={12}>
                                    Currency:{eachProcessotSettlement.currency} Amount:{eachProcessotSettlement.amount} 
                                    <IconButton
                                        disabled={disputeDetails.isResolved}
                                        onClick={() => { 
                                            let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            currdisputeDetails.processorNetSettlement = currdisputeDetails.processorNetSettlement.filter((targetCb, idx) => {
                                                if (idx == cbIndex) return false
                                                return true
                                            })
                                            setDisputeDetails(currdisputeDetails)
                                        }}
                                        component="span"
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Grid>
                            })}
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Input Processor Net Settlement Currency:</Typography>
                                <Select
                                    disabled={disputeDetails.isResolved} 
                                    fullWidth
                                    variant="outlined"
                                    className={classes.select}
                                    value={(addProcessorNetSettlementCurrency) || ""}
                                    onChange={(e) => {
                                        setAddProcessorNetSettlementCurrency(e.target.value)
                                    }}
                                    label="Currency"
                                >
                                    {
                                        currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                            if (currency.value && currency.value.currency_alpha3_code) {
                                                return <MenuItem key={'processorNetSettlementCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                            }
                                        })
                                    }
                                </Select>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Amount (Lowest Denomination):</Typography>
                                <OutlinedInputMod
                                    placeholder={'Processor\'s Net Settlement Amount'}
                                    disabled={disputeDetails.isResolved} 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(addProcessorNetSettlementAmount) || ''}
                                    onChange={(e) => setAddProcessorNetSettlementAmount(e.target.value)}
                                />
                            </Grid> 
                            <Grid item md={3} xs={3} sm={3}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom> </Typography>
                                <IconButton
                                    disabled={disputeDetails.isResolved} 
                                    style={{color: '#68d0af'}} 
                                    aria-controls="simple-menu" 
                                    aria-haspopup="true"
                                    onClick={() => {
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        if (!currdisputeDetails.processorNetSettlement) currdisputeDetails.processorNetSettlement = []
                                        currdisputeDetails.processorNetSettlement.push({ currency: addProcessorNetSettlementCurrency, amount: parseInt(addProcessorNetSettlementAmount)})
                                        setDisputeDetails(currdisputeDetails)
                                    }}
                                >
                                    <AddIcon fontSize="large"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Processor Merchant Profile */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Processor Merchant Profile:
                        </Typography>
                        {/* Name */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Name
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.processorMerchantProfile) currDisputeDetails.processorMerchantProfile = { name: "", mcc: "", city: "", country: "", postal: "" }
                                currDisputeDetails.processorMerchantProfile.name = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.processorMerchantProfile && disputeDetails.processorMerchantProfile.name) || ""}
                        />
                        {/* City */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            City
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.processorMerchantProfile) currDisputeDetails.processorMerchantProfile = { name: "", mcc: "", city: "", country: "", postal: "" }
                                currDisputeDetails.processorMerchantProfile.city = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.processorMerchantProfile && disputeDetails.processorMerchantProfile.city) || ""}
                        />
                        {/* Postal */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Postal
                        </Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.processorMerchantProfile) currDisputeDetails.processorMerchantProfile = { name: "", mcc: "", city: "", country: "", postal: "" }
                                currDisputeDetails.processorMerchantProfile.postal = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.processorMerchantProfile && disputeDetails.processorMerchantProfile.postal) || ""}
                        />
                        {/* Country */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            Country
                        </Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.processorMerchantProfile && disputeDetails.processorMerchantProfile.country) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.processorMerchantProfile) currDisputeDetails.processorMerchantProfile = { name: "", mcc: "", city: "", country: "", postal: "" }
                                currDisputeDetails.processorMerchantProfile.country = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Country"
                        >
                            {
                                countries && countries.length > 0 && countries.map((cntry, index) => {
                                    if (cntry.value && cntry.value.country_alpha3_code) {
                                        return <MenuItem key={'processorMerchantProfileCountry'+index} value={cntry.value.country_alpha3_code}>{cntry.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                        {/* MCC */}
                        <Typography component={'span'} className={classes.title} color="textSecondary" gutterBottom>
                            MCC
                        </Typography>
                        <OutlinedInputMod
                            disabled={disputeDetails.isResolved} 
                            fullWidth 
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                if (!currDisputeDetails.processorMerchantProfile) currDisputeDetails.processorMerchantProfile = { name: "", mcc: "", city: "", country: "", postal: "" }
                                currDisputeDetails.processorMerchantProfile.mcc = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            defaultValue={(disputeDetails.processorMerchantProfile && disputeDetails.processorMerchantProfile.mcc) || ""}
                        />
                    </Grid>
                    {/* Processor Merchant Currency */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor Merchant Currency:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.processorMerchantCurrency) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.processorMerchantCurrency = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'processorMerchantCurrency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Processor Merchant Identifier */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor Merchant Identifier:</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.processorMerchantIdentifier) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails = {...currDisputeDetails, processorMerchantIdentifier: e.target.value}
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Processor Dispute Category Code */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor Dispute Category Code:</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.processorDisputeCategoryCode) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails = {...currDisputeDetails, processorDisputeCategoryCode: e.target.value}
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Processor Dispute Category */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor Dispute Category:</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.processorDisputeCategory) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails = {...currDisputeDetails, processorDisputeCategory: e.target.value}
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Processor Uploaded Documents File */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor Uploaded Documents Files:</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.processorUploadedDocuments && disputeDetails.processorUploadedDocuments.map((eachFile, fileIndex) => {
                            return <div key={'processorUploadedDocuments'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.processorUploadedDocuments = currdisputeDetails.processorUploadedDocuments.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'processorUploadedDocuments')}} id={`processorUploadedDocuments-button-file`} type="file" />
                        <label htmlFor="processorUploadedDocuments-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Processor Case ID */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor Case ID:</Typography>
                        <OutlinedInputMod 
                            disabled={disputeDetails.isResolved} 
                            maxWidth={'md'} 
                            fullWidth
                            value={(disputeDetails && disputeDetails.processorCaseId) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails = {...currDisputeDetails, processorCaseId: e.target.value}
                                setDisputeDetails(currDisputeDetails)
                            }}
                        />
                    </Grid>
                    {/* Resolve Type */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Resolve Type:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.resolveType) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.resolveType = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="ResolveType"
                        >
                            {
                                ['FULL_DISPUTE_DEDUCTION', 'PARTIAL_DISPUTE_DEDUCTION', 'FULL_WITHHOLDING_RELEASED', 'NO_TYPE'].map((resolveType, index) => {
                                    return <MenuItem key={'resolveType'+index} value={resolveType}>{resolveType}</MenuItem>
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Processors */}
                    <Grid item md={3} xs={3} sm={3}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor:</Typography>
                        <Select
                            disabled={disputeDetails.isResolved} 
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(disputeDetails && disputeDetails.processor && disputeDetails.processor) || ""}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.processor = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }}
                            label="PdtSvcType"
                        >
                            {
                                processors && processors.length > 0 && processors.map((pcssr, index) => {
                                    if (pcssr.name) {
                                        return <MenuItem key={'pcssr'+index} value={pcssr.name}>{pcssr.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>
                    {/* Documents for Processor Files */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Documents For Processor</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {disputeDetails && disputeDetails.documentsForProcessor && disputeDetails.documentsForProcessor.map((eachFile, fileIndex) => {
                            return <div key={'documentsForProcessor'+fileIndex}>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!disputeDetails.isResolved && <IconButton onClick={() => { 
                                        let currdisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                        currdisputeDetails.documentsForProcessor = currdisputeDetails.documentsForProcessor.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setDisputeDetails(currdisputeDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'documentsForProcessor')}} id={`documentsForProcessor-button-file`} type="file" />
                        <label htmlFor="documentsForProcessor-button-file">
                            {!disputeDetails.isResolved && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>}
                    {/* Description For Processor */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Description For Processor:</Typography>
                        <TextareaAutosize
                            disabled={disputeDetails.isResolved} 
                            aria-label="minimum height" 
                            className={classes.textArea}
                            value={(disputeDetails.descriptionForProcessor && disputeDetails.descriptionForProcessor) || ''}
                            onChange={(e) => {
                                let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                currDisputeDetails.descriptionForProcessor = e.target.value
                                setDisputeDetails(currDisputeDetails)
                            }} 
                            rowsMin={3} 
                        />
                    </Grid>
                    {
                        disputeOfficerQuestions && disputeOfficerQuestions.length > 0 && disputeOfficerQuestions.map((eachOfficer) => {
                            return <Grid container spacing={3}>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>{eachOfficer.display}</Typography>
                                </Grid>
                                <Grid item md={5} xs={5} sm={5}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Name</Typography>
                                    <OutlinedInputMod
                                        disabled={disputeDetails.isResolved} 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(disputeDetails[eachOfficer.officer] && disputeDetails[eachOfficer.officer].name) || ''}
                                        onChange={async (e) => {
                                            let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            let targetOfficer = currDisputeDetails[eachOfficer.officer] || {}
                                            targetOfficer.name = e.target.value
                                            targetOfficer.userId = currentUser.id
                                            targetOfficer.userEmail = currentUser.email
                                            targetOfficer.jwt = window.localStorage.libJwt;
                                            targetOfficer.datetime = new Date().toISOString();
                                            currDisputeDetails[eachOfficer.officer] = targetOfficer
                                            setDisputeDetails(currDisputeDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Comments</Typography>
                                    <OutlinedInputMod
                                        disabled={disputeDetails.isResolved}
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(disputeDetails[eachOfficer.officer] && disputeDetails[eachOfficer.officer].comments) || ''}
                                        onChange={(e) => {
                                            let currDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                                            let targetOfficer = currDisputeDetails[eachOfficer.officer] || {}
                                            targetOfficer.comments = e.target.value
                                            targetOfficer.userId = currentUser.id
                                            targetOfficer.userEmail = currentUser.email
                                            targetOfficer.jwt = window.localStorage.libJwt;
                                            targetOfficer.datetime = new Date().toISOString();
                                            currDisputeDetails[eachOfficer.officer] = targetOfficer
                                            setDisputeDetails(currDisputeDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={5} xs={5} sm={5}>
                                    Date: {disputeDetails[eachOfficer.officer] && disputeDetails[eachOfficer.officer].datetime ? new Date(disputeDetails[eachOfficer.officer].datetime).toString() : '' }
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    Digitally Signed By: {disputeDetails && disputeDetails[eachOfficer.officer] && disputeDetails[eachOfficer.officer].userEmail}
                                </Grid>
                            </Grid>
                        })
                    }
                    
                    <Grid item md={11} xs={11} sm={11}>
                        {disputeDetails && !disputeDetails.id && <ButtonMod onClick={() => {
                            if (!disputeDetails.organizationId) {
                                alert('No Organization!')
                                throw new Error ('No Organization')
                            }
                            if (disputeDetails.adminFeesCounter && disputeDetails.adminFeesCounter.rate) {
                                disputeDetails.adminFeesCounter.rate = parseFloat(disputeDetails.adminFeesCounter.rate)
                            }
                            if (disputeDetails.disputeCounter && disputeDetails.disputeCounter.rate) {
                                disputeDetails.disputeCounter.rate = parseFloat(disputeDetails.disputeCounter.rate)
                            }
                            createDispute({ organizationId: disputeDetails.organizationId}, disputeDetails)
                            setDisputeDialog(false)
                            setDisputeDetails({})
                        }}>
                            CREATE
                        </ButtonMod>}
                        {disputeDetails && disputeDetails.id && !disputeDetails.isResolved && <ButtonMod onClick={() => {
                            deleteDispute({ disputeId: disputeDetails.id})
                            setDisputeDialog(false)
                            setDisputeDetails({})
                        }}>
                            DELETE
                        </ButtonMod>}
                        {disputeDetails && disputeDetails.id && !disputeDetails.isResolved && <ButtonMod onClick={() => {
                            if (disputeDetails.adminFeesCounter && disputeDetails.adminFeesCounter.rate) {
                                disputeDetails.adminFeesCounter.rate = parseFloat(disputeDetails.adminFeesCounter.rate)
                            }
                            if (disputeDetails.disputeCounter && disputeDetails.disputeCounter.rate) {
                                disputeDetails.disputeCounter.rate = parseFloat(disputeDetails.disputeCounter.rate)
                            }
                            updateDispute({ disputeId: disputeDetails.id}, disputeDetails)
                            setDisputeDialog(false)
                            setDisputeDetails({})
                        }}>
                            SAVE
                        </ButtonMod>}
                        {disputeDetails && disputeDetails.id && !disputeDetails.isResolved && <ButtonMod onClick={() => {
                            const updateDisputeDetails = JSON.parse(JSON.stringify(disputeDetails))
                            updateDisputeDetails.isResolved = true;
                            if (disputeDetails.adminFeesCounter && disputeDetails.adminFeesCounter.rate) {
                                disputeDetails.adminFeesCounter.rate = parseFloat(disputeDetails.adminFeesCounter.rate)
                            }
                            if (disputeDetails.disputeCounter && disputeDetails.disputeCounter.rate) {
                                disputeDetails.disputeCounter.rate = parseFloat(disputeDetails.disputeCounter.rate)
                            }
                            updateDispute({ disputeId: disputeDetails.id}, updateDisputeDetails)
                            setDisputeDialog(false)
                            setDisputeDetails({})
                        }}>
                            COMPLETE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Disputes</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Resolved? y/n</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchIsResolved) || ''}
                                            onChange={(e) => setSearchIsResolved(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Organization</Typography>
                                            <GreenAutocomplete
                                                id="tags-filled"
                                                options={[{ name: 'Please Select An Organization' }, ...organizations]}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e,v) => { 
                                                    if (v && v.id) {
                                                        setSearchOrganizationId(v.id)
                                                    } else {
                                                        setSearchOrganizationId('')
                                                    }
                                                }}
                                                fullWidth
                                                freeSolo
                                                renderInput={(params) => (
                                                    <GreenTextField 
                                                        {...params}
                                                        variant="filled"
                                                        placeholder="Organizations"
                                                        onChange={(e) => {
                                                            let esSearchReqBody = constructSearchReqBody(10, true, e.target.value)
                                                            postSearchOrganizations(esSearchReqBody)
                                                        }}
                                                    />
                                                )}
                                            />
                                        {searchOrganizationId && <Typography className={classes.title} color="textSecondary" gutterBottom>OrganizationID: {searchOrganizationId}</Typography>}
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Processor</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={""}
                                            onChange={(e) => {
                                                setSearchPrcoessor(e.target.value)
                                            }}
                                            label="Processors"
                                        >
                                            {
                                                processors && processors.length > 0 && processors.map((pcsr, index) => {
                                                    if (pcsr.name) {
                                                        return <MenuItem key={'pcsr'+index} value={pcsr.name}>{pcsr.name}</MenuItem>
                                                    }
                                                })
                                            }
                                            <MenuItem key={'pcsr999'} value={""}>Select a Processor</MenuItem>
                                        </Select>
                                    </Grid>
                                    {/* <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Client Advisor Name</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchClientAdvisorName) || ''}
                                            onChange={(e) => setSearchClientAdvisorName(e.target.value)} 
                                        />
                                    </Grid> */}
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(null, true)
                                                postDisputesSearch(esSearchReqBody)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setDisputeDialog(true)
                                setDisputeDetails({})
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody()
                                console.log('esSearchReqBody -> ', esSearchReqBody)
                                postDisputesSearchAfter(esSearchReqBody)
                            }}
                            onPageSizeChange={(params) =>{
                                if (searchSize === params.pageSize) return
                                setSearchSize(params.pageSize)
                                setPage(1);
                                setLoadedPage(1)
                                let esSearchReqBody = constructSearchReqBody(params.pageSize, true)
                                postDisputesSearch(esSearchReqBody)
                            }}
                            rowCount={totalHitsDisputes}
                            rowsPerPageOptions={[10,25,50,100]}
                            pageSize={10}
                            pagination
                            columns={[
                                { 
                                    field: 'id',
                                    headerName: 'Dispute ID',
                                    width: 140,
                                    valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                                },
                                { 
                                    field: 'organizationId',
                                    headerName: 'OrganizationID',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.organizationId) || ""}`
                                    },
                                },
                                { 
                                    field: 'state',
                                    headerName: 'State',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.state) || ""}`
                                    },
                                },
                                { 
                                    field: 'processor',
                                    headerName: 'Processor',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.processor) || ""}`
                                    },
                                },
                                { 
                                    field: 'processorNetSettlement.amount',
                                    headerName: 'Amount',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.processorNetSettlement && params.row.processorNetSettlement.amount) || ""}`
                                    },
                                },
                                { 
                                    field: 'processorNetSettlement.currency',
                                    headerName: 'Currency',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.processorNetSettlement && params.row.processorNetSettlement.currency) || ""}`
                                    },
                                },
                                { 
                                    field: 'type',
                                    headerName: 'Type',
                                    width: 140,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.type) || ""}`
                                    },
                                },
                                { 
                                    field: 'isResolved',
                                    headerName: 'Resolved',
                                    width: 80,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.isResolved && "YES") || "NO"}`
                                    },
                                },
                                { 
                                    field: 'resolveType',
                                    headerName: 'Resolved Type',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.resolveType) || ""}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At (UTC)',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'updatedAt',
                                    headerName: 'Updated At (UTC)',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.updatedAt) {
                                            let date = new Date(params.row.updatedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                            ]}
                            rows={allDisputes}
                            onRowClick={async (rowParams) => {
                                console.log('rowParams.row -> ', rowParams.row);
                                setDisputeDetails(rowParams.row)
                                setDisputeDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default Disputes