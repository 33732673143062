import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, clearAlertMessage, alertMessage, searchPayout, 
    searchAfterPayout, createPayout, clearPayouts, postPaymentAccountsSearch, postKYCsSearch, updatePayout,
    approvePayout, cancelPayout, completePayout, postSearchOrganizations
    } from '../actions'
import Payouts from '../components/Payouts.js'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.payoutsInvoices && state.payoutsInvoices.isLoading) || false,
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    countries: (state.misc && state.misc.enum && state.misc.enum.country) || [],
    payouts: (state.payoutsInvoices && state.payoutsInvoices.payouts) || [],
    totalHitsPayouts: (state.payoutsInvoices && state.payoutsInvoices.totalHitsPayouts) || 0,
    nextToken: (state.payoutsInvoices && state.payoutsInvoices.nextToken) || [],
    allKycs: (state.kyc && state.kyc.allKycs) || [],
    user: state.users.user,
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    clearAlertMessage: (reqBody) => dispatch(clearAlertMessage(reqBody)),
    alertMessage: (reqBody) => dispatch(alertMessage(reqBody)),
    searchPayout: (reqBody) => dispatch(searchPayout(reqBody)),
    searchAfterPayout: (reqBody) => dispatch(searchAfterPayout(reqBody)),
    createPayout: (reqBody) => dispatch(createPayout(reqBody)),
    clearPayouts: () => dispatch(clearPayouts()),
    postKYCsSearch: (reqBody) => dispatch(postKYCsSearch(reqBody)),
    updatePayout: (params, reqBody) => dispatch(updatePayout(params, reqBody)),
    approvePayout: (params, reqBody) => dispatch(approvePayout(params, reqBody)),
    cancelPayout: (params, reqBody) => dispatch(cancelPayout(params, reqBody)),
    completePayout: (params, reqBody) => dispatch(completePayout(params, reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Payouts)