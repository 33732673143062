function users(state ={}, action) {
    switch (action.type) {
      case 'SET_USER':
          return {...state,
            user: action.data.user 
          }
      default:
        return state
    }
  }

export default users