function kyc(state ={isLoading: false}, action) {
    switch (action.type) {
        case 'GET_S3_FILE_REQUEST':
            return {...state, isLoading: true} 
        case 'GET_S3_FILE_SUCCESS':
            return {...state, isLoading: false} 
        case 'GET_S3_FILE_FAILURE':
            return {...state, isLoading: false}
        case 'SET_IS_LOADING':
            return {...state, isLoading: true}
        case 'SET_NOT_LOADING':
            return {...state, isLoading: false}
        case 'POST_CREATE_KYC_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_CREATE_KYC_SUCCESS':
            const currentKycs = state.allKycs || []
            return {...state,
                isLoading: false,
                allKycs: [...currentKycs, action.data]
            }
        case 'POST_CREATE_KYC_FAILURE':
            return {...state, isLoading: false}
        case 'PUT_UPDATE_KYC_REQUEST':
            return {...state, isLoading: true}
        case 'PUT_UPDATE_KYC_SUCCESS':
            console.log('PUT_UPDATE_KYC_SUCCESS', action);
            let targetPatchKyc = (action && action.data) || {}
            const patchKycHits = state.allKycs.map((eachHit) => {
                if (eachHit.id === targetPatchKyc.id) return targetPatchKyc
                return eachHit
            })
            return {...state, isLoading: false, allKycs: patchKycHits}
        case 'PUT_UPDATE_KYC_FAILURE':
            return {...state, isLoading: false}
        case 'DELETE_KYC_REQUEST':
            return {...state, isLoading: true}
        case 'DELETE_KYC_SUCCESS':
            console.log('DELETE_KYC_SUCCESS', action);
            return {...state, isLoading: false}
        case 'DELETE_KYC_FAILURE':
            return {...state, isLoading: false}
        case 'POST_KYCS_SEARCH_REQUEST':
            return {...state, isLoading: true}
        case 'POST_KYCS_SEARCH_SUCCESS':
            console.log('POST_KYCS_SEARCH_SUCCESS', action);
            const kyc_hits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const search_after_kycs = kyc_hits.length > 0 ? kyc_hits[kyc_hits.length - 1].sort : [];
            const totalHitsKYCs = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const kycHitsSource = kyc_hits.map((eachHit) => eachHit['_source'])
            return {...state,
                allKycs: kycHitsSource,
                totalHitsKYCs: totalHitsKYCs,
                search_after_kycs: search_after_kycs,
                isLoading: false
            }
        case 'POST_KYCS_SEARCH_FAILURE':
            return {...state, isLoading: false}
        case 'POST_KYCS_SEARCH_AFTER_REQUEST':
            return {...state, isLoading: true}
        case 'POST_KYCS_SEARCH_AFTER_SUCCESS':
            console.log('POST_KYCS_SEARCH_AFTER_SUCCESS', action);
            const nextKYCHits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const totalKYCsHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const next_search_after_kycs = nextKYCHits.length > 0 ? nextKYCHits[nextKYCHits.length - 1].sort : [];
            const nextKYCHitsSource = nextKYCHits.map((eachHit) => eachHit['_source'])
            const combinedKYCsHits = [...state.allKycs]
            const combinedKycIDs = {};
            combinedKYCsHits.forEach((eachHit) => combinedKycIDs[eachHit.id] = true)
            nextKYCHitsSource.forEach((newHit) => {
                if (!combinedKycIDs[newHit.id]) {
                    combinedKYCsHits.push(newHit)
                }
            })
            return {...state,
                allKycs: combinedKYCsHits,
                totalHitsKYCs: totalKYCsHitsAfter,
                search_after_kycs: next_search_after_kycs,
                isLoading: false
            }
        case 'POST_KYCS_SEARCH_AFTER_FAILURE':
            return {...state, isLoading: false}
        case 'CLEAR_KYC_SEARCH':
            return {...state, allKycs: [], totalHitsKYCs: 0, search_after_kycs: [], isLoading: false}
      default:
        return state
    }
  }

export default kyc