import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, clearAlertMessage, alertMessage, searchFundingSchedules, searchAfterFundingSchedules, 
    createFundingSchedule, clearFundingSchedules, postPaymentAccountsSearch, fetchOrganization, patchOrganization, setOrganizationLoading,
    setOrganizationNotLoading, postContractsSearch
    } from '../actions'
import CompanySettings from '../components/CompanySettings.js'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.fundingsBillings && state.fundingsBillings.isLoading) || false,
    paymentAccounts: (state.organizations && state.organizations.paymentAccounts) || [],
    funding_schedules: (state.fundingsBillings && state.fundingsBillings.funding_schedules) || [],
    totalHitsFundingSchdules: (state.fundingsBillings && state.fundingsBillings.totalHitsFundingSchdules) || 0,
    search_after_funding_schedules: (state.fundingsBillings && state.fundingsBillings.search_after_funding_schedules) || [],
    countries: (state.misc && state.misc.enum && state.misc.enum.country) || [],
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    mcc: (state.misc && state.misc.enum && state.misc.enum.mcc) || [],
    environment: (state.appstate && state.appstate.environment) || 'staging',
    contracts: (state.organizations && state.organizations.contracts) || [],
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    clearAlertMessage: (reqBody) => dispatch(clearAlertMessage(reqBody)),
    alertMessage: (reqBody) => dispatch(alertMessage(reqBody)),
    postPaymentAccountsSearch: (reqBody) => dispatch(postPaymentAccountsSearch(reqBody)),
    searchFundingSchedules: (params, reqBody) => dispatch(searchFundingSchedules(params, reqBody)),
    searchAfterFundingSchedules: (params, reqBody) => dispatch(searchAfterFundingSchedules(params, reqBody)),
    createFundingSchedule: (reqBody) => dispatch(createFundingSchedule(reqBody)),
    clearFundingSchedules: () => dispatch(clearFundingSchedules()),
    fetchOrganization: (params) => dispatch(fetchOrganization(params)), 
    patchOrganization: (params, reqBody) => dispatch(patchOrganization(params, reqBody)),
    setOrganizationLoading: () => dispatch(setOrganizationLoading()), 
    setOrganizationNotLoading: () => dispatch(setOrganizationNotLoading()),
    postContractsSearch: (reqBody) => dispatch(postContractsSearch(reqBody)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySettings)