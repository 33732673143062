function navDrawers(state ={}, action) {
    switch (action.type) {
      case 'TOGGLE_DRAWER':
          console.log('toggle drawer action !');
        return {
          ...state, 
          isOpen: !state.isOpen
        }
      default:
        return state
    }
  }

export default navDrawers