import { combineReducers } from 'redux'
import navDrawers from './navDrawers'
import appBars from './appBars'
import users from './users'
import organizations from './organizations'
import appstate from './appstate'
import misc from './misc'
import balancesPayables from './balancesPayables'
import kyc from './kyc'
import disputes from './disputes'
import fundingsBillings from './fundingsBillings'
import payoutsInvoices from './payoutsInvoices'
import payments from './payments'
import contracts from './contracts'
import billings from './billings'
import schedules from './schedules'

const mainApp = combineReducers({
    navDrawers,
    appBars,
    users,
    organizations,
    appstate,
    misc,
    kyc,
    balancesPayables,
    fundingsBillings,
    payoutsInvoices,
    disputes,
    payments,
    billings,
    contracts,
    schedules
  })

export default mainApp