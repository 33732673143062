import { CALL_BALANCES_PAYABLES_API } from '../app/middleware/callBalancesPayablesApi.js';
import { CALL_BALANCES_APPSYNC_API } from '../app/middleware/callBalancesAppSync.js';

export const createBalance = (reqBody) => ({
    types: ['POST_CREATE_BALANCE_REQUEST', 'POST_CREATE_BALANCE_SUCCESS', 'POST_CREATE_BALANCE_FAILURE'],
    [CALL_BALANCES_PAYABLES_API]: '/balances',
    method: 'post',
    reqBody: reqBody,
})

export const searchBalances = (reqBody) => ({
    types: ['POST_SEARCH_BALANCES_REQUEST', 'POST_SEARCH_BALANCES_SUCCESS', 'POST_SEARCH_BALANCES_FAILURE'],
    [CALL_BALANCES_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody,
})

export const searchAfterBalances = (reqBody) => ({
    types: ['POST_SEARCH_AFTER_BALANCES_REQUEST', 'POST_SEARCH_AFTER_BALANCES_SUCCESS', 'POST_SEARCH_AFTER_BALANCES_FAILURE'],
    [CALL_BALANCES_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody,
})

export const clearBalances = () => ({
    type: 'SET_CLEAR_BALANCES',
})

export const createPayable = (reqBody) => ({
    types: ['POST_CREATE_PAYABLE_REQUEST', 'POST_CREATE_PAYABLE_SUCCESS', 'POST_CREATE_PAYABLE_FAILURE'],
    [CALL_BALANCES_PAYABLES_API]: '/payables',
    method: 'post',
    reqBody: reqBody,
})

export const searchPayables = (params, reqBody) => ({
    types: ['POST_SEARCH_PAYABLES_REQUEST', 'POST_SEARCH_PAYABLES_SUCCESS', 'POST_SEARCH_PAYABLES_FAILURE'],
    [CALL_BALANCES_PAYABLES_API]: '/payables/organizations/:organizationId/search',
    method: 'post',
    params: params,
    reqBody: reqBody,
})

export const searchAfterPayables = (params, reqBody) => ({
    types: ['POST_SEARCH_AFTER_PAYABLES_REQUEST', 'POST_SEARCH_AFTER_PAYABLES_SUCCESS', 'POST_SEARCH_AFTER_PAYABLES_FAILURE'],
    [CALL_BALANCES_PAYABLES_API]: '/payables/organizations/:organizationId/search',
    method: 'post',
    params: params,
    reqBody: reqBody,
})

export const clearPayables = () => ({
    type: 'SET_CLEAR_PAYABLES',
})