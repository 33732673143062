import React, { useEffect, useLayoutEffect, useState } from 'react'
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Link } from "react-router-dom"
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";

import { allComplianceApprovalStatus } from '../app/globals';
import rocket from '../../src/rocket.png';

const ButtonMod = withStyles({
    root: {
        '&.MuiButton-root:hover': {
            backgroundColor: '#68d0af' // 1fa595
        },
    },
})(Button);

const TextFieldMod = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #68d0af' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#8b8b8b',
        },
    },
})(TextField);

const WhiteTextFieldMod = withStyles({
    root: {
        '& .MuiInputBase-root': {
            color: '#ffffff',
        },
        '& .MuiInput-underline:after': {
            borderBottom: '2px solid #ffffff' // 1fa595
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: '#ffffff',
        },
    },
})(TextField);

const useStyles = makeStyles(theme => ({
    ComplianceStatusBtn: {
        color: '#ffffff',
        borderWidth: '0px',
        letterSpacing: '1px',
        fontSize: '10px',
        textTransform: 'uppercase',
        backgroundImage: 'linear-gradient(210deg,#5DE455 0%,#14C0AA 100%)',
    },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    accordion: {
        marginTop: '1em'
    },
    colorBanner: {
        background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%)`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'initial',
        minHeight: '22em',
        marginBottom: '-14em'
    },
    companylist: {
        margin: '0 auto',
        position: 'relative',
        maxWidth: '60%'
    },
    title: {
        fontWeight: 560,
        fontSize: '1.5em',
        color: '#ffffff',
        marginBottom: '1em',
    },
    name: {
        overflowWrap: 'break-word',
        fontWeight: 490,
        fontSize: '1.5em',
    },
    card: {
        display: 'flex',
        minHeight: '17em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    action: {
        position: 'absolute',
        bottom: 0,
    },
    add: {
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        textTransform: 'none'
    },
    closeBtn: {
        zIndex: 10,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    formContainer: {
        margin: '2em'
    },
    bannerRight: {
        zIndex: '0',
        position: 'absolute',
        height: '100%',
        minWidth: '28em',
        right: 0,
        background: `linear-gradient(210deg,#8CD987 0%,#1FA595 100%)`,
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'initial',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    imageRight: {
        position: 'absolute',
        zIndex: 0,
        right: 0,
        bottom: '-1em',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    formTitle: {
        margin: '2em',
        maxWidth: '10em',
        fontSize: '1.2rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
            maxWidth: '28em',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 49,
        },
    },
    greenColor: {
        color: '#68d0af' // 1fa595
    },
    whiteColor: {
        color: '#ffffff'
    },
    coName: {
        fontSize: '1rem',
        '@media (min-width:600px)': {
            fontSize: '1rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 42,
        },
    },
    createBtn: {
        backgroundColor: "#a2a2a2",
        color: 'white'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

const Main = (props) => {
    const classes = useStyles(props)
    const { openDrawer, toggleDrawer, organizations, postSearchOrganizations, nextToken, totalHits, isLoading,
        organization, setOrgState, postSearchAfterOrganizations } = props
    const [createCompanyOpen, setCreateCompanyOpen] = React.useState(false);
    const [cName, setCName] = useState('');
    const [complianceStatus, setComplianceStatus] = useState('');
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [isExpanded, setIsExpanded] = useState(false);
    const history = useHistory();
    // before render events, one time run
    useLayoutEffect(() => {
        if (openDrawer) toggleDrawer() // close it if its open
      },[])
    const orgID = (organization && organization.id) || ''
    useEffect(() => {
        if (orgID) {
            history.push({
                pathname: `/company/${orgID}`,
                state: {organizationId: orgID}
            })
        }
    },[orgID])
    // click events
    const companyDetail = (org) => {
        if (!org.id) return
        setOrgState(org)
        history.push({
            pathname: `/company/${org.id}`,
            state: {organizationId: org.id}
        })
    }
    const constructSearchReqBody = (restartSearch=false) => {
        let initialStruct = {}
        // id is available but not in use
        let query = ""
        if (cName) {
            query = `
                query listOrganizations {
                    listOrganizations(
                        filter: { 
                            ${cName ? `name: { contains: "${cName}" }` : ""}
                        },
                        limit: 1000,
                        nextToken: "${(!restartSearch && nextToken) ? nextToken : ""}",
                    ) 
                    { items { 
                        id
                        name
                    }, nextToken } 
                }
            `
        } else {
            query = `
                query listOrganizations {
                    listOrganizations(
                        limit: 1000,
                        nextToken: "${(!restartSearch && nextToken) ? nextToken : ""}",
                    ) 
                    { items { 
                        id
                        name
                    }, nextToken } 
                }
            `
        }
        initialStruct.query = query
        return initialStruct
    }
    const constructCompliaceSearchReqBody = (passedPageSize, restartSearch=false, complianceStatus) => {
        // console.log('process.env.MATERIAL_LICENSE_KEY -> ', process.env.REACT_APP_MATERIAL_LICENSE_KEY);
        let initialStruct = {
            from: searchFrom,
            size: passedPageSize || searchSize,
            query: {
                bool: {
                    must: [
                      {
                        term: {
                          "complianceApproval.keyword": {
                            value: complianceStatus
                          }
                        }
                      }
                    ]
                }
            },
            sort: [
                { createdAt: {order: "desc"} },
                { _id: {order: "asc"} }
            ]
        }

        return initialStruct
    }
    return (
    <main>
        <div className={classes.colorBanner}></div>
        <div className={classes.companylist}>
            {/* It should be a tab instead in the future.. to have possibly todo list and search organizations tabs */}
            <div className={classes.title}>Search Liberalize's Companies</div>
            <Grid container>
                <Grid item md={10} xs={10} sm={10}>
                    <WhiteTextFieldMod
                            onChange={e => setCName(e.target.value)}
                            placeholder="Enter a company name"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                                input: classes.whiteColor,
                            }}
                            InputProps={{
                                classes: {
                                    input: classes.coName,
                                },
                            }}
                        >
                    </WhiteTextFieldMod>
                </Grid>
                <Grid item md={2} xs={2} sm={2}>
                    <IconButton 
                        style={{color: '#ffffff', marginTop: '1em'}} 
                        aria-controls="simple-menu" 
                        aria-haspopup="true"
                        onClick={() => {
                            let esSearchReqBody = constructSearchReqBody(true)
                            console.log("esSearchReqBody: \n",esSearchReqBody);
                            postSearchOrganizations(esSearchReqBody)
                        }}
                    >
                        <SearchIcon fontSize="large"/>
                    </IconButton>
                </Grid>
                {
                    allComplianceApprovalStatus && allComplianceApprovalStatus.map((eachStatus) => {
                        let status = eachStatus
                        if (eachStatus === "ORGANIZATION_ACTION_REQUIRED") status = "ORG_ACTION"
                        if (eachStatus === "ADMIN_ACTION_REQUIRED") status = "ADMIN_ACTION"
                        return <Grid item md={2} xs={2} sm={2} >
                        <Button 
                            fullWidth 
                            className={classes.ComplianceStatusBtn}
                            onClick={(e) => {
                                let esSearchReqBody = constructCompliaceSearchReqBody(searchSize, false, eachStatus)
                                postSearchOrganizations(esSearchReqBody)
                            }}
                        >
                            {"Get " + status}
                        </Button>
                    </Grid>
                    })
                }
            </Grid><br/>
            <div>
                <Grid container spacing={3}>
                    {/* Search company */}
                    {
                        organizations && organizations.length > 0 && organizations.map((eachOrg, index) => {
                            return <Grid key={index} item md={4} xs={12} sm={6} onClick={() => {companyDetail(eachOrg)}}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography className={classes.name} gutterBottom>
                                        {eachOrg.name}
                                    </Typography>
                                    <Typography className={classes.id} color="textSecondary">
                                        Company ID:
                                    </Typography>
                                    <Typography className={classes.id} color="textSecondary">
                                        {eachOrg.id}
                                    </Typography>
                                    <Typography className={classes.id} color="textSecondary">
                                        Description:
                                    </Typography>
                                    <Typography className={classes.id} color="textSecondary">
                                        {eachOrg && eachOrg.description && eachOrg.description.substring(0,27)}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        })
                    }
                    {
                        nextToken ? <Button 
                        fullWidth 
                        className={classes.ComplianceStatusBtn}
                        onClick={(e) => {
                            let esSearchReqBody = constructSearchReqBody(false)
                            postSearchAfterOrganizations(esSearchReqBody)
                        }}
                    >
                        {"MORE "}
                    </Button> : ""
                    }
                </Grid>
            </div>
        </div>
    </main>
    )
}

export default Main