function balancesPayables(state ={isLoading: false}, action) {
    switch (action.type) {
        case 'POST_SEARCH_BALANCES_REQUEST':
            console.log('POST_SEARCH_BALANCES_REQUEST', action);
            return {...state, isLoading: true} 
        case 'POST_SEARCH_BALANCES_SUCCESS':
            console.log('POST_SEARCH_BALANCES_SUCCESS', action);
            const listBalances = (action.data && action.data.data && action.data.data.listBalances) || null
            let hits = listBalances ? listBalances.items : []
            const nextToken = listBalances ? listBalances.nextToken : undefined
            hits = hits.map((eachBalance) => {
                return {...eachBalance, id: eachBalance.currency}
            })
            return {...state,
                balances: hits,
                balancesNextToken: nextToken,
                isLoading: false
            }
        case 'POST_SEARCH_BALANCES_FAILURE':
            console.log('POST_SEARCH_BALANCES_FAILURE', action);
            return {...state, isLoading: false}
        case 'POST_SEARCH_AFTER_BALANCES_REQUEST':
            return {...state, isLoading: true}
        case 'POST_SEARCH_AFTER_BALANCES_SUCCESS':
            console.log('POST_SEARCH_AFTER_BALANCES_SUCCESS', action);
            const nextListBalances = (action.data && action.data.data && action.data.data.listBalances) || null
            const nextHits = nextListBalances ? nextListBalances.items : []
            const nextNextToken = nextListBalances ? nextListBalances.nextToken : undefined
            let combinedHits = state.billings.concat(nextHits);
            combinedHits = combinedHits.map((eachBalance) => {
                return {...eachBalance, id: eachBalance.currency}
            })
            return {...state,
                balances: combinedHits,
                balancesNextToken: nextNextToken,
                isLoading: false
            }
        case 'POST_SEARCH_AFTER_BALANCES_FAILURE':
            return {...state, isLoading: false}
        case 'POST_CREATE_BALANCE_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_CREATE_BALANCE_SUCCESS':
            const currentBalances = state.balances || []
            console.log('action.data -> ', action.data);
            const newBalance = action.data || {}
            newBalance.id = newBalance.currency
            return {...state,
                isLoading: false,
                balances: [...currentBalances, newBalance]
            }
        case 'POST_CREATE_BALANCE_FAILURE':
            return {...state, isLoading: false}
        case 'SET_CLEAR_BALANCES':
            return {...state, isLoading: false, balances: []}
        case 'POST_SEARCH_PAYABLES_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_SEARCH_PAYABLES_SUCCESS':
            console.log('POST_SEARCH_PAYABLES_SUCCESS', action);
            const payables_hits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const search_after_payables = payables_hits.length > 0 ? payables_hits[payables_hits.length - 1].sort : [];
            const totalHitsPayables = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const payablesHitsSource = payables_hits.map((eachHit) => {
                eachHit['_source'].id = eachHit['_source'].currency
                return eachHit['_source']
            })
            return {...state,
                payables: payablesHitsSource,
                totalHitsPayables: totalHitsPayables,
                search_after_payables: search_after_payables,
                isLoading: false
            }
        case 'POST_SEARCH_PAYABLES_FAILURE':
            return {...state, isLoading: false}
        case 'POST_SEARCH_AFTER_PAYABLES_REQUEST':
            return {...state, isLoading: true}
        case 'POST_SEARCH_AFTER_PAYABLES_SUCCESS':
            console.log('POST_SEARCH_PAYABLES_SUCCESS', action);
            const nextPayableHits = (action && action.data && action.data.hits && action.data.hits.hits) || [];
            const totalPayableHitsAfter = (action && action.data && action.data.hits && action.data.hits.total && action.data.hits.total.value) || 0;
            const next_search_after_payables = nextPayableHits.length > 0 ? nextPayableHits[nextPayableHits.length - 1].sort : [];
            const nextPayableHitsSource = nextPayableHits.map((eachHit) => {
                eachHit['_source'].id = eachHit['_source'].currency
                return eachHit['_source']
            })
            const combinedPayablesHits = [...state.balances]
            const combinedPayableIDs = {};
            combinedPayablesHits.forEach((eachHit) => combinedPayableIDs[eachHit.id] = true)
            nextPayableHitsSource.forEach((newHit) => {
                if (!combinedPayableIDs[newHit.id]) {
                    combinedPayablesHits.push(newHit)
                }
            })
            return {...state,
                payables: combinedPayablesHits,
                totalHitsPayables: totalPayableHitsAfter,
                search_after_payables: next_search_after_payables,
                isLoading: false
            }
        case 'POST_SEARCH_AFTER_PAYABLES_FAILURE':
            return {...state, isLoading: false}
        case 'POST_CREATE_PAYABLE_REQUEST':
            return {...state, isLoading: true} 
        case 'POST_CREATE_PAYABLE_SUCCESS':
            const currentPayables = state.payables || []
            const newPayable = action.data || {}
            newPayable.id = newPayable.currency
            return {...state,
                isLoading: false,
                payables: [...currentPayables, newPayable]
            }
        case 'POST_CREATE_PAYABLE_FAILURE':
            return {...state, isLoading: false}
        case 'SET_CLEAR_PAYABLES':
            return {...state, isLoading: false, payables: []}
      default:
        return state
    }
  }

export default balancesPayables