function appBars(state ={}, action) {
    switch (action.type) {
      case 'APPBAR_FONT_COLOR':
          console.log('toggle drawer action ! : ', action);
        return {
          ...state,
          isWhiteFont: action.data.isWhite
        }
      default:
        return state
    }
  }

export default appBars