import { connect } from 'react-redux'
import { toggleDrawer, toggleAppbarWhiteFont, getEnumeration, setLoading, setNotLoading, createKyc, 
    updateKyc, deleteKyc, postKYCsSearch, postKYCsSearchAfter, clearKYCsSearch, setOrgState } from '../actions'
import KnowYourCustomer from '../components/KnowYourCustomer'

const mapStateToProps = state => ({
    openDrawer: state.navDrawers.isOpen,
    organization: state.organizations.organization,
    isLoading: (state.kyc && state.kyc.isLoading) || false,
    search_after_kycs: (state.kyc && state.kyc.search_after_kycs) || [],
    currentUser: (state.users && state.users.user) || {},
    countries: (state.misc && state.misc.enum && state.misc.enum.country) || [],
    currencies: (state.misc && state.misc.enum && state.misc.enum.currency) || [],
    mcc: (state.misc && state.misc.enum && state.misc.enum.mcc) || [],
    environment: (state.appstate && state.appstate.environment) || 'staging',
    allKycs: (state.kyc && state.kyc.allKycs) || [],
})

const mapDispatchToProps = dispatch => ({
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleAppbarWhiteFont: (isWhite) => dispatch(toggleAppbarWhiteFont(isWhite)),
    getEnumeration: (params) => dispatch(getEnumeration(params)),
    setLoading: () => dispatch(setLoading()),
    setNotLoading: () => dispatch(setNotLoading()),
    createKyc: (params, reqBody) => dispatch(createKyc(params, reqBody)),
    updateKyc: (params, reqBody) => dispatch(updateKyc(params, reqBody)),
    deleteKyc: (params) => dispatch(deleteKyc(params)),
    postKYCsSearch: (reqBody) => dispatch(postKYCsSearch(reqBody)),
    postKYCsSearchAfter: (reqBody) => dispatch(postKYCsSearchAfter(reqBody)),
    clearKYCsSearch: () => dispatch(clearKYCsSearch()),
    setOrgState: (reqBody) => dispatch(setOrgState(reqBody))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KnowYourCustomer)