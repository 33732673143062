function billings(state ={isLoading: false, hits: [], search_after: [], totalHits: 0 }, action) {
    switch (action.type) {
        case 'POST_BILLINGS_SEARCH_REQUEST':
            return {...state, isLoading: true}
        case 'POST_BILLINGS_SEARCH_SUCCESS':
            console.log('POST_BILLINGS_SEARCH_SUCCESS', action);
            const queryBillingsByOrganizationID = (action.data && action.data.data && action.data.data.queryBillingsByOrganizationID) || null
            const getPayments = (action.data && action.data.data && action.data.data.getPayments) || null
            const hits = queryBillingsByOrganizationID ? queryBillingsByOrganizationID.items : getPayments ? [getPayments] : []
            const nextToken = queryBillingsByOrganizationID ? queryBillingsByOrganizationID.nextToken : undefined
            return {...state,
                billings: hits,
                billingsNextToken: nextToken,
                isLoading: false
            }
        case 'POST_BILLINGS_SEARCH_FAILURE':
            return {...state, isLoading: false}
        case 'POST_BILLINGS_SEARCH_AFTER_REQUEST':
            return {...state, isLoading: true}
        case 'POST_BILLINGS_SEARCH_AFTER_SUCCESS':
            console.log('POST_BILLINGS_SEARCH_AFTER_SUCCESS', action);
            const nextQueryBillingsByOrganizationId = (action.data && action.data.data && action.data.data.queryBillingsByOrganizationID) || null
            const nextHits = nextQueryBillingsByOrganizationId ? nextQueryBillingsByOrganizationId.items : []
            const nextNextToken = nextQueryBillingsByOrganizationId ? nextQueryBillingsByOrganizationId.nextToken : undefined
            const combinedHits = state.billings.concat(nextHits);
            return {...state,
                billings: combinedHits,
                billingsNextToken: nextNextToken,
                isLoading: false
            }
        case 'POST_BILLINGS_SEARCH_AFTER_FAILURE':
            // console.log('POST_PAYMENTS_SEARCH_AFTER_FAILURE', action);
            return {...state, isLoading: false}
        case 'CLEAR_BILLINGS_SEARCH':
            return {...state,
                billings: [],
                billingsNextToken: "",
            }
        default:
            return {...state, isLoading: false} 
    }
  }

export default billings