import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';

import { customerRiskQuestions, transactionRiskQuestions, industryRiskQuestions, 
    geographicalRiskQuestions, productServiceRiskQuestions, parentCompanyRiskQuestions,
    regulatoryProceedingsQuestions, finalRiskRatingQuestions, teamMemberQuestions } from '../app/globals';
import { fetchApi } from '../app/utils/callKycApi';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    textArea: {
        width: '100%',
        '&:focus-visible': {
            'outline-color': '#68d0af',
        },
    },
    showMore: {
        color: '#68d0af',
        fontWeight: 700,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    hiddeninput: {
        display: 'none',
    },
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const GreenSwitch = withStyles({
    switchBase: {
      color: green[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
        color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const KnowYourCustomer = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, currentUser, postKYCsSearch, postKYCsSearchAfter, 
        totalHitsKYCs, getEnumeration, countries, currencies, mcc, environment, setLoading, setNotLoading,
        kyc_nextToken, clearKYCsSearch, createKyc, updateKyc, deleteKyc, allKycs, setOrgState } = props
    const urlArr = window.location.href.split('/')
    const orgID = urlArr[urlArr.length - 1]
    const [kycDialog, setKycDialog] = useState(false);
    const [kycDetails, setKycDetails] = useState({
        customerRisk: customerRiskQuestions, 
        transactionRisk: transactionRiskQuestions,
        industryRisk: industryRiskQuestions,
        geographicalRisk: geographicalRiskQuestions,
        productServiceRisk: productServiceRiskQuestions,
        parentCompanyRisk: parentCompanyRiskQuestions,
        regulatoryProceedings: regulatoryProceedingsQuestions
    });
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchVersion, setSearchVersion] = useState('');
    const [searchIsCompleted, setSearchIsCompleted] = useState('');
    const [searchClientAdvisorName, setSearchClientAdvisorName] = useState('');
    const [searchUnderwriterName, setSearchUnderwriterName] = useState('');
    const [searchComplianceOfficerName, setSearchComplianceOfficerName] = useState('');
    const [searchId, setSearchId] = useState('');
    const [page, setPage] = useState(1);
    const [showEdd, setShowEdd] = useState(false);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody()
        postKYCsSearch(esSearchReqBody)
        setLoadedPage(1)
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        getEnumeration({enumGroup: 'mcc'})
        // setOrgState(org)
        return function cleanup() {
            clearKYCsSearch()
        };
    },[])

    useEffect(()=> {
        if (organization && organization.id) {
            let currentKycDetails = JSON.parse(JSON.stringify({...kycDetails, 
                customerRisk: customerRiskQuestions, 
                transactionRisk: transactionRiskQuestions,
                industryRisk: industryRiskQuestions,
                geographicalRisk: geographicalRiskQuestions,
                parentCompanyRisk: parentCompanyRiskQuestions,
                regulatoryProceedings: regulatoryProceedingsQuestions
            }))
            currentKycDetails.organization = organization
            setKycDetails(currentKycDetails)
        }
    },[organization, organization.id])

    const downloadFile = (s3Key) => {
        console.log(s3Key);
        setLoading()
        fetchApi(environment,
            '/organizations/:organizationId/kycdoc',
            'get',
            {organizationId: organization.id},
            null,
            {key: s3Key}
            ).then((result) => {
                console.log('presigned result => ', result);
                setNotLoading()
                const { data } = result
                window.open(data, "_blank")
        })
    }

    const uploadFile = (eventFile, kycDetailsAttribute) => {
        setLoading()
        let file = eventFile[0]
        // console.log('file -> ', file);
        let matchedFileType = false;
        if (!file) {
            setNotLoading()
            return;
        }
        console.log(file.type);
        switch (file.type) {
            case 'image/png':
            case 'image/jpeg':
            case 'image/heic':
            case 'application/pdf':
            case 'video/quicktime':
                matchedFileType = true;
                break;
            default:
                break;
        }
        if(!matchedFileType) {
            setNotLoading()
            alert('Only png, jpeg and pdf file types allowed')
            // this.imageUploading = false
            return;
        }
        // const max_file_size = 102400 * 10 1 MB
        const max_file_size = 102400 * 10000
        if (file.size <= max_file_size){
            const reader = new FileReader()
            // console.log('file 1 -> ', file);
            reader.readAsDataURL(file)

            reader.onload = readerEvent => {
                const content = readerEvent.target.result
                // console.log('content -> ', content);
                // console.log('file 2 -> ', file);

                saveFile(file, content, kycDetailsAttribute)
            }
        } else {
            setNotLoading();
            alert('Maximum size exceeded for file! Please make sure file size is less than 1000MB');
            // this.imageUploading = false
        }
    }

    const saveFile = (file, content, kycDetailsAttribute) => {
        try {
            let fileNameArr = file.name.split('.')
            fetchApi(environment,
                '/organizations/:organizationId/kycdoc',
                'post',
                {organizationId: organization.id},
                {}
                ).then((result) => {
                    const { data } =result
                    if (!data.url) return
                    const xhr = new XMLHttpRequest()
                    let url = data.url
                    
                    xhr.open(data.method, url)
                    xhr.setRequestHeader('x-amz-acl', data.XamzAcl)

                    /* eslint func-names: "off" */
                    xhr.onreadystatechange = () => {
                        if (xhr.status == 200){
                            let currentKycDetails = JSON.parse(JSON.stringify(kycDetails))
                            if (kycDetailsAttribute === 'additionalFiles') {
                                let currentAdditionalFiles = currentKycDetails.additionalFiles || []
                                currentKycDetails.additionalFiles = [...currentAdditionalFiles, { name: file.name, key: data.key }]
                            } else if (kycDetailsAttribute === 'eddFiles') {
                                currentKycDetails.enhancedDueDiligence = currentKycDetails.enhancedDueDiligence || {}
                                currentKycDetails.enhancedDueDiligence.documents = currentKycDetails.enhancedDueDiligence.documents || {}
                                currentKycDetails.enhancedDueDiligence.documents.eddFiles = currentKycDetails.enhancedDueDiligence.documents.eddFiles || []
                                let currentEddFiles = currentKycDetails.enhancedDueDiligence.documents.eddFiles
                                currentKycDetails.enhancedDueDiligence.documents.eddFiles = [...currentEddFiles, { name: file.name, key: data.key }]
                            }
                            setKycDetails(currentKycDetails)
                            setNotLoading();
                        }
                    }
                    xhr.send(file)
            })
        } catch (err) {
            setNotLoading();
            alert(err)
        }
    }

    const constructSearchReqBody = (passedPageSize, restartSearch=false) => {
        // let initialStruct = {
        //     from: searchFrom,
        //     size: passedPageSize || searchSize,
        //     query: {
        //         bool: {
        //             must: [
        //             ]
        //         },
        //     },
        //     sort: [
        //         { createdAt: {order: "desc"} },
        //         { _id: {order: "asc"} }
        //     ]
        // }
        // if (searchVersion) {
        //     initialStruct.query.bool.must.push(
        //         { "match": { "version": searchVersion }}
        //     )
        // }
        // if (searchIsCompleted) {
        //     const letter = searchIsCompleted.toLowerCase()
        //     if (letter === 'y') {
        //         initialStruct.query.bool.must.push(
        //             { "match": { "isCompleted": true }}
        //         )
        //     } else {
        //         initialStruct.query.bool.must.push(
        //             { "match": { "isCompleted": false }}
        //         )
        //     }
        // }
        // if (searchClientAdvisorName) {
        //     initialStruct.query.bool.must.push(
        //         { "match": { "clientAdvisor.name": searchClientAdvisorName }}
        //     )
        // }
        // if (searchUnderwriterName) {
        //     initialStruct.query.bool.must.push(
        //         { "match": { "underwriter.name": searchUnderwriterName }}
        //     )
        // }
        // if (searchComplianceOfficerName) {
        //     initialStruct.query.bool.must.push(
        //         { "match": { "complianceOfficer.name": searchComplianceOfficerName }}
        //     )
        // }
        // if (organization && organization.id) {
        //     initialStruct.query.bool.must.push(
        //         { "match": { "organization.id": (organization && organization.id) }}
        //     )
        // }
        // if (!restartSearch && kyc_nextToken.length > 0) {
        //     initialStruct.from = -1
        //     initialStruct.search_after = kyc_nextToken
        // }

        let initialStruct = {}
        // id is available but not in use
        let query = ""
        query = `
            query listKYCs {
                listKYCs(
                    nextToken: "${(!restartSearch && kyc_nextToken) ? kyc_nextToken : ""}",
                ) 
                { items { 
                    createdAt
                    id
                    organization
                    version
                    isCompleted
                    customerRisk
                    transactionRisk
                    industryRisk
                    geographicalRisk
                    productServiceRisk
                    parentCompanyRisk
                    regulatoryProceedings
                    additionalFiles
                    finalRiskRating
                    enhancedDueDiligence
                    clientAdvisor
                    underwriter
                    complianceOfficer
                    updatedAt
                }, nextToken } 
            }
        `
        initialStruct.query = query
        return initialStruct
    }

    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={kycDialog} 
            onClose={()=> {setKycDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            {/* Loading screen */}
            <Backdrop className={classes.backdrop} open={isLoading} >
                <CircularProgress className={classes.circularProgress} />
            </Backdrop>
            <CloseIcon className={classes.closeBtn} onClick={()=> {setKycDialog(false)}}/>
            {kycDetails && !kycDetails.id &&<Typography className={classes.Title} color="textSecondary" gutterBottom>Create KYC</Typography>}
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        kycDetails && kycDetails.id && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>KYC ID: {kycDetails.id}</Typography>
                        </Grid>
                    }
                    {/* Organization Details */}
                    <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Organization Details</Typography>
                        <Grid container spacing={2}>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Legal Name:</Typography>
                                {kycDetails.organization && kycDetails.organization.name}
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Doing Biz As (DBA): </Typography>
                                {kycDetails.organization && kycDetails.organization.dba}
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Organization ID:  </Typography>
                                {kycDetails.organization && kycDetails.organization.id}
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Country:</Typography>
                                { kycDetails.organization && kycDetails.organization.country && countries.map((country) => {
                                        if (country.value.country_alpha3_code == kycDetails.organization.country) {
                                            return country.name
                                        }
                                        return ''
                                    })
                                }
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Company Type:</Typography>
                                {kycDetails.organization && kycDetails.organization.type}
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Category Code:</Typography>
                                {
                                    kycDetails.organization && kycDetails.organization.mcc + ": "
                                }
                                { kycDetails.organization && kycDetails.organization.mcc && mcc.map((catCode) => {
                                        if (catCode.value.code == kycDetails.organization.mcc) {
                                            return catCode.value.text
                                        }
                                        return ''
                                    })
                                }
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>UEN</Typography>
                                {kycDetails.organization && kycDetails.organization.uen}
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Business Registration Certificate</Typography>
                                {kycDetails.organization && kycDetails.organization.bizRegCert && kycDetails.organization.bizRegCert.name}
                                <IconButton onClick={() => { downloadFile(kycDetails.organization.bizRegCert.key)}} component="span">
                                    <CloudDownloadIcon />
                                </IconButton>
                            </Grid>
                            <Grid item md={4} xs={4} sm={4}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Website</Typography>
                                {kycDetails.organization && kycDetails.organization.website}
                            </Grid>
                            <Grid item md={6} xs={6} sm={6}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Business Description</Typography>
                                {kycDetails.organization && kycDetails.organization.description}
                            </Grid>
                            <Grid item md={3} xs={3} sm={3}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Created At</Typography>
                                {kycDetails.organization && kycDetails.organization.createdAt && new Date(kycDetails.organization.createdAt).toString()}
                            </Grid>
                            <Grid item md={3} xs={3} sm={3}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Updated At</Typography>
                                {kycDetails.organization && kycDetails.organization.updatedAt && new Date(kycDetails.organization.updatedAt).toString()}
                            </Grid>
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Address</Typography>
                                {kycDetails.organization && kycDetails.organization.address && kycDetails.organization && kycDetails.organization.address.line1 + ', '} {kycDetails.organization && kycDetails.organization.address && kycDetails.organization && kycDetails.organization.address.line2 + ', '} 
                                {kycDetails.organization && kycDetails.organization.address && kycDetails.organization && kycDetails.organization.address.city + ', '} {kycDetails.organization && kycDetails.organization.address && kycDetails.organization && kycDetails.organization.address.state + ', '} {kycDetails.organization && kycDetails.organization.address && kycDetails.organization && kycDetails.organization.address.postal}
                            </Grid>
                            <Grid item md={12} xs={12} sm={12}>
                                <Grid container spacing={2}>
                                    {
                                        kycDetails.organization && kycDetails.organization.banks && kycDetails.organization.banks.length > 0 && kycDetails.organization.banks.map((eachBank, index) => {
                                            return <Grid item md={4} xs={4} sm={4}>
                                            <Typography className={classes.title} color="textSecondary" gutterBottom>Bank #{index+1}</Typography>
                                            {eachBank.name ? `Bank Name: ${eachBank.name}` : ''} {eachBank.name ? <br/> : ''}
                                            {eachBank.code ? `Bank Code: ${eachBank.code}` : ''} {eachBank.code ? <br/> : ''}
                                            {eachBank.swift ? `Swift Code: ${eachBank.swift}` : ''} {eachBank.swift ? <br/> : ''}
                                            {eachBank.branch ? `Branch Code: ${eachBank.branch}` : ''} {eachBank.branch ? <br/> : ''}

                                            {eachBank.account && eachBank.account.currency ? `Account Currency: ${eachBank.account.currency}` : ''}
                                            {eachBank.account && eachBank.account.currency ? <br/> : ''}

                                            {eachBank.account && eachBank.account.name ? `Account Name: ${eachBank.account.name}` : ''}
                                            {eachBank.account && eachBank.account.name ? <br/> : ''}

                                            {eachBank.account && eachBank.account.number ? `Account Number: ${eachBank.account.number}` : ''}
                                            {eachBank.account && eachBank.account.number ? <br/> : ''}

                                            {eachBank.bankStatement && eachBank.bankStatement.name ? 'Bank Statement ': '' }
                                            {eachBank.bankStatement && eachBank.bankStatement.key ? <IconButton 
                                                onClick={() => { downloadFile(eachBank.bankStatement.key)}} 
                                                component="span"
                                            >
                                                <CloudDownloadIcon />
                                            </IconButton> : '' }
                                        </Grid>
                                        })
                                    }
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Other Supporting Documents</Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Certificates</Typography>
                                        <Grid container spacing={2}>
                                            {
                                                kycDetails.organization && kycDetails.organization.otherSupportingDocuments && kycDetails.organization.otherSupportingDocuments.certificates && 
                                                kycDetails.organization.otherSupportingDocuments.certificates.length > 0 && kycDetails.organization.otherSupportingDocuments.certificates.map((eachCert, index) => {
                                                    return <Grid item md={12} xs={12} sm={12}>
                                                    {eachCert && eachCert.name ? `${eachCert.name} `: '' }
                                                    {eachCert && eachCert.key ? <IconButton 
                                                        onClick={() => { downloadFile(eachCert.key)}} 
                                                        component="span"
                                                    >
                                                        <CloudDownloadIcon />
                                                    </IconButton> : '' }
                                                </Grid>
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Previous Processings</Typography>
                                        <Grid container spacing={2}>
                                            {
                                                kycDetails.organization && kycDetails.organization.otherSupportingDocuments && kycDetails.organization.otherSupportingDocuments.previousProcessings && 
                                                kycDetails.organization.otherSupportingDocuments.previousProcessings.length > 0 && kycDetails.organization.otherSupportingDocuments.previousProcessings.map((eachDocu, index) => {
                                                    return <Grid item md={12} xs={12} sm={12}>
                                                    {eachDocu && eachDocu.name ? `${eachDocu.name} `: '' }
                                                    {eachDocu && eachDocu.key ? <IconButton 
                                                        onClick={() => { downloadFile(eachDocu.key)}} 
                                                        component="span"
                                                    >
                                                        <CloudDownloadIcon />
                                                    </IconButton> : '' }
                                                </Grid>
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Primary Owner</Typography>
                                <Grid container spacing={3}>
                                    <Grid item md={4} xs={4} sm={4}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Name</Typography>
                                        {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.firstName ? kycDetails.organization.primaryOwner.firstName + ' ' : ''}
                                        {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.lastName ? kycDetails.organization.primaryOwner.lastName : ''}
                                    </Grid>
                                    <Grid item md={4} xs={4} sm={4}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Date of Birth:</Typography>
                                        {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.dob}
                                    </Grid>
                                    <Grid item md={4} xs={4} sm={4}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Identification:</Typography>
                                        {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.identification}
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Gender:</Typography>
                                        {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.gender}
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Nationality:</Typography>
                                        {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.nationality}
                                    </Grid>
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Proof of Identification:</Typography>
                                            {
                                                kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.proofOfIdentification && 
                                                kycDetails.organization.primaryOwner.proofOfIdentification.name 
                                            }
                                            {
                                                kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.proofOfIdentification && 
                                                kycDetails.organization.primaryOwner.proofOfIdentification.key && <IconButton 
                                                    onClick={() => { downloadFile(kycDetails.organization.primaryOwner.proofOfIdentification.key)}} 
                                                    component="span"
                                                >
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            }
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Address</Typography>
                                            {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.address && kycDetails.organization.primaryOwner.address.line1 + ', '} {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.address && kycDetails.organization.primaryOwner.address.line2 + ', '} 
                                            {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.address && kycDetails.organization.primaryOwner.address.city + ', '} {kycDetails.organization && kycDetails.organization.primaryOwner &&  kycDetails.organization.primaryOwner.address && kycDetails.organization.primaryOwner.address.state + ', '} {kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.address && kycDetails.organization.primaryOwner.address.postal +', '}
                                            { kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.country && countries.map((country) => {
                                                if (country.value.country_alpha3_code == kycDetails.organization.primaryOwner.country) {
                                                    return country.name
                                                }
                                                return ''
                                            })
                                        }
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Proof of Address:</Typography>
                                            {
                                                kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.proofOfAddress && 
                                                kycDetails.organization.primaryOwner.proofOfAddress.name 
                                            }
                                            {
                                                kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.proofOfAddress && 
                                                kycDetails.organization.primaryOwner.proofOfAddress.key && <IconButton 
                                                    onClick={() => { downloadFile(kycDetails.organization.primaryOwner.proofOfAddress.key)}} 
                                                    component="span"
                                                >
                                                    <CloudDownloadIcon />
                                                </IconButton>
                                            }
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Share Percentage:</Typography>
                                        {
                                            kycDetails.organization && kycDetails.organization.primaryOwner && kycDetails.organization.primaryOwner.sharePercentage  && kycDetails.organization.primaryOwner.sharePercentage + '%'
                                        }
                                    </Grid>
                                    <Grid item md={6} xs={6} sm={6}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Article of Association:</Typography>
                                        {
                                                kycDetails.organization && kycDetails.organization.articleOfAssociation && kycDetails.organization.articleOfAssociation.name
                                        }
                                        {
                                            kycDetails.organization && kycDetails.organization.articleOfAssociation && kycDetails.organization.articleOfAssociation.key && <IconButton 
                                                onClick={() => { downloadFile(kycDetails.organization.articleOfAssociation.key)}} 
                                                component="span"
                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12} sm={12}>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>Beneficial Owner(s)</Typography>
                                <Grid container spacing={3}>
                                    {
                                        kycDetails.organization && kycDetails.organization.beneficialOwners && kycDetails.organization.beneficialOwners.length > 0 &&
                                        kycDetails.organization.beneficialOwners.map((eachOwner, index) => {
                                            return <Grid item md={12} xs={12} sm={12}>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>Beneficial Owner #{index + 1}</Typography>
                                                <Grid container spacing={2}>
                                                    {/* isEntity */}
                                                    <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Beneficial Owner is an</Typography>
                                                        {eachOwner.isEntity ? 'Entity' : 'Individual'}
                                                    </Grid>
                                                    {eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Entity Name</Typography>
                                                        {eachOwner.entityName}
                                                    </Grid>}
                                                    {!eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Individual Name</Typography>
                                                        {`${eachOwner.firstName} ${eachOwner.lastName}`}
                                                    </Grid>}
                                                    {!eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Individual Date of Birth</Typography>
                                                        {`${eachOwner.dob}`}
                                                    </Grid>}
                                                    {!eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Individual Gender</Typography>
                                                        {`${eachOwner.gender}`}
                                                    </Grid>}
                                                    {!eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Individual Nationality</Typography>
                                                        {`${eachOwner.nationality}`}
                                                    </Grid>}
                                                    {eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Business Registration Number</Typography>
                                                        {eachOwner.uen}
                                                    </Grid>}
                                                    {!eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Individual Identification</Typography>
                                                        {eachOwner.identification}
                                                    </Grid>}
                                                    {eachOwner.isEntity && <Grid item md={12} xs={12} sm={12}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Business Description</Typography>
                                                        {eachOwner.description}
                                                    </Grid>}
                                                    {!eachOwner.isEntity && <Grid item md={6} xs={6} sm={6}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Individual Email</Typography>
                                                        {eachOwner.email}
                                                    </Grid>}
                                                    {!eachOwner.isEntity && <Grid item md={6} xs={6} sm={6}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Proof of Identification</Typography>
                                                            {
                                                                eachOwner && eachOwner.proofOfIdentification && eachOwner.proofOfIdentification.name 
                                                            }
                                                            {
                                                                eachOwner && eachOwner.proofOfIdentification && eachOwner.proofOfIdentification.key && <IconButton 
                                                                    onClick={() => { downloadFile(eachOwner.proofOfIdentification.key)}} 
                                                                    component="span"
                                                                >
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            }
                                                    </Grid>}
                                                    <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Share Percentage</Typography>
                                                        {`${eachOwner.sharePercentage} %`}
                                                    </Grid>
                                                    {eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Article of Association</Typography>
                                                            {
                                                                eachOwner && eachOwner.articleOfAssociation && eachOwner.articleOfAssociation.name 
                                                            }
                                                            {
                                                                eachOwner && eachOwner.articleOfAssociation && eachOwner.articleOfAssociation.key && <IconButton 
                                                                    onClick={() => { downloadFile(eachOwner.articleOfAssociation.key)}} 
                                                                    component="span"
                                                                >
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            }
                                                    </Grid>}
                                                    {eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Company Type</Typography>
                                                            { eachOwner && eachOwner.type }
                                                    </Grid>}
                                                    {eachOwner.isEntity && <Grid item md={4} xs={4} sm={4}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Merchant Category Code</Typography>
                                                            {
                                                                eachOwner && eachOwner.mcc && eachOwner.mcc + ": "
                                                            }
                                                            { eachOwner.mcc && mcc.map((catCode) => {
                                                                    if (catCode.value.code == eachOwner.mcc) {
                                                                        return catCode.value.text
                                                                    }
                                                                    return ''
                                                                })
                                                            }
                                                    </Grid>}
                                                    {eachOwner.isEntity && <Grid item md={6} xs={6} sm={6}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Business Registration Certificate</Typography>
                                                            {
                                                                eachOwner && eachOwner.bizRegCert && eachOwner.bizRegCert.name 
                                                            }
                                                            {
                                                                eachOwner && eachOwner.bizRegCert && eachOwner.bizRegCert.key && <IconButton 
                                                                    onClick={() => { downloadFile(eachOwner.bizRegCert.key)}} 
                                                                    component="span"
                                                                >
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            }
                                                    </Grid>}
                                                    {eachOwner.isEntity && <Grid item md={6} xs={6} sm={6}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Financial Statement(s)</Typography>
                                                            {
                                                                eachOwner && eachOwner.financialStatements && eachOwner.financialStatements.length > 0 && eachOwner.financialStatements.map((statement) => {
                                                                    return <span>{statement.name}<IconButton 
                                                                        onClick={() => { downloadFile(statement.key)}} 
                                                                        component="span"
                                                                    >
                                                                        <CloudDownloadIcon />
                                                                    </IconButton></span>
                                                                })
                                                            }
                                                    </Grid>}
                                                    {/* Address */}
                                                    <Grid item md={6} xs={6} sm={6}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Address</Typography>
                                                            {eachOwner && eachOwner.address && eachOwner.address.line1 + ', '} {eachOwner && eachOwner.address && eachOwner.address.line2 + ', '} 
                                                            {eachOwner && eachOwner.address && eachOwner.address.city + ', '} {eachOwner && eachOwner.address && eachOwner.address.state + ', '} {eachOwner && eachOwner.address && eachOwner.address.postal +', '}
                                                            { eachOwner && eachOwner.country && countries.map((country) => {
                                                                if (country.value.country_alpha3_code == eachOwner.country) {
                                                                    return country.name
                                                                }
                                                                return ''
                                                            })
                                                        }
                                                    </Grid>
                                                    <Grid item md={6} xs={6} sm={6}>
                                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Proof of Address:</Typography>
                                                            {
                                                                eachOwner && eachOwner.proofOfAddress && eachOwner.proofOfAddress.name 
                                                            }
                                                            {
                                                                eachOwner && eachOwner.proofOfAddress && eachOwner.proofOfAddress.key && <IconButton 
                                                                    onClick={() => { downloadFile(eachOwner.proofOfAddress.key)}} 
                                                                    component="span"
                                                                >
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Grid>
                            {/* {JSON.stringify(mcc)} */}
                        </Grid>
                    </Grid>
                    {/* Customer Risk */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Customer Risk</Typography>
                            {/* IF OLD KYC */}
                            {kycDetails.isCompleted && kycDetails.customerRisk && Object.keys(kycDetails.customerRisk).map((eachQuestion) => {
                                return <div><hr/><Grid container spacing={2}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            {kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].value}
                                        </Typography>
                                    </Grid>
                                    {/* If it exists in the questionaire show the binary answer */}
                                    {customerRiskQuestions[eachQuestion] && customerRiskQuestions[eachQuestion].binaryRiskMapping && <Grid item md={4} xs={4} sm={4}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Binary Answer</Typography>
                                        {kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No'}
                                    </Grid>}
                                    <Grid item md={4} xs={4} sm={4}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Risk Rating</Typography>
                                        {kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].rating == 0 ? 'No Rating': ''}
                                        {kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].rating == 1 ? 'Low Risk': ''}
                                        {kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].rating == 2 ? 'Medium Risk': ''}
                                        {kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].rating == 3 ? 'High Risk': ''}
                                    </Grid>
                                    {kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].otherInfo && <Grid item md={4} xs={4} sm={4}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Other Info</Typography>
                                        {kycDetails.customerRisk[eachQuestion].otherInfo}
                                    </Grid>}
                                </Grid></div>
                            })}
                            {/* IF NEW KYC */}
                            { !kycDetails.isCompleted && Object.keys(customerRiskQuestions).map((eachQuestion) => {
                                    return <div>
                                        <hr/>
                                        {/* Yes No Answer, Binary Answer */}
                                        {customerRiskQuestions[eachQuestion].binaryRiskMapping ? <span>
                                            {(kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No')}
                                            <GreenSwitch 
                                            checked={(kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].binaryAnswer) || false}
                                            onChange={(e) => {
                                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                currKycDetails.customerRisk[eachQuestion].binaryAnswer = e.target.checked
                                                // set rating based on binaryRiskMapping
                                                console.log('currKycDetails.customerRisk[eachQuestion] -> ', currKycDetails.customerRisk[eachQuestion])
                                                currKycDetails.customerRisk[eachQuestion].rating = customerRiskQuestions[eachQuestion].binaryRiskMapping[e.target.checked ? 1 : 0]
                                                setKycDetails(currKycDetails)
                                            }}
                                            /></span> : ''
                                        }
                                        {/* Question */}
                                        {`${customerRiskQuestions[eachQuestion].value} ${(kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].rating == 1 ? 
                                            'Low Risk' : kycDetails.customerRisk[eachQuestion].rating == 2 ? 'Medium Risk' : kycDetails.customerRisk[eachQuestion].rating == 3 ? 'High Risk' : 'No Rating')}`}
                                        {/* Radio Buttons */}
                                        {
                                            customerRiskQuestions[eachQuestion].radioRiskMapping && customerRiskQuestions[eachQuestion].radioRiskMapping.map((eachSelection) => {
                                                return <span><GreenRadio 
                                                    checked={kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].rating == eachSelection.rating}
                                                    value={eachSelection.rating}
                                                    onChange={(e) => {
                                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                        currKycDetails.customerRisk[eachQuestion].rating = parseInt(e.target.value)
                                                        setKycDetails(currKycDetails)
                                                    }}
                                                />{eachSelection.value}</span>
                                            })
                                        }
                                        {/* Free Text */}
                                        { customerRiskQuestions[eachQuestion] && customerRiskQuestions[eachQuestion].otherInfoQns && <p>{customerRiskQuestions[eachQuestion].otherInfoQns}</p> }
                                        { customerRiskQuestions[eachQuestion] && customerRiskQuestions[eachQuestion].otherInfoQns && <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(kycDetails.customerRisk && kycDetails.customerRisk[eachQuestion] && kycDetails.customerRisk[eachQuestion].otherInfo) || ''}
                                            onChange={(e) => {
                                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                currKycDetails.customerRisk[eachQuestion].otherInfo = e.target.value
                                                setKycDetails(currKycDetails)
                                            }} 
                                        />}
                                    </div>
                                })
                            }
                    </Grid>}
                    {/* Transaction Risk */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Transaction Risk</Typography>
                        {/* IF OLD KYC */}
                        {kycDetails.isCompleted && kycDetails.transactionRisk && Object.keys(kycDetails.transactionRisk).map((eachQuestion) => {
                            return <div><hr/><Grid container spacing={2}>
                                <Grid item md={11} xs={11} sm={11}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].value}
                                    </Typography>
                                </Grid>
                                {/* If it exists in the questionaire show the binary answer */}
                                {transactionRiskQuestions[eachQuestion] && transactionRiskQuestions[eachQuestion].binaryRiskMapping && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Binary Answer</Typography>
                                    {kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No'}
                                </Grid>}
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Risk Rating</Typography>
                                    {kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].rating == 0 ? 'No Rating': ''}
                                    {kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].rating == 1 ? 'Low Risk': ''}
                                    {kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].rating == 2 ? 'Medium Risk': ''}
                                    {kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].rating == 3 ? 'High Risk': ''}
                                </Grid>
                                {kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].otherInfo && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Other Info</Typography>
                                    {kycDetails.transactionRisk[eachQuestion].otherInfo}
                                </Grid>}
                            </Grid></div>
                        })}
                        {/* IF NEW KYC */}
                        { !kycDetails.isCompleted && Object.keys(transactionRiskQuestions).map((eachQuestion) => {
                            return <div>
                                <hr/>
                                {/* Yes No Answer, Binary Answer */}
                                {transactionRiskQuestions[eachQuestion].binaryRiskMapping ? <span>
                                    {(kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No')}
                                    <GreenSwitch 
                                    checked={(kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].binaryAnswer) || false}
                                    onChange={(e) => {
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.transactionRisk[eachQuestion].binaryAnswer = e.target.checked
                                        // set rating based on binaryRiskMapping
                                        currKycDetails.transactionRisk[eachQuestion].rating = transactionRiskQuestions[eachQuestion].binaryRiskMapping[e.target.checked ? 1 : 0]
                                        setKycDetails(currKycDetails)
                                    }}
                                    /></span> : ''
                                }
                                {/* Question */}
                                {`${transactionRiskQuestions[eachQuestion].value} ${(kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].rating == 1 ? 
                                    'Low Risk' : kycDetails.transactionRisk[eachQuestion].rating == 2 ? 'Medium Risk' : kycDetails.transactionRisk[eachQuestion].rating == 3 ? 'High Risk' : 'No Rating')}`}
                                {/* Radio Buttons */}
                                {
                                    transactionRiskQuestions[eachQuestion].radioRiskMapping && transactionRiskQuestions[eachQuestion].radioRiskMapping.map((eachSelection) => {
                                        return <span><GreenRadio 
                                            checked={kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].rating == eachSelection.rating}
                                            value={eachSelection.rating}
                                            onChange={(e) => {
                                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                currKycDetails.transactionRisk[eachQuestion].rating = parseInt(e.target.value)
                                                setKycDetails(currKycDetails)
                                            }}
                                        />{eachSelection.value}</span>
                                    })
                                }
                                {/* Free Text */}
                                { transactionRiskQuestions[eachQuestion] && transactionRiskQuestions[eachQuestion].otherInfoQns && <p>{transactionRiskQuestions[eachQuestion].otherInfoQns}</p> }
                                { transactionRiskQuestions[eachQuestion] && transactionRiskQuestions[eachQuestion].otherInfoQns && <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(kycDetails.transactionRisk && kycDetails.transactionRisk[eachQuestion] && kycDetails.transactionRisk[eachQuestion].otherInfo) || ''}
                                    onChange={(e) => {
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.transactionRisk[eachQuestion].otherInfo = e.target.value
                                        setKycDetails(currKycDetails)
                                    }} 
                                />}
                            </div>
                            })
                        }
                    </Grid>
                    }
                    {/* Industry Risk */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Industry Risk</Typography>
                        {/* IF OLD KYC */}
                        {kycDetails.isCompleted && kycDetails.industryRisk && Object.keys(kycDetails.industryRisk).map((eachQuestion) => {
                            return <div><hr/><Grid container spacing={2}>
                                <Grid item md={11} xs={11} sm={11}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].value}
                                    </Typography>
                                </Grid>
                                {/* If it exists in the questionaire show the binary answer */}
                                {industryRiskQuestions[eachQuestion] && industryRiskQuestions[eachQuestion].binaryRiskMapping && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Binary Answer</Typography>
                                    {kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No'}
                                </Grid>}
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Risk Rating</Typography>
                                    {kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].rating == 0 ? 'No Rating': ''}
                                    {kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].rating == 1 ? 'Low Risk': ''}
                                    {kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].rating == 2 ? 'Medium Risk': ''}
                                    {kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].rating == 3 ? 'High Risk': ''}
                                </Grid>
                                {kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].otherInfo && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Other Info</Typography>
                                    {kycDetails.industryRisk[eachQuestion].otherInfo}
                                </Grid>}
                            </Grid></div>
                        })}
                        {/* IF NEW KYC */}
                        { !kycDetails.isCompleted && Object.keys(industryRiskQuestions).map((eachQuestion) => {
                            return <div>
                                <hr/>
                                {/* Yes No Answer, Binary Answer */}
                                {industryRiskQuestions[eachQuestion].binaryRiskMapping ? <span>
                                    {(kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No')}
                                    <GreenSwitch 
                                    checked={(kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].binaryAnswer) || false}
                                    onChange={(e) => {
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.industryRisk[eachQuestion].binaryAnswer = e.target.checked
                                        // set rating based on binaryRiskMapping
                                        currKycDetails.industryRisk[eachQuestion].rating = industryRiskQuestions[eachQuestion].binaryRiskMapping[e.target.checked ? 1 : 0]
                                        setKycDetails(currKycDetails)
                                    }}
                                    /></span> : ''
                                }
                                {/* Question */}
                                {`${industryRiskQuestions[eachQuestion].value} ${(kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].rating == 1 ? 
                                    'Low Risk' : kycDetails.industryRisk[eachQuestion].rating == 2 ? 'Medium Risk' : kycDetails.industryRisk[eachQuestion].rating == 3 ? 'High Risk' : 'No Rating')}`}
                                {/* Radio Buttons */}
                                {
                                    industryRiskQuestions[eachQuestion].radioRiskMapping && industryRiskQuestions[eachQuestion].radioRiskMapping.map((eachSelection) => {
                                        return <span><GreenRadio 
                                            checked={kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].rating == eachSelection.rating}
                                            value={eachSelection.rating}
                                            onChange={(e) => {
                                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                currKycDetails.industryRisk[eachQuestion].rating = parseInt(e.target.value)
                                                setKycDetails(currKycDetails)
                                            }}
                                        />{eachSelection.value}</span>
                                    })
                                }
                                {/* Free Text */}
                                { industryRiskQuestions[eachQuestion] && industryRiskQuestions[eachQuestion].otherInfoQns && <p>{industryRiskQuestions[eachQuestion].otherInfoQns}</p> }
                                { industryRiskQuestions[eachQuestion] && industryRiskQuestions[eachQuestion].otherInfoQns && <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(kycDetails.industryRisk && kycDetails.industryRisk[eachQuestion] && kycDetails.industryRisk[eachQuestion].otherInfo) || ''}
                                    onChange={(e) => {
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.industryRisk[eachQuestion].otherInfo = e.target.value
                                        setKycDetails(currKycDetails)
                                    }} 
                                />}
                            </div>
                            })
                        }
                    </Grid>}
                    {/* Geographical Risk */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Country and Geographical Risk</Typography>
                        {/* IF OLD KYC */}
                        {kycDetails.isCompleted && kycDetails.geographicalRisk && Object.keys(kycDetails.geographicalRisk).map((eachQuestion) => {
                            return <div><hr/><Grid container spacing={2}>
                                <Grid item md={11} xs={11} sm={11}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].value}
                                    </Typography>
                                </Grid>
                                {/* If it exists in the questionaire show the binary answer */}
                                {geographicalRiskQuestions[eachQuestion] && geographicalRiskQuestions[eachQuestion].binaryRiskMapping && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Binary Answer</Typography>
                                    {kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No'}
                                </Grid>}
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Risk Rating</Typography>
                                    {kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].rating == 0 ? 'No Rating': ''}
                                    {kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].rating == 1 ? 'Low Risk': ''}
                                    {kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].rating == 2 ? 'Medium Risk': ''}
                                    {kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].rating == 3 ? 'High Risk': ''}
                                </Grid>
                                {kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].otherInfo && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Other Info</Typography>
                                    {kycDetails.geographicalRisk[eachQuestion].otherInfo}
                                </Grid>}
                            </Grid></div>
                        })}
                        {/* IF NEW KYC */}
                        { !kycDetails.isCompleted && Object.keys(geographicalRiskQuestions).map((eachQuestion) => {
                            return <div>
                                <hr/>
                                {/* Yes No Answer, Binary Answer */}
                                {geographicalRiskQuestions[eachQuestion].binaryRiskMapping ? <span>
                                    {(kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No')}
                                    <GreenSwitch 
                                        checked={(kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].binaryAnswer) || false}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.geographicalRisk[eachQuestion].binaryAnswer = e.target.checked
                                            // set rating based on binaryRiskMapping
                                            currKycDetails.geographicalRisk[eachQuestion].rating = geographicalRiskQuestions[eachQuestion].binaryRiskMapping[e.target.checked ? 1 : 0]
                                            setKycDetails(currKycDetails)
                                        }}
                                    /></span> : ''
                                }
                                {/* Question */}
                                {`${geographicalRiskQuestions[eachQuestion].value} ${(kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].rating == 1 ? 
                                    'Low Risk' : kycDetails.geographicalRisk[eachQuestion].rating == 2 ? 'Medium Risk' : kycDetails.geographicalRisk[eachQuestion].rating == 3 ? 'High Risk' : 'No Rating')}`}
                                {/* Radio Buttons */}
                                {
                                    geographicalRiskQuestions[eachQuestion].radioRiskMapping && geographicalRiskQuestions[eachQuestion].radioRiskMapping.map((eachSelection) => {
                                        return <span><GreenRadio 
                                            checked={kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].rating == eachSelection.rating}
                                            value={eachSelection.rating}
                                            onChange={(e) => {
                                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                currKycDetails.geographicalRisk[eachQuestion].rating = parseInt(e.target.value)
                                                setKycDetails(currKycDetails)
                                            }}
                                        />{eachSelection.value}</span>
                                    })
                                }
                                {/* Free Text */}
                                { geographicalRiskQuestions[eachQuestion] && geographicalRiskQuestions[eachQuestion].otherInfoQns && <p>{geographicalRiskQuestions[eachQuestion].otherInfoQns}</p> }
                                { geographicalRiskQuestions[eachQuestion] && geographicalRiskQuestions[eachQuestion].otherInfoQns && <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(kycDetails.geographicalRisk && kycDetails.geographicalRisk[eachQuestion] && kycDetails.geographicalRisk[eachQuestion].otherInfo) || ''}
                                    onChange={(e) => {
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.geographicalRisk[eachQuestion].otherInfo = e.target.value
                                        setKycDetails(currKycDetails)
                                    }} 
                                />}
                            </div>
                            })
                        }
                    </Grid>}
                    {/* Product Service Risk */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Product and Service Risk</Typography>
                        {/* IF OLD KYC */}
                        {kycDetails.isCompleted && kycDetails.productServiceRisk && Object.keys(kycDetails.productServiceRisk).map((eachQuestion) => {
                            return <div><hr/><Grid container spacing={2}>
                                <Grid item md={11} xs={11} sm={11}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].value}
                                    </Typography>
                                </Grid>
                                {/* If it exists in the questionaire show the binary answer */}
                                {productServiceRiskQuestions[eachQuestion] && productServiceRiskQuestions[eachQuestion].binaryRiskMapping && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Binary Answer</Typography>
                                    {kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No'}
                                </Grid>}
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Risk Rating</Typography>
                                    {kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].rating == 0 ? 'No Rating': ''}
                                    {kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].rating == 1 ? 'Low Risk': ''}
                                    {kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].rating == 2 ? 'Medium Risk': ''}
                                    {kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].rating == 3 ? 'High Risk': ''}
                                </Grid>
                                {kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].otherInfo && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Other Info</Typography>
                                    {kycDetails.productServiceRisk[eachQuestion].otherInfo}
                                </Grid>}
                            </Grid></div>
                        })}
                        {/* IF NEW KYC */}
                        { !kycDetails.isCompleted && Object.keys(productServiceRiskQuestions).map((eachQuestion) => {
                            return <div>
                                <hr/>
                                {/* Yes No Answer, Binary Answer */}
                                {productServiceRiskQuestions[eachQuestion].binaryRiskMapping ? <span>
                                    {(kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No')}
                                    <GreenSwitch 
                                        checked={(kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].binaryAnswer) || false}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.productServiceRisk[eachQuestion].binaryAnswer = e.target.checked
                                            // set rating based on binaryRiskMapping
                                            currKycDetails.productServiceRisk[eachQuestion].rating = productServiceRiskQuestions[eachQuestion].binaryRiskMapping[e.target.checked ? 1 : 0]
                                            setKycDetails(currKycDetails)
                                        }}
                                    /></span> : ''
                                }
                                {/* Question */}
                                {`${productServiceRiskQuestions[eachQuestion].value} ${(kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].rating == 1 ? 
                                    'Low Risk' : kycDetails.productServiceRisk[eachQuestion].rating == 2 ? 'Medium Risk' : kycDetails.productServiceRisk[eachQuestion].rating == 3 ? 'High Risk' : 'No Rating')}`}
                                {/* Radio Buttons */}
                                {
                                    productServiceRiskQuestions[eachQuestion].radioRiskMapping && productServiceRiskQuestions[eachQuestion].radioRiskMapping.map((eachSelection) => {
                                        return <span><GreenRadio 
                                            checked={kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].rating == eachSelection.rating}
                                            value={eachSelection.rating}
                                            onChange={(e) => {
                                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                currKycDetails.productServiceRisk[eachQuestion].rating = parseInt(e.target.value)
                                                setKycDetails(currKycDetails)
                                            }}
                                        />{eachSelection.value}</span>
                                    })
                                }
                                {/* Free Text */}
                                { productServiceRiskQuestions[eachQuestion] && productServiceRiskQuestions[eachQuestion].otherInfoQns && <p>{productServiceRiskQuestions[eachQuestion].otherInfoQns}</p> }
                                { productServiceRiskQuestions[eachQuestion] && productServiceRiskQuestions[eachQuestion].otherInfoQns && <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(kycDetails.productServiceRisk && kycDetails.productServiceRisk[eachQuestion] && kycDetails.productServiceRisk[eachQuestion].otherInfo) || ''}
                                    onChange={(e) => {
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.productServiceRisk[eachQuestion].otherInfo = e.target.value
                                        setKycDetails(currKycDetails)
                                    }} 
                                />}
                            </div>
                            })
                        }
                    </Grid>
                    }
                    {/* Parent Company Risk */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Parent Company Risk</Typography>
                        <p>Where the External Third Party is a member of a group of companies (subsidiary or foreign branch), please provide the following information on the parent company.</p>
                        {/* IF OLD KYC */}
                        {kycDetails.isCompleted && kycDetails.parentCompanyRisk && Object.keys(kycDetails.parentCompanyRisk).map((eachQuestion) => {
                            return <div><hr/><Grid container spacing={2}>
                                <Grid item md={11} xs={11} sm={11}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].value}
                                    </Typography>
                                </Grid>
                                {/* If it exists in the questionaire show the binary answer */}
                                {parentCompanyRiskQuestions[eachQuestion] && parentCompanyRiskQuestions[eachQuestion].binaryRiskMapping && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Binary Answer</Typography>
                                    {kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No'}
                                </Grid>}
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Risk Rating</Typography>
                                    {kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].rating == 0 ? 'No Rating': ''}
                                    {kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].rating == 1 ? 'Low Risk': ''}
                                    {kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].rating == 2 ? 'Medium Risk': ''}
                                    {kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].rating == 3 ? 'High Risk': ''}
                                </Grid>
                                {kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].otherInfo && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Other Info</Typography>
                                    {kycDetails.parentCompanyRisk[eachQuestion].otherInfo}
                                </Grid>}
                            </Grid></div>
                        })}
                        {/* IF NEW KYC */}
                        { !kycDetails.isCompleted && Object.keys(parentCompanyRiskQuestions).map((eachQuestion) => {
                            return <div>
                                <hr/>
                                {/* Yes No Answer, Binary Answer */}
                                {parentCompanyRiskQuestions[eachQuestion].binaryRiskMapping ? <span>
                                    {(kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].binaryAnswer ? 'Yes' : 'No')}
                                    <GreenSwitch 
                                        checked={(kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].binaryAnswer) || false}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.parentCompanyRisk[eachQuestion].binaryAnswer = e.target.checked
                                            // set rating based on binaryRiskMapping
                                            currKycDetails.parentCompanyRisk[eachQuestion].rating = parentCompanyRiskQuestions[eachQuestion].binaryRiskMapping[e.target.checked ? 1 : 0]
                                            setKycDetails(currKycDetails)
                                        }}
                                    /></span> : ''
                                }
                                {/* Question */}
                                {`${parentCompanyRiskQuestions[eachQuestion].value} ${(kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].rating == 1 ? 
                                    'Low Risk' : kycDetails.parentCompanyRisk[eachQuestion].rating == 2 ? 'Medium Risk' : kycDetails.parentCompanyRisk[eachQuestion].rating == 3 ? 'High Risk' : 'No Rating')}`}
                                {/* Radio Buttons */}
                                {
                                    parentCompanyRiskQuestions[eachQuestion].radioRiskMapping && parentCompanyRiskQuestions[eachQuestion].radioRiskMapping.map((eachSelection) => {
                                        return <span><GreenRadio 
                                            checked={kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].rating == eachSelection.rating}
                                            value={eachSelection.rating}
                                            onChange={(e) => {
                                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                currKycDetails.parentCompanyRisk[eachQuestion].rating = parseInt(e.target.value)
                                                setKycDetails(currKycDetails)
                                            }}
                                        />{eachSelection.value}</span>
                                    })
                                }
                                {/* Free Text */}
                                { parentCompanyRiskQuestions[eachQuestion] && parentCompanyRiskQuestions[eachQuestion].otherInfoQns && <p>{parentCompanyRiskQuestions[eachQuestion].otherInfoQns}</p> }
                                { parentCompanyRiskQuestions[eachQuestion] && parentCompanyRiskQuestions[eachQuestion].otherInfoQns && <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(kycDetails.parentCompanyRisk && kycDetails.parentCompanyRisk[eachQuestion] && kycDetails.parentCompanyRisk[eachQuestion].otherInfo) || ''}
                                    onChange={(e) => {
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.parentCompanyRisk[eachQuestion].otherInfo = e.target.value
                                        setKycDetails(currKycDetails)
                                    }} 
                                />}
                            </div>
                            })
                        }
                    </Grid>
                    }
                    {/* Regulatory Actions/Proceedings */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Regulatory Actions/Proceedings</Typography>
                        {/* IF OLD KYC */}
                        {kycDetails.isCompleted && kycDetails.regulatoryProceedings && Object.keys(kycDetails.regulatoryProceedings).map((eachQuestion) => {
                            return <div><hr/><Grid container spacing={2}>
                                <Grid item md={11} xs={11} sm={11}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].value}
                                    </Typography>
                                </Grid>
                                {/* If it exists in the questionaire show the binary answer */}
                                {regulatoryProceedingsQuestions[eachQuestion] && regulatoryProceedingsQuestions[eachQuestion].binaryRiskMapping && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Binary Answer</Typography>
                                    {kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].binaryAnswer ? 'Yes' : 'No'}
                                </Grid>}
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Risk Rating</Typography>
                                    {kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].rating == 0 ? 'No Rating': ''}
                                    {kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].rating == 1 ? 'Low Risk': ''}
                                    {kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].rating == 2 ? 'Medium Risk': ''}
                                    {kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].rating == 3 ? 'High Risk': ''}
                                </Grid>
                                {kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].otherInfo && <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Other Info</Typography>
                                    {kycDetails.regulatoryProceedings[eachQuestion].otherInfo}
                                </Grid>}
                            </Grid></div>
                        })}
                        {/* IF NEW KYC */}
                        { !kycDetails.isCompleted && Object.keys(regulatoryProceedingsQuestions).map((eachQuestion) => {
                            return <div>
                                <hr/>
                                {/* Yes No Answer, Binary Answer */}
                                {regulatoryProceedingsQuestions[eachQuestion].binaryRiskMapping ? <span>
                                    {(kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].binaryAnswer ? 'Yes' : 'No')}
                                    <GreenSwitch 
                                        checked={(kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].binaryAnswer) || false}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.regulatoryProceedings[eachQuestion].binaryAnswer = e.target.checked
                                            // set rating based on binaryRiskMapping
                                            currKycDetails.regulatoryProceedings[eachQuestion].rating = regulatoryProceedingsQuestions[eachQuestion].binaryRiskMapping[e.target.checked ? 1 : 0]
                                            setKycDetails(currKycDetails)
                                        }}
                                    /></span> : ''
                                }
                                {/* Question */}
                                {`${regulatoryProceedingsQuestions[eachQuestion].value} ${(kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].rating == 1 ? 
                                    'Low Risk' : kycDetails.regulatoryProceedings[eachQuestion].rating == 2 ? 'Medium Risk' : kycDetails.regulatoryProceedings[eachQuestion].rating == 3 ? 'High Risk' : 'No Rating')}`}
                                {/* Radio Buttons */}
                                {
                                    regulatoryProceedingsQuestions[eachQuestion].radioRiskMapping && regulatoryProceedingsQuestions[eachQuestion].radioRiskMapping.map((eachSelection) => {
                                        return <span><GreenRadio 
                                            checked={kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].rating == eachSelection.rating}
                                            value={eachSelection.rating}
                                            onChange={(e) => {
                                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                currKycDetails.regulatoryProceedings[eachQuestion].rating = parseInt(e.target.value)
                                                setKycDetails(currKycDetails)
                                            }}
                                        />{eachSelection.value}</span>
                                    })
                                }
                                {/* Free Text */}
                                { regulatoryProceedingsQuestions[eachQuestion] && regulatoryProceedingsQuestions[eachQuestion].otherInfoQns && <p>{regulatoryProceedingsQuestions[eachQuestion].otherInfoQns}</p> }
                                { regulatoryProceedingsQuestions[eachQuestion] && regulatoryProceedingsQuestions[eachQuestion].otherInfoQns && <OutlinedInputMod 
                                    maxWidth={'md'} 
                                    fullWidth
                                    value={(kycDetails.regulatoryProceedings && kycDetails.regulatoryProceedings[eachQuestion] && kycDetails.regulatoryProceedings[eachQuestion].otherInfo) || ''}
                                    onChange={(e) => {
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.regulatoryProceedings[eachQuestion].otherInfo = e.target.value
                                        setKycDetails(currKycDetails)
                                    }} 
                                />}
                            </div>
                            })
                        }
                    </Grid>
                    }
                    {/* Additional Files */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Additional Files</Typography>
                        <hr/>
                        {/* Uploaded Files to take care of deleting files and downloading files */}
                        {kycDetails && kycDetails.additionalFiles && kycDetails.additionalFiles && kycDetails.additionalFiles.map((eachFile, fileIndex) => {
                            return <div>
                                <Typography component={'span'}>{eachFile.name}</Typography>
                                {/* Delete */}
                                {!kycDetails.isCompleted && <IconButton onClick={() => { 
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.additionalFiles = currKycDetails.additionalFiles.filter((targetFile, idx) => {
                                            if (idx == fileIndex) return false
                                            return true
                                        })
                                        setKycDetails(currKycDetails)
                                    }} component="span">
                                    <DeleteOutlineIcon />
                                </IconButton>}
                                {/* Download */}
                                <IconButton 
                                    onClick={() => { downloadFile(eachFile.key)}} 
                                    component="span"
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                        })}
                        <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'additionalFiles')}} id={`additionalFiles-button-file`} type="file" />
                        <label htmlFor="additionalFiles-button-file">
                            {!kycDetails.isCompleted && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                <CloudUploadIcon />
                            </IconButton>}
                        </label>
                    </Grid>
                    }
                    {/* Final Risk Rating */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Final Risk Rating</Typography>
                        <hr/>
                        {
                            finalRiskRatingQuestions.finalRiskRating && finalRiskRatingQuestions.finalRiskRating.radioRiskMapping.map((eachRating) => {
                                return <span><GreenRadio 
                                    disabled={kycDetails.isCompleted}
                                    checked={kycDetails.finalRiskRating == eachRating.rating}
                                    value={eachRating.rating}
                                    onChange={(e) => {
                                        let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                        currKycDetails.finalRiskRating = parseInt(e.target.value)
                                        setKycDetails(currKycDetails)
                                    }}
                                />{eachRating.value}</span>
                            })
                        }

                    </Grid>
                    }
                    {/* Enhanced Due Diliegence */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Enhanced Due Diliegence</Typography>
                        {<span className={classes.showMore} onClick={() => { 
                            if (showEdd) {
                                setShowEdd(false)
                            } else {
                                setShowEdd(true)
                            }
                        }}>Show {showEdd ? 'Less': 'More'}</span>}
                        <hr/>
                        {
                            showEdd && <Grid container spacing={3}>
                                {/* SOW SOF */}
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>External Third Party source of wealth and source of funds</Typography>
                                    <TextareaAutosize
                                        disabled={kycDetails.isCompleted} 
                                        aria-label="minimum height" 
                                        className={classes.textArea}
                                        value={(kycDetails.enhancedDueDiligence && kycDetails.enhancedDueDiligence.sourceOfWealthSourceOfFunds) || ''}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.enhancedDueDiligence = currKycDetails.enhancedDueDiligence || { sourceOfWealthSourceOfFunds: '' }
                                            currKycDetails.enhancedDueDiligence.sourceOfWealthSourceOfFunds = e.target.value
                                            setKycDetails(currKycDetails)
                                        }} 
                                        rowsMin={3} 
                                    />
                                </Grid>
                                {/* Inddividual Risk */}
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Identified high risk individual’s source of wealth and source of funds (e.g. PEP)</Typography>
                                    <TextareaAutosize 
                                        disabled={kycDetails.isCompleted} 
                                        aria-label="minimum height" 
                                        className={classes.textArea}
                                        value={(kycDetails.enhancedDueDiligence && kycDetails.enhancedDueDiligence.individualSourceOfWealthSourceOfFunds) || ''}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.enhancedDueDiligence = currKycDetails.enhancedDueDiligence || { individualSourceOfWealthSourceOfFunds: '' }
                                            currKycDetails.enhancedDueDiligence.individualSourceOfWealthSourceOfFunds = e.target.value
                                            setKycDetails(currKycDetails)
                                        }} 
                                        rowsMin={3} 
                                    />
                                </Grid>
                                {/* Physical Assessment */}
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Physical / Location Assessment</Typography>
                                    <TextareaAutosize 
                                        disabled={kycDetails.isCompleted} 
                                        aria-label="minimum height" 
                                        className={classes.textArea}
                                        value={(kycDetails.enhancedDueDiligence && kycDetails.enhancedDueDiligence.physicalLocationAssessment) || ''}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.enhancedDueDiligence = currKycDetails.enhancedDueDiligence || { physicalLocationAssessment: '' }
                                            currKycDetails.enhancedDueDiligence.physicalLocationAssessment = e.target.value
                                            setKycDetails(currKycDetails)
                                        }} 
                                        rowsMin={3} 
                                    />
                                </Grid>
                                {/* Others */}
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Others</Typography>
                                    <TextareaAutosize 
                                        disabled={kycDetails.isCompleted} 
                                        aria-label="minimum height" 
                                        className={classes.textArea}
                                        value={(kycDetails.enhancedDueDiligence && kycDetails.enhancedDueDiligence.others) || ''}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.enhancedDueDiligence = currKycDetails.enhancedDueDiligence || { others: '' }
                                            currKycDetails.enhancedDueDiligence.others = e.target.value
                                            setKycDetails(currKycDetails)
                                        }} 
                                        rowsMin={3} 
                                    />
                                </Grid>
                                {/* Documents */}
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Enhanced Due Diligence Files</Typography>
                                    <hr/>
                                </Grid>
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Proof of Address</Typography>
                                    <GreenCheckbox
                                        disabled={kycDetails.isCompleted} 
                                        checked={(kycDetails.enhancedDueDiligence && kycDetails.enhancedDueDiligence.documents && kycDetails.enhancedDueDiligence.documents.proofOfAddress) || false}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.enhancedDueDiligence = currKycDetails.enhancedDueDiligence || { documents: { } }
                                            currKycDetails.enhancedDueDiligence.documents = currKycDetails.enhancedDueDiligence.documents || { }
                                            currKycDetails.enhancedDueDiligence.documents.proofOfAddress = e.target.checked
                                            setKycDetails(currKycDetails)
                                        }}
                                    />
                                </Grid>
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Proof of Source of Wealth/Funds</Typography>
                                    <GreenCheckbox
                                        disabled={kycDetails.isCompleted} 
                                        checked={(kycDetails.enhancedDueDiligence && kycDetails.enhancedDueDiligence.documents && kycDetails.enhancedDueDiligence.documents.proofOfSourceOfWealthFunds) || false}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.enhancedDueDiligence = currKycDetails.enhancedDueDiligence || { documents: { } }
                                            currKycDetails.enhancedDueDiligence.documents = currKycDetails.enhancedDueDiligence.documents || { }
                                            currKycDetails.enhancedDueDiligence.documents.proofOfSourceOfWealthFunds = e.target.checked
                                            setKycDetails(currKycDetails)
                                        }}
                                    />
                                </Grid>
                                <Grid item md={4} xs={4} sm={4}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Website Screening Reports</Typography>
                                    <GreenCheckbox
                                        disabled={kycDetails.isCompleted} 
                                        checked={(kycDetails.enhancedDueDiligence && kycDetails.enhancedDueDiligence.documents && kycDetails.enhancedDueDiligence.documents.websiteScreenReports) || false}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            currKycDetails.enhancedDueDiligence = currKycDetails.enhancedDueDiligence || { documents: { } }
                                            currKycDetails.enhancedDueDiligence.documents = currKycDetails.enhancedDueDiligence.documents || { }
                                            currKycDetails.enhancedDueDiligence.documents.websiteScreenReports = e.target.checked
                                            setKycDetails(currKycDetails)
                                        }}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    {/* Uploaded Files to take care of deleting files and downloading files */}
                                    {kycDetails && kycDetails.enhancedDueDiligence && kycDetails.enhancedDueDiligence.documents && kycDetails.enhancedDueDiligence.documents.eddFiles && 
                                        kycDetails.enhancedDueDiligence.documents.eddFiles.map((eachFile, fileIndex) => {
                                        return <div>
                                            <Typography component={'span'}>{eachFile.name}</Typography>
                                            {/* Delete */}
                                            {!kycDetails.isCompleted && <IconButton onClick={() => { 
                                                    let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                    currKycDetails.enhancedDueDiligence.documents.eddFiles = currKycDetails.enhancedDueDiligence.documents.eddFiles.filter((targetFile, idx) => {
                                                        if (idx == fileIndex) return false
                                                        return true
                                                    })
                                                    setKycDetails(currKycDetails)
                                                }} component="span">
                                                <DeleteOutlineIcon />
                                            </IconButton>}
                                            {/* Download */}
                                            <IconButton 
                                                onClick={() => { downloadFile(eachFile.key)}} 
                                                component="span"
                                            >
                                                <CloudDownloadIcon />
                                            </IconButton>
                                        </div>
                                    })}
                                    <input className={classes.hiddeninput} onChange={(e) => { uploadFile(e.target.files, 'eddFiles')}} id={`eddFiles-button-file`} type="file" />
                                    <label htmlFor="eddFiles-button-file">
                                        {!kycDetails.isCompleted && <IconButton disabled={isLoading} aria-label="upload file" component="span">
                                            <CloudUploadIcon />
                                        </IconButton>}
                                    </label>
                                </Grid>
                            </Grid>
                        }
                    </Grid>}
                    {/* KYC Version */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>KYC Version</Typography>
                        <OutlinedInputMod 
                            disabled={kycDetails.isCompleted}
                            maxWidth={'md'} 
                            fullWidth
                            value={(kycDetails.version) || ''}
                            onChange={async (e) => {
                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                currKycDetails.version = e.target.value
                                setKycDetails(currKycDetails)
                            }} 
                        />
                    </Grid>
                    }
                    {/* Team Members */}
                    {<Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Team Members</Typography>
                        {/* IF OLD KYC, check if completed */}
                        {kycDetails.isCompleted && Object.keys(teamMemberQuestions).map((eachMember) => {
                            return <div><hr/><Grid container spacing={3}>
                                <Grid item md={12} xs={12} sm={12}>
                                    {teamMemberQuestions[eachMember].value}: {kycDetails[eachMember] && kycDetails[eachMember].name} <br/><br/>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails[eachMember] && kycDetails[eachMember].userId && kycDetails[eachMember].signature ? `Digitally Signed by UserID: ${kycDetails[eachMember].userId}` : ''}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails[eachMember] && kycDetails[eachMember].designation ? `Designation: ${kycDetails[eachMember].designation}` : ''}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails[eachMember] && kycDetails[eachMember].comments ? `Comments: ${kycDetails[eachMember].comments}` : ''}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        {kycDetails[eachMember] && kycDetails[eachMember].place ? `Place: ${kycDetails[eachMember].place}` : ''}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Date: {kycDetails[eachMember] && kycDetails[eachMember].date ? `${ new Date(kycDetails[eachMember].date).toString()}` : ''}
                                    </Typography>
                                </Grid>
                            </Grid></div>
                        })}
                        {/* UnCompleted KYC */}
                        { !kycDetails.isCompleted && Object.keys(teamMemberQuestions).map((eachMember) => {
                            return <Grid container spacing={3}>
                                <hr/>
                                <Grid item md={12} xs={12} sm={12}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>{teamMemberQuestions[eachMember].value}
                                        &nbsp;&nbsp;&nbsp;
                                        <ButtonMod
                                            onClick={() => {
                                                let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                                let currentMember = currKycDetails[eachMember] || {}
                                                currentMember.userId = currentUser.id
                                                currentMember.name = currentUser.firstName + " " + currentUser.lastName
                                                currentMember.date = new Date().toISOString();
                                                currentMember.signature = window.localStorage.libJwt;
                                                currKycDetails[eachMember] = currentMember
                                                setKycDetails(currKycDetails)
                                            }}
                                        >Set As Me</ButtonMod> &nbsp;&nbsp;&nbsp; 
                                        {(kycDetails[eachMember] && kycDetails[eachMember].userId && kycDetails[eachMember].signature ? `Digitally Signed By UserID: ${kycDetails[eachMember].userId}` : '')}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Name</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(kycDetails[eachMember] && kycDetails[eachMember].name) || ''}
                                        onChange={async (e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            let targetMember = currKycDetails[eachMember] || {}
                                            targetMember.name = e.target.value
                                            targetMember.date = new Date().toISOString();
                                            currKycDetails[eachMember] = targetMember
                                            setKycDetails(currKycDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Designation</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(kycDetails[eachMember] && kycDetails[eachMember].designation) || ''}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            let targetMember = currKycDetails[eachMember] || {}
                                            targetMember.designation = e.target.value
                                            targetMember.date = new Date().toISOString();
                                            currKycDetails[eachMember] = targetMember
                                            setKycDetails(currKycDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Comments</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(kycDetails[eachMember] && kycDetails[eachMember].comments) || ''}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            let targetMember = currKycDetails[eachMember] || {}
                                            targetMember.comments = e.target.value
                                            targetMember.date = new Date().toISOString();
                                            currKycDetails[eachMember] = targetMember
                                            setKycDetails(currKycDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>Place</Typography>
                                    <OutlinedInputMod 
                                        maxWidth={'md'} 
                                        fullWidth
                                        value={(kycDetails[eachMember] && kycDetails[eachMember].place) || ''}
                                        onChange={(e) => {
                                            let currKycDetails = JSON.parse(JSON.stringify(kycDetails))
                                            let targetMember = currKycDetails[eachMember] || {}
                                            targetMember.place = e.target.value
                                            targetMember.date = new Date().toISOString();
                                            currKycDetails[eachMember] = targetMember
                                            setKycDetails(currKycDetails)
                                        }} 
                                    />
                                </Grid>
                                <Grid item md={6} xs={6} sm={6}>
                                    Date: {kycDetails[eachMember] && kycDetails[eachMember].date ? new Date(kycDetails[eachMember].date).toString() : '' }
                                </Grid>
                            </Grid>
                            })
                        }
                    </Grid>
                    }
                    <Grid item md={11} xs={11} sm={11}>
                        {kycDetails && !kycDetails.id && <ButtonMod onClick={() => {
                            createKyc({ organizationId: organization.id}, kycDetails)
                            setKycDialog(false)
                            setKycDetails({ 
                                organization: organization, 
                                customerRisk: customerRiskQuestions, 
                                transactionRisk: transactionRiskQuestions,
                                industryRisk: industryRiskQuestions,
                                geographicalRisk: geographicalRiskQuestions,
                                productServiceRisk: productServiceRiskQuestions,
                                parentCompanyRisk: parentCompanyRiskQuestions,
                                regulatoryProceedings: regulatoryProceedingsQuestions
                            })
                        }}>
                            CREATE
                        </ButtonMod>}
                        {kycDetails && kycDetails.id && !kycDetails.isCompleted && <ButtonMod onClick={() => {
                            deleteKyc({ kycId: kycDetails.id})
                            setKycDialog(false)
                            setKycDetails({
                                organization: organization, 
                                customerRisk: customerRiskQuestions, 
                                transactionRisk: transactionRiskQuestions,
                                industryRisk: industryRiskQuestions,
                                geographicalRisk: geographicalRiskQuestions,
                                productServiceRisk: productServiceRiskQuestions,
                                parentCompanyRisk: parentCompanyRiskQuestions,
                                regulatoryProceedings: regulatoryProceedingsQuestions
                            })
                        }}>
                            DELETE
                        </ButtonMod>}
                        {kycDetails && kycDetails.id && !kycDetails.isCompleted && <ButtonMod onClick={() => {
                            console.log('kycDetails -> ', kycDetails)
                            updateKyc({ kycId: kycDetails.id}, kycDetails)
                            setKycDialog(false)
                            setKycDetails({
                                organization: organization, 
                                customerRisk: customerRiskQuestions, 
                                transactionRisk: transactionRiskQuestions,
                                industryRisk: industryRiskQuestions,
                                geographicalRisk: geographicalRiskQuestions,
                                productServiceRisk: productServiceRiskQuestions,
                                parentCompanyRisk: parentCompanyRiskQuestions,
                                regulatoryProceedings: regulatoryProceedingsQuestions
                            })
                        }}>
                            SAVE
                        </ButtonMod>}
                        {kycDetails && kycDetails.id && !kycDetails.isCompleted && <ButtonMod onClick={() => {
                            const updateKycDetails = JSON.parse(JSON.stringify(kycDetails))
                            updateKycDetails.isCompleted = true;
                            updateKyc({ kycId: kycDetails.id}, updateKycDetails)
                            setKycDialog(false)
                            setKycDetails({
                                organization: organization, 
                                customerRisk: customerRiskQuestions, 
                                transactionRisk: transactionRiskQuestions,
                                industryRisk: industryRiskQuestions,
                                geographicalRisk: geographicalRiskQuestions,
                                productServiceRisk: productServiceRiskQuestions,
                                parentCompanyRisk: parentCompanyRiskQuestions,
                                regulatoryProceedings: regulatoryProceedingsQuestions
                            })
                        }}>
                            COMPLETE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Know Your Customer</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>KYC Version</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchVersion) || ''}
                                            onChange={(e) => setSearchVersion(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Completed? y/n</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchIsCompleted) || ''}
                                            onChange={(e) => setSearchIsCompleted(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Client Advisor Name</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchClientAdvisorName) || ''}
                                            onChange={(e) => setSearchClientAdvisorName(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Underwriter Name</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchUnderwriterName) || ''}
                                            onChange={(e) => setSearchUnderwriterName(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Compliance Officer Name</Typography>
                                        <OutlinedInputMod 
                                            maxWidth={'md'} 
                                            fullWidth
                                            value={(searchComplianceOfficerName) || ''}
                                            onChange={(e) => setSearchComplianceOfficerName(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody(null, true)
                                                postKYCsSearch(esSearchReqBody)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setKycDialog(true)
                                setKycDetails({ 
                                    organization: organization, 
                                    customerRisk: customerRiskQuestions, 
                                    transactionRisk: transactionRiskQuestions,
                                    industryRisk: industryRiskQuestions,
                                    geographicalRisk: geographicalRiskQuestions,
                                    productServiceRisk: productServiceRiskQuestions,
                                    parentCompanyRisk: parentCompanyRiskQuestions,
                                    regulatoryProceedings: regulatoryProceedingsQuestions
                                })
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody()
                                console.log('esSearchReqBody -> ', esSearchReqBody)
                                postKYCsSearchAfter(esSearchReqBody)
                            }}
                            onPageSizeChange={(params) =>{
                                if (searchSize === params.pageSize) return
                                setSearchSize(params.pageSize)
                                setPage(1);
                                setLoadedPage(1)
                                let esSearchReqBody = constructSearchReqBody(params.pageSize, true)
                                postKYCsSearch(esSearchReqBody)
                            }}
                            rowCount={totalHitsKYCs}
                            rowsPerPageOptions={[10,25,50,100]}
                            pageSize={10}
                            pagination
                            columns={[
                                { 
                                    field: 'id',
                                    headerName: 'KYC ID',
                                    width: 140,
                                    valueGetter: (params) => `${(params && params.row && params.row.id) || ""}`,
                                },
                                { 
                                    field: 'version',
                                    headerName: 'Ver.',
                                    width: 70,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.version) || ""}`
                                    },
                                },
                                { 
                                    field: 'isCompleted',
                                    headerName: 'Completed',
                                    width: 70,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.isCompleted && "YES") || "NO"}`
                                    },
                                },
                                { 
                                    field: 'finalRiskRating',
                                    headerName: 'Final Risk Rating',
                                    width: 140,
                                    valueGetter: (params) => {
                                        const finalRating = (params && params.row && params.row.finalRiskRating) || -1
                                        switch (finalRating) {
                                            case 1:
                                                return "LOW RISK"
                                            case 2:
                                                return "MEDIUM RISK"
                                            case 3:
                                                return "HIGH RISK"
                                            default:
                                                return "No Rating"
                                        }
                                    },
                                },
                                { 
                                    field: 'clientAdvisor.name',
                                    headerName: 'Client Advisor',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.clientAdvisor && params.row.clientAdvisor.name) || ""}`
                                    },
                                },
                                { 
                                    field: 'underwriter.name',
                                    headerName: 'Underwriter',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.underwriter && params.row.underwriter.name) || ""}`
                                    },
                                },
                                { 
                                    field: 'complianceOfficer.name',
                                    headerName: 'Compliance Officer',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.complianceOfficer && params.row.complianceOfficer.name) || ""}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At (UTC)',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'updatedAt',
                                    headerName: 'Updated At (UTC)',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.updatedAt) {
                                            let date = new Date(params.row.updatedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                            ]}
                            rows={allKycs}
                            onRowClick={async (rowParams) => {
                                console.log('rowParams.row -> ', rowParams.row);
                                setKycDetails(rowParams.row)
                                setKycDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default KnowYourCustomer