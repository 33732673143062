import { CALL_BILLINGS_APPSYNC_API } from '../app/middleware/callBillingsAppSync.js';

export const postBillingsSearch = (reqBody) => ({
    types: ['POST_BILLINGS_SEARCH_REQUEST', 'POST_BILLINGS_SEARCH_SUCCESS', 'POST_BILLINGS_SEARCH_FAILURE'],
    [CALL_BILLINGS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const postBillingsSearchAfter = (reqBody) => ({
    types: ['POST_BILLINGS_SEARCH_AFTER_REQUEST', 'POST_BILLINGS_SEARCH_AFTER_SUCCESS', 'POST_BILLINGS_SEARCH_AFTER_FAILURE'],
    [CALL_BILLINGS_APPSYNC_API]: '/',
    method: 'post',
    reqBody: reqBody
})

export const clearBillingsSearch = () => ({
    type: 'CLEAR_BILLINGS_SEARCH'
})

