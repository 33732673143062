import React, {useLayoutEffect, useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import { feeType } from '../app/globals';
import { XGrid, LicenseInfo } from '@material-ui/x-grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MATERIAL_LICENSE_KEY,
);

const useStyles = makeStyles(theme => ({
    accordion: {
        marginTop: '2em'
    },
    xgrid: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer'
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
      },
    select: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #68d0af', // 1fa595 
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    circularProgress: {
        color: '#68d0af'
    },
    topMargin: {
        marginTop: '64px',
        marginLeft: '1.7em',
        marginRight: '1.7em',
    },
    Title: {
        color: '#0c3336'
    },
    contractlist: {
        margin: '5% auto',
        position: 'relative',
        maxWidth: '96%'
    },
    formContainer: {
        margin: '2em'
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const OutlinedInputMod = withStyles({
    root: {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px',
        },
    },
})(OutlinedInput);

const ButtonMod = withStyles({
    root: {
        marginRight: '1em',
        marginTop: '1em',
        backgroundColor: '#68d0af',
        color: '#ffffff',
        '&.MuiButton-root:hover': {
            backgroundColor: '#76e6c3' // 1fa595
        },
        verticalAlign: 'baseline'
    },
})(Button);

const GreenAutocomplete = withStyles({
    root: {

    },
})(Autocomplete);

const GreenTextField = withStyles({
    root: {
        '& .MuiFilledInput-underline:after': {
            borderColor: '#68d0af', // 1fa595
            borderWidth: '2px'
        }
    }
})(TextField);

const Balances = (props) => {
    const classes = useStyles(props)
    const { location: {state: {organizationId =''}={}}, 
        openDrawer, toggleDrawer, organization, isLoading, clearBalances, getEnumeration, currencies, clearAlertMessage, alertMessage,
        createBalance, searchBalances, balancesNextToken, balances, searchAfterBalances } = props
    const [balanceDialog, setBalanceDialog] = useState(false);
    const [balanceDetails, setBalanceDetails] = useState({});
    const [searchFrom, setSearchFrom] = useState(0);
    const [searchSize, setSearchSize] = useState(10);
    const [searchCurrency, setSearchCurrency] = useState('');
    const [page, setPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);

    useLayoutEffect(() => {
        if (!openDrawer) toggleDrawer() // close it if its open
        let esSearchReqBody = constructSearchReqBody()
        searchBalances(esSearchReqBody)
        setLoadedPage(1)
        getEnumeration({enumGroup: 'country'})
        getEnumeration({enumGroup: 'currency'})
        return function cleanup() {
            clearBalances()
        };
    },[])

    useEffect(() => {
        if (organization && organization.id) {
            let esSearchReqBody = constructSearchReqBody()
            searchBalances(esSearchReqBody)
        }
    },[organization, organization.id])

    const constructSearchReqBody = (passedCurrency="", restartSearch=false) => {
        // console.log('process.env.MATERIAL_LICENSE_KEY -> ', process.env.REACT_APP_MATERIAL_LICENSE_KEY);
        if (!organization || !organization.id) return
        let targetCurrency = passedCurrency
        let initialStruct = {}
        // ${dateQuery ? `createdAt: ${dateQuery}` : ""}
        console.log('organization : ', organization.id);
        let query = `
        query listBalances {
            listBalances(
                organizationId: "${organization.id}", 
                ${passedCurrency ? `currency: "${passedCurrency}"` : ""},
                nextToken: "${(!restartSearch && balancesNextToken) ? balancesNextToken : ""}",
            ) {
              items {
                available
                createdAt
                currency
                held
                organizationId
                rollingReserve
                rollingReserveHeld
                rollingReverseAvailable
                updatedAt
              }
            }
          }
        `
        initialStruct.variables = {
            organizationId: organization.id
        }
        initialStruct.query = query
        return initialStruct
    }
    return (
    <main className={classes.topMargin}>
        {/* Loading screen */}
        <Backdrop className={classes.backdrop} open={isLoading} >
            <CircularProgress className={classes.circularProgress} />
        </Backdrop>
        {/* Dialog */}
        <Dialog
            fullWidth
            open={balanceDialog} 
            onClose={()=> {setBalanceDialog(false)}} 
            TransitionComponent={Transition}
            maxWidth={'lg'}
        >
            <CloseIcon className={classes.closeBtn} onClick={()=> {setBalanceDialog(false)}}/>
            {balanceDetails && !balanceDetails.id &&<Typography className={classes.Title} color="textSecondary" gutterBottom>Create Balance</Typography>}
            <Container className={classes.formContainer} maxWidth="lg">
                <Grid container spacing={3}>
                    {
                        balanceDetails && balanceDetails.organizationId && <Grid item md={8} xs={8} sm={8}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Organization ID: {balanceDetails.organizationId}</Typography>
                        </Grid>
                    }
                    {/* Currency */}
                    {
                        balanceDetails && balanceDetails.organizationId && <Grid item md={8} xs={8} sm={8}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Currency: &nbsp;
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code == balanceDetails.currency) {
                                        return <span>{currency.name}</span>
                                    }
                                })
                            }
                            </Typography>
                        </Grid>
                    }
                    {balanceDetails && ! balanceDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                        <Select
                            fullWidth
                            variant="outlined"
                            className={classes.select}
                            value={(balanceDetails && balanceDetails.currency) || ""}
                            onChange={(e) => {
                                setBalanceDetails({...balanceDetails, currency: e.target.value })
                            }}
                            label="Currency"
                        >
                            {
                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                    if (currency.value && currency.value.currency_alpha3_code) {
                                        return <MenuItem key={'currency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                    }
                                })
                            }
                        </Select>
                    </Grid>}
                    {/* Available */}
                    {
                        balanceDetails && balanceDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Available: {balanceDetails.available}</Typography>
                        </Grid>
                    }
                    {/* Held */}
                    {
                        balanceDetails && balanceDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Held: {balanceDetails.held}</Typography>
                        </Grid>
                    }
                    {/* rollingReserve */}
                    {
                        balanceDetails && balanceDetails.organizationId && <Grid item md={4} xs={4} sm={4}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Rolling Reserve: {balanceDetails.rollingReserve}</Typography>
                        </Grid>
                    }
                    {/* fundingIds */}
                    {
                        balanceDetails && balanceDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Funding IDs</Typography>
                            {balanceDetails.fundingIds && balanceDetails.fundingIds.length > 0 ? balanceDetails.fundingIds.map((eachId) => {
                                return <div>{eachId}</div>
                            }): 'empty'}
                        </Grid>
                    }
                    {/* rollingReserveIds */}
                    {
                        balanceDetails && balanceDetails.organizationId && <Grid item md={11} xs={11} sm={11}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Rolling Reserve IDs</Typography>
                            {balanceDetails.rollingReserveIds && balanceDetails.rollingReserveIds.length > 0 ? balanceDetails.rollingReserveIds.map((eachId) => {
                                return <div>{eachId}</div>
                            }): 'empty'}
                        </Grid>
                    }
                    {/* createdAt */}
                    {
                        balanceDetails && balanceDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Created At: {balanceDetails.createdAt && new Date(balanceDetails.createdAt).toString()}</Typography>
                        </Grid>
                    }
                    {/* updatedAt */}
                    {
                        balanceDetails && balanceDetails.organizationId && <Grid item md={6} xs={6} sm={6}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>Updated At: {balanceDetails.updatedAt && new Date(balanceDetails.updatedAt).toString()}</Typography>
                        </Grid>
                    }
                    {/* {JSON.stringify(balanceDetails)} */}
                    <Grid item md={11} xs={11} sm={11}>
                        {balanceDetails && !balanceDetails.id && <ButtonMod onClick={() => {
                            if (!organization || !organization.id) {
                                alertMessage({alertType: 'warning', alertMessage: 'No Organization Loaded'})
                                setTimeout(function () {
                                    clearAlertMessage({alertType: 'warning'})
                                }, 3000)
                            } else {
                                const currBalanceDetails = balanceDetails
                                currBalanceDetails.available = 0
                                currBalanceDetails.held = 0
                                currBalanceDetails.payable = 0
                                currBalanceDetails.organizationId = organization.id
                                createBalance(currBalanceDetails)
                            }
                            setBalanceDialog(false)
                            setBalanceDetails({})
                        }}>
                            CREATE
                        </ButtonMod>}
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
        {/* Content */}
        <Typography variant="h4" className={classes.Title}>Balances</Typography>
        <Container>
            <div className={classes.contractlist}>
                {/* Accordion */}
                <Grid container>
                    <Grid item md={11} xs={11} sm={11}>
                        <Accordion expanded={isExpanded} className={classes.accordion}>
                            <AccordionSummary
                                onClick={() => {setIsExpanded(!isExpanded)}}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <FilterListIcon/>&nbsp;&nbsp;<Typography className={classes.heading}>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>Currency</Typography>
                                        <Select
                                            fullWidth
                                            variant="outlined"
                                            className={classes.select}
                                            value={(searchCurrency) || ""}
                                            onChange={(e) => {
                                                setSearchCurrency(e.target.value)
                                            }}
                                            label="Transaction"
                                        >
                                            {
                                                currencies && currencies.length > 0 && currencies.map((currency, index) => {
                                                    if (currency.value && currency.value.currency_alpha3_code) {
                                                        return <MenuItem key={'currency'+index} value={currency.value.currency_alpha3_code}>{currency.name}</MenuItem>
                                                    }
                                                })
                                            }
                                        </Select>
                                    </Grid>
                                    <Grid item md={11} xs={11} sm={11}>
                                        <ButtonMod 
                                            onClick={() => {
                                                setPage(1);
                                                setLoadedPage(1)
                                                setSearchFrom(0);
                                                let esSearchReqBody = constructSearchReqBody()
                                                searchBalances(esSearchReqBody)
                                                setSearchCurrency('')
                                                setIsExpanded(false)
                                            }}
                                        >
                                            SEARCH
                                        </ButtonMod>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item md={1} xs={1} sm={1}>
                        <IconButton 
                            style={{color: '#68d0af', marginTop: '1em'}} 
                            aria-controls="simple-menu" 
                            aria-haspopup="true"
                            onClick={() => {
                                setBalanceDialog(true)
                                setBalanceDetails({})
                            }}
                        >
                            <AddIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* XGrid */}
                <Grid container spacing={3}>
                    <div style={{ height: 870, width: '100%', marginTop: '2em' }}>
                        <XGrid
                            className={classes.xgrid}
                            page={page}
                            onPageChange={(params) => {
                                if (page >= params.page) return
                                setPage(params.page);
                                if (params.page <= loadedPage) return
                                setLoadedPage(loadedPage + 1)
                                let esSearchReqBody = constructSearchReqBody()
                                searchAfterBalances(esSearchReqBody)
                            }}
                            hideFooterRowCount
                            rowsPerPageOptions={[10]}
                            pageSize={13}
                            pagination={balancesNextToken ? true : false}
                            columns={[
                                { 
                                    field: 'organizationId',
                                    headerName: 'Organization ID',
                                    width: 210,
                                    valueGetter: (params) => `${(params && params.row && params.row.organizationId) || ""}`,
                                },
                                { 
                                    field: 'id', // this is field was added in the reducer, which is the currency field
                                    headerName: 'Currency',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.id) || ""}`
                                    },
                                },
                                { 
                                    field: 'available',
                                    headerName: 'Available',
                                    width: 170,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.available)}`
                                    },
                                },
                                { 
                                    field: 'held',
                                    headerName: 'Held',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.held)}`
                                    },
                                },
                                { 
                                    field: 'rollingReserve',
                                    headerName: 'Rolling Reserve',
                                    width: 210,
                                    valueGetter: (params) => {
                                        return `${(params && params.row && params.row.rollingReserve)}`
                                    },
                                },
                                { 
                                    field: 'createdAt',
                                    headerName: 'Created At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.createdAt) {
                                            let date = new Date(params.row.createdAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                                { 
                                    field: 'updatedAt',
                                    headerName: 'Updated At',
                                    width: 170,
                                    valueGetter: (params) => {
                                        let dateString = ''
                                        if (params && params.row && params.row.updatedAt) {
                                            let date = new Date(params.row.updatedAt)
                                            dateString = date.toString()
                                        } 
                                        return dateString
                                    },
                                },
                            ]}
                            rows={balances}
                            onRowClick={async (rowParams) => {
                                setBalanceDetails(rowParams.row)
                                setBalanceDialog(true)
                            }}
                        />
                    </div>
                </Grid>
            </div>
        </Container>
    </main>
    )
}

export default Balances